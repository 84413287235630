import { useState, FunctionComponent, useEffect } from 'react';
import { Grid, Button, Box } from '@mui/material';
import { Typography, Dialog, Input } from './../../MaterialUiComponents';
import CertificatesApi from '../../api/certificates'
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { CertificateRights, RatingsDifference } from '../../types/certificates';
import { settings } from '../../config';

const useStyles = makeStyles(theme => ({
    approval: {
        marginTop: '2%'
    },
}));

type ProcessApprovalProps = {
    approvalSendBy: string,
    changes: string[],
    closeCertificate: Function,
    certificateId: number,
    approverId: number,
    employeeId?: number,
    certificates: CertificateRights[],
    ratingsDifference: RatingsDifference
}

type Certificate = {
    rating_name: string,
    aircraft_category_name: string,
}

const ProcessApproval: FunctionComponent<ProcessApprovalProps> = ({ employeeId, closeCertificate, certificateId, approverId, certificates, changes, approvalSendBy, ratingsDifference }) => {
    const [rejectDialog, setRejectDialog] = useState(false);
    const [approveDialog, setApproveDialog] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [showValidation, setShowValidation] = useState(false);
    const [certificatestoApprove, setCertifactesToApprove] = useState<Certificate[]>([]);

    useEffect(() => {
        // filtering the certificates that are modified and need review.
        const toApprove = certificates.filter(certificate => certificate.is_row_modified === true);
        const temp2 = toApprove.map(certificate => {
            return {
                rating_name: certificate.rating_name,
                aircraft_category_name: certificate.aircraft_category_name
            }
        });
        setCertifactesToApprove(temp2)
    }, []);

    const rejectValidation = 'You must type a reason to continue';
    const store = useSelector((state: RootState) => state);

    const onReasonEdit = (value: string) => {
        setRejectReason(value);
    }
    const rejectCertificate = () => {
        if (rejectReason === '') {
            setShowValidation(true);
        }
        else {
            CertificatesApi.QualityAssuranceReject(certificateId, approverId, rejectReason).then(res => {
                if (res.status === true) {
                    setRejectDialog(false);
                    closeCertificate();
                    sendEmailApprovalMessage(false);
                }
            });
        }
    }
    const approveCertificate = () => {
        CertificatesApi.QualityAssuranceAccept(certificateId, approverId).then(res => {
            if (res.status === true) {
                setApproveDialog(false);
                closeCertificate();
                sendEmailApprovalMessage(true);
                CertificatesApi.UpdateMxiRolesAndSkills(ratingsDifference).then(res => {});
            }
        });
    }
    const sendEmailApprovalMessage = (approved: boolean) => {
        const employeeEmail = store.employees.all_employees.find(user => user.user_id === employeeId)?.userName || "";
        
        let subject = approved ? "Certificate Approval" : "Certificate Rejection";

        if(settings.HOST !== "https://api.icelandair.is/api/maintener-backend")
        {
            subject = "TEST - " + subject;
        }
        const ratingChanges = changes.map(change => `<li>${!approved ? change.replace("NEW", "REJECTED"): change}</li>`).join('');
        const body = `
                    <!DOCTYPE html>
                    <html>
                    <head>
                        <meta charset="UTF-8">
                        <title>Certificates</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                            }
                        </style>
                    </head>
                    <body>
                        <p>Dear employee</p>
                        <p>You have following changes on your certificate:</p>
                        <ul>
                            ${ratingChanges}
                        </ul>
                        <p>For more information visit <a href="https://maintener.icelandair.is/#/certificates">Maintener</a></p>
                        <p>If you have any questions or need further information, please do not hesitate to contact your supervisor.</p>
                        <p>Best regards,</p>
                        <p>Maintener</p>
                        <br/>
                        <hr/>
                        <br/>
                        <p>Kæri starfsmaður</p>
                        <p>Þú ert með eftirfarandi breytingar á skírteininu þínu:</p>
                        <ul>
                            ${ratingChanges}
                        </ul>
                        <p>Frekari upplýsingar má finna á <a href="https://maintener.icelandair.is/#/certificates">Maintener</a></p>
                        <p>Ef þú hefur einhverjar spurningar eða þarft frekari upplýsingar skaltu ekki hika við að hafa samband við yfirmann þinn.</p>
                        <p>Bestu kveðjur,</p>
                        <p>Maintener</p>
                        <br/>
                    </body>
                    </html>`;
        if (employeeEmail !== undefined) {
            let shortenedStringWithName = approvalSendBy.split(' - ')[0];
            const approvalSentByEmail = store.employees.all_employees.find(user => user.employee_name === shortenedStringWithName)?.userName || "";
            CertificatesApi.SendEmailApprovalMessage(employeeEmail, body, subject, approvalSentByEmail).then(res => { });
        }
    }
    const classes = useStyles();
    return (
        <Grid className={classes.approval} container xs={12}>
            <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={() => setApproveDialog(true)}>Approve and publish certificate</Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained" color="secondary" onClick={() => setRejectDialog(true)}>Reject certificate</Button>
            </Grid>
            {/*Reject Dialog - Need to fix center placement in Dialog */}
            <Dialog
                visible={rejectDialog}
                max_width={"md"} title={"Are you sure you want to reject?"}
                context={
                    <div>
                        <Box display="flex" justifyContent="center" width="100%" m={1} p={1}>
                            <Input
                                label_text={"Type a reason for rejection"} value={rejectReason} help_text=""
                                onTextChange={(value: string) => onReasonEdit(value)} type="text" width={50} multiline={false} />
                        </Box>
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <Box p={1} >
                                <Button variant="contained" color="primary" onClick={() => rejectCertificate()}>Reject certificate</Button>
                            </Box>
                            <Box p={1}>
                                <Button variant="contained" color="secondary" onClick={() => setRejectDialog(false)}>Cancel</Button>
                            </Box>
                            {showValidation &&
                                <Typography variant="body1" color="error" align="center" text={rejectValidation} />
                            }
                        </Box>
                    </div>
                }
                onClose={(status: any) => setRejectDialog(false)}
                strict={false}
                show_options={false}
            />
            <Dialog
                visible={approveDialog}
                max_width={"md"} title={"Are you sure you want to approve?"}
                context={
                    <Box display="flex" justifyContent="center" m={1} p={1}>
                        <Box p={1} >
                            <Button variant="contained" color="primary" onClick={() => approveCertificate()}>Approve certificate</Button>
                        </Box>
                        <Box p={1} >
                            <Button variant="contained" color="secondary" onClick={() => setApproveDialog(false)}>Cancel</Button>
                        </Box>
                    </Box>
                }
                onClose={(status: any) => setApproveDialog(false)}
                strict={false}
                show_options={false}
            />
        </Grid>
    );
};
export default ProcessApproval;
