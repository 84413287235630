import { Grid } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { Typography } from '../../MaterialUiComponents';

function NotAuthorized() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Lock fontSize="large" color="error" />
      </Grid>
      <Grid item xs={4}>
        <Typography text="Unauthorized Access" align="center" variant="h1" />
      </Grid>
      <Grid item xs={3}>
        <Typography text="You do not have access to this part of Maintener. Please contact your superior if you believe you should be granted access." margin={[2, 0, 0, 0]} align="center" variant="subtitle1" />
      </Grid>
    </Grid>
  );
};
export default NotAuthorized