import { useEffect, useState } from 'react';
import { Grid, Button, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Input, Dialog, Tabs, LinearProgress } from '../../MaterialUiComponents';
import SkillsApi from '../../api/skillsApi';
import { Skills } from '../../types/skills';
import { Dictionary } from '../../components';
import ManageSkills from './ManageSkills';
import Abbreviations from './Abbriviations';

const useStyles = makeStyles(theme => ({
    createButton: {
        backgroundColor: '#407BAC',
        color: 'white',
        fontSize: '1em',
    }
}));

function MechanicInfo() {
    const [skill, setMechanicSkills] = useState<string>("");
    const [allSkills, setAllMechanicSkills] = useState<Skills[]>([]);
    const [showActive, setShowActive] = useState<boolean>(true);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [skillsId, setSkillsId] = useState<number>(-1);
    const [valadiationText, setValidationText] = useState(false);
    const [reason, setReason] = useState<string>("");
    const classes = useStyles();
    const [tabValue, setTabValue] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        SkillsApi.GetSkills().then(res => {
            setAllMechanicSkills(res.data);
            setSkillsId(-1);
            setValidationText(false);
            setLoading(false);
        });
    }

    const addMechanicSkill = () => {
        setLoading(true);
        SkillsApi.CreateSkill(skill).then(res => {
            refresh();
            setLoading(false);
        });
    }

    const onOpenDeleteDialog = (row: Skills) => {
        setIsDialogOpen(true);
        setSkillsId(row.Id);
    }

    const onDeleteSkill = (status: boolean) => {
        if (status === true && skillsId > -1 && reason !== "" && !valadiationText) {
            setLoading(true);
            SkillsApi.DeleteSkill(reason, skillsId).then(res => {
                refresh();
                setIsDialogOpen(false);
                setLoading(false);
            });
        }
        else if (status === true && reason === "") {
            setValidationText(true);
        }
        else if (status === false) {
            setIsDialogOpen(false);
        }
    }

    const onInputChange = (text: string) => {
        setReason(text);
        if (text !== "") {
            setValidationText(false);
        }
    }

    return (
        <Grid>
            {loading === true &&
                <Grid>
                    <LinearProgress />
                </Grid>
            }
            <Tabs
                tabNames={["All skills", "Mechanic skills", "Abbreviations"]}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                tabValue={tabValue}
            />
            {(tabValue === 0) &&
                <Grid xs={12} container alignItems="flex-start" justifyContent="center" >
                    <Grid item xs={6}>
                        <Grid container xs={12}>
                            <Grid item xs={4} lg={1}>
                                <Typography text="Current" variant="subtitle2" color="textPrimary" margin={[2, 0, 0, 0]} />
                            </Grid>
                            <Grid item xs={2} lg={1} margin={[1, 0, 0, 1]}>
                                <Switch
                                    checked={!showActive}
                                    onChange={() => setShowActive(!showActive)}
                                    color="secondary"
                                    name="checkedB"
                                />
                            </Grid>
                            <Grid item xs={2} lg={1}>
                                <Typography text="History" variant="subtitle2" color="textPrimary" margin={[2, 0, 0, 2]} />
                            </Grid>
                        </Grid>
                        <Grid xs={10} item>
                            {
                                allSkills !== undefined && allSkills.filter(type => type.Visible === showActive).map(row => (
                                    <Dictionary deleteProperty={(row: Skills) => onOpenDeleteDialog(row)} deleteButton={true} name={row.Skill} object={row} deleted={showActive === true ? false : true} id={row.Id} ></Dictionary>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid item xs={12}>
                            <br />
                            <Input
                                multiline={false}
                                label_text="Add Skill"
                                help_text="Please type in skill"
                                type="text"
                                value={skill}
                                width={50}
                                onTextChange={(value: string) => setMechanicSkills(value)}
                            />
                        </Grid>
                        <br />
                        <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addMechanicSkill()} >
                            Add Skill
                        </Button>
                    </Grid>
                </Grid>
            }
            {tabValue === 1 &&
                <ManageSkills allSkills={allSkills} loading={loading} setLoading={setLoading} />
            }
            {tabValue === 2 && 
                <Abbreviations loading={loading} setLoading={setLoading}/>
            }
            <Dialog
                visible={isDialogOpen}
                max_width={"sm"}
                title={"Delete skill"}
                context={
                    <Grid>
                        <Input
                            error={valadiationText}
                            multiline={true}
                            help_text={valadiationText ? "Reason can not be blank" : ""}
                            label_text={"Please provide a reason"}
                            value={reason}
                            onTextChange={(text: string) => onInputChange(text)}
                            type="text"
                            width={100}
                        />
                    </Grid>
                }
                onClose={(status: any) => onDeleteSkill(status)}
                strict={false}
                show_options={true}
            />
        </Grid>
    );
};
export default MechanicInfo;