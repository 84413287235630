import { useEffect, useState, FunctionComponent } from 'react';
import { Grid, TableRow, TableCell, Button, Checkbox, FormControlLabel } from '@mui/material';
import { BasicCalendar } from '../../components';
import VacationApi from './../../api/vacation';
import { CalendarVacationCell, ShiftLocation } from '../../types/vacation';
import { makeStyles } from '@mui/styles';
import { Dialog, Table, Input, Typography, Select, Snackbar } from '../../MaterialUiComponents';
import { VacationRequest } from './../../types/vacation';
import { Datepicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';


const useStyles = makeStyles(theme => ({
  applyForVacationButton: {
    marginBottom: theme.spacing(1)
  }
}));

const VacationCalendar: FunctionComponent = () => {
  const classes = useStyles();
  const [eventsEmployeeVacations, setEventsEmployeeVacations] = useState(Array<CalendarVacationCell>());
  const [departmentTypesOfVacation, setDepartmentTypesOfVacation] = useState(Array<VacationRequest>());
  const [applyForVacationDialogOpen, setApplyForVacationDialogOpen] = useState(false);
  const [cellDialogOpen, setCellDialogOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
  const [mechanicReason, setMechanicReason] = useState("");
  const [allLocations, setAllLocations] = useState(Array<string>);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [halfDayChecked, sethalfDayChecked] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  useEffect(() => {
    if (allLocations.length === 0) {
      VacationApi.GetAllVacationLocations().then(res => {
        setAllLocations(res.data.map((location: ShiftLocation) => location.location));
      });
    }
    if (selectedLocation !== "") {
      VacationApi.GetAllVacationEventsForCalendar(selectedLocation).then(res => {
        setEventsEmployeeVacations(res.data);
      });
    }
  }, [selectedLocation, applyForVacationDialogOpen, selectedDates, halfDayChecked]);

  const generateTableBody = (row: VacationRequest, index: number) => {
    const statusColor = row.status === "Approved" ? "green" : "orange";
    return (
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell>{row.created_date_text}</TableCell>
        <TableCell>{row.department_name}</TableCell>
        <TableCell>{row.created_by_employee_name}</TableCell>
        <TableCell sx={{color: statusColor}}>{row.status}</TableCell>
      </TableRow>
    )
  }
  const onCloseApplyForVacationDialog = (status: boolean) => {
    if (status === true && selectedLocation !== "") {
      VacationApi.CreateVacationRequest(selectedDates[0]!, selectedDates[1]!, mechanicReason, selectedLocation, halfDayChecked).then(res => {
        if (res.status === true) {
          setApplyForVacationDialogOpen(false);
          sethalfDayChecked(false);
        } else {
          setSnackbarMessage(res.message);
        }
      });
    }
    else {
      setApplyForVacationDialogOpen(false);
    }
  }
  const onEventClick = (event: CalendarVacationCell) => {
    VacationApi.GetVacationForTheDayByStartTime(event.start, selectedLocation).then(res => {
      if (res.status === true ) {
        setCellDialogOpen(true);
        setDepartmentTypesOfVacation(res.data);
      }
    });
  }
  const onDateChange = (ev: any) => {
    setSelectedDates(ev.value);
  };
  return (
    <Grid>
      <Select options={allLocations} selectedItem={selectedLocation} widthPercentage width={20} onSelectedItem={(e: any) => setSelectedLocation(e.target.value)} filter_by_text="Select Work location" />
      {
        selectedLocation !== "" ?
          <Grid>
            <Typography align='center' text={selectedLocation} variant='h2' />
            {
              selectedLocation === "Bay" ?
                <Typography align='center' text={"11 employees per shift"} variant='h3' />
                :
                <Typography align='center' text={"1 employee per shift "} variant='h3' />
            }
            <Grid container alignContent={'center'} justifyContent={'center'}>
              <Button variant="contained" color='primary' className={classes.applyForVacationButton} onClick={(e: any) => setApplyForVacationDialogOpen(true)}>Apply for Vacation</Button>
            </Grid>
            <br />
            <BasicCalendar defaultView='month' events={eventsEmployeeVacations} resizableEvent={false} onEventClick={(e: CalendarVacationCell) => onEventClick(e)} />
          </Grid>
          :
          <Grid>
            <Typography text={"Please select workstation"} align='center' variant='h2' />
          </Grid>
      }
      <Dialog
        visible={applyForVacationDialogOpen}
        max_width={"sm"} title={"Select date range for your vacation request"}
        context={
          <Grid xs={12} item  container style={{ justifyContent: 'center' }}>
            <Datepicker
              controls={['calendar']}
              select="range"
              inRangeInvalid={true}
              value={selectedDates}
              onChange={(el: any) => onDateChange(el)}
            />
            <FormControlLabel
                control={<Checkbox color="primary" disabled={selectedDates[0]?.getDate() === selectedDates[1]?.getDate() ? false : true} checked={halfDayChecked} onChange={() => { sethalfDayChecked(!halfDayChecked) }} />} 
                label="Half day"
                labelPlacement="end"
            />
            <Input multiline={true} help_text={"[Optional] "} error={false} label_text={"Add comment"} value={mechanicReason} onTextChange={(text: string) => setMechanicReason(text)} type="text" width={60} />
          </Grid>
        }
        onClose={(status: any) => onCloseApplyForVacationDialog(status)}
        strict={false}
        show_options={true}
        cancelText="Cancel"
      />
      <Dialog
        visible={cellDialogOpen}
        max_width={"sm"} title={"Types of vacation for this day"}
        context={
          <Grid xs={12} item container style={{ justifyContent: 'center' }}>
            <Table
              headers={["Created date", "Department", "Employee", "Status"]}
              rows={departmentTypesOfVacation}
              generateBody={(row: any, index: number) => generateTableBody(row, index)}
              defaultRowsPerPage={25}
            />
          </Grid>
        }
        onClose={(status: boolean) => setCellDialogOpen(false)}
        strict={false}
        show_options={false}
        cancelText="Cancel"
      />
      <Snackbar onSnackBarClose={() => setSnackbarMessage("")} open={snackbarMessage.length > 0} message={snackbarMessage} autoHide={true} severity='error' horizontalPosition='right' />
    </Grid>
  );
}
export default VacationCalendar;