import { FunctionComponent, useState } from "react";
import { AccessControlGroup, AccessControlPermission, UserAccessInfo, EmployeeInUserGroupPermission, AccessGroupUserGroupRelation } from '../../types/accessControl';
import { Grid } from '@mui/material';
import Group from './Group';
import Permissions from "./Permissions";
import AccessControlApi from '../../api/accessControl';
import { makeStyles } from '@mui/styles';
import { EmployeeSearch } from '../../components';
import { Input } from './../../MaterialUiComponents';

const useStyles = makeStyles(theme => ({
    employeeSearch: {
        marginLeft: '1%',
    },
    groupSearch: {
        marginLeft: '2px',
    },
    customScrollbar: {
        height: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555'
        }
    }
}));

type GroupsAndPermissionsProps = {
    groupsRelation: AccessGroupUserGroupRelation[],
    empInUserGroupsPermissions: EmployeeInUserGroupPermission[],
    tabValue: number,
    userGroups: AccessControlGroup[],
    accessGroups: AccessControlGroup[],
    setLoading: Function,
    accessControlPermissions: AccessControlPermission[],
    refresh: Function
}

const GroupsAndPermissions: FunctionComponent<GroupsAndPermissionsProps> = ({ groupsRelation, empInUserGroupsPermissions, tabValue, accessControlPermissions, accessGroups, userGroups, setLoading, refresh }) => {

    const classes = useStyles();
    const [groupDetails, setGroup] = useState<AccessControlGroup>();
    const [userInfo, setUserInfo] = useState<UserAccessInfo[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<UserAccessInfo[]>([]);
    const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(undefined);
    const [inGroupState, setInGroupState] = useState<boolean>(true);
    const [filteredGroups, setFilteredGroups] = useState<AccessControlGroup[]>([]);
    const [showFilteredGroups, setShowFilteredGroup] = useState<boolean>(false);
    const [groupSearch, setGroupSearch] = useState<string>("");

    const onGroupSearch = (value: string) => {
        setGroupSearch(value);
        value = value.toUpperCase();
        if (value.length > 0) {
            if (tabValue === 0) {
                const tempGroups = accessGroups.filter(x => x.group_name.toUpperCase().includes(value));
                setFilteredGroups(tempGroups);
                setShowFilteredGroup(true);
                setFilteredUsers([]);
            }
            else if (tabValue === 1) {
                const tempGroups = userGroups.filter(x => x.group_name.toUpperCase().includes(value));
                setFilteredGroups(tempGroups);
                setShowFilteredGroup(true);
                setFilteredUsers([]);
            }
        }
        else {
            setFilteredGroups([]);
            setShowFilteredGroup(false);
            setFilteredUsers([]);
        }
    }

    const closeSearchField = () => {
        setFilteredGroups([]);
        setShowFilteredGroup(false);
        setFilteredUsers([]);
    }

    const onSetInGroupState = () => {
        if (inGroupState) {
            const filteredUsers = userInfo.filter(x => x.inGroup === false);
            setFilteredUsers(filteredUsers);
        }
        else {
            const filteredUsers = userInfo.filter(x => x.inGroup === true);
            setFilteredUsers(filteredUsers);
        }
        setInGroupState(!inGroupState);
    }

    const onUpdateGroupDetails = (group: AccessControlGroup) => {

        setLoading(true);
        setInGroupState(true);
        setUserInfo([]);
        setFilteredUsers([]);

        // For filtering by access group or user group in backend route
        const filter = tabValue === 0 ? true : false;
        AccessControlApi.GetAllUsersByGroupId(group.id, filter).then(res => {
            setUserInfo(res.data);
            const users = res.data.filter(x => x.inGroup === true);
            setFilteredUsers(users);
            setGroup(group);
            setLoading(false);
        });
    }

    const onSelectedEmployeeRow = (employeeId: number) => {
        const tempGroups: AccessControlGroup[] | undefined = [];
        if (tabValue === 0) {
            accessControlPermissions.forEach(permission => {
                if (permission.employee_id === employeeId) {
                    const group = accessGroups.find(x => x.id === permission.access_group_id);
                    if (group) {
                        tempGroups.push(group);
                    }
                }
            })
        }
        else if (tabValue === 1) {
            empInUserGroupsPermissions.forEach(permission => {
                if (permission.employee_id === employeeId) {
                    const group = userGroups.find(x => x.id === permission.group_id);
                    if (group) {
                        tempGroups.push(group);
                    }
                }
            })
        }
        setFilteredGroups(tempGroups);
        setFilteredUsers([]);

        // This function(onSelectedEmployeeRow) runs every time the employeeSearch component is clicked, also when the x (close) is clicked 
        // Therefore in the case if close is clicked then do not show filters. employeeId is 0 when close is clicked.
        if (employeeId > 0) {
            setShowFilteredGroup(true);
        }
    }

    return (
        <Grid >
            <Grid container direction="row">
                <Grid item className={classes.groupSearch} xs={4}>
                    <Input
                        multiline={false}
                        label_text="Search for groups"
                        help_text=""
                        type="text"
                        value={groupSearch}
                        width={75}
                        onTextChange={(value: string) => onGroupSearch(value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <EmployeeSearch
                        inputWidth={90}
                        place_holder={"Write employee name"}
                        onSelectedEemployee={(employeeId: number) => onSelectedEmployeeRow(employeeId)}
                        customCloseFunction={closeSearchField}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item sx={{ width: '25%', padding: '4px' }} className={classes.customScrollbar}>
                    {((showFilteredGroups && filteredGroups !== undefined) && tabValue === 0) &&
                        filteredGroups.map(group => {
                            return <Group
                                setLoading={setLoading}
                                groupsRelation={groupsRelation}
                                tabValue={tabValue}
                                refresh={refresh}
                                group={group}
                                updateGroupDetails={onUpdateGroupDetails}
                                setSelectedGroupId={setSelectedGroupId}
                                selectedGroupId={selectedGroupId}
                            />
                        })
                    }
                    {((!showFilteredGroups && accessGroups !== undefined) && tabValue === 0) &&
                        accessGroups.map(group => {
                            return <Group
                                setLoading={setLoading}
                                groupsRelation={groupsRelation}
                                tabValue={tabValue}
                                refresh={refresh}
                                group={group}
                                updateGroupDetails={onUpdateGroupDetails}
                                setSelectedGroupId={setSelectedGroupId}
                                selectedGroupId={selectedGroupId}
                            />
                        })
                    }
                    {((showFilteredGroups && filteredGroups !== undefined) && tabValue === 1) &&
                        filteredGroups.map(group => {
                            return <Group
                                setLoading={setLoading}
                                groupsRelation={groupsRelation}
                                tabValue={tabValue}
                                refresh={refresh}
                                group={group}
                                updateGroupDetails={onUpdateGroupDetails}
                                setSelectedGroupId={setSelectedGroupId}
                                selectedGroupId={selectedGroupId}
                            />
                        })
                    }
                    {((!showFilteredGroups && userGroups !== undefined) && tabValue === 1) &&
                        userGroups.map(group => {
                            return <Group
                                setLoading={setLoading}
                                groupsRelation={groupsRelation}
                                tabValue={tabValue}
                                refresh={refresh}
                                group={group}
                                updateGroupDetails={onUpdateGroupDetails}
                                setSelectedGroupId={setSelectedGroupId}
                                selectedGroupId={selectedGroupId}
                            />
                        })
                    }
                </Grid>
                <Grid item sx={{ width: '75%', padding: '4px' }}>
                    <Permissions
                        groupsRelation={groupsRelation}
                        setLoading={setLoading}
                        tabValue={tabValue}
                        refresh={refresh}
                        groupDetails={groupDetails}
                        permissions={filteredUsers}
                        setInGroupState={onSetInGroupState}
                        inGroupState={inGroupState}
                        onUpdateGroupDetails={onUpdateGroupDetails}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default GroupsAndPermissions;