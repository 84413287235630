import { FunctionComponent, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Typography } from './../../MaterialUiComponents';
import { PieChart } from './../../components';
import OverviewApi from './../../api/overview';
import { WorkpackageStatistic } from './../../types/overview';
import { redirectToMxi } from './../../config/mxiProvider';
import { LongtermObj } from '../../types/longterm';

type PackageInformationProps = {
  woNumber: string
}

const PackageInformation: FunctionComponent<PackageInformationProps> = ({ woNumber }) => {
  const [workpackageStatistic, setWorkPackageStatistic] = useState({} as WorkpackageStatistic);
  useEffect(() => {
    OverviewApi.GetWorkpackageStatistic(woNumber).then(res => {
      setWorkPackageStatistic(res.data);
    })
    //Todo: what are these eslint comments doing all over the code, remove perhaps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (workpackageStatistic.workpackage === undefined) {
    return (<div></div>)
  }
  return (
    <Grid container >
      <Grid lg={4} sm={12}>
        <Grid container>
          <Grid container>
            <Typography color={'primary'} fontWeight={'bolder'} text={"Aircraft : "} margin={[0, 1, 0, 0]} />
            <Typography variant={'h4'} color={'primary'} fontWeight={'bold'} text={workpackageStatistic.mxiLongtermObj.tail_number} margin={[0.5, 0, 0, 0]} />
          </Grid>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Progress : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} variant={'h4'} fontWeight={'bold'} text={`${workpackageStatistic.progress} %`} margin={[0.5, 0, 0, 0]} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Status : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.wo_status} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Name : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.title} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Workpackage number : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.woNumber} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Total tasks : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.totalCount} />
        </Grid>
        <Grid container onClick={() => redirectToMxi(workpackageStatistic.mxiLongtermObj.wo_barcode)}>
          <Typography cursor={'pointer'} color={'primary'} fontWeight={'bolder'} text={"Barcode : "} margin={[0, 1, 0, 0]} />
          <Typography textColor={'blue'} cursor={'pointer'} color={'primary'} text={workpackageStatistic.mxiLongtermObj.wo_barcode} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Bay : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.bay_name} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Schedule start date : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.sched_start_time_text} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Actual start date: "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.start_time_text} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Schedule end date : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.sched_end_time_text} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Actual end date: "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.end_time_text} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Delay : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={`${workpackageStatistic.mxiLongtermObj.delay_in_days} days`} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Duration : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={`${workpackageStatistic.mxiLongtermObj.wo_duration_days} days `} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Send shop requests : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.shop_request_count} />
        </Grid>
        <Grid container>
          <Typography color={'primary'} fontWeight={'bolder'} text={"Corrosion : "} margin={[0, 1, 0, 0]} />
          <Typography color={'primary'} text={workpackageStatistic.mxiLongtermObj.corrosion_count} />
        </Grid>
      </Grid>
      <Grid lg={4} sm={12}>
        <PieChart small={false} title={"Task Overview"} workpackage={workpackageStatistic} onPieClick={() => ""} />
      </Grid>
      <Grid lg={4} sm={12}>
      </Grid>
    </Grid>
  );
};
export default PackageInformation