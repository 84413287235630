import { settings } from '../config';
import { CreateExtraPaymentObj, postPayment, paymentType, myPayment, CarRidePrices, DeleteCarRidePrice, AddCarRidePrice, UpdateCarRidePrice } from '../types/extraPayments';
import { CarRideLocations } from '../types/extraPayments';
import { getToken } from './../global/TokenValidator';

class ExtraPayments {
    GetAllSlippsPayments = async (): Promise<{ woPaymentsIds: number[], _payments: paymentType[] }> => {
        return fetch(`${settings.HOST}/ExtraPayments/GetAllSlippsPayments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    GetAllCarRideLocation = async (): Promise<{ status: boolean, data: CarRideLocations[], message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/GetAllCarRideLocation`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    AddCarRideLocation = async (location: string): Promise<{ status: boolean, data: CarRideLocations[], message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/AddCarRideLocation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    location: location,
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    DeleteCarRideLocation = async (deleted_reason: string, extra_payment_location_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/DeleteCarRideLocations`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                deleted_reason: deleted_reason,
                extra_payment_location_id: extra_payment_location_id,
            })
        }).then(x => {
            return x.json()
        })
    }

    GetAllCarRidePrices = async (): Promise<{ status: boolean, data: CarRidePrices[], message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/GetAllCarRidePrices`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }

    AddCarRidePrice = async (newCarRidePrice: AddCarRidePrice): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/AddCarRidePrice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(newCarRidePrice)
        }).then(x => {
            return x.json();
        })
    }

    UpdateCarRidePrice = async (updatedCarRidePrice: UpdateCarRidePrice): Promise<{ status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/UpdateCarRidePrice`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(updatedCarRidePrice)
        }).then(x => {
            return x.json()
        })
    }

    DeleteCarRidePrice = async (deleteCarRidePrice: DeleteCarRidePrice): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/DeleteCarRidePrice`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(deleteCarRidePrice)
        }).then(x => {
            return x.json()
        })
    }

    SearchPacketByFlightNumber = async (woNumber: string): Promise<any> => {
        return fetch(`${settings.HOST}/packet/SearchPacketByFlightNumber/${woNumber}/true/true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetSupervisorNotifications = async (): Promise<{ status: boolean, message: string, data: myPayment[] }> => {
        return fetch(`${settings.HOST}/ExtraPayments/GetSupervisorNotifications`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getAllMyExtraPaymentsForMechanic = async (status: string[]): Promise<{ status: boolean, data: myPayment[], message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/getAllMyExtraPaymentsForMechanic`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    status: status,
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    getAllMyExtraPaymentsForSupervisor = async (status: string[]): Promise<{ status: boolean, data: myPayment[], message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/getAllMyExtraPaymentsForSupervisor`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    status: status,
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    GetAllExtraPaymentsForTimeControl = async (status: string[]): Promise<{ status: boolean, data: myPayment[], message: string }> => {
        return fetch(`${settings.HOST}/ExtraPayments/getAllExtraPaymentsForTimeControl`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    status: status,
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    CreateNewExtraPayments = async (payments: Array<CreateExtraPaymentObj>): Promise<{ status: Boolean, message: string}> => {
        return fetch(`${settings.HOST}/ExtraPayments/CreateNewExtraPayments`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(payments)
        }).then(x => {
            return x.json()
        })
    }
    AddTypeOfPayment = async (name: string): Promise<{ status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/AddTypeOfPayment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    type_name: name
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    UpdateExistingPaymentType = async (id: number, description: string): Promise<{ status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/UpdateExistingPaymentType`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    description: description,
                    id: id
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    UpdateExtraPaymentStatus = async (paymentId: number): Promise<{ message: string, status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/UpdateExtraPaymentStatus`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    id: paymentId
                }
            )
        }).then(x =>
            x.json()
        )
    }
    UpdateExistingRow = async (data: postPayment): Promise<{ status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/UpdateExistingRow`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    out_date_string: data.outDate,
                    in_date_string: data.inDate,
                    employee_id: data.employee_id,
                    supervisor_employee_id: data.supervisor_employee_id,
                    wo: data.wo,
                    item_no: data.item_no,
                    description: data.description,
                    types_of_payment_ids: data.selectPaymentTypes,
                    id: data.id,
                    row_type: data.row_type,
                    selected_car_rides: data.selected_car_rides,
                }
            )
        }).then(x => {
            return { status: true }
        })
    }
    SupervisorAccepted = async (greenSlipId: number): Promise<{ status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/SupervisorAccepted`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    id: greenSlipId
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    DeclineSlip = async (greenSlipId: number, declinedReason: string): Promise<{ status: boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/DeclineSlip`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    id: greenSlipId,
                    declined_text: declinedReason,
                    isTest: !settings.HOST.includes('https://api.icelandair.is/api/maintener-backend')? true : false
                }
            )
        }).then(x => {
            if (x.ok) {
                return { status: true }
            }
            else {
                return { status: false }
            }
        })
    }
    TimeControlEmployeeAccepts = async (greenSlipId: number): Promise<{ status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/TimeControlEmployeeAccepts`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    id: greenSlipId
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    DeleteExistingPayment = async (id: number): Promise<{ status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/DeleteExistingPayment`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    id: id
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    RemoveExtraPaymentRow = async (id: number): Promise<{ status: Boolean }> => {
        return fetch(`${settings.HOST}/ExtraPayments/RemoveExtraPaymentRow`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    id: id
                }
            )
        }).then(x => {
            return x.json()
        })
    }
}
const ExtraPaymentsApi = new ExtraPayments();
export default ExtraPaymentsApi;