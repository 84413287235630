import { useLayoutEffect, useState } from "react";

// Custom hook that returns an array of window dimensions, [width, height]. Useful when stuff depends on width, height 
export default function useGetWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => { window.removeEventListener('resize', updateSize) }
    }, [])
    return size;
}