import { Grid, Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { FunctionComponent } from "react";
import { Input } from '../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';

type TailAndIssueSearchProps = {
    setSearchTail: Function,
    setSearchIssue: Function,
    getHandoversBySearch: Function,
    refreshHandOverTab: Function,
    searchTail: string,
    searchIssue: string,
}

const useStyles = makeStyles(theme => ({
    searchComponent: {
        marginTop: '10px',
    }
}));

const SearchTailAndIssue: FunctionComponent<TailAndIssueSearchProps> = ({ searchIssue, searchTail, setSearchTail, setSearchIssue, getHandoversBySearch, refreshHandOverTab }) => {
    const classes = useStyles();
    return (
        <Grid>
            <Input
                multiline={true}
                label_text="Search by tail"
                value={searchTail}
                help_text=""
                onTextChange={(value: string) => setSearchTail(value)}
                type="text"
                width={25}
                icon={<IconButton onClick={() => setSearchTail("")}><Close /> </IconButton>}
                iconPlacement="end"
            />
            <Input
                multiline={true}
                label_text="Search by issue"
                value={searchIssue}
                help_text=""
                onTextChange={(value: string) => { setSearchIssue(value); }}
                type="text"
                width={25}
                icon={<IconButton onClick={() => setSearchIssue("")}><Close /> </IconButton>}
                iconPlacement="end"
            />
            <Button className={classes.searchComponent} onClick={() => getHandoversBySearch()} color='primary'>Filter</Button>
            <Button className={classes.searchComponent} onClick={() => { refreshHandOverTab(); setSearchIssue(""); setSearchTail(""); }} color='primary'>Clear Filters</Button>
        </Grid>
    )
}

export default SearchTailAndIssue;