import { LineWPOFiltersAction } from "../types/actions";
import { LineFilters } from "../types/line";

const filtersInitial = {
	linefilters:{} as LineFilters,	
}

export const lineReducer = (state = { ...filtersInitial }, action: LineWPOFiltersAction) => {
	switch (action.type) {
		case 'LINE_WORK_PACKAGE_OVERVIEW_FILTERS': 
			return { ...state, linefilters: action.linefilters };
		default:
			return state;
	}
}