import { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import EditRow from '../../MaterialUiComponents/Table/EditRow';
import { Button, Grid, TableCell, TableRow } from '@mui/material';
import { Dialog, Select, Table, Typography, Input, Snackbar } from '../../MaterialUiComponents';
import VacationApi from '../../api/vacation';
import { PeriodConflicts, ShiftLocation, VacationPeriod } from '../../types/vacation';
import { Datepicker } from '@mobiscroll/react';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    "& TableCell": {
        paddingRight: '0px',
    },
    ".MuiFormControl-root": {
        flexDirection: 'unset'
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

interface MobiscrollEvent {
    value: Date;
}

type PeriodProps = {
    setLoading: Function
}
const SlotsAndPeriods: FunctionComponent<PeriodProps> = ({ setLoading }) => {

    const [allPeriods, setAllPeriods] = useState<VacationPeriod[]>([]);
    const [allLocations, setAllLocations] = useState<ShiftLocation[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<string>("");
    const [createPeriodLocation, setCreatePeriodLocation] = useState<string>("All");
    const [periodConflicts, setPeriodConflicts] = useState<PeriodConflicts[]>([]);
    const [conflictModalOpen, setConflictModalOpen] = useState<boolean>(false);
    const [vacationPeriods, setVacationPeriods] = useState<VacationPeriod[]>([]);
    const [createPeriodDialogOpen, setCreatePeriodDialogOpen] = useState<boolean>(false);

    const [defaultSlotsDialogOpen, setdefaultSlotsDialogOpen] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const [availableSlots, setAvailableSlots] = useState<number>(0);
    const [holidayName, setHolidayName] = useState<string>("");

    const [editPeriodRow, setEditPeriodRow] = useState<number>(-1);

    const [editPeriod, setEditPeriod] = useState<VacationPeriod>({} as VacationPeriod);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const classes = useStyles();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        VacationApi.GetAllVacationLocations().then(res => {
            setAllLocations([{ id: -1, available_slots: -1, location: 'All' }, ...res.data]);
            if (selectedLocation !== "") {
                onGetSelectedLocationInfo(selectedLocation);
            }
        });
        VacationApi.GetAllHolidayPeriods().then(res => {
            setAllPeriods(res.data);
            setLoading(false);
        });
    }

    const onGetSelectedLocationInfo = (location: string) => {
        if (location === "All") {
            setEditPeriodRow(-1);
            setSelectedLocation("");
            setCreatePeriodLocation(location);
            return;
        }
        setLoading(true);
        VacationApi.GetVacationPeriodsByGroupName(location).then(res => {
            setVacationPeriods(res.data);
            setSelectedLocation(location);
            setCreatePeriodLocation(location);
            setEditPeriodRow(-1);
            setLoading(false);
        });
    }

    const onSelectRow = (row: VacationPeriod, index: number) => {
        if (index === editPeriodRow) {
            setEditPeriodRow(-1);
        }
        else {
            setEditPeriod(row);
            setEditPeriodRow(index);
        }
    }

    const saveChanges = () => {
        const isOverlapping = isStartDateAfterEndDate(editPeriod.start_date, editPeriod.end_date);
        const locId = selectedLocation === "" ? editPeriod.location_id : allLocations.find(loc => loc.location === selectedLocation)?.id;
        if (locId !== undefined && !isOverlapping) {
            setLoading(true);
            VacationApi.UpdateHolidayPeriod(editPeriod.id, editPeriod.name, editPeriod.available_slots, locId, DateTime.fromISO(editPeriod.start_date).toJSDate(), DateTime.fromISO(editPeriod.end_date).toJSDate()).then(res => {
                if (res.status === false) {
                    setSnackbarMessage(res.message);
                }
                else {
                    setEditPeriodRow(-1);
                    refresh();
                }
                setLoading(false);
            });
        }
        else {
            setSnackbarMessage("Start date can not be later than end date");
        }
    }

    const deleteRow = () => {
        setLoading(true);
        VacationApi.DeleteHolidayPeriod(editPeriod.id).then(res => {
            setEditPeriodRow(-1);
            refresh();
            setLoading(false);
        });
    }

    const generateTableBody = (row: VacationPeriod, index: number) => {
        return (
            <TableRow
                key={index}
                className={
                    editPeriodRow !== -1 && editPeriodRow !== index
                        ? classes.overlay
                        : index % 2
                            ? classes.evenRows
                            : ''
                }
            >
                <TableCell>{index}</TableCell>
                <TableCell>{allLocations.find(s => s.id === row.location_id)?.location}</TableCell>
                <TableCell>
                    {index === editPeriodRow ? (
                        <Input
                            multiline={true}
                            help_text={"Type new name"}
                            error={false}
                            label_text={"Type holiday name"}
                            value={editPeriod.name}
                            onTextChange={(text: string) =>
                                setEditPeriod(prevPeriod => ({
                                    ...prevPeriod,
                                    name: text,
                                }))
                            }
                            type="text"
                            width={100}
                        />
                    ) : (
                        row.name
                    )}
                </TableCell>

                <TableCell>{DateTime.fromISO(row.created_date).toFormat('yyyy-MM-dd')}</TableCell>

                <TableCell>
                    {index === editPeriodRow ? (
                        <Datepicker
                            controls={['calendar']}
                            inputProps={{
                                placeholder: 'Select date'
                            }}
                            display='anchored'
                            touchUi={true}
                            value={editPeriod.start_date}
                            onChange={(e: MobiscrollEvent) => setEditDatesForPeriod("start", e.value)}
                            themeVariant='light'
                            dateFormat='DD.MM.YYYY'
                        />
                    ) : (
                        DateTime.fromISO(row.start_date).toFormat('yyyy-MM-dd')
                    )}
                </TableCell>

                <TableCell>
                    {index === editPeriodRow ? (
                        <Datepicker
                            controls={['calendar']}
                            inputProps={{
                                placeholder: 'Select date'
                            }}
                            display='anchored'
                            touchUi={true}
                            value={editPeriod.end_date}
                            onChange={(e: MobiscrollEvent) => setEditDatesForPeriod("end", e.value)}
                            themeVariant='light'
                            dateFormat='DD.MM.YYYY'
                        />
                    ) : (
                        DateTime.fromISO(row.end_date).toFormat('yyyy-MM-dd')
                    )}
                </TableCell>

                <TableCell>
                    {index === editPeriodRow ? (
                        <Input
                            help_text={"Enter available slots"}
                            error={false}

                            label_text={"Available Slots"}
                            value={editPeriod.available_slots} // Ensure it's a string for input
                            onTextChange={(text: string) => setEditPeriod(prevPeriod => ({
                                ...prevPeriod,
                                available_slots: parseInt(text, 10), // Convert back to number
                            }))}
                            type="number"
                            width={100} multiline={false}
                        />
                    ) : (
                        row.available_slots
                    )}
                </TableCell>

                <EditRow
                    abort={true}
                    index={index}
                    saveChanges={() => saveChanges()}
                    deleteRow={() => deleteRow()}
                    editRow={editPeriodRow}
                    setEditRow={() => onSelectRow(row, index)}
                />
            </TableRow>
        );
    };

    const onConfirmCreatePeriod = (status: boolean) => {

        const isStartBeforeEnd = isStartDateAfterEndDate(startDate, endDate);
        const locId = allLocations.find(loc => loc.location === selectedLocation)?.id;
        const { isOverlapping, overlappingPeriods } = isOverlap(startDate, endDate);

        if (status === false) {
            setCreatePeriodDialogOpen(false);
        }
        else if (isOverlapping) {

            const periodConflicts: PeriodConflicts[] = overlappingPeriods.map(period => ({
                location_name: allLocations.find(loc => loc.id === period.location_id)?.location ?? "All",
                overlapping_period: {
                    id: period.id,
                    name: period.name,
                    start_date: period.start_date,
                    end_date: period.end_date,
                    available_slots: period.available_slots
                }
            }));
            setPeriodConflicts(periodConflicts);
            setConflictModalOpen(true);
            setSnackbarMessage("Period can not overlap with existing periods");
        }
        else if (isStartBeforeEnd) {
            setSnackbarMessage("Start date can not be later than end date");
        }
        else if (isOverlapping === false && status === true && selectedLocation !== "" && startDate !== null && endDate !== null && availableSlots !== 0 && locId !== undefined && createPeriodLocation !== "All") {
            setLoading(true);
            VacationApi.CreateHolidayPeriod(holidayName, availableSlots, locId, startDate, endDate, false).then(res => {
                if (res.status === false) {
                    setSnackbarMessage(res.message);
                    setPeriodConflicts(res.conflicts);
                    setConflictModalOpen(true);
                }
                setCreatePeriodDialogOpen(false);
                refresh();
                setLoading(false);
            });
        }
        else if (isOverlapping === false && status === true && startDate !== null && endDate !== null && availableSlots !== 0 && createPeriodLocation === "All") {
            setLoading(true);
            VacationApi.CreateHolidayPeriod(holidayName, availableSlots, -1, startDate, endDate, true).then(res => {
                if (res.status === false) {
                    setSnackbarMessage(res.message);
                    setPeriodConflicts(res.conflicts);
                    setConflictModalOpen(true);
                }
                setCreatePeriodDialogOpen(false);
                refresh();
                setLoading(false);
            });
        }
    }

    const setEditDatesForPeriod = (type: string, date: Date) => {
        const overlap = type === "start" ? isStartDateAfterEndDate(date, editPeriod.end_date) : isStartDateAfterEndDate(editPeriod.start_date, date);
        if (!overlap) {
            if (type === "start") {
                setEditPeriod(prevPeriod => ({
                    ...prevPeriod,
                    start_date: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
                }));
            } else {
                setEditPeriod(prevPeriod => ({
                    ...prevPeriod,
                    end_date: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
                }));
            }
        }
        else {
            setSnackbarMessage("Start date can not be later than end date");
        }
    }

    function isStartDateAfterEndDate(startDate: string | Date, endDate: string | Date): boolean {
        // Convert startDate to a Luxon DateTime object
        const start = typeof startDate === 'string'
            ? DateTime.fromISO(startDate)           // If it's a string, assume ISO format
            : DateTime.fromJSDate(startDate);       // If it's a Date object

        // Convert endDate to a Luxon DateTime object
        const end = typeof endDate === 'string'
            ? DateTime.fromISO(endDate)             // If it's a string, assume ISO format
            : DateTime.fromJSDate(endDate);         // If it's a Date object

        // Check if both dates are valid
        if (!start.isValid) {
            return false;
        }
        if (!end.isValid) {
            return false;
        }

        // Return true if the start date is after the end date
        return start > end;
    }

    const isOverlap = (newStartDate: Date, newEndDate: Date): { isOverlapping: boolean, overlappingPeriods: VacationPeriod[] } => {

        const newStart = DateTime.fromJSDate(newStartDate).toUTC();
        const newEnd = DateTime.fromJSDate(newEndDate).toUTC();

        const overlappingPeriods = createPeriodLocation === "All" ? allPeriods.filter(period => {
            const periodStart = DateTime.fromISO(period.start_date, { zone: 'utc' });
            const periodEnd = DateTime.fromISO(period.end_date, { zone: 'utc' });

            return newStart <= periodEnd && newEnd >= periodStart;
        }) : vacationPeriods.filter(period => {
            const periodStart = DateTime.fromISO(period.start_date, { zone: 'utc' });
            const periodEnd = DateTime.fromISO(period.end_date, { zone: 'utc' });

            return newStart <= periodEnd && newEnd >= periodStart;
        });

        return {
            isOverlapping: overlappingPeriods.length > 0,
            overlappingPeriods: overlappingPeriods
        };
    };

    const onConfirmAvailableSlots = (status: boolean) => {
        const locId = allLocations.find(loc => loc.location === selectedLocation)?.id;
        if (status === false) {
            setdefaultSlotsDialogOpen(false);
        }
        else if (status === true && selectedLocation !== "" && availableSlots !== 0 && locId !== undefined) {
            setLoading(true);
            VacationApi.UpdateDefaultSlots(locId, availableSlots).then(res => {
                setdefaultSlotsDialogOpen(false);
                refresh();
                setLoading(false);
            });
        }
    }

    return (
        <Grid>
            <Grid>
                <Select options={allLocations.map(s => s.location)} selectedItem={selectedLocation} widthPercentage width={20} onSelectedItem={(e: any) => onGetSelectedLocationInfo(e.target.value)} filter_by_text="Select Work location" />
                {selectedLocation !== "" ?
                    <>
                        <Button style={{ marginTop: "10px", marginLeft: "8px" }} onClick={() => setCreatePeriodDialogOpen(true)} variant={"contained"}>Create new period</Button>
                        <Button style={{ marginTop: "8px", marginLeft: "8px" }} onClick={() => setdefaultSlotsDialogOpen(true)} variant={"contained"}>Change default slots </Button>
                    </>
                    :
                    <>
                        <Button style={{ marginTop: "10px", marginLeft: "8px" }} onClick={() => setCreatePeriodDialogOpen(true)} variant={"contained"}>Create new period</Button>
                    </>
                }
            </Grid>

            {selectedLocation !== "" ?
                <Grid style={{ marginTop: "24px" }}>
                    <Typography align='center' text={`Default number of slots: ${allLocations.find(s => s.location == selectedLocation)?.available_slots}`} variant='h2' />
                </Grid>
                :
                <Grid style={{ marginTop: "24px" }}>
                    <Typography align='center' text={`All periods`} variant='h2' />
                </Grid>
            }
            {
                selectedLocation !== "" ?
                    <Grid style={{ marginTop: "40px" }}>
                        <Typography align='center' text={`Holiday periods for ${selectedLocation}`} variant='h2' />
                        {vacationPeriods.length > 0 ?
                            <Table
                                headers={["Location", "Name", "Created Date", "Start Date", "End Date", "Available Slots", "Edit"]}
                                generateBody={(row: VacationPeriod, index: number) => generateTableBody(row, index)}
                                rows={vacationPeriods}
                                pagination={false}
                                skipIndexCol={false}
                            />
                            : null
                        }
                    </Grid>
                    :
                    <Grid>
                        {allPeriods !== undefined && allPeriods.length > 0 ?
                            <Table
                                headers={["Location", "Name", "Created Date", "Start Date", "End Date", "Available Slots", "Edit"]}
                                generateBody={(row: VacationPeriod, index: number) => generateTableBody(row, index)}
                                rows={allPeriods}
                                pagination={false}
                                skipIndexCol={false}
                            />
                            : null
                        }

                    </Grid>
            }
            {snackbarMessage && snackbarMessage.length > 0 && <Snackbar onSnackBarClose={() => setSnackbarMessage("")} open={true} message={snackbarMessage} severity='error' autoHide={true} horizontalPosition='right' />}

            <Dialog
                visible={createPeriodDialogOpen}
                max_width={"md"} title={`Create new period for  ${createPeriodLocation} ${createPeriodLocation === "All" ? " locations" : ""}`}
                context={
                    <Grid item xs={12} container style={{ justifyContent: 'center' }}>
                        <Grid xs={12} style={{ display: "flex", flexDirection: "row", justifyContent: 'center' }}>

                            <Grid xs={3} style={{ marginTop: "30px" }}>
                                <Select options={allLocations.map(s => s.location)} selectedItem={createPeriodLocation} widthPercentage width={100} onSelectedItem={(e: React.ChangeEvent<HTMLInputElement>) => setCreatePeriodLocation(e.target.value)} filter_by_text="Select Work location" />
                            </Grid>
                            <Grid xs={3}>
                                <Datepicker
                                    controls={['calendar']}
                                    inputProps={{
                                        placeholder: 'Select date'
                                    }}
                                    label="Start date"
                                    display='anchored'
                                    touchUi={true}
                                    value={startDate}
                                    onChange={(e: MobiscrollEvent) => setStartDate(e.value)}
                                    themeVariant='light'
                                    dateFormat='DD.MM.YYYY'
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Datepicker
                                    controls={['calendar']}
                                    inputProps={{
                                        placeholder: 'Select date'
                                    }}
                                    label="End date"
                                    display='anchored'
                                    touchUi={true}
                                    value={endDate}
                                    onChange={(e: MobiscrollEvent) => setEndDate(e.value)}
                                    themeVariant='light'
                                    dateFormat='DD.MM.YYYY'
                                />
                            </Grid>
                        </Grid>
                        <Input
                            multiline={true}
                            label_text={'Slots available'}
                            value={availableSlots}
                            help_text=""
                            required={true}
                            onTextChange={(value: number) => setAvailableSlots(value)}
                            type="text"
                            width={100}
                            iconPlacement="end"
                            autoFocus={true}
                            disabled={false}
                        />
                        <Input
                            multiline={true}
                            label_text={'Type holiday name'}
                            value={holidayName}
                            help_text=""
                            required={true}
                            onTextChange={(value: string) => setHolidayName(value)}
                            type="text"
                            width={100}
                            iconPlacement="end"
                            autoFocus={false}
                            disabled={false}
                        />
                    </Grid>
                }
                onClose={(status: boolean) => onConfirmCreatePeriod(status)}
                strict={false}
                show_options={true}
                cancelText="Cancel"
            />
            <Dialog
                visible={defaultSlotsDialogOpen}
                max_width={"sm"} title={"Change default slots for " + selectedLocation}
                context={
                    <Grid item xs={12} container style={{ justifyContent: 'center' }}>
                        <Input
                            multiline={true}
                            label_text={'Slots available'}
                            value={availableSlots}
                            help_text=""
                            required={true}
                            onTextChange={(value: number) => setAvailableSlots(value)}
                            type="text"
                            width={100}
                            iconPlacement="end"
                            autoFocus={true}
                            disabled={false}
                        />
                    </Grid>
                }
                onClose={(status: boolean) => onConfirmAvailableSlots(status)}
                strict={false}
                show_options={true}
                cancelText="Cancel"
            />
            <Dialog
                visible={conflictModalOpen}
                max_width={"md"} title={"Conflicting periods"}
                context={
                    <Grid item xs={12} container style={{ justifyContent: 'center' }}>
                        <Table
                            headers={["Location", "Period Name", "Available slots", "Start date", "End date"]}
                            rows={periodConflicts}
                            generateBody={(row: PeriodConflicts, index: number) => (
                                <TableRow key={index}>
                                    <TableCell></TableCell>
                                    <TableCell>{row.location_name}</TableCell>
                                    <TableCell>{row.overlapping_period.name}</TableCell>
                                    <TableCell>{row.overlapping_period.available_slots}</TableCell>
                                    <TableCell>{DateTime.fromISO(row.overlapping_period.start_date).toFormat('yyyy-MM-dd')}</TableCell>
                                    <TableCell>{DateTime.fromISO(row.overlapping_period.end_date).toFormat('yyyy-MM-dd')}</TableCell>
                                </TableRow>
                            )}
                            defaultRowsPerPage={5}
                        />
                    </Grid>
                }
                onClose={(status: boolean) => setConflictModalOpen(status)}
                strict={false}
                show_options={false}
                cancelText="Cancel"
            />
        </Grid>
    );
}
export default SlotsAndPeriods;