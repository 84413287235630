import React, { FunctionComponent } from 'react';
import { SelectObject } from './../../types/certificates';
import { FormControl, InputLabel, Select, MenuItem, Input } from '@mui/material';

type SelectUIProps = {
  selectedItem?: string | number | null,
  width: number,
  widthPercentage?: boolean
  filter_by_text: string,
  options?: string[],
  optionsObject?: SelectObject[],
  onSelectedItem: Function,
  customSelectedText?: string,
  required?: boolean,
  disabled?: boolean,
  children?: React.ReactNode;
}

const SelectUI: FunctionComponent<SelectUIProps> = React.memo(({ selectedItem, required = false, width, filter_by_text, options, optionsObject, onSelectedItem, widthPercentage = false, disabled = false }) => {
  return (
    <FormControl
      style={widthPercentage === false ? { minWidth: width + "px", maxWidth: width + "px" } : { width: width + "%" }}
      variant="standard">
      <InputLabel htmlFor="select-type" required={required}>{filter_by_text}</InputLabel>
      <Select
        value={selectedItem}
        onChange={(e: any) => onSelectedItem(e)}
        input={<Input id="select-type" />}
        renderValue={(selected) =>
          optionsObject !== undefined ?
            optionsObject?.find(o => o.id === selected)?.name
            :
            selected as string}
        multiple={false}
        variant="standard"
        disabled={disabled}
      >
        {
          options !== undefined ?
            options.map((item: string, index: number) => (
              <MenuItem value={item} key={index}>{item}</MenuItem>
            ))
            :
            optionsObject?.map(item => (
              <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
            ))
        }
      </Select>
    </FormControl>
  );
});
export default SelectUI;