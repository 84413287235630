const claimClassificationOptions = {
    lht: "LHT Contract",
    oem: "OEM Direct",
    serviceBulletin: "Service Bulletin",
    serviceLetter: "Service Letter",
    repair: "Repair/Mod",
    mro: "MRO",
    vendor: "Vendor",
    insurance: "Insurance",
  } as const;

  const claimStatusOptions = {
    process: "In process",
    accepted: "Accepted",
    rejected: "Rejected",
    notClaimed: "Not Claimed",
  } as const;

  const claimResultRejectedOptions = {
    warrentyExpired: "Warranty expired/Not in warranty",
    costInducedDamage: "Customer induced damage",
    wearAndTear: "Normal wear and tear",
    nff: "NFF",
    USPartMissing: "US part missing",
    USPartDiscarded: "US Part Discarded",
  } as const;

  const claimResultOptions = {
    focSpare: "FOC spare",
    focRepair: "FOC Repair",
    credit: "Credit",
    partialClaim: "Partial Claim",
  } as const;

  export {
    claimClassificationOptions,
    claimStatusOptions,
    claimResultRejectedOptions,
    claimResultOptions
}