import { useState, DragEvent, useRef, RefObject, useEffect, useCallback } from "react";
import CardComponent from './CardComponent';
import { Grid } from '@mui/material';
import { Input } from '../../MaterialUiComponents';
import { StaffManagementBays, StaffMtpEmployee, StaffManagementAreas } from "../../types/staffManagement";
import { User } from "../../types/user";
import { makeStyles } from '@mui/styles';
import staffApi from "../../api/staffmanagement";
import EmpCard from "./EmpCard";
import React from "react";
import { DragInfo } from "./StaffManagement";

const useStyles = makeStyles(theme => ({
    Masonry: {
        columnCount: '2',
        columnGap: '16px',
    },
    MItem: {
        display: 'inline-block',
        marginBottom: '16px',
        width: '100%',
    },
    marginLeft: {
        marginLeft: '16px'
    }
}));

type Data = {
    bay: StaffManagementBays,
    bayIndex: number,
    isSearchComponent: boolean,
    employees: User[],
    setDragging: Function,
    setLoading: Function,
    isStmAdmin: boolean,
    handleAboutEmployeeDialog: Function,
    attendanceStatus: string[],
    mechanicsSkills: string[],
    dragInfo: RefObject<DragInfo>
    moveEmployee: (destinationAreaIndex: number, bay: StaffManagementBays) => void
}

const GroupList = ({dragInfo, moveEmployee, bay, bayIndex, isSearchComponent, employees, setDragging, setLoading, isStmAdmin, handleAboutEmployeeDialog, attendanceStatus, mechanicsSkills }: Data) => {
    const searhText = useRef<string>("");

    // Changes to useState & useEffect to avoid race conditions during bad internet connections
    // If component is mounted and employees are not yet fetched, the array would be set empty and no mechanism to update it - hence an empty seach list
    const [filteredEmployees, setFilteredEmployees] = useState<User[] | undefined>(undefined);
    
    useEffect(() => {
        if(filteredEmployees === undefined && employees.length !== 0){
            setFilteredEmployees(employees);
        }
    }, [employees])
 
    const classes = useStyles();

    const searchEmployees = (text: string) => {
        searhText.current = text;
        const filteredEmployeesTemp = employees?.filter((employee) => {
            if (attendanceStatus.length === 0 || attendanceStatus.includes(employee.employee_attendance_status)) {
                return employee.name.toLowerCase().includes(text.toLowerCase());
            }
        })
        if (filteredEmployeesTemp !== undefined) {
            setFilteredEmployees(filteredEmployeesTemp);
        }
        if (text.length === 3 || text.length === 2) {
            const employee = employees.filter(s => s.employee_abbreviation?.toUpperCase().trim() === text.toUpperCase());
            if (employee.length > 0) {
                setFilteredEmployees(employee);
            }
        }
    }

    //When the user starts dragging an employee, save the employee info and the bay and group info.
    //! Example of useCallback in GroupList, not strictly necessary unless a group gets really large
    const handleDragStart = useCallback((
        employee: StaffMtpEmployee | User,
        bay: StaffManagementBays | undefined,
        group: StaffManagementAreas | undefined,
        empMappedKey: number,
        groupIndex: number
    ) => {
        //tracking the indexes of the dragged employee and his workstation.
        if(dragInfo && dragInfo.current){
            dragInfo.current.draggingFromBay = bay;
            dragInfo.current.draggingFromGroup = group;
            dragInfo.current.draggingFromBayIndex = bayIndex;
            dragInfo.current.draggingFromGroupIndex = groupIndex;
            dragInfo.current.draggedEmployeeIndex = empMappedKey;
            dragInfo.current.fromSearchDraggedEmployee = (employee as User).name;
            dragInfo.current.fromSearchDraggedEmployeeNr = (employee as User).Id;
            dragInfo.current.fromSearchDraggedEmployeeStatus = (employee as StaffMtpEmployee).employee_attendance_status;
        }
    }, [bayIndex]);

    const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }, []);

    const handleDrop = (e: DragEvent<HTMLDivElement>, bay: StaffManagementBays, group: StaffManagementAreas, dropIngroupIndex: number) => {
        if (isStmAdmin) {
            setDragging(false);
            if (dragInfo?.current?.draggingFromBay?.bay === bay.bay && dragInfo.current.draggingFromGroup?.area === group.area) {//if the dragged employee is from the same bay and group, do nothing.
                return;
            }
            else if (dragInfo?.current?.draggingFromBay?.bay !== bay.bay || dragInfo.current.draggingFromGroup?.area !== group.area) {
                moveEmployee(dropIngroupIndex, bay);
            }
        }
    };

    const deleteEmployeeFromGroup = useCallback((employee: StaffMtpEmployee, group: StaffManagementAreas) => {
        if (group !== undefined && employee !== undefined) {
            setLoading(true);
            const userName = employee.user_name.split('@')[0];
            const tempGroup = group;
            
            const updatedBays: StaffManagementBays[] = [];
            // get the index of the employee to be deleted
            const empIndex = group.employyes_in_group.findIndex((emp) => emp.user_id === employee.user_id);
            // remove the employee from the group
            tempGroup.employyes_in_group.splice(empIndex, 1);
            // set the updated group as the new group
            updatedBays.forEach((element, idx) => {
                if (element.areas[idx] === group) {
                    element.areas[idx] = tempGroup;
                }
            });
            onDeleteEmployeeFromGroup(userName, group.area, updatedBays);
        }
    }, [])

    const onDeleteEmployeeFromGroup = (userName: string, area: string, updatedBays: StaffManagementBays[]) => {
        staffApi.DeleteUserFromGroup(userName, area).then((res) => {
            setLoading(false);
        });
    }

    return (
        <Grid>
            {isSearchComponent ?
                <Grid>
                    <Grid style={{ position: 'fixed', top: '60px', overflowY: 'auto' }}>
                        <Grid style={{ width: '300px', padding: '6px', margin: '6px', height: `${60}px` }}>
                            <Input
                                disabled={false}
                                multiline={true}
                                help_text={""}
                                label_text={"Search By Name or Abbreviation"}
                                value={searhText.current}
                                onTextChange={(text: string) => searchEmployees(text)}
                                type="text"
                                width={100}
                            />
                        </Grid>
                    </Grid>
                    <Grid style={{ top: '60px' }}>
                        {
                            filteredEmployees &&
                            filteredEmployees
                            .filter(el => 
                                (
                                    (attendanceStatus.length === 0 || attendanceStatus.includes(el.employee_attendance_status))
                                    && (mechanicsSkills.length === 0 || mechanicsSkills === undefined || mechanicsSkills.every(value => el.employee_skills.includes(value)))
                                )
                            )
                            .map((item, ix) => <EmpCard key={ix} employee={item} bay={bay} group={undefined} empMappedKey={ix} handleDragStart={handleDragStart} handleDragOver={handleDragOver} handleAboutEmployeeDialog={handleAboutEmployeeDialog} isStmAdmin={isStmAdmin} setDragging={setDragging} deleteEmployeeFromGroup={deleteEmployeeFromGroup} />) 
                        }
                    </Grid>
                </Grid >
                :
                <Grid className={classes.Masonry}>
                    {bay !== undefined && bay.areas.map((group, idx) => {
                        return (
                            <Grid className={classes.MItem} key={idx} onDragOver={handleDragOver} onDrop={e => handleDrop(e, bay, group, idx)}>
                                <CardComponent banner_color="#2E8B57" bay={`${group.area}`} key={idx} >
                                    <Grid>
                                        {
                                            group.employyes_in_group
                                            .filter(el => 
                                                (
                                                    (attendanceStatus.length === 0 || attendanceStatus.includes(el.employee_attendance_status))
                                                    && (mechanicsSkills.length === 0 || mechanicsSkills === undefined || mechanicsSkills.every(value => el.employee_skills.includes(value)))
                                                )
                                            )
                                            .map((item: StaffMtpEmployee, ix) => <EmpCard key={ix} employee={item} bay={bay} group={group} empMappedKey={ix} handleDragStart={handleDragStart} handleDragOver={handleDragOver} handleAboutEmployeeDialog={handleAboutEmployeeDialog} isStmAdmin={isStmAdmin} setDragging={setDragging} deleteEmployeeFromGroup={deleteEmployeeFromGroup} />)
                                        }
                                    </Grid>
                                </CardComponent>
                            </Grid>
                        )
                    })}
                </Grid>
            }
        </Grid >
    )
}
export default React.memo(GroupList);