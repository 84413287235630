import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { FunctionComponent, useState } from "react";
import { Grid, TableCell, TableRow, Button, AlertColor } from '@mui/material';
import { Table, Input, Snackbar } from './../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import AccessControlApi from "../../api/accessControl";
import UserApi from '../../api/userApi';
import { User } from "../../types/user";
import ProfileImg from '../../components/Images/ProfileImg';

const useStyles = makeStyles(theme => ({
    Button: {
        marginBottom: theme.spacing(4),
    },
    userPhoto: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: "50%",
        marginRight: "4%"
    },
}));

type UserHistoryProps = {
    setLoading: Function,
    refresh: Function
}

const AllUsers: FunctionComponent<UserHistoryProps> = ({ setLoading, refresh }) => {
    const classes = useStyles();
    const store = useSelector((state: RootState) => state);
    const allUsers = store.employees.all_employees.filter(s => s.visible === true);
    const [user, setUser] = useState<string>("");
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success");

    const generateTableBody = (row: User, index: number) => {
        return (
            <TableRow>
                <TableCell >
                    <Grid container>
                        <ProfileImg imgKey={index} userName={row.userName?.split('@')[0]} />
                    </Grid>
                </TableCell>
                <TableCell> {row.name}</TableCell>
                <TableCell>{row.userName}</TableCell>
                <TableCell>{row.job_title}</TableCell>
            </TableRow>
        )
    }

    const onCreateUser = () => {
        setLoading(true);
        UserApi.CreateUser(user).then(res => {
            setUser('');
            refresh();
            setLoading(false);

            if (res.status === true) {
                setSnackbarMessage(res.message);
                setSnackbarSeverity('success');
            }
            else {
                setSnackbarMessage(res.message);
                setSnackbarSeverity('error');
            }
        })
    }

    const onRefreshUsers = () => {
        setLoading(true);
        AccessControlApi.SyncMicrosoftGraphAndMaintener().then(res => {
            setLoading(false);
        });
    }

    async function uploadUsersPhotosToAwsBucket() {
         for(let i = 0; i < allUsers.length; i++){
            AccessControlApi.UploadUserPhotoToAwsBucket(allUsers[i]?.userName?.split('@')[0]).then();
        }
    }
    return (
        <Grid>
            <Grid container direction="row">
                <Grid item style={{ marginRight: '4px', width: '400px' }} >
                    <Input
                        multiline={false}
                        label_text="User name"
                        help_text="Please type in a username e.g. username@icelandair.is"
                        type="text"
                        value={user}
                        width={100}
                        onTextChange={(value: string) => setUser(value)}
                    />
                </Grid>
                <Grid item style={{ marginTop: '8px' }}>
                    <Button variant="contained" color='primary' className={classes.Button} onClick={() => onCreateUser()}>Create user</Button>
                </Grid>
                <Grid item style={{ marginTop: '8px', marginLeft: '16px' }}>
                    <Button variant="contained" color='primary' className={classes.Button} onClick={() => onRefreshUsers()}>Refresh users</Button>
                </Grid>
                <Grid item style={{ marginTop: '8px', marginLeft: '16px' }}>
                    <Button variant="contained" color='primary' className={classes.Button} onClick={() => uploadUsersPhotosToAwsBucket()}>Upload user photos</Button>
                </Grid>
            </Grid>
            <Table
                headers={["", "NAME", "USERNAME", "JOBTITLE"]}
                rows={allUsers}
                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                pagination={true}
                defaultRowsPerPage={10}
                skipIndexCol={true}
            />
            <Snackbar
                onSnackBarClose={() => setSnackbarMessage("")}
                open={snackbarMessage.length > 0}
                message={snackbarMessage}
                autoHide={true}
                horizontalPosition='right'
                severity={snackbarSeverity}
            >
            </Snackbar>
        </Grid>
    )
}
export default AllUsers;