import { FunctionComponent } from 'react';
import { Grid, TextField, FormControl, TextFieldProps } from '@mui/material';
import { StaticDateRangePicker } from '@mui/lab';
import { DatePicker, TimePicker, DateTimePicker, StaticDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRange } from '@mui/lab/DateRangePicker';

type DatePickerProps = {
    type: "Date" | "Time" | "DateAndTime" | "StaticDate" | "DateRange",
    selected: Date | null,
    label: string,
    onDateChange: Function,
    width?: number,
    errorString?: string | "",
    selectedRange?: DateRange<Date>,
    disableFuture?: boolean,
    disabled?: boolean
}

const DatePickerUI: FunctionComponent<DatePickerProps> = ({ type, disabled, disableFuture, selected, label, onDateChange, width = 100, errorString = "", selectedRange = ([null, null]) }) => {
    const handleDateChange = (newDate: Date | null) => {
        onDateChange(newDate);
    };
    const handleDateRangeChange = (newDates: DateRange<Date>) => {
        onDateChange(newDates);
    }
    return (
        <Grid>
            <FormControl style={{ width: width + "%" }}>
                {type === "Date" ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            showDaysOutsideCurrentMonth
                            label={label}
                            format='dd/MM/yyyy'
                            value={selected}
                            onChange={(newDate: Date | null) => handleDateChange(newDate)}
                            slotProps={{
                                textField: {
                                  variant: 'standard',
                                  helperText: errorString,
                                }
                              }}
                            disableFuture={disableFuture}
                            disabled={disabled}
                        />
                    </LocalizationProvider>
                    :
                    type === "StaticDate" ?
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {/* <StaticDatePicker
                                showDaysOutsideCurrentMonth
                                displayStaticWrapperAs="desktop"
                                label={label}
                                inputFormat="dd/MM/yyyy"
                                value={selected}
                                onChange={(newDate: Date | null) => handleDateChange(newDate)}
                                renderInput={(props: TextFieldProps) => <TextField variant="standard"{...props} helperText={errorString} />}
                                disabled={disabled}
                            /> */}

                            <StaticDatePicker
                                showDaysOutsideCurrentMonth
                                displayStaticWrapperAs="desktop"
                                value={selected}
                                onChange={(newDate: Date | null) => handleDateChange(newDate)}
                                // format="dd/MM/yyyy"
                                disabled={disabled}
                                />
                        </LocalizationProvider>
                        :
                        type === "Time" ?
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label={label}
                                    slotProps={{
                                        textField: {
                                          variant: 'standard',
                                          helperText: errorString,
                                        }
                                      }}
                                    value={selected}
                                    onChange={handleDateChange}
                                    ampm={false}
                                    disabled={disabled}
                                />

                                
                            </LocalizationProvider>
                            :
                            // Formats the display as timestamp only. Used in Security Report. format props need to be added in order to re-use elsewhere with different display format
                            type === "DateAndTime" ?
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        label={label}
                                        showDaysOutsideCurrentMonth
                                        slotProps={{
                                            textField: {
                                              variant: 'standard',
                                              helperText: errorString,
                                            }
                                          }}
                                        format="HH:mm"
                                        value={selected}
                                        onChange={handleDateChange}
                                        ampm={false}
                                        disabled={disabled}
                                    />
                                </LocalizationProvider>
                                :
                                type === "DateRange" &&
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <StaticDateRangePicker
                                        showDaysOutsideCurrentMonth
                                        showToolbar={false}
                                        label={label}
                                        format="dd/MM/yyyy"
                                        value={selectedRange}
                                        onChange={(newDate: DateRange<Date>) => handleDateRangeChange(newDate)}
                                        renderInput={(props: TextFieldProps) => <TextField variant="standard"{...props} helperText={errorString} />}
                                        disabled={disabled}
                                    />
                                </LocalizationProvider>
                }
            </FormControl>
        </Grid>
    );
};
export default DatePickerUI