import { FunctionComponent } from "react";
import { Grid } from '@mui/material';
import { Typography, Tooltip } from './../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { EmpInGroup } from '../../types/accessControl';

const useStyles = makeStyles(theme => ({
    center: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    groups: {
        marginTop: '8px',
        height: '50px',
    }
}));

type ShowGroupsProps = {
    groups: EmpInGroup[] | undefined,
    groupType: string
}

const ShowGroups: FunctionComponent<ShowGroupsProps> = ({ groups, groupType }) => {
    const classes = useStyles();
    return (
        <Grid>
            <Typography alignSelf="center" align="center" fontWeight="normal" margin={[0, 0, 0, 0]} text={`${groupType === "Access" ? "Access groups" : groupType === "User" ? "User groups" : ""}`} />
            {groups !== undefined &&
                groups.map(item => {
                    return (
                        <Grid className={classes.center}>
                            <Tooltip title={item.group_permission_text} type='primary' placement="top-start">
                                <Paper elevation={3} >
                                    <Grid className={classes.groups}>
                                        <Typography alignSelf="center" align="center" padding={[1.5, 0, 0, 0]} fontWeight="bold" text={item.group_name} />
                                    </Grid>
                                </Paper>
                            </Tooltip>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}
export default ShowGroups;