import { useEffect, useState } from 'react';
import authApi from './../../api/auth';
import { EmployeeAttendanceStatistic } from '../../types/user';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Table, Typography, LinearProgress } from './../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  evenRows: {
    backgroundColor: theme.palette.action.hover
  },
}));

function EmployeeAttendances() {
  const classes = useStyles();
  const [hangarEmployeeAttendance, setHangarEmployeeAttendance] = useState(Array<EmployeeAttendanceStatistic>());
  const [totalAttendedEmployees, settotalAttendedEmployees] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    authApi.AllHangarEmployeeAttendance().then(res => {
      setHangarEmployeeAttendance(res.data);
      settotalAttendedEmployees(res.total);
      setLoading(false);
    })
  }, []);

  const generateTableBody = (row: EmployeeAttendanceStatistic, index: number) => {
    return (
      <TableRow className={index % 2 ? classes.evenRows : ''} >
        <TableCell>{index}</TableCell>
        <TableCell>{row.departments}</TableCell>
        <TableCell>{row.all_employees}</TableCell>
        <TableCell>{row.attended_employees}</TableCell>
        <TableCell>{row.attended_procentage}</TableCell>
      </TableRow>
    )
  }
  return (
    <Grid>
      {loading === true && <LinearProgress />}
      <Typography variant={'h2'} align={'center'} text={`Total of attended employees: ${totalAttendedEmployees} !!`} />
      <Table
        headers={["Department", "All employees", "Attendance", "%"]}
        rows={hangarEmployeeAttendance}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={25}
      />
    </Grid>
  );
};
export default EmployeeAttendances;