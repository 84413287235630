import { settings } from '../config';
import { securityResult, reportSearchResults, informationRow, AircraftInHangar, work_bays } from '../types/security';
import { getToken } from './../global/TokenValidator';

class Security {
    AircraftInHangar = async (): Promise<{ status: boolean, data: Array<AircraftInHangar> }> => {
        return fetch(`${settings.HOST}/Security/AircraftInHangar`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    GetAllBays = async (type: string): Promise<{ status: boolean, data: Array<work_bays> }> => {
        return fetch(`${settings.HOST}/ScheduleTasks/getAllBays/${type}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllInformationRows = async (): Promise<{ status: boolean, data: informationRow[], message: string }> => {
        return fetch(`${settings.HOST}/Security/GetAllInformationRows`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetOpenReport = async (): Promise<{ status: boolean, data: securityResult }> => {
        return fetch(`${settings.HOST}/Security/GetOpenReport`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetReportById = async (reportId: number): Promise<{ status: boolean, data: { Result: securityResult } }> => {
        return fetch(`${settings.HOST}/Security/GetReportById?reportId=${reportId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    // Post Requests
    SearchRequests = async (fromDate: Date | null, toDate: Date, tail: string | null, searchWord: string, ownerId: number): Promise<reportSearchResults[]> => {
        return fetch(`${settings.HOST}/Security/SearchRequests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "from": fromDate,
                "to": toDate,
                "tail": tail,
                "search_word": searchWord,
                "owner_employee_id": ownerId
            })
        }).then(x => {
            return x.json()
        })
    }
    CreateNewDailyReport = async (employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/CreateNewDailyReport/${employeeId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    CreateNewInformationRow = async (employeeId: number, message: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/CreateNewInformationRow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "created_by_employee_id": employeeId,
                "text": message
            })
        }).then(x => {
            return x.json()
        })
    }
    AircraftMovementsCreateNewRow = async (created_by_employee_id: number, tail_number: string, bay_id: number, aircraft_moved_inside: boolean, reason: string, report_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/AircraftMovementsCreateNewRow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "created_by_employee_id": created_by_employee_id,
                "tail_number": tail_number,
                "bay_id": bay_id,
                "aircraft_moved_inside": aircraft_moved_inside,
                "reason": reason,
                "report_id": report_id
            })
        }).then(x => {
            return x.json()
        })
    }
    CallOutCreateNewRow = async (created_by_employee_id: number, outcall_employee_id: number, employee_in_date: Date, employee_out_date: Date, reason: string, report_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/CallOutCreateNewRow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "created_by_employee_id": created_by_employee_id,
                "employee_in_date": employee_in_date,
                "employee_out_date": employee_out_date,
                "outcall_employee_id": outcall_employee_id,
                "reason": reason,
                "report_id": report_id
            })
        }).then(x => {
            return x.json()
        })
    }
    WalkAroundCreateNewRow = async (created_by_employee_id: number, note: string, report_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/WalkAroundCreateNewRow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "created_by_employee_id": created_by_employee_id,
                "note": note,
                "report_id": report_id,
            })
        }).then(x => {
            return x.json()
        })
    }
    OtherIncidentsCreateNewRow = async (created_by_employee_id: number, incident: string, report_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/OtherIncidentsCreateNewRow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "created_by_employee_id": created_by_employee_id,
                "incident": incident,
                "report_id": report_id
            })
        }).then(x => {
            return x.json()
        })
    }
    // Put Requests
    CloseDailyReport = async (employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/CloseDailyReport/${employeeId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    UpdateAmountEmployeeEntered = async (amount: number, reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/UpdateAmountEmployeeEntered`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "amount": amount,
                    "reportId": reportId,
                    "modifedByEmployeeId": employeeId
                })
        }).then(x => {
            return x.json()
        })
    }
    UpdateInformationRow = async (message: string, rowId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/UpdateInformationRow`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "id": rowId,
                    "last_modified_by_employee_id": employeeId,
                    "text": message
                })
        }).then(x => {
            return x.json()
        })
    }
    UpdateAmountHandSearches = async (amount: number, reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/UpdateAmountHandSearches`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "amount": amount,
                    "reportId": reportId,
                    "modifedByEmployeeId": employeeId
                })
        }).then(x => {
            return x.json()
        })
    }
    UpdateAmountLuggages = async (amount: number, reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/UpdateAmountLuggages`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "amount": amount,
                    "reportId": reportId,
                    "modifedByEmployeeId": employeeId
                })
        }).then(x => {
            return x.json()
        })
    }
    AircraftMovementsUpdateRow = async (id: number, last_modified_by_employee_id: number, tail_number: string, bay_id: number, aircraft_moved_inside: boolean, reason: string, report_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/AircraftMovementsUpdateRow`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": id,
                "last_modified_by_employee_id": last_modified_by_employee_id,
                "tail_number": tail_number,
                "bay_id": bay_id,
                "aircraft_moved_inside": aircraft_moved_inside,
                "reason": reason,
                "report_id": report_id
            })
        }).then(x => {
            return x.json()
        })
    }
    CallOutUpdateRow = async (last_modified_by_employee_id: number, outcall_employee_id: number, employee_in_date: Date, employee_out_date: Date, reason: string, report_id: number, callOutId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/CallOutUpdateRow`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "last_modified_by_employee_id": last_modified_by_employee_id,
                "employee_in_date": employee_in_date,
                "employee_out_date": employee_out_date,
                "outcall_employee_id": outcall_employee_id,
                "reason": reason,
                "report_id": report_id,
                "id": callOutId,
            })
        }).then(x => {
            return x.json()
        })
    }
    WalkAroundFinishedRound = async (id: number, stop_date_by_employee_id: number, report_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/WalkAroundFinishedRound`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": id,
                "stop_date_by_employee_id": stop_date_by_employee_id,
                "report_id": report_id
            })
        }).then(x => {
            return x.json()
        })
    }
    WalkAroundUpdateRow = async (id: number, last_modified_by_employee_id: number, stop_date: Date, stop_date_by_employee_id: number, note: string, report_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/WalkAroundUpdateRow`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": id,
                "last_modified_by_employee_id": last_modified_by_employee_id,
                "stop_date": stop_date,
                "stop_date_by_employee_id": stop_date_by_employee_id,
                "note": note,
                "report_id": report_id
            })
        }).then(x => {
            return x.json()
        })
    }
    OtherIncidentsUpdateRow = async (id: number, last_modified_by_employee_id: number, incident: string, report_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/OtherIncidentsUpdateRow`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": id,
                "last_modified_by_employee_id": last_modified_by_employee_id,
                "incident": incident,
                "report_id": report_id
            })
        }).then(x => {
            return x.json()
        })
    }
    // Delete Requests
    AircraftMovementsRemoveRow = async (movementId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/AircraftMovementsRemoveRow?aircraftMovementId=${movementId}&ownerEmployeeId=${employeeId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    CallOutRemoveRow = async (calloutId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/CallOutRemoveRow?outcallId=${calloutId}&ownerEmployeeId=${employeeId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "outcallId": calloutId,
                    "ownerEmployeeId": employeeId,
                })
        }).then(x => {
            return x.json()
        })
    }
    WalkAroundRemoveRow = async (walkAroundId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/WalkAroundRemoveRow?walkAroundId=${walkAroundId}&ownerEmployeeId=${employeeId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "walkAroundId": walkAroundId,
                    "ownerEmployeeId": employeeId,
                })
        }).then(x => {
            return x.json()
        })
    }
    OtherIncidentsRemoveRow = async (otherIncidentId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/OtherIncidentsRemoveRow?otherIncidentId=${otherIncidentId}&ownerEmployeeId=${employeeId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "otherIncidentId": otherIncidentId,
                    "ownerEmployeeId": employeeId,
                })
        }).then(x => {
            return x.json()
        })
    }
    DeleteInformationRow = async (rowId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Security/DeleteInformationRow`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "id": rowId,
                    "deleted_by_employee_id": employeeId,
                })
        }).then(x => {
            return x.json()
        })
    }
}
const SecurityApi = new Security();
export default SecurityApi; 