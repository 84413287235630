import { FunctionComponent, useEffect, useState } from 'react';
import { Input, Typography, Select, SelectMultiple } from '../../MaterialUiComponents';
import { Grid } from '@mui/material';
import { MenuBook, FormatListNumbered, Description, Title } from '@mui/icons-material';
import { Request, ATAChapter } from '../../types/capabilityList';

interface ShopOptions {
    name: string,
    id: number
}

interface TextFieldsProps {
    request: Request,
    viewMode: boolean,
    setRequest: Function,
    ATAChapters: ATAChapter[],
    resetShops: boolean,
    setResetShops: Function,
}

const shopOptions: ShopOptions[] = [{ name: 'Select/Unselect All', id: 0 }, { name: 'AVIONICS', id: 1 }, { name: 'COMPONENT', id: 2 }, { name: "COMPOSITE", id: 3 }, { name: 'ENGINE', id: 4 }, { name: 'INTERIOR', id: 5 }, { name: 'METAL', id: 6 }];

const TextFields: FunctionComponent<TextFieldsProps> = ({ request, setRequest, viewMode, ATAChapters, resetShops, setResetShops }) => {

    const [toggleSelectAreas, setToggleSelectAreas] = useState<boolean>(false);
    const [selectedShops, setSelectedShops] = useState<number[]>([]);

    useEffect(() => {
        if (resetShops) {
            setResetShops(false);
            setInitialShopState(request);
        }
        else {
            setInitialShopState(request);
        }
    }, [request.request_id, request.version_nr, resetShops]);

    const onUpdateTextFields = (field: keyof Request, value: string) => {
        setRequest((prevState: Request) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const toggleSelectAllAreas = (event: number[]) => {
        if (event.includes(0)) {
            setSelectedShops([1, 2, 3, 4, 5, 6]);
            setToggleSelectAreas(!toggleSelectAreas);
        }
        if (event.includes(0) && toggleSelectAreas === true) {
            setToggleSelectAreas(!toggleSelectAreas);
            setSelectedShops([]);
        }
    }
    const setInitialShopState = (data: Request) => {
        const selectedShops: number[] = [];
        data.shops.forEach(shop => {
            const index = shopOptions.findIndex(s => s.name === shop.shop_name);
            if (index > -1) {
                selectedShops.push(index);
            }
        }
        );
        setSelectedShops(selectedShops);
    }
    const onSelectedShop = (e: number[]) => {
        setSelectedShops(e);
        toggleSelectAllAreas(e);
        const selectedShopNames: { shop_name: string }[] = [];
        e.forEach(index => {
            const shopOption = shopOptions[index];
            if (shopOption) {
                selectedShopNames.push({ shop_name: shopOption.name });
            }
        });
        setRequest((prevState: Request) => ({ ...prevState, shops: selectedShopNames }));
    }

    return (
        <>
            <Grid container justifyContent="center" >
                <Typography color='primary' variant='h1' text={request.part_number} align='center' />
            </Grid>
            <Grid container justifyContent="center"  >
                <Grid item xs={12} sm={12} md={5} justifyContent={"center"}>
                    <Input icon={<Description />} disabled={viewMode} multiline={true} help_text={""} label_text={"Part number"} value={request.part_number} onTextChange={(text: string) => { onUpdateTextFields("part_number", text) }} type="text" width={95} />
                </Grid>
                <Grid item xs={12} sm={12} md={5} justifyContent={"center"}>
                    <Input icon={<FormatListNumbered />} disabled={viewMode} multiline={true} help_text={""} label_text={"Part name"} value={request.part_name} onTextChange={(text: string) => { onUpdateTextFields("part_name", text) }} type="text" width={95} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={10}>
                    <Input icon={<Description />} disabled={viewMode} multiline={true} help_text={""} label_text={"Dash numbers"} value={request.dash_numbers} onTextChange={(text: string) => { onUpdateTextFields("dash_numbers", text) }} type="text" width={95} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center"  >
                <Grid item xs={12} sm={12} md={5} justifyContent={"center"}>
                    <SelectMultiple
                        disabled={viewMode}
                        customSelectedText={shopOptions
                            .filter(option => selectedShops.includes(option.id))
                            .map(option => option.name)
                            .join(', ')
                        }
                        optionsObject={shopOptions}
                        selectedItems={selectedShops}
                        widthPercentage={true}
                        width={90}
                        onSelectedItem={(e: any) => { onSelectedShop(e.target.value) }}
                        filter_by_text="Shops"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                    <Input icon={<MenuBook />} disabled={viewMode} multiline={true} help_text={""} label_text={"CMM"} value={request.cmm_chapters} onTextChange={(text: string) => { onUpdateTextFields("cmm_chapters", text) }} type="text" width={90} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" >
                <Grid item xs={12} sm={12} md={5} justifyContent={"center"}>
                    <Select
                        filter_by_text={`ATA Chapters ${request.ata_chapters}`}
                        options={ATAChapters.map((obj: ATAChapter) => obj.ata_chapter)}
                        onSelectedItem={(e: any) => {
                            const temp = ATAChapters.find(s => s.ata_chapter === e.target.value);
                            setRequest((prevState: Request) => ({ ...prevState, ata_chapters: e.target.value, class_type: temp!.rating }));
                        }}
                        disabled={viewMode}
                        widthPercentage={true}
                        width={90}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={5} justifyContent={"center"}>
                    <Input icon={<Title />} disabled={true} multiline={true} help_text={""} label_text={"Class Type"} value={ATAChapters.find(s => s.ata_chapter === request.ata_chapters)?.rating} onTextChange={(text: string) => { }} type="text" width={90} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} md={10}>
                    <Input icon={<Description />} disabled={viewMode} multiline={true} help_text={""} label_text={"Description of capability"} value={request.description} onTextChange={(text: string) => { onUpdateTextFields("description", text) }} type="text" width={95} />
                </Grid>
            </Grid>
        </>
    )
}
export default TextFields;

