import { Grid } from "@mui/material";
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import { useState, useEffect, useMemo } from 'react';
import { Tabs, LinearProgress,Typography } from '../../MaterialUiComponents';
import taskApi from './../../api/tasks'
import lineApi from '../../api/line';
import { FlightSchedule, LineWorkPackages } from '../../types/line';
import LineWorkPackageOverview from '../../components/Line/LineWorkPackageOverview';
import LineSupervisorPlanedTasks from "./LineSupervisorPlanedTasks";
import LineHandover from "./LineHandover";
import AircraftIssueHistory from "./AircraftIssueHistory";
import FlightScheduleView from "./FlightSchedule";
import { lineOrLiteEnum } from '../../components/Line/LineWorkPackageOverview';
import { useParams } from "react-router-dom";

const Line = () => {
  const store = useSelector((state: RootState) => state);
  const groups = store.user.me.user_clearence_groups;
  const isAdmin = groups.includes("Line Planner") || groups.includes("Line Supervisor Edit");
  const [loading, setLoading] = useState<boolean>(true);
  const [FlightScheduleObj, setFlightSchedule] = useState(Array<FlightSchedule>());
  const [tabValue, setTabValue] = useState(0);
  const [orderByText, setOrderByText] = useState("Arrival");
  const [ascending, setAscending] = useState(false);
  const [allTails, setAllTails] = useState(Array<string>());
  const [data_fetched_when, set_data_fetched_when] = useState("");
  const [flightScheduleError, setFlightScheduleError] = useState(false); // set the initial state as error so we do not show the table until we have data

  const isLineSupervisorAdmin = groups.includes("Line Supervisor Edit");

  const { id } = useParams();

  useEffect(() => {
    if (id ==="1") {
      setTabValue(1);
    }
    else  {
      setTabValue(0);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    lineApi.getFlightSchedule().then(res => {
      setFlightScheduleError(false);
      setLoading(false);
      sortByColumn(orderByText, res.data, !ascending);
      set_data_fetched_when(res.data_fetched_when);
      if(res.data.length === 0) {
        setFlightScheduleError(true);
      }
    }).catch(err => {
      console.log(err);
      setFlightScheduleError(true);
      setLoading(false);
    });
    taskApi.GetAllTailNumbers().then(res => {
      setAllTails(res.data);
    });
  }, []);

  const refresh = () => {
    setLoading(true);
    lineApi.getFlightSchedule().then(res => {
      setLoading(false);
      sortByColumn(orderByText, res.data, !ascending);
      set_data_fetched_when(res.data_fetched_when);
      setFlightScheduleError(false);
      if(res.data.length === 0) {
        setFlightScheduleError(true);
      }
    });
  }

  const sortByColumn = (columnName: string, flights: LineWorkPackages[], tempAscending: boolean) => {
    if (columnName === orderByText) {
      setAscending(!tempAscending);
    }
    else {
      setAscending(true);
      setOrderByText(columnName);
    }
    switch (columnName) {
      case "Tail":
        tempAscending ?
          flights.sort(function (a, b) { return b.tail_number > a.tail_number ? 1 : -1 })
          :
          flights.sort(function (a, b) { return a.tail_number > b.tail_number ? 1 : -1 })
        break;
      case "Curr Airport":
        ascending ?
          flights.sort(function (a, b) { return b.current_location > a.current_location ? 1 : -1 })
          :
          flights.sort(function (a, b) { return a.current_location > b.current_location ? 1 : -1 })
        break;
      case "Stand":
        tempAscending ?
          flights.sort(function (a, b) { return b.aircraft_stand_location > a.aircraft_stand_location ? 1 : -1 })
          :
          flights.sort(function (a, b) { return a.aircraft_stand_location > b.aircraft_stand_location ? 1 : -1 })
        break;
      case "Arr Fl, nr.":
        tempAscending ?
          flights.sort(function (a, b) { return b.inbound_departure_airport_code > a.inbound_departure_airport_code ? 1 : -1 })
          :
          flights.sort(function (a, b) { return a.inbound_departure_airport_code > b.inbound_departure_airport_code ? 1 : -1 })
        break;
      case "Arrival":
        tempAscending ?
          flights.sort(function (a, b) { return new Date(a.arrival_date).getTime() - new Date(b.arrival_date).getTime() })
          :
          flights.sort(function (b, a) { return new Date(a.arrival_date).getTime() - new Date(b.arrival_date).getTime() })
        break;
      case "Departure":
        tempAscending ?
          flights.sort(function (a, b) { return new Date(a.departure_date).getTime() - new Date(b.departure_date).getTime() })
          :
          flights.sort(function (b, a) { return new Date(a.departure_date).getTime() - new Date(b.departure_date).getTime() })
        break;
      case "Dep Fl. nr.":
        tempAscending ?
          flights.sort(function (a, b) { return b.outbound_arrival_airport_code > a.outbound_arrival_airport_code ? 1 : -1 })
          :
          flights.sort(function (b, a) { return a.outbound_arrival_airport_code > b.outbound_arrival_airport_code ? 1 : -1 })
        break;
      case "Mechanics":
        tempAscending ?
          flights.sort(function (a, b) { return b.assigned_mechanics > a.assigned_mechanics ? 1 : -1 })
          :
          flights.sort(function (a, b) { return a.assigned_mechanics > b.assigned_mechanics ? 1 : -1 })
        break;
      case "Remarks":
        tempAscending ?
          flights.sort(function (a, b) { return b.remarks > a.remarks ? 1 : -1 })
          :
          flights.sort(function (b, a) { return a.remarks > b.remarks ? 1 : -1 })
        break;
    }
    setFlightSchedule(flights);
  }
  return (
    <Grid>
      {loading && <LinearProgress />}
      <Tabs
        tabNames={["Calendar", "Flight schedule", "Workpackage overview", "Supervisor Handover", "Issue History"]}
        onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
        tabValue={tabValue}
      />
      {tabValue === 0 && <LineSupervisorPlanedTasks isAdmin={isAdmin} accessGroups={groups} />}
      {tabValue === 1 &&
        <Grid>
          { flightScheduleError &&
          <Grid style={{ marginTop: '50px' }}>
            <Typography alignSelf="center" variant='h3' text={"There was an error fetching the flight schedule. Please try again later or contact Core Solutions"} />
            <Typography variant='h3' text={"This is most likely a CDATA network issue."} />
          </Grid>
          }
          < FlightScheduleView
            refresh={() => refresh()}
            flights={FlightScheduleObj}
            dataFetched={data_fetched_when}
            sortByColumn={(columnName: string, flights: LineWorkPackages[], ascending: boolean) => sortByColumn(columnName, flights, ascending)}
            ascending={ascending}
            orderByText={orderByText}
            isLineSupervisorAdmin={isLineSupervisorAdmin}
          />
        </Grid>
      }
      {tabValue === 2 && <Grid> < LineWorkPackageOverview allTails={allTails} setLoadingProp={(loading: boolean) => setLoading(loading)} lineOrLite={lineOrLiteEnum.line} /></Grid>}
      {tabValue === 3 && < LineHandover allTails={allTails} isLineSupervisorAdmin={isLineSupervisorAdmin} />}
      {tabValue === 4 && < AircraftIssueHistory />}
    </Grid>
  )
}

export default Line;