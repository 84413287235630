import { useState, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './../../reducer';
import { Grid } from '@mui/material';
import { Tabs, LinearProgress } from '../../MaterialUiComponents';
import VacationCalendar from './VacationCalendar';
import MyVacationRequests from './MyVacationRequests';
import TimeControlVacationRequests from './TimeControlVacationRequests';
import SlotsAndPeriods from './SlotsAndPeriods';

const Vacations: FunctionComponent = () => {
  const store = useSelector((state: RootState) => state);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const isTimeControlAdmin = store.user.me.user_clearence_groups.includes("Vacation Admin");

  const tabs = isTimeControlAdmin ? ["Vacation Calendar", "Time Control", "Slots & Periods"] : ["Vacation Calendar", "My Requests"];
  return (
    <Grid>
      {loading && <LinearProgress />}
      <Tabs
        fixedTabs
        tabNames={tabs}
        tabValue={tabValue}
        onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
      />
      {
        isTimeControlAdmin ?
          <Grid>
            {tabValue === 0 && <VacationCalendar />}
            {tabValue === 1 && <TimeControlVacationRequests />}
            {tabValue === 2 && <SlotsAndPeriods setLoading={setLoading} />}
          </Grid>
          :
          <Grid>
            {tabValue === 0 && <VacationCalendar />}
            {tabValue === 1 && <MyVacationRequests />}
          </Grid>
      }
    </Grid>
  );
}
export default Vacations;