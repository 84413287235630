import { dictionary, warrantyRequest, WarrantyMaintenerPart, warrantyMxiData, comment, actionItem, pdfObj } from '../types/warranty';
import { settings } from './../config';
import { getToken } from './../global/TokenValidator';
class Warranty {
    getAllCageCodes = async (): Promise<{ status: boolean, message: string, data: dictionary[] }> => {
        return fetch(`${settings.HOST}/Warranty/GetAllCageCodes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getAllHourlyRates = async (): Promise<{ status: boolean, message: string, data: dictionary[], valid_rate: string }> => {
        return fetch(`${settings.HOST}/Warranty/GetAllHourlyRates`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getWarrantyRequestById = async (id: number): Promise<{ status: boolean, message: string, maintenerParts: WarrantyMaintenerPart[], mxiData: warrantyMxiData[], comments: comment[], data: warrantyRequest, actionItems: actionItem[] }> => {
        return fetch(`${settings.HOST}/Warranty/GetWarrantyRequestById/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetFaultByBarcode = async (barcode: string): Promise<{ status: boolean, message: string, mxiData: warrantyMxiData[], data: warrantyRequest, actionItems: actionItem[] }> => {
        return fetch(`${settings.HOST}/Warranty/GetFaultByBarcode/${barcode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    SearchForAllWarrantyRequests = async (fromDate: Date | null, toDate: Date | null, status: string[], barcode: string, tails: string[], partNumber: string, classifications: string[], cageCodes: string[]): Promise<{ status: boolean, message: string, data: warrantyRequest[] }> => {
        return fetch(`${settings.HOST}/Warranty/SearchForAllWarrantyRequests`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "from_date": fromDate,
                "to_date": toDate,
                "status": status,
                "barcode": barcode,
                "tail_number": tails,
                "part_number": partNumber,
                "claim_classification_type": classifications,
                "cage_codes": cageCodes,
            })
        }).then(x => {
            return x.json()
        })
    }
    createWarrantyRequest = async (employeeId: number, faultBarcode: string, cageCodeId: number, requestStatus: string, claimClassification: string, partPurchased: boolean, shippingCost: number, reasonForRemoval: string, hourlyRate: number, createdDate: Date, actualHours: string, actualMinutes: string, poNumber: string | null, isDummy: boolean): Promise<{ status: boolean, message: string, id: number }> => {
        return fetch(`${settings.HOST}/Warranty/CreateWarrantyRequest`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "created_by_employee_id": employeeId,
                "last_modified_by_employee_id": employeeId,
                "fault_barcode": faultBarcode,
                "cage_code_id": cageCodeId,
                "request_status": requestStatus,
                "claim_classification_type": claimClassification,
                "is_part_purchased": partPurchased,
                "claimed_shipping_cost_usd": shippingCost,
                "visible": true,
                "reason_for_removal": reasonForRemoval,
                "created_date": createdDate,
                "claimed_man_hrs_by_min": parseInt(actualHours) * 60 + parseInt(actualMinutes),
                "po_number": poNumber,
                "mechanic_hourly_rate": hourlyRate,
                "is_dummy": isDummy,
            })
        }).then(x => {
            return x.json()
        })
    }
    AddComment = async (warrantyRequestId: number, createdById: number, commentText: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/AddComment`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "warranty_request_id": warrantyRequestId,
                "created_by_employee_id": createdById,
                "text": commentText,
                "visible": true
            })
        }).then(x => {
            return x.json()
        })
    }
    updateWarrantyRequest = async (warrantyId: number, employeeId: number, faultBarcode: string, cageCodeId: number, requestStatus: string, claimClassification: string, partPurchased: boolean, shippingCost: number, reasonForRemoval: string, createdDate: Date, actualHours: string, actualMinutes: string, claimResult: string | null, poNumber: string | null, hourlyRate: number | null): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/updateWarrantyRequest`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "warranty_request_id": warrantyId,
                "last_modified_by_employee_id": employeeId,
                "fault_barcode": faultBarcode,
                "cage_code_id": cageCodeId,
                "request_status": requestStatus,
                "claim_classification_type": claimClassification,
                "is_part_purchased": partPurchased,
                "shipping_claimed_cost": shippingCost,
                "visible": true,
                "reason_for_removal": reasonForRemoval,
                "created_date": createdDate,
                "actual_manhours_worked_total_hrs": parseInt(actualHours),
                "actual_manhours_worked_total_min": parseInt(actualMinutes),
                "claim_request_type": claimResult,
                "po_number": poNumber,
                "mechanic_hourly_rate": hourlyRate
            })
        }).then(x => {
            return x.json()
        })
    }
    updatePartRequest = async (editPart: WarrantyMaintenerPart): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/updatePartRequest`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(editPart)
        }).then(x => {
            return x.json()
        })
    }
    CreatePartRequest = async (editPart: WarrantyMaintenerPart): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/CreatePartRequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(editPart)
        }).then(x => {
            return x.json()
        })
    }
    addHourlyRate = async (name: string, createdByEmployeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/AddHourlyRate`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "name": name,
                "created_by_employee_id": createdByEmployeeId,
                "visible": true
            })
        }).then(x => {
            return x.json()
        })
    }
    addNewCageCode = async (name: string, description: string, createdByEmployeeId: number, other: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/AddNewCageCode`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "name": name,
                "description": description,
                "other": other,
                "created_by_employee_id": createdByEmployeeId,
                "last_modified_by_employee_id": createdByEmployeeId,
                "visible": true
            })
        }).then(x => {
            return x.json()
        })
    }
    updateCageCode = async (cageCode: dictionary): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/UpdateCageCode`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({ cageCode })
        }).then(x => {
            return x.json()
        })
    }
    DeleteWarrantyRequest = async (warrantyRequestId: number | null, deletedReason: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/DeleteWarrantyRequest`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": warrantyRequestId,
                "deleted_reason": deletedReason
            })
        }).then(x => {
            return x.json()
        })
    }
    deleteCageCode = async (cageCode: dictionary): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/DeleteCageCode`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(cageCode)
        }).then(x => {
            return x.json()
        })
    }
    deleteComment = async (comment: comment, userId: number): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/DeleteComment`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": comment.warranty_request_comment_id,
                "last_modified_by_employee_id": userId
            })
        }).then(x => {
            return x.json()
        })
    }
    deletePartRequest = async (partRequestId: number, userId: number): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/DeletePartRequest`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "part_request_id": partRequestId,
                "last_modified_by": userId
            })
        }).then(x => {
            return x.json()
        })
    }
    SavePDF = async (fileData: FormData, warrantyId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/SavePDF/${warrantyId}`, {
            method: 'POST',
            headers: {
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: fileData
        }).then(x => {
            return x.json()
        })
    }
    DownloadPDF = async (requestId: number): Promise<{ status: boolean, message: string, data: pdfObj[] }> => {
        return fetch(`${settings.HOST}/Warranty/DownloadPDF/${requestId}`, {
            method: 'GET',
            headers: {
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    DeletePDF = async (pdfId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Warranty/DeletePDF/${pdfId}`, {
            method: 'DELETE',
            headers: {
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
}
const WarrantyApi = new Warranty();
export default WarrantyApi;