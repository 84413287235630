import { FunctionComponent } from 'react';
import { Link, LocalAirport, PriorityHigh, HourglassFull, Search } from '@mui/icons-material';
import { Card, Grid, IconButton, CardContent } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Typography, Tooltip } from '../../MaterialUiComponents';
import { LinePackets } from '../../types/line';
import { redirectToMxi } from '../../config/mxiProvider';

const useStyles = makeStyles(theme => ({
    inWork: {
        borderRightWidth: '13px',
        borderRightStyle: 'solid',
        borderColor: theme.palette.primary.main,
        cursor: 'grab'
    },
    complete: {
        borderRightWidth: '13px',
        borderRightStyle: 'solid',
        borderColor: theme.palette.success.main,
        cursor: 'grab'
    },
    active: {
        borderRightWidth: '13px',
        borderRightStyle: 'solid',
        borderColor: theme.palette.secondary.main,
        cursor: 'grab'
    },
    pause: {
        borderRightWidth: '13px',
        borderRightStyle: 'solid',
        borderColor: '#FFFF66',
        color: theme.palette.text.primary,
        cursor: 'grab'
    },
    cancel: {
        borderRightWidth: '13px',
        borderRightStyle: 'solid',
        borderColor: theme.palette.error.main,
        cursor: 'grab'
    },
    commit: {
        borderRightWidth: '13px',
        borderRightStyle: 'solid',
        borderColor: "#FFA500",
        cursor: 'grab'
    },
}));

interface LineWorkpackageCardProps {
    task: LinePackets,
    type: "LINE" | "BAY" | "BAY-USER"
    onOpenDialogClicked: Function
}

const LineWorkpackageCard: FunctionComponent<LineWorkpackageCardProps> = ({ task, type, onOpenDialogClicked }) => {
    const classes = useStyles();
    return (
        <Card className={task.status === "IN WORK" ? classes.inWork : task.status === "COMPLETE" ? classes.complete : task.status === "ACTV" ? classes.active : task.status === "PAUSE" ? classes.pause : task.status === "COMMIT" ? classes.commit : classes.cancel}>
            {type === "LINE" &&
                <Grid container direction="column" >
                    <Grid container direction="row" style={{ padding: "4px" }} >
                        <Grid item xs={6}>
                            <Grid container>
                                <Typography text={"Name:"} variant="h5" margin={[1, 1, 0, 1]} align="left" />
                            </Grid>
                            <Typography fontWeight="bolder" text={task.wo_name} variant="h5" margin={[1, 1, 0, 1]} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography text={"Due date:"} variant="h5" margin={[1, 1, 0, 2]} align="left" />
                            <Typography fontWeight="bold" text={task.mxi_due_date_text} variant="h5" margin={[1, 1, 0, 2]} align="left" />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ padding: "4px" }} >
                        <Grid item md={2}>
                            <Typography text={"Tail"} variant="h5" margin={[1, 1, 0, 1]} align="left" />
                            <Typography text={task.tail_number} color="primary" fontWeight="bolder" variant="h5" margin={[1, 1, 0, 1]} align="left" />
                        </Grid>
                        <Grid item md={2}>
                            <Typography text={"Mxi Start date:"} variant="h5" margin={[1, 1, 0, 1]} align="left" />
                            <Typography text={task.mxi_start_date_text} color="primary" fontWeight="bolder" variant="h5" margin={[1, 1, 0, 1]} align="left" />
                        </Grid>
                        <Grid item md={3} direction="row">
                            <Typography text={"Mxi End date:"} variant="h5" margin={[1, 1, 0, 1]} align="left" />
                            <Typography text={task.mxi_end_date_text} color='primary' fontWeight="bolder" variant="h5" margin={[1, 1, 0, 1]} align="left" />
                        </Grid>
                        <Grid item md={3} direction="row">
                            <Typography text={"Package duration :"} variant="h5" margin={[1, 1, 0, 1]} align="left" />
                            <Typography text={task.package_duration_text} color='primary' fontWeight="bolder" variant="h5" margin={[1, 1, 0, 1]} align="left" />
                        </Grid>
                        <Grid item xs={2} >
                            <Typography text={"MXI"} variant="h5" margin={[1, 1, 0, 2]} align="left" />
                            <IconButton onClick={() => redirectToMxi(task.wo_barcode)} style={{ marginLeft: 8 }}><Link color="primary" /></IconButton>
                        </Grid>
                    </Grid>
                    {(task.is_task_critical || task.is_on_hold || task.is_on_time === false) && <Grid container>
                        {task.is_task_critical && <Grid xs={4} container justifyContent={'center'}>
                            <Tooltip title={'Critical Work'} type='primary'><PriorityHigh color={'error'} /></Tooltip>
                        </Grid>}
                        {task.is_on_hold && <Grid xs={4} container justifyContent={'center'}>
                            <Tooltip title={'On hold'} type='primary' placement="top-start"><HourglassFull color={'error'} /></Tooltip>
                        </Grid>}
                        {task.is_on_time === false && <Grid xs={4} container justifyContent={'center'}>
                            <Tooltip title={'Aircraft is not on time, Reschedule'} type='primary' placement="top-start"><LocalAirport color={'error'} /></Tooltip>
                        </Grid>}
                    </Grid>}
                </Grid>
            }
            {
                type === "BAY" &&
                <Grid container direction="column" >
                    <Grid container direction="row" style={{ padding: "4px" }} >
                        <Grid item xs={4} container justifyContent={'center'}>
                            <Grid xs={12} container justifyContent={'center'}><Typography text={"Mxi Start date:"} variant="h5" margin={[1, 1, 0, 1]} align="left" /></Grid>
                            <Typography text={task.mxi_start_date_text} color="primary" fontWeight="bolder" variant="h5" margin={[1, 1, 0, 1]} align="left" />
                        </Grid>
                        <Grid item xs={4} container justifyContent={'center'}>
                            <Grid xs={12} container justifyContent={'center'}><Typography text={"Mxi End date:"} variant="h5" margin={[1, 1, 0, 1]} align="left" /></Grid>
                            <Typography text={task.mxi_end_date_text} color='primary' fontWeight="bolder" variant="h5" margin={[1, 1, 0, 1]} align="left" />
                        </Grid>
                        <Grid item xs={4} container justifyContent={'center'}>
                            <Grid xs={12} container justifyContent={'center'}><Typography text={"MXI"} variant="h5" margin={[1, 1, 0, 2]} align="left" /></Grid>
                            <IconButton onClick={() => redirectToMxi(task.task_barcode)} style={{ marginLeft: 8 }}><Link color="primary" /></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                type === "BAY-USER" &&
                <CardContent>
                    <Grid>
                        <Typography text={`${task.task_name}`} color={'primary'} variant={'h3'} align={'center'} margin={[0, 0, 0, 0]} />
                    </Grid>
                    <Grid xs={12} justifyContent={'center'} container>
                        <IconButton onClick={() => onOpenDialogClicked(task.task_barcode)}><Search color="primary" /></IconButton>
                    </Grid>
                    <Grid container direction={"row"} justifyContent='center' >
                        <Typography text={`Status: ${' '}`} color={'primary'} variant={'h5'} align={'center'} margin={[0, 0, 1, 0]} />
                        <Typography text={``} color={'primary'} variant={'h5'} align={'center'} margin={[1, 1, 1, 1]} />
                        <Typography text={`${task.status}`} fontWeight="bold" textColor={"black"} color={'primary'} variant={'h5'} align={'center'} margin={[0, 0, 1, 0]} />
                    </Grid>
                    <Grid>
                        <Typography text={`Wo Name: ${task.wo_name}`} color={'primary'} variant={'h5'} align={'center'} margin={[0, 0, 1, 0]} />
                    </Grid>
                    <Grid>
                        <Typography text={`Start date: ${task.mxi_start_date_text}`} color={'primary'} variant={'h5'} align={'center'} margin={[0, 0, 1, 0]} />
                    </Grid>
                    <Grid>
                        <Typography text={`End date: ${task.mxi_end_date_text}`} color={'primary'} variant={'h5'} align={'center'} margin={[0, 0, 1, 0]} />
                    </Grid>
                    <Grid xs={12} container justifyContent={'center'}><Typography text={"Assigned Labour"} variant="h5" fontWeight='bold' margin={[1, 0, 1, 0]} align="center" /></Grid>
                    <Grid xs={12} >
                        <Typography text={task.assigned_employees} color='primary' variant="h5" margin={[1, 1, 0, 1]} align="center" />
                    </Grid>
                </CardContent>
            }
        </Card>
    )
}

export default LineWorkpackageCard;