import { FunctionComponent } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Grid, Avatar } from '@mui/material';
import { Typography } from '../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import { WorkpackageStatistic } from './../../types/overview';

type PieChartProps = {
  title: string,
  workpackage: WorkpackageStatistic,
  small: boolean,
  onPieClick: Function,
}

const useStyles = makeStyles(theme => ({
  gridContainer: {
    height: "40%"
  },
  legendContainer: {
    paddingLeft: theme.spacing(5),
    marginTop: theme.spacing(8)
  }
}));

const Pie: FunctionComponent<PieChartProps> = ({ title, workpackage, small, onPieClick }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridContainer}>
      <br />
      <Grid container>
        <Grid item xs={small ? 10 : 6} >
          <Typography text={title} color={'primary'} variant={'h2'} align={'center'} margin={[0, 0, -1, 0]} />
          <PieChart
            onClick={(e: any) => small === true ? onPieClick() : ""}
            data={workpackage.workpackage}
            totalValue={100}
            label={({ dataEntry }) => `${dataEntry.value}%`}
            labelStyle={(index) => ({
              fontSize: '7px',
            })}
            lineWidth={80}
            animate={true}
            animationDuration={500}
            paddingAngle={1}
            labelPosition={70}
          />
        </Grid>
        {small === false && <Grid container xs={6} className={classes.legendContainer}>
          {workpackage.workpackage.map((item, index) => (
            <Grid container direction='row'>
              <Avatar style={{ backgroundColor: item.color }}></Avatar>
              <Typography margin={[1, 0, 0, 1]} text={item.title}></Typography>
            </Grid>
          ))}
        </Grid>}
      </Grid>
    </Grid>
  )
};
export default Pie;
