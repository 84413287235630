import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { Grid } from '@mui/material';
import { Tabs, LinearProgress } from '../../MaterialUiComponents';
import NewPayment from './NewPayment';
import MyExtraPayments from './MyExtraPayments';
import EditPaymentTypes from './EditPaymentTypes';
import Destinations from './Destinations';
import Routes from './Routes';

function ExtraPayments() {
    const [tabValue, setTabValue] = useState(0);
    const store = useSelector((state: RootState) => state);
    const user = store.user.me;
    const [hideTabs, setHideTabs] = useState(Array<number>());
    const [loading, setLoading] = useState(false);
    const isSupervisor = user.user_clearence_groups.includes("Extra Payment Supervisor");
    const isTimeControl = user.user_clearence_groups.includes("Extra Payment Admin");

    useEffect(() => {
        // Checking for supervisor and time control access and setting booleans based on resulta and starting tab value     
        let temphideTabs = [2, 3, 4, 5, 6, 7];//Mechanic
        if (isTimeControl) {
            temphideTabs = [0, 1, 2];
            setTabValue(3);
        }
        else if (isSupervisor) {
            temphideTabs = [3, 4, 5, 6, 7];
            setTabValue(2);
        }
        else {
            setTabValue(0);
        }
        setHideTabs(temphideTabs);
    }, [])

    // Different tabs are hidden based on who the user is. Only hidden so it doesn't affect tabValues. 
    return (
        <Grid item>
            {loading &&
                <LinearProgress />
            }
            <Tabs
                tabNames={["Create New Extra Payment", "My Extra Payments", "Waiting for Supervisor Approval", "Waiting for Time Control Approval", "All Extra Payments", "Add New Payment Type", "Destinations", "Routes"]}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                tabValue={tabValue}
                hideTabs={hideTabs}
            />
            {tabValue === 0 && <NewPayment type="new" closeNewPayment={(value: number) => setTabValue(value)} />}
            {tabValue === 1 && <MyExtraPayments setLoading={setLoading} viewType="user" />}
            {(tabValue === 2 && isSupervisor) && <MyExtraPayments setLoading={setLoading} viewType="supervisor" />}
            {(tabValue === 3 && isTimeControl) && <MyExtraPayments setLoading={setLoading} viewType="timeControl" />}
            {tabValue === 4 && <MyExtraPayments setLoading={setLoading} viewType="all" isSupervisor={isSupervisor} isTimeControl={isTimeControl} />}
            {tabValue === 5 && <EditPaymentTypes />}
            {tabValue === 6 && <Destinations />}
            {tabValue === 7 && <Routes />}
        </Grid>
    );
};
export default ExtraPayments;