import { useEffect } from 'react';
import { FunctionComponent, useState } from 'react';
import { Grid, Card } from '@mui/material';
import { Typography, LinearProgress, Dialog, SelectMultiple } from '../../MaterialUiComponents';
import LineWorkpackageCard from "../Line/LineWorkpackageCard";
import CrewPlanDialog from '../CrewPlan/CrewPlanDialog';
import { LineWorkPackages } from './../../types/line';
import { MxiStaffManagementEmployee } from './../../types/staffManagement';
import crewPlanApi from '../../api/crewPlan';

type TaskProps = {
    myCrew: Array<MxiStaffManagementEmployee>
}

const statusOptions = [
    { id: 0, name: "COMPLETE" },
    { id: 1, name: "ACTV" },
    { id: 2, name: "CANCEL" },
    { id: 3, name: "IN WORK" },
    { id: 4, name: "PAUSE" },
];

const MyTaskPlan: FunctionComponent<TaskProps> = ({ myCrew }) => {
    const [taskBayDialogOpen, setTaskBayDialogOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<LineWorkPackages>();
    const [tasks, setTasks] = useState(Array<LineWorkPackages>);
    const [allTasks, setAllTasks] = useState(Array<LineWorkPackages>);
    const [selectedOptions, setSelectedOptions] = useState<number[]>([1, 3, 4]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        crewPlanApi.GetTasksAssignedToMe().then(res => {
            if (res.status) {
                const array = [] as Array<LineWorkPackages>;
                res.data.forEach(s => {
                    const taskDetails = s.task_details;
                    const task = {
                        task_barcode: taskDetails.barcode,
                        status: taskDetails.status,
                        task_name: s.title,
                        mxi_start_date_text: s.start_date_text,
                        mxi_end_date_text: s.end_date_text,
                        is_task_critical: taskDetails.is_task_critical,
                        assigned_employees: taskDetails.assigned_employees_on_calendar,
                        wo_name: taskDetails.wo_name,
                    } as LineWorkPackages;
                    array.push(task);
                });
                const tempSelectedStatus: string[] = [];
                selectedOptions.forEach(index => {
                    tempSelectedStatus.push(statusOptions[index].name)
                });
                setTasks(array.filter(s => tempSelectedStatus.includes(s.status)));
                setAllTasks(array);
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        const tempSelectedStatus: string[] = [];
        selectedOptions.forEach(index => {
            tempSelectedStatus.push(statusOptions[index].name);
        });
        setTasks(allTasks.filter(s => tempSelectedStatus.includes(s.status)));

    }, [selectedOptions]);

    const onSelectTask = (taskBarcode: string) => {
        setSelectedEvent(tasks.find(s => s.task_barcode === taskBarcode));
        setTaskBayDialogOpen(true);
    }

    return (
        <Grid>
            {loading ?
                <LinearProgress />
                :
                <Grid>
                    <Grid>
                        <SelectMultiple
                            optionsObject={statusOptions}
                            onSelectedItem={(e: any) => setSelectedOptions(e.target.value)}
                            selectedItems={selectedOptions}
                            width={200}
                            filter_by_text={"Filter by status"}
                        />
                    </Grid>
                    {
                        tasks.length > 0 ?
                            <Grid>
                                <Typography text={`Tasks (${tasks.length})`} variant='h3' align='center' margin={[1, 0, 1, 0]} fontWeight='bold' />
                                <Grid container spacing={2}>
                                    {tasks.map((task, index) => (
                                        <Grid item xs={12} sm={6} key={index}>
                                            <Card>
                                                <LineWorkpackageCard type='BAY-USER' task={task} onOpenDialogClicked={(taskBarcode: string) => { onSelectTask(taskBarcode) }} />
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            :
                            <Typography text={`No tasks assigned`} color={'primary'} variant={'h2'} align={'center'} margin={[0, 0, 1, 0]} />
                    }
                </Grid>
            }
            <Dialog
                visible={taskBayDialogOpen}
                max_width={"lg"}
                title={selectedEvent?.title}
                context={
                    <CrewPlanDialog myCrew={myCrew} selectedEvent={selectedEvent} />
                }
                onClose={(status: any) => { setTaskBayDialogOpen(false) }}
                strict={false}
                show_options={false}
            />
        </Grid>
    )
}

export default MyTaskPlan;
