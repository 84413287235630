import { settings } from '../config';
import { AccessControlGroup, AccessControlPermission, UserAccessInfo, EmpInGroup, EmployeeInUserGroupPermission, AccessGroupUserGroupRelation, UserGroupPermissions } from '../types/accessControl';
import { getToken } from './../global/TokenValidator';
import { Employee } from '../types/user';

class AccessControl {
    UploadUserPhotoToAwsBucket = async (userName:string): Promise<{ status: boolean, message: string }> => {
        return fetch(`/api/maintener/upload-user-image/${userName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllGroups = async (): Promise<{ status: boolean, message: string, data: AccessControlGroup[], accessControlGroups: AccessControlGroup[], userGroups: AccessControlGroup[] }> => {
        return fetch(`${settings.HOST}/Access/GetAllGroups`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllPermissions = async (): Promise<{ status: boolean, message: string, accessControlPermissions: AccessControlPermission[], accessGroupUserGroupRelation: AccessGroupUserGroupRelation[], allUserGroupPermissions: UserGroupPermissions[] }> => {
        return fetch(`${settings.HOST}/Access/GetAllPermissions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllUserGroupEmployeePermissions = async (): Promise<{ status: boolean, message: string, data: EmployeeInUserGroupPermission[] }> => {
        return fetch(`${settings.HOST}/Access/GetAllUserGroupEmployeePermissions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    CreateAccessGroup = async (groupName: string, groupPermission: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/CreateAccessGroup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "group_name": groupName,
                    "group_permission_text": groupPermission
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    CreateUserGroup = async (groupName: string, groupPermission: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/CreateUserGroup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "group_name": groupName,
                    "group_permission_text": groupPermission
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    DeleteUserGroup = async (userGroupId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/DeleteUserGroup`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "user_group_id": userGroupId
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    CreateUserGroupPermission = async (accessGroupName: string, userGroupName: string, userGroupPermissionReason: string, expirationDate: Date, startsAt: Date): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/CreateUserGroupPermission`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "access_group_name": accessGroupName,
                    "user_group_name": userGroupName,
                    "created_reason": userGroupPermissionReason,
                    "expiration_date": expirationDate,
                    "starts_at": startsAt
                }
            )
        }).then(x => {
            return x.json()
        })
    }

    EditAccessGroup = async (groupId: number, groupName: string, groupPermission: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/EditAccessGroup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "group_id": groupId,
                    "group_name": groupName,
                    "group_permission_text": groupPermission
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    EditUserGroup = async (groupId: number, groupName: string, groupPermission: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/EditUserGroup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "group_id": groupId,
                    "group_name": groupName,
                    "group_permission_text": groupPermission
                }
            )
        }).then(x => {
            return x.json()
        })
    }

    CreateAccessGroupPermission = async (groupName: string, createdReason: string, accessGroupId: number, employeeId: number, expirationDate: Date, startsAt: Date): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/CreateAccessGroupPermission`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "access_group_name": groupName,
                    "access_group_id": accessGroupId,
                    "employee_id": employeeId,
                    "expiration_date": expirationDate,
                    "created_reason": createdReason,
                    "starts_at": startsAt,
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    RemoveAccessGroupPermission = async (permissionId: number, removedReason: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/RemoveAccessGroupPermission`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "permission_id": permissionId,
                    "removed_reason": removedReason
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    RemoveUserGroupFromAccessGroup = async (permissionId: number, removedReason: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/RemoveUserGroupFromAccessGroup`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "user_groups_access_permissions_id": permissionId,
                    "removed_reason": removedReason
                }
            )
        }).then(x => {
            return x.json()
        })
    }

    RemoveUserFromUserGroup = async (userGroupId: number, employeeId: number, removedReason: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/RemoveUserFromUserGroup`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "group_id": userGroupId,
                    "employee_id": employeeId,
                    "removed_reason": removedReason
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    AddUserToUserGroup = async (createdReason: string, userGroupId: number, employeeId: number, expirationDate: Date, startsAt: Date): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/AddUserToUserGroup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "group_id": userGroupId,
                    "employee_id": employeeId,
                    "created_reason": createdReason,
                    "expiration_date": expirationDate,
                    "starts_at": startsAt
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    GetAllUsersByGroupId = async (groupId: number, isAcceessGroup: boolean = false): Promise<{ status: boolean, message: string, data: UserAccessInfo[] }> => {
        return fetch(`${settings.HOST}/Access/GetAllUsersByGroupId/${groupId}/${isAcceessGroup}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },

        }).then(x => {
            return x.json()
        })
    }
    GetUserHistoryByEmployeeId = async (employeeId: number): Promise<{ status: boolean, message: string, data: EmpInGroup[], groups: EmpInGroup[], employeeInUserGroupsHistory: EmpInGroup[], employeeInUserGroups: EmpInGroup[] }> => {
        return fetch(`${settings.HOST}/Access/GetUserHistoryByEmployeeId/${employeeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllUsersWithNoAccess = async (): Promise<{ status: boolean, message: string, data: Employee[] }> => {
        return fetch(`${settings.HOST}/Access/GetAllUsersWithNoAccess`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    SyncMicrosoftGraphAndMaintener = async (): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Access/SyncMicrosoftGraphAndMaintener`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
}
const AccessControlAPI = new AccessControl();
export default AccessControlAPI;