import { FunctionComponent } from 'react';
import { Typography } from '../../MaterialUiComponents';
import { Grid, Button } from '@mui/material';
import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { Request } from '../../types/capabilityList';

interface PermissionsProps {
    request: Request,
    viewMode: boolean,
    setRequest: Function,
}

const Permissions: FunctionComponent<PermissionsProps> = ({ request, setRequest, viewMode }) => {

    const onUpdateBooleanFields = (field: keyof Request, value: boolean) => {
        setRequest((prevState: Request) => ({
            ...prevState,
            [field]: !value,
        }));
    };

    return (
        <Grid style={{ marginTop: '18px' }}>
            <Grid item xs={12}><Typography color='primary' variant='h2' text={"Permissions"} align='center' /></Grid>
            <Grid container>
                <Grid item xs={3} container>
                    <Grid container justifyContent={'center'}>
                        <Grid><Typography text={"Inspect Tested"} align='center' /></Grid>
                        <Grid container justifyContent={'center'}>
                            <Button disabled={viewMode} onClick={() => { onUpdateBooleanFields("is_inspection_test", request.is_inspection_test) }}>
                                {
                                    request.is_inspection_test ?
                                        <CheckCircle color='success' /> :
                                        <RemoveCircle color="error" />
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} container>
                    <Grid container justifyContent={'center'}>
                        <Grid><Typography text={"OH"} align='center' /></Grid>
                        <Grid container justifyContent={'center'}>
                            <Button disabled={viewMode} onClick={() => { onUpdateBooleanFields("is_oh", request.is_oh) }}>
                                {
                                    request.is_oh ?
                                        <CheckCircle color='success' /> :
                                        <RemoveCircle color="error" />
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} container>
                    <Grid container justifyContent={'center'}>
                        <Grid><Typography text={"MOD'S"} align='center' /></Grid>
                        <Grid container justifyContent={'center'}>
                            <Button disabled={viewMode} onClick={() => { onUpdateBooleanFields("is_mods", request.is_mods) }}>
                                {
                                    request.is_mods ?
                                        <CheckCircle color='success' /> :
                                        <RemoveCircle color="error" />
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} container>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12}><Typography text={"Repair"} align='center' /></Grid>
                        <Grid container justifyContent={'center'}>
                            <Button disabled={viewMode} onClick={() => { onUpdateBooleanFields("is_repair", request.is_repair) }}>
                                {
                                    request.is_repair ?
                                        <CheckCircle color='success' /> :
                                        <RemoveCircle color="error" />
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Permissions;