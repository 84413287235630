import { useState, useEffect, FunctionComponent, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Switch, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Input, Dialog, Select } from '../../MaterialUiComponents';
import { Dictionary } from '../../components';
import { RootState } from '../../reducer';
import UserApi from '../../api/userApi';
import SubcontractsApi from './../../api/subContracts';
import { dictionary } from '../../types/general';
import { User } from './../../types/user';

const useStyles = makeStyles(theme => ({
    createButton: {
        backgroundColor: '#407BAC',
        color: 'white',
        fontSize: '1em',
    },
}));

type CertificateProps = {
    setLoading: Function
}

// Basic dictionary page using the dictionary component so easy to change style of all dictionary pages if needed.
const EditSubcontracts: FunctionComponent<CertificateProps> = ({ setLoading }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [subContracts, setSubContracts] = useState(Array<User>());
    const [newEmployeeName, setNewEmployeeName] = useState("");
    const [newSSN, setNewSSN] = useState('');
    const [newEmployeeNumber, setNewEmployeeNumber] = useState('');
    const [newJobTitle, setNewJobTitle] = useState("");
    const [newDepartment, setNewDepartment] = useState("");
    const [selectedUser, setSelectedUser] = useState<User>();
    const store = useSelector((state: RootState) => state);
    const [showActive, setShowActive] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [allCompanies, setAllCompanies] = useState(Array<dictionary>());
    const [selectedCompany, setSelectedCompany] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refresh = () => {
        setLoading(true);
        setDeleteDialogOpen(false);
        UserApi.getAllUsers().then(res => {
            const subContracts = res.filter(s => s.company_name !== "Icelandair");
            setSubContracts(subContracts);
            dispatch({ type: "GET_ALL_EMPLOYEES", all_employees: res });
            setLoading(false);
        })
        SubcontractsApi.GetAllCompanies().then(res => {
            if (res.status === true) {
                setAllCompanies(res.data.filter(s => s.visible));
            }
        })
    }

    const addSubContractor = () => {
        const companyId = allCompanies.find(s => s.name === selectedCompany)!.id;
        SubcontractsApi.AddNewSubContract(newEmployeeName, newJobTitle, newDepartment, newSSN, newEmployeeNumber, companyId, store.user.me.user_id).then(res => {
            if (res.status === true) {
                refresh();
                setNewDepartment("");
                setNewEmployeeName("");
                setNewEmployeeNumber("");
                setNewJobTitle("");
                setNewSSN("");
                setSelectedCompany("");
                setErrorMessage("");
            } else {
                setErrorMessage(res.message);
            }
        })
    }
    const confirmDelete = () => {
        selectedUser!.deleted_reason = deleteReason;
        SubcontractsApi.DeleteSubContract(selectedUser!).then(res => {
            refresh();
        })
    }
    const onDeleteSubContract = (user: User) => {
        setDeleteDialogOpen(true);
        setSelectedUser(user);
    }
    const returnStringArray = (data: Array<dictionary>) => {
        const temp: Array<string> = data.map(el => el.name);
        return temp;
    }
    return (
        <Grid>
            <Grid xs={12} container alignItems="flex-start" justifyContent="center" >
                <Grid item xs={6}>
                    <Grid container xs={12}>
                        <Grid item xs={4} lg={8}></Grid>
                        <Grid item xs={2} lg={1}>
                            <Typography text="Active" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
                        </Grid>
                        <Grid item xs={2} lg={1}>
                            <Switch
                                checked={!showActive}
                                onChange={() => setShowActive(!showActive)}
                                color="secondary"
                                name="checkedB"
                            />
                        </Grid>
                        <Grid item xs={2} lg={1}>
                            <Typography text="Deleted" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={7}>
                        {
                            subContracts.filter(type => type.visible === showActive).map(row => (
                                <Dictionary name={row.name} object={row} deleted={showActive === true ? false : true} description={showActive === true ? row.company_name : row.deleted_reason} id={row.Id} deleteProperty={(user: User) => onDeleteSubContract(user)}></Dictionary>
                            ))
                        }
                    </Grid>
                    <Grid container xs={5}>
                        <Grid item xs={12}>
                            <br />
                            <Input
                                multiline={false}
                                label_text="Name"
                                help_text="Please type in a name for the new employee"
                                type="text"
                                value={newEmployeeName}
                                width={50}
                                onTextChange={(value: string) => setNewEmployeeName(value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <Input
                                multiline={false}
                                label_text="Job title"
                                help_text="Please type in a job title for the new employee"
                                type="text"
                                value={newJobTitle}
                                width={50}
                                onTextChange={(value: string) => setNewJobTitle(value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <Select onSelectedItem={(e: any) => setNewDepartment(e.target.value)} selectedItem={newDepartment} width={300} filter_by_text={"Department"} options={["Base Maintenance", "Shops", "Other"]} />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <Input
                                multiline={false}
                                label_text="Social security number"
                                help_text="Please type in a social security number for the new employee"
                                type="text"
                                value={newSSN}
                                width={50}
                                onTextChange={(value: string) => setNewSSN(value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <Input
                                multiline={false}
                                label_text="Employee Hr number"
                                help_text="Please type in a Hr number for the new employee"
                                type="text"
                                value={newEmployeeNumber}
                                width={50}
                                onTextChange={(value: string) => setNewEmployeeNumber(value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            <Select onSelectedItem={(e: any) => setSelectedCompany(e.target.value)} selectedItem={selectedCompany} width={200} filter_by_text={"Choose companies"} options={returnStringArray(allCompanies)} />
                        </Grid>
                        <Grid>
                            <br />
                            <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addSubContractor()} >
                                Add Subcontract
                            </Button>
                            <Typography text={errorMessage} textColor='red' align='center' />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                visible={deleteDialogOpen}
                max_width={"md"} title={"Are you sure you want to delete this?"}
                context={
                    <div>
                        <Box display="flex" justifyContent="center" width="100%" m={1} p={1}>
                            <Input
                                label_text={"Type a reason"} value={deleteReason} help_text=""
                                onTextChange={(value: string) => setDeleteReason(value)} type="text" width={50} multiline={false} />
                        </Box>

                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <Box p={1} >
                                <Button variant="contained" color="primary" onClick={() => confirmDelete()}>Confirm delete</Button>
                            </Box>
                            <Box p={1}>
                                <Button variant="contained" color="secondary" onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                            </Box>
                        </Box>
                    </div>
                }
                onClose={(status: any) => setDeleteDialogOpen(false)}
                strict={false}
                show_options={false}
            />
        </Grid>
    );
};
export default EditSubcontracts;
