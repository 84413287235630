import { settings } from '../config';
import { ClothingRequests, ClothingObj, clothingData } from '../types/clothRequests';
import { getToken } from './../global/TokenValidator';
class ClothingRequestApi {
    GetAllClothingDictionariesTypes = async (): Promise<ClothingRequests> => {
        return fetch(`${settings.HOST}/ClothingRequests/GetAllClothingDictionariesTypes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    AddNewClothingRequests = async (clothing_requests: ClothingObj): Promise<ClothingRequests> => {
        return fetch(`${settings.HOST}/ClothingRequests/AddNewClothingRequests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(clothing_requests)
        }).then(x => {
            return x.json()
        })
    }
    GetAllClothingRequests = async (order_by: string, ascending: boolean): Promise<ClothingRequests> => {
        return fetch(`${settings.HOST}/ClothingRequests/GetAllClothingRequests/${order_by}/${ascending}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetClothingRequestByEmployeeId = async (order_by: string, ascending: boolean, employee_id: number): Promise<ClothingRequests> => {
        return fetch(`${settings.HOST}/ClothingRequests/GetClothingRequestByEmployeeId/${order_by}/${ascending}/${employee_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllClothingRequestsForOverview = async (order_by: string, ascending: boolean): Promise<{ data: clothingData[] }> => {
        return fetch(`${settings.HOST}/ClothingRequests/GetAllClothingRequestsForOverview/${order_by}/${ascending}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    GetClothingRequests = async (from: Date | null = null, to: Date | null = null, employeeID: number | null, order_by: string, ascending: boolean): Promise<{ data: clothingData[] }> => {
        return fetch(`${settings.HOST}/ClothingRequests/GetClothingRequests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    from: from,
                    to: to,
                    order_by: order_by,
                    ascending: ascending,
                    employee_id: employeeID,
                }
            )
        }).then(x => {
            return x.json()
        })
    }
}
const ClothingRequestsApi = new ClothingRequestApi();
export default ClothingRequestsApi;