import { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, TableRow, TableCell, IconButton } from '@mui/material';
import { User } from "./../../types/user";
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import { Table, Typography, Input } from './../../MaterialUiComponents/';
import { Close } from '@mui/icons-material';
import ProfileImg from '../Images/ProfileImg';
import userApi from '../../api/userApi';

type EmployeeSearchProps = {
  place_holder: string,
  onSelectedEemployee: Function,
  reset?: boolean,
  maxRows?: number
  inputWidth?: number,
  defaultEmployee?: string,
  clearOnSelect?: boolean,
  fullUser?: boolean,
  absolute?: boolean,
  filterByIds?: number[],
  required?: boolean,
  searchByAbbreviation?: boolean; // To search for abbreviated names instead of regular names
  autoFocusOnInput?: boolean;
  customCloseFunction?: Function;
  disabled?: boolean;
  showDisabledEmployees?: boolean;
}

const useStyles = makeStyles(theme => ({
  userPhoto: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: "50%"
  },
  userContainer: {
    display: "flex",
  },

  tableRow: {
    cursor: "pointer",
  },
  resultsTable: {
    position: 'absolute',
    backgroundColor: '#F1F1F1',
    zIndex: 100,
    border: '2px solid black',
    top: '50px'
  },
}));

const EmployeeSearch: FunctionComponent<EmployeeSearchProps> = ({ customCloseFunction, place_holder, onSelectedEemployee, required = false, reset = false, maxRows, inputWidth = 100, defaultEmployee = "", clearOnSelect = false, fullUser = false, absolute = false, filterByIds = [], searchByAbbreviation = false, autoFocusOnInput = false, disabled = false, showDisabledEmployees = false }) => {
  const classes = useStyles();
  const [employeeSearchString, setEmployeeSearchString] = useState(defaultEmployee);
  const [employees, setFilteredEmployees] = useState(Array<User>());
  const store = useSelector((state: RootState) => state);
  const [allEmployees, setAllEmployees] = useState<User[]>(store.employees.all_employees.filter(s => s.visible));

  useEffect(() => {
    if (reset === true) {
      resetSearch();
    }
    if (filterByIds.length > 0) {
      let tempEmployees: Array<User> = [];
      tempEmployees = store.employees.all_employees.filter(s => s.visible).filter(s => filterByIds.includes(s.Id));
      
      setAllEmployees(tempEmployees);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, filterByIds, store])

  //Store doesn't load the employees fast enough, this updates allEmployees when the store updates
  useEffect(() => {
    
      const tempEmployees = store.employees.all_employees.filter(s => s.visible);
    
      if(showDisabledEmployees === true)
      {
        userApi.getDisabledUsers().then(res => {
          setAllEmployees([...tempEmployees, ...res]);  
        });
      }
      else
      {
        if (allEmployees.length <= 0) {
          setAllEmployees(tempEmployees);
        }
      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.employees.all_employees]);

  const generateTableBody = (row: User, index: number) => {
    return (
      <TableRow className={classes.tableRow} onClick={() => onSelectRow(row.Id)} key={row.Id}>
        <TableCell>{index}</TableCell>
        <TableCell className={classes.userContainer}>
          <ProfileImg imgKey={index} userName={row.userName?.split('@')[0]} />
          <Typography align="left" text={row.name} cursor='pointer'></Typography>
        </TableCell>
      </TableRow>
    )
  }
  
  const resetSearch = () => {
    customCloseFunction && customCloseFunction(); //IF customCloseFunction not undefined run customCloseFunction
    setFilteredEmployees([]);
    setEmployeeSearchString("");
    if (fullUser) {
      onSelectedEemployee(null);
    }
    else {
      onSelectedEemployee(0);
    }
  }
  const onSelectRow = (id: number) => {
    const employees = allEmployees.filter(s => s.user_id === id);
    if (clearOnSelect === true) {
      setEmployeeSearchString("");
    }
    else {
      setEmployeeSearchString(employees[0].name);
    }
    if (fullUser === true) {
      onSelectedEemployee(employees[0]);
    }
    else {
      onSelectedEemployee(id);
    }
    setFilteredEmployees([]);
  }

  const onEmployeeSearchString = (value: string) => {
    if (allEmployees.length < 50) {
      setAllEmployees(store.employees.all_employees.filter(s => s.visible));
    }
    if (value.length === 0 || value.length === null) {
      resetSearch();
    }
    else {
      let tempEmployees = allEmployees.filter(s => s.name.toUpperCase().startsWith(value.toUpperCase()));
      searchByAbbreviation
        ? setFilteredEmployees(allEmployees.filter(s => s.employee_abbreviation != null && s.employee_abbreviation.toUpperCase().startsWith(value.toUpperCase())))
        : setFilteredEmployees(tempEmployees);
      setEmployeeSearchString(value);
    }
  }

  return (
    <FormControl fullWidth={true} variant="standard">
      <Input
        multiline={true}
        label_text={place_holder}
        value={employeeSearchString}
        help_text=""
        required={required}
        onTextChange={(value: string) => onEmployeeSearchString(value)}
        type="text"
        width={inputWidth}
        icon={employeeSearchString.length > 0 ? <IconButton onClick={() => resetSearch()}><Close color='error' /></IconButton> : <div></div>}
        iconPlacement="end"
        autoFocus={autoFocusOnInput}
        disabled={disabled}
      />
      {employees.length > 0 &&
        <div className={absolute ? classes.resultsTable : ''}>
          <Table
            headers={["Employee"]}
            rows={employees}
            generateBody={(row: any, index: number) => generateTableBody(row, index)}
            defaultRowsPerPage={5}
            OnOrderByTitles={(order_by_text: string) => alert(order_by_text)}
          />
        </div>}
    </FormControl>
  );
};
export default EmployeeSearch;