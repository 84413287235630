import { FunctionComponent } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { CalendarVacationCell } from '../../types/vacation';
import { makeStyles } from '@mui/styles';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar as any);

type BasicCalendarProps = {
  defaultView: 'month' | 'week' | 'work_week' | 'day',
  events: Array<CalendarVacationCell>,
  resizableEvent: false | true,
  onEventClick: Function
}

const useStyles = makeStyles(theme => ({
  event: {
    padding: theme.spacing(0.1, 1, 0.1, 1),
    borderRadius: "4px"
  }
}));

const BasicCalendar: FunctionComponent<BasicCalendarProps> = ({ defaultView, events, resizableEvent, onEventClick }) => {
  const classes = useStyles();

  const CustomEvent = (event: any) => {
    return (
      <div style={{ backgroundColor: event.event.color }} className={classes.event}>
        <div>{event.event.title}</div>
      </div>
    )
  }

  const onEventResize = (data: any) => {

  };

  const onEventDrop = (data: any) => {
  };


  return (
    <div className="App">
      <style>{
        ".rbc-time-content {\
          display:none\
        }\
        .rbc-label{\
          display:none\
        }\
        .rbc-time-view{\
          display:grid\
        }\
        .rbc-allday-cell{\
          overflow-y: auto;\
          height: 85vh !important;\
        }\
        .rbc-today{\
          background-color: #8d7dff !important\
        }\
        .rbc-event{\
          background-color:transparent !important;\
        }"
      }
      </style>
      <DnDCalendar
        defaultDate={moment().toDate()}
        views={['month']}
        events={events}
        defaultView={defaultView}
        localizer={localizer}
        onDragStart={(e: any) => {
          if (resizableEvent === true) {
            const scroll = document.getElementsByClassName("rbc-allday-cell")[0] as HTMLElement;
            scroll.scrollTop = 0;
          }
        }}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        resizable={resizableEvent}
        style={{ height: "85vh", }}
        components={{
          event: CustomEvent
        }}
        onSelectEvent={(e: any) => onEventClick(e)}
        onSelectSlot={(e: any) => { }}
      />
    </div>
  );
}

export default BasicCalendar;