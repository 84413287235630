import { settings } from '../config';
import { getToken } from '../global/TokenValidator';
import { Me, User } from '../types/user';

class UserApi {
    getUserByUserName = async (): Promise<{ message: string, me: Me }> => {
        return fetch(`${settings.HOST}/User/getUserByUserName`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    getAllUsers = async (): Promise<User[]> => {
        return fetch(`${settings.HOST}/User/getAllUsers/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    
    CreateUser = async (userName: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/User/CreateUser/${userName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    getDisabledUsers = async (): Promise<User[]> => {
        return fetch(`${settings.HOST}/User/getDisabledUsers/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

}

export default new UserApi();