import { FunctionComponent, useEffect, useState } from "react";
import { Grid, TableCell, TableRow } from '@mui/material';
import { Table, Typography, Dialog } from './../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import AccessControlApi from '../../api/accessControl';
import { EmpInGroup } from '../../types/accessControl';
import { EmployeeSearch } from '../../components'
import { User } from "../../types/user";
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import ShowGroups from "./ShowGroups";

const useStyles = makeStyles(theme => ({
    center: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    groups: {
        marginTop: '8px',
        height: '50px',
    }
}));

type UserHistoryProps = {
    setLoading: Function,
}

const UserHistory: FunctionComponent<UserHistoryProps> = ({ setLoading }) => {
    const [employeeInAccessGroups, setEmployeeInAccessGroups] = useState<EmpInGroup[]>();
    const [employeePermissionHistory, setEmployeePermissionHistory] = useState<EmpInGroup[]>();
    const [empUserGroupHistory, setEmpUserGroupHistory] = useState<EmpInGroup[]>();
    const [employeeInUserGroups, setEmployeeInUserGroups] = useState<EmpInGroup[]>();
    const [employee, setEmployee] = useState<User | undefined>();
    const [permissionModalInfo, setPermissionModalInfo] = useState<EmpInGroup | undefined>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const classes = useStyles();
    const store = useSelector((state: RootState) => state);
    const currentUserId = store.user.me.user_id;

    useEffect(() => {
        GetUserHistoryByEmployeeId(currentUserId);
    }, [])

    const onEmployeeClicked = (employeeId: number) => {
        GetUserHistoryByEmployeeId(employeeId);
    }

    const GetUserHistoryByEmployeeId = (employeeId: number) => {
        const user: User | undefined = store.employees.all_employees.find(s => s.visible === true && s.Id === employeeId);
        setEmployee(user);
        setLoading(true);
        AccessControlApi.GetUserHistoryByEmployeeId(employeeId).then(res => {
            setEmployeeInUserGroups(res.employeeInUserGroups);
            setEmpUserGroupHistory(res.employeeInUserGroupsHistory);
            setEmployeeInAccessGroups(res.groups);
            setEmployeePermissionHistory(res.data);
            setLoading(false);
        })
    }

    const onClickedRow = (row: EmpInGroup) => {
        setOpenModal(true);
        setPermissionModalInfo(row);
    }

    const generateTableBody = (row: EmpInGroup) => {
        return (
            <TableRow sx={{ backgroundColor: `${row.removed_date ? "#FFC4C4" : "#C2F2C7"}` }} onClick={() => onClickedRow(row)}>
                <TableCell > {row.group_name} </TableCell>
                <TableCell> {row.created_date}  {`${row.removed_date && `- `}` + row.removed_date}</TableCell>
                <TableCell>{row.created_reason?.substring(0, 50)}</TableCell>
                <TableCell>{row.removed_reason?.substring(0, 50)}</TableCell>
                <TableCell>{row.starts_at}</TableCell>
                <TableCell>{row.expiration_date}</TableCell>
            </TableRow>
        )
    }

    return (
        <Grid item >
            <Grid container direction="row">
                <Grid item className={classes.center}>
                    <Grid className={classes.center}>
                        <EmployeeSearch
                            place_holder={"Write employees name"}
                            onSelectedEemployee={(employeeId: number) => onEmployeeClicked(employeeId)}
                        />
                    </Grid>
                </Grid>
                <Grid item className={classes.center}>
                    {employee !== undefined &&
                        <Grid>
                            <Grid>
                                <ShowGroups groups={employeeInAccessGroups} groupType="Access" />
                            </Grid>
                            <Grid style={{ marginTop: '18px' }}>
                                <ShowGroups groups={employeeInUserGroups} groupType="User" />
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid style={{ marginTop: '40px' }}>
                <Typography align="center" fontWeight="bold" margin={[1, 1, 1, 1]} text={`${employee?.name !== undefined ? `Access group Permission history for ${employee?.name}` : ""}`} />
                {employeePermissionHistory !== undefined &&
                    <Table
                        headers={["GROUP", "FROM/TO", "CREATED REASON", "REMOVED REASON", "STARTS AT", "EXPIRES AT"]}
                        rows={employeePermissionHistory}
                        generateBody={(row: any) => generateTableBody(row)}
                        pagination={true}
                        defaultRowsPerPage={10}
                        skipIndexCol={true}
                    />
                }
            </Grid>
            <Grid style={{ marginTop: '40px' }}>
                <Typography align="center" fontWeight="bold" margin={[1, 1, 1, 1]} text={`${employee?.name !== undefined ? `User group Permission history for ${employee?.name}` : ""}`} />
                {empUserGroupHistory !== undefined &&
                    <Table
                        headers={["GROUP", "FROM/TO", "CREATED REASON", "REMOVED REASON", "STARTS AT", "EXPIRES AT"]}
                        rows={empUserGroupHistory}
                        generateBody={(row: any) => generateTableBody(row)}
                        pagination={true}
                        defaultRowsPerPage={10}
                        skipIndexCol={true}
                    />
                }
            </Grid>
            <Dialog
                visible={openModal}
                max_width={"sm"}
                title={"Permission event details"}
                context={
                    <Grid>{permissionModalInfo !== undefined &&
                        <Grid>
                            <Grid>
                                <Grid container xs={12}>
                                    <Grid xs={2} />
                                    <Grid xs={4}>
                                        <Typography alignSelf="center" align="center" textColor="black" fontWeight="bolder" text={`Created reason :`} />
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography
                                            alignSelf="center"
                                            align="center"
                                            fontWeight="bold"
                                            text={`${(permissionModalInfo.created_reason === null || permissionModalInfo.created_reason === undefined) ? "No reason provided" : `${permissionModalInfo.created_reason}`}`}
                                        />
                                        <Grid xs={2} />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid xs={2} />
                                    <Grid xs={4}>
                                        <Typography alignSelf="center" align="center" textColor="black" fontWeight="bolder" text={`Deleted reason :`} />
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography
                                            alignSelf="center"
                                            align="center"
                                            fontWeight="bold"
                                            text={`${(permissionModalInfo.removed_reason === null || permissionModalInfo.removed_reason === undefined) ? "No reason provided" : `${permissionModalInfo.removed_reason}`}`}
                                        />
                                    </Grid>
                                    <Grid xs={2} />
                                </Grid>
                                <Grid container>
                                    <Grid xs={2} />
                                    <Grid xs={4}>
                                        <Typography alignSelf="center" align="center" textColor="black" fontWeight="bolder" text={`Created by :`} />
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography
                                            alignSelf="center"
                                            align="center"
                                            fontWeight="bold"
                                            text={`${store.employees.all_employees.find(x => x.Id === permissionModalInfo.created_by_employee_id)?.name}`}
                                        />
                                    </Grid>
                                    <Grid xs={2} />
                                </Grid>
                                <Grid container>
                                    <Grid xs={2} />
                                    <Grid xs={4}>
                                        <Typography alignSelf="center" align="center" textColor="black" fontWeight="bolder" text={`Deleted by :`} />
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography
                                            alignSelf="center"
                                            align="center"
                                            fontWeight="bold"
                                            text={`${store.employees.all_employees.find(x => x.Id === permissionModalInfo.removed_by_employee_id)?.name}`}
                                        />
                                    </Grid>
                                    <Grid xs={2} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    </Grid>
                }
                onClose={(status: any) => { setOpenModal(status) }}
                strict={false}
                show_options={false}
            />
        </Grid>
    )
}
export default UserHistory;