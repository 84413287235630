import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducer';
import { Grid, TableRow, TableCell, Checkbox } from '@mui/material';
import { Typography, Table, Dialog } from './../../../MaterialUiComponents';
import { ToolData } from '../../../types/tool';
import { makeStyles } from '@mui/styles';
import { EmployeeSearch, ProfileImg } from './../../../components';
import toolsApi from './../../../api/tools';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    marginLeft: "35%"
  },
  userPhoto: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: "50%"
  },
  userContainer: {
    display: "flex"
  },
}));

function ToolsLateReturns() {
  const store = useSelector((state: RootState) => state);
  const [filtered_tools, setFilteredTools] = useState(Array<ToolData>());
  const [late_return_tools_for_employee, setLateReturnsForEmployee] = useState(Array<ToolData>());
  const [order_by_text, setOrderByText] = useState("Created date");
  const [ascending, setAscending] = useState(false);
  const [late_returns_dialog_open, setLateReturnDialogOpen] = useState(false);
  const [employee_id, setEmployeeId] = useState(0);
  const classes = useStyles();
  const users = useSelector((state: RootState) => state.employees.all_employees);

  useEffect(() => {
    refresh(employee_id, order_by_text, ascending);
  }, [employee_id, order_by_text, ascending]);

  const generateTableBody = (row: ToolData, index: number) => {
    return (
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell>{row.created_date_text}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.bay}</TableCell>
        <TableCell>{row.area}</TableCell>
        <TableCell>{row.out_date_text}</TableCell>
        <TableCell>{row.in_date_text}</TableCell>
        <TableCell>{row.extended_loan === true ? <Checkbox style={{ color: "green" }} checked={true} /> : ""}</TableCell>
      </TableRow>
    )
  }

  const generateTableBodyForAllEMployees = (row: ToolData, index: number) => {
    return (
      <TableRow onClick={() => onRowClick(row)}>
        <TableCell>{index}</TableCell>
        <TableCell className={classes.userContainer}>
          <ProfileImg imgKey={index} userName={users.find(s => s.Id === row.employee_id)?.userName?.split('@')[0]} />
          <Typography margin={[1, 0, 0, 0]} align="left" text={row.employee_name}></Typography>
        </TableCell>
        <TableCell>{row.late_returns_counter}</TableCell>
      </TableRow>
    )
  }

  const refresh = (employee_id: Number, order_by_text: string, ascending: boolean) => {
    if (late_returns_dialog_open === false) {
      toolsApi.getLateToolReturns(order_by_text, ascending, employee_id).then(res => {
        setFilteredTools(res);
      });
    } else {
      toolsApi.getLateToolReturnsByEmployee(order_by_text, ascending, employee_id).then(res => {
        setLateReturnsForEmployee(res);
      });
    }
  }

  const onRowClick = (row: ToolData) => {
    toolsApi.getLateToolReturnsByEmployee(order_by_text, ascending, row.employee_id).then(res => {
      setLateReturnDialogOpen(true);
      setLateReturnsForEmployee(res);
      setEmployeeId(row.employee_id);
    });
  }

  const OnOrderByTitles = (given_order_by_text: string) => {
    let temp_ascending = true;
    if (order_by_text === given_order_by_text) { temp_ascending = ascending === false ? true : false; }
    setAscending(temp_ascending);
    setOrderByText(given_order_by_text);
    refresh(employee_id, given_order_by_text, temp_ascending);
  }

  const onSelectedEmployeeRow = (employee_id: number) => {
    setEmployeeId(employee_id);
    refresh(employee_id, order_by_text, ascending);
  };
  const closeLateReturnDialog = (status: boolean) => {
    setEmployeeId(0);
    refresh(0, order_by_text, ascending);
    setLateReturnDialogOpen(false);
  }
  return (
    <Grid item xs={12}>
      <Typography text={"Search for users history"} align="center" variant="h2" />
      <Typography text={`Total available (${filtered_tools.length})`} align="center" variant={"body1"} />
      <Grid container xs={4} className={classes.searchContainer}>
        <EmployeeSearch place_holder={"Write employees name"} onSelectedEemployee={(employee_id: number) => onSelectedEmployeeRow(employee_id)} />
      </Grid>
      <Table
        headers={["Name", "Late returns"]}
        rows={filtered_tools}
        generateBody={(row: any, index: number) => generateTableBodyForAllEMployees(row, index)}
        defaultRowsPerPage={100}
        OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
      />
      <Dialog
        visible={late_returns_dialog_open}
        max_width={"xl"} title={`History for late returns ${store.employees.all_employees.find(s => s.user_id === employee_id)?.name}`}
        context={
          <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
            <Table
              headers={["Created date", "Description", "Bay", "Area", "Loaned out", "Returned", "Extended loan"]}
              rows={late_return_tools_for_employee}
              generateBody={(row: any, index: number) => generateTableBody(row, index)}
              defaultRowsPerPage={100}
              OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
            />
          </Grid>
        }
        onClose={(status: any) => closeLateReturnDialog(status)}
        strict={false}
        show_options={false}
      />
    </Grid>
  );
};
export default ToolsLateReturns;