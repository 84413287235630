import { useState, useEffect } from 'react';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Table, Typography } from '../../../MaterialUiComponents';
import { ToolData } from '../../../types/tool';
import ToolsApi from '../../../api/tools';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  headerRow: {
    backgroundColor: 'red',
    padding: theme.spacing(2, 0, 2, 0),
    borderRadius: theme.spacing(2)
  }
}));

function LoanedToolForToLong() {
  const classes = useStyles();
  const [loanedOutTools, setLoanedOutTools] = useState(Array<ToolData>());

  useEffect(() => {
    document.getElementById("header")!.style.display = "none";
    ToolsApi.getAllLoanedToolsbyDays(0, "All positions", 0, "empty", true, false).then(res => {
      setLoanedOutTools(res.filter(s => s.tool_to_long_in_circulations === true))
    });
  }, []);

  const generateTableBody = (row: ToolData, index: number) => {
    return (
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell>{row.employee_name}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.bay}</TableCell>
        <TableCell>{row.area}</TableCell>
        <TableCell>{row.serial}</TableCell>
        <TableCell>{row.out_date_text}</TableCell>
        <TableCell>{row.days_allowed}</TableCell>
      </TableRow>
    )
  }
  return (
    <Grid xs={12} item>
      <Grid className={classes.headerRow}>  <Typography color={'primary'} variant={'h1'} align={'center'} text={`Most wanted tools (${loanedOutTools.length})`} /></Grid>
      <Typography variant={'body1'} align={'center'} text={new Date().toLocaleString()} />
      <Table
        headers={["Name", "Description", "Bay", "Area", "Serial", "Loaned out", "Allowed Days"]}
        rows={loanedOutTools}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        pagination={false}
        OnOrderByTitles={(order_by_text: string) => { }}
      />
    </Grid>
  );
};
export default LoanedToolForToLong