import { useState } from 'react';
import { Grid } from '@mui/material';
import { Tabs } from '../../../MaterialUiComponents';
import LoanedOutTools from './LoanedOutTools';
import ToolsOnStock from './ToolsOnStock';
import UserHistory from './UserHistory';
import ToolsLateReturns from './ToolsLateReturns';
import DefectiveReports from './DefectiveReports';
import UserCards from './UserCards';
import SyncMaintenerMxiTools from './SyncMaintenerMxiTools';

function ToolRegister() {
  const [tabValue, setTabValue] = useState(0);
  return (
    <Grid xs={12} item>
      <Tabs
        tabNames={["Tools loaned out", "Search for tools on stock", "User History", "Denied", "Defective", "User Cards", "Update Tools"]}
        onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
        tabValue={tabValue}
      />
      {tabValue === 0 && <LoanedOutTools />}
      {tabValue === 1 && <ToolsOnStock />}
      {tabValue === 2 && <UserHistory />}
      {tabValue === 3 && <ToolsLateReturns />}
      {tabValue === 4 && <DefectiveReports />}
      {tabValue === 5 && <UserCards />}
      {tabValue === 6 && <SyncMaintenerMxiTools />}
    </Grid>
  );
};
export default ToolRegister