import { FunctionComponent, useEffect, useState } from 'react';
import lineApi from '../../api/line';
import { LinePackets } from '../../types/line';
import { CommentsObj } from '../../types/messages';
import Messages from '../../components/Messages/Messages';

interface LineWorkpackageNotesProps {
    task: LinePackets,
    refreshLine?: Function,
    isEditMode: Boolean
}

const LineWorkpackageNotes: FunctionComponent<LineWorkpackageNotesProps> = ({ task, isEditMode, refreshLine = () => { } }) => {
    const [allComments, setAllComments] = useState<CommentsObj[]>([]);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        lineApi.GetLineMessagesByWoBarcode(task!.wo_barcode as string).then(res => {
            setAllComments(res.data)
        });
    }
    const onDelete = (commentId: number) => {
        lineApi.DeleteLineMessage(commentId).then(res => {
            if (res) {
                refresh();
            }
            refreshLine();
        });
    }

    const onAddComment = (message: string) => {
        if (message !== "") {
            lineApi.CreateLineMessage(task!.wo_barcode as string, message).then(res => {
                if (res) {
                    refresh();
                }
                refreshLine();
            });
        }
    }
    return (
        <Messages
            isEditMode={isEditMode}
            allComments={allComments}
            onAddComment={(message: string) => onAddComment(message)}
            onDelete={(commentId: number) => onDelete(commentId)}
        />
    )
}

export default LineWorkpackageNotes;