import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Grid, IconButton, Paper } from '@mui/material';
import { informationRow } from '../../types/security';
import SecurityApi from '../../api/security';
import { Dialog, Input, Typography } from '../../MaterialUiComponents';
import { Edit, AddBox, Delete, AccountCircle } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import ProfileImg from '../../components/Images/ProfileImg';

const useStyles = makeStyles(theme => ({
    commentsContainer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1),
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    myCommentsContainer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1),
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    userPhoto: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        borderRadius: "50%",
        marginTop: theme.spacing(1)
    },
    deleteComment: {
        color: theme.palette.common.white
    },
    addMessage: {
        position: "relative",
        left: theme.spacing(2),
        top: "5px",
        marginBottom: theme.spacing(2)
    },
    addMessageIcon: {
        position: "absolute",
    }
}));

type InformationPageProps = {
    isSecurityAdmin: Boolean
}

const InformationPage: FunctionComponent<InformationPageProps> = ({ isSecurityAdmin }) => {
    const classes = useStyles();
    const [allInformationRows, setAllInformationRows] = useState<informationRow[]>([]);
    const [allDates, setAllDates] = useState<string[]>([]);
    const [commentDialogOpen, setCommentDialogOpen] = useState<boolean>(false);
    const [deleteCommentDialogOpen, setDeleteCommentDialogOpen] = useState<boolean>(false);
    const [commentToAdd, setCommentToAdd] = useState<string>("");
    const [commentType, setCommentType] = useState<"create" | "update">("create");
    const [selectedComment, setSelectedComment] = useState<informationRow>();
    const today = new Date();
    const formattedToday = "" + (today.getDate() <= 9 ? "0" + today.getDate() : today.getDate()) + "." + ((today.getMonth() + 1) <= 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + "." + today.getFullYear();
    const formattedYesterday = "" + ((today.getDate() - 1) <= 9 ? "0" + (today.getDate() - 1) : (today.getDate() - 1)) + "." + ((today.getMonth() + 1) <= 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + "." + today.getFullYear();
    const store = useSelector((state: RootState) => state);
    const user = store.user.me;
    const users = store.employees.all_employees;
    const [imageErrors, setImageErrors] = useState<Record<string, boolean>>({});

    useEffect(() => {
        refresh();
    }, []);

    const onCommentDialogClose = (status: boolean) => {
        if (status && commentType === "create") {
            SecurityApi.CreateNewInformationRow(user.user_id, commentToAdd).then(res => {
                refresh();
            });
        }
        if (status && commentType === "update" && selectedComment !== undefined) {
            SecurityApi.UpdateInformationRow(commentToAdd, selectedComment.id, user.user_id).then(res => {
                refresh();
            });
        }
        setCommentToAdd("");
        setCommentDialogOpen(false);
    }

    const refresh = () => {
        let tempDates: string[] = [];
        SecurityApi.GetAllInformationRows().then(res => {
            setAllInformationRows(res.data.sort(function (a, b) {
                return new Date(b.created_date).getTime() - new Date(a.created_date).getTime();
            }));
            res.data.map(comment => {
                if (tempDates.some(date => date === comment.created_date_text.substring(0, 10)) === false) {
                    tempDates.push(comment.created_date_text.substring(0, 10));
                }
            });
            setAllDates(tempDates);
        });
    }

    const openCommentDialog = (type: "create" | "update", comment?: informationRow) => {
        setCommentType(type);
        setCommentDialogOpen(true);
        if (type === "update" && comment !== undefined) {
            setSelectedComment(comment);
            setCommentToAdd(comment.text);
        }
    }

    const openDeleteCommentDialog = (comment?: informationRow) => {
        setDeleteCommentDialogOpen(true);
        setSelectedComment(comment);
    }

    const onDeleteCommentDialogClose = (status: boolean) => {
        if (status === true && selectedComment !== undefined) {
            deleteComment(selectedComment.id);
            setDeleteCommentDialogOpen(false);
        } else if (status === false) {
            setDeleteCommentDialogOpen(false);
        }
    }

    const deleteComment = (commentId: number) => {
        SecurityApi.DeleteInformationRow(commentId, user.user_id).then(res => {
            refresh();
        });
    }

    const lessThanAWeekAgo = (date: Date) => {
        const now = new Date().getTime();
        const oneWeekAgo = now - 7 * 24 * 60 * 60 * 1000;
        return date.getTime() > oneWeekAgo;
    }

    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
        const target = event.target as HTMLImageElement;
        setImageErrors((prevErrors) => ({
            ...prevErrors,
            [target.src]: true,
        }));
    }

    return (
        <Grid container>
            {isSecurityAdmin && <Grid item xs={12}>
                <div className={classes.addMessage}>
                    <Button className={classes.addMessageIcon} endIcon={<AddBox />} variant="contained" color="primary" size="medium" onClick={() => openCommentDialog("create")}>Add Message</Button>
                </div>
            </Grid>}
            {allDates.map(date => (
                <Grid container direction={"row"}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <Typography text={date === formattedToday ? "Today" : date === formattedYesterday ? "Yesterday" : date} textDecoration variant="h2" align="center" margin={[0, 0, 2, 0]} />
                        {allInformationRows.filter(filteredRow => filteredRow.created_date_text.substring(0, 10) === date).map((row, index) => (
                            // Old Paper, shows the messages from user as yellow
                            // <Paper variant="elevation" elevation={5} className={row.last_modified_by_name === null && row.created_by_name === user.display_name ? classes.myCommentsContainer : row.last_modified_by_name === user.display_name ? classes.myCommentsContainer : classes.commentsContainer}>
                            // New Paper, shows the messages from the last week as yellow
                            // row.created_date acts like a string even though it is classified as a Date, that's why it is constructed into a new Date
                            <Paper key={row.id} variant="elevation" elevation={5} className={lessThanAWeekAgo(new Date(row.created_date.toString())) ? classes.myCommentsContainer : classes.commentsContainer}>
                                <Grid container direction={"row"}>
                                    <Grid item xs={1} justifyContent="center">
                                        {/* If the <img> returns an error, show profile icon */}
                                        {imageErrors[row.created_by_user_image] ?
                                            <AccountCircle className={classes.userPhoto}></AccountCircle>
                                            :
                                            <ProfileImg imgKey={index} userName={users?.find(s => s.Id === row.created_by_employee_id)?.userName?.split('@')[0]} />
                                       }
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container>
                                            <Grid item xs={"auto"}>
                                                <Typography align="left" display="inline" text={(row.last_modified_by_name !== null ? row.last_modified_by_name : row.created_by_name) + " - " + (row.last_modified_date_text !== "" ? "edited " + row.last_modified_date_text : row.created_date_text)} variant="caption" />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography display="inline" customFontSize={20} text={row.text} variant="body1" />
                                        </Grid>
                                    </Grid>
                                    {user.display_name === row.created_by_name && isSecurityAdmin &&  //No user id in row object
                                        <Grid item xs={1}>
                                            <IconButton onClick={() => openCommentDialog("update", row)} className={classes.deleteComment}><Edit color={lessThanAWeekAgo(new Date(row.created_date.toString())) ? "primary" : "inherit"} /></IconButton>
                                            <IconButton onClick={() => openDeleteCommentDialog(row)} className={classes.deleteComment}><Delete color="error" /></IconButton>
                                        </Grid>
                                    }
                                </Grid>
                            </Paper>
                        ))}
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            ))}
            <Dialog
                visible={commentDialogOpen}
                max_width="md"
                title="Please type in your comment"
                show_options={true}
                strict={false}
                onClose={(status: boolean) => { onCommentDialogClose(status) }}
                context={
                    <Grid container justifyContent="center">
                        <Input multiline={true} help_text={"Type in your comment"} label_text={"Comment to be added"} value={commentToAdd} onTextChange={(text: string) => { setCommentToAdd(text) }} type="text" width={80} />
                    </Grid>
                }
            />
            <Dialog
                visible={deleteCommentDialogOpen}
                max_width="md"
                title="Are you sure you want to delete the message?"
                show_options={true}
                strict={false}
                onClose={(status: boolean) => { onDeleteCommentDialogClose(status) }}
                context={selectedComment && <Grid>
                    <Typography align="left" display="inline" text={selectedComment.text} variant="body1" color="primary" />
                </Grid>}
            />
        </Grid>
    );
};
export default InformationPage;
