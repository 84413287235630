import { useState } from 'react';
import { Grid, Button, CircularProgress, TableRow, TableCell } from '@mui/material';
import { Typography, Table } from '../../../MaterialUiComponents';
import toolsApi from '../../../api/tools';
import { makeStyles } from '@mui/styles';
import { UpdateTool } from '../../../types/tool';

const useStyles = makeStyles(theme => ({
    Button: {
        marginBottom: theme.spacing(4),
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

function SyncMaintenerMxiTools() {
    const classes = useStyles();
    const [isUpdatedTools, setIsUpdatedTools] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isUpdateing, setIsUpdating] = useState<boolean>(false);
    const [addedTools, setAddedTools] = useState<UpdateTool[]>([]);
    const [updatedTools, setUpdatedTools] = useState<UpdateTool[]>([]);

    const onSyncTools = () => {
        setIsUpdating(true);
        toolsApi.UpdateToolsLocation().then(res => {
            if (res.status === true) {
                setUpdatedTools(res.updatedTools);
                setAddedTools(res.addedTools);
                setMessage(res.message);
                setIsUpdatedTools(true);
            }
            else {
                setMessage(res.message);
                setIsUpdatedTools(false);
            }
            setIsUpdating(false);
        });
    }

    const generateToolsTableBody = (row: UpdateTool, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''}>
                <TableCell>{index}</TableCell>
                <TableCell>{row.toolName}</TableCell>
                <TableCell>{row.toolLocation}</TableCell>
                {row.previousToolLocation && <TableCell>{row.previousToolLocation}</TableCell>}
            </TableRow>
        )
    }

    return (
        <Grid>
            <Grid container direction="row">
                <Grid item style={{ marginTop: '16px' }}>
                    <Button variant="contained" color='primary' className={classes.Button} onClick={() => onSyncTools()}>Update tool locations</Button>
                </Grid>
                <Grid item style={{ marginTop: '16px' }}>
                    {isUpdateing && <CircularProgress style={{ marginLeft: '8px', marginTop: '3px' }} size="2rem" />}
                </Grid>
            </Grid>
            <Grid>
                {(isUpdatedTools && !isUpdateing) && <Typography align='center' variant='h3' text={message} margin={[0, 0, 0, 0]} />}
            </Grid>
            {(isUpdatedTools && !isUpdateing) &&
                <Grid>
                    <Grid style={{ marginTop: '50px' }} >
                        <Typography align='center' variant='h3' text={"Added tools"} />
                        <Table
                            headers={["Tool name", "Location"]}
                            rows={addedTools}
                            generateBody={(row: UpdateTool, index: number) => generateToolsTableBody(row, index)}
                            pagination={true}
                            defaultRowsPerPage={10}
                        />
                    </Grid>
                    <Grid style={{ marginTop: '100px' }}>
                        <Typography align='center' variant='h3' text={"Updated tools"} />
                        <Table
                            headers={["Tool name", "New location", "Old location"]}
                            rows={updatedTools}
                            generateBody={(row: UpdateTool, index: number) => generateToolsTableBody(row, index)}
                            pagination={true}
                            defaultRowsPerPage={10}
                        />
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default SyncMaintenerMxiTools;
