import { useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, Tabs, Typography } from '../../MaterialUiComponents';
import Overview from './Overview';
import NewWorldTrip from './NewWorldTrip';
import WorldToolsApi from './../../api/worldTools'
import { destinations, WorldTrip } from '../../types/worldTools';
import { RootState } from '../../reducer';
import { useSelector } from 'react-redux';
import EditDestinations from './EditDestinations';
import { Autocomplete } from '@mui/material';
import taskApi from './../../api/tasks';

const useStyles = makeStyles(theme => ({
    resultsAmount: {
        marginTop: theme.spacing(1),
        color: 'gray'
    },
    autoCompleteBox: {
        marginRight: theme.spacing(4)
    },
    resetButton: {
        height: '30px',
        marginTop: theme.spacing(2)
    },
}));

// Main World Tools Page. Fetches all the World Trips. Contains 3 tabs, Overview page, create/edit page and dictionary edit page
function WorldTools() {
    const store = useSelector((state: RootState) => state);
    const isWorldToolAdmin = store.user.me.user_clearence_groups.includes("World Tools Edit");
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedTrip, setSelectedTrip] = useState<WorldTrip>();
    const [allWorldTrips, setAllWorldTrips] = useState<WorldTrip[]>();
    const [allDestinations, setAllDestinations] = useState<destinations[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [destination, setDestination] = useState<destinations | null>(null);
    const [locationId, setLocationId] = useState<number>(-1);
    const [status, setStatus] = useState<string | null>(null);
    const [tailNumber, setTailNumber] = useState<string | null>(null);
    const statusOptions = ["In Work", "Not Started", "Tools missing", "Complete"];
    const [tails, setTails] = useState<string[]>([]);

    useEffect(() => {
        refresh();
        if (allDestinations.length === 0) {
            WorldToolsApi.GetAllWorldTripLocations().then(res => {
                setAllDestinations(res.data.filter(destination => destination.visible === true));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, locationId, status, tailNumber]);

    useEffect(() => {
        taskApi.GetAllTailNumbers().then(res => {
            setTails(res.data);
        });
    }, []);

    const refresh = () => {
        WorldToolsApi.GetAllWorldTrips(startDate, endDate, locationId, status, tailNumber).then(res => {
            setAllWorldTrips(res.data);
        });
    }

    const changeTab = (tabValue: number) => {
        if (tabValue === 0) {
            onResetFields();
            refresh();
        }
        setSelectedTrip(undefined);
        setTabValue(tabValue);
    }

    const openTrip = (index: number) => {
        if (allWorldTrips !== undefined) {
            setSelectedTrip(allWorldTrips[index - 1]);
        }
    }
    const closeForm = () => {
        refresh();
        setSelectedTrip(undefined);
        changeTab(0);
    }
    const setSelectedLocation = (location: destinations | null) => {
        if (location !== null) {
            setDestination(location);
            setLocationId(location.id);
        }
        else {
            setDestination(null);
            setLocationId(-1);
        }
    }
    const onResetFields = () => {
        setStartDate(null);
        setEndDate(null);
        setDestination(null);
        setLocationId(-1);
        setStatus(null);
        setTailNumber(null);
    }
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Tabs
                tabNames={isWorldToolAdmin ? ["Overview", "Create new world trip", "Edit Destinations"] : ["Overview"]}
                onChangeTabValue={(tabValue: number) => changeTab(tabValue)}
                tabValue={tabValue}
            />
            {tabValue === 0 && allWorldTrips !== undefined && selectedTrip === undefined &&
                <Grid item xs={12}>
                    <Grid container>
                        <Grid container item sm={5} lg={2}>
                            <DatePicker type="Date" selected={startDate} label="Start date from" onDateChange={(date: Date) => setStartDate(date)} />
                        </Grid>
                        <Grid container item sm={5} lg={2}>
                            <DatePicker type="Date" selected={endDate} label="Start date to" onDateChange={(date: Date) => setEndDate(date)} />
                        </Grid>
                        <Grid container item sm={5} lg={2} >
                            <Autocomplete
                                fullWidth
                                className={classes.autoCompleteBox}
                                noOptionsText="No tails found"
                                options={allDestinations}
                                getOptionLabel={option => option.location}
                                defaultValue={destination}
                                value={destination}
                                clearOnEscape
                                autoHighlight
                                onChange={(e: any, newValue: destinations | null) => setSelectedLocation(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Destination" variant="standard" />
                                )}
                            />
                        </Grid>
                        <Grid container item sm={5} lg={2} >
                            <Autocomplete
                                fullWidth
                                className={classes.autoCompleteBox}
                                noOptionsText="No status found"
                                options={statusOptions}
                                value={status}
                                clearOnEscape
                                autoHighlight
                                onChange={(e: any, newStatus: any | null) => setStatus(newStatus)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Status" variant="standard" />
                                )}
                            />
                        </Grid>
                        <Grid container item sm={5} lg={2} >
                            <Autocomplete
                                fullWidth
                                className={classes.autoCompleteBox}
                                options={tails}
                                value={tailNumber}
                                noOptionsText="No tails found"
                                clearOnEscape
                                autoHighlight
                                onChange={(e: any, newValue: string | null) => setTailNumber(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tails" margin="none" variant="standard" size="small" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid container item sm={5} lg={2} >
                            <Button variant="text" className={classes.resetButton} color="primary" size="small" onClick={() => onResetFields()}>Reset filters</Button>
                        </Grid>
                        <Grid item xs={12} className={classes.resultsAmount}>
                            <Typography text={allWorldTrips !== undefined ? allWorldTrips.length + " reports found" : '0 reports found'} />
                        </Grid>
                    </Grid>
                    <Overview userId={store.user.me.user_id} allWorldTrips={allWorldTrips} refresh={() => refresh()} openTrip={(index: number) => openTrip(index)} />
                </Grid>
            }
            {(tabValue === 1 || selectedTrip !== undefined) &&
                <Grid item xs={12}>
                    <NewWorldTrip allDestinations={allDestinations} selected={selectedTrip} closeForm={() => closeForm()} />
                </Grid>
            }
            {tabValue === 2 &&
                <Grid item xs={12}>
                    <EditDestinations />
                </Grid>
            }
        </Grid>
    );
}

export default WorldTools;