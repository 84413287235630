import { useState, useEffect } from 'react';
import { RootState } from '../../../reducer';
import { useSelector } from 'react-redux'
import { Grid, TableRow, TableCell, Switch } from '@mui/material';
import { Typography, Table, Select } from './../../../MaterialUiComponents';
import toolsApi from '../../../api/tools';
import { ToolData } from '../../../types/tool';
import { makeStyles } from '@mui/styles';
import { EmployeeSearch, EditAllowedDays } from '../../../components';
import { ReturnNameData } from '../../../components/Tools';

const useStyles = makeStyles(theme => ({
  selectContainer: {
    justifyContent: "space-evenly"
  },
  toolToLongInCirculation: {
    backgroundColor: 'red'
  }
}));

function LoanedOutTools() {
  const store = useSelector((state: RootState) => state);
  const isToolAdmin = store.user.me.user_clearence_groups.includes("Tool Stock");
  const [loaned_days, setLoanedDays] = useState("current day");
  const [tool_positon, setToolPosition] = useState("All positions");
  const [employee_id, setEmployeeId] = useState(0);
  const [selected_row, setSelectedRow] = useState({} as ToolData);
  const [days_allowed, setDaysAllowed] = useState("");
  const [order_by_text, setOrderByText] = useState("empty");
  const [allowed_days_dialog_open, setAllowedDaysDialogOpen] = useState(false);
  const [ascending, setAscending] = useState(true);
  const [loaned_out_tools, setLoanedOutTools] = useState(Array<ToolData>());
  const [tooLong, setTooLong] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    const escFunction = (event: any) => {
      if (event.keyCode === 27) {
        setLoanedDays("current day");
        setToolPosition("All positions");
        setEmployeeId(0);
      }
    }
    document.addEventListener('keydown', escFunction, false);
    refreshData(loaned_days, tool_positon, employee_id, order_by_text, ascending);
    const returnInput = document.getElementById('textfield');
    returnInput?.focus();
  }, [loaned_days, tool_positon, order_by_text, ascending, employee_id, tooLong]);


  const generateTableBody = (row: ToolData, index: number) => {
    return (
      <TableRow className={row.tool_to_long_in_circulations === true ? classes.toolToLongInCirculation : ""}>
        <TableCell>{index}</TableCell>
        <TableCell>
          <ReturnNameData
            employee_id={row.employee_id}
            tail_number={row.tail_number}
            world_trip_name={row.world_trip_name}
            employee_image={row.employee_image}
            employee_name={row.employee_name}
          />
        </TableCell>
        <TableCell>{row.tail_number}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.bay}</TableCell>
        <TableCell>{row.area}</TableCell>
        <TableCell>{row.serial}</TableCell>
        <TableCell>{row.out_date_text}</TableCell>
        <TableCell onClick={() => onSelectedDaysAllowed(row)}>{row.days_allowed}</TableCell>
      </TableRow>
    )
  }

  const OnOrderByTitles = (given_order_by_text: string) => {
    let temp_ascending = true;
    if (order_by_text === given_order_by_text) { temp_ascending = ascending === false ? true : false; }
    setAscending(temp_ascending);
    setOrderByText(given_order_by_text);
  }

  const onSelectedDaysAllowed = (row: ToolData) => {
    if (isToolAdmin) {
      setDaysAllowed(row.days_allowed.toString());
      setSelectedRow(row);
      setAllowedDaysDialogOpen(true);
    }
  }

  const onSelectedLoanedDays = (value: string) => setLoanedDays(value);
  const onSelectToolPosition = (value: string) => setToolPosition(value);
  const onDaysAllowedChange = (days: number) => { setDaysAllowed(days.toString()) }

  function refreshData(loaned_days: string, tool_positon: string, employee_id: number, order_by_text: string, ascending: boolean) {
    if (employee_id === null) { employee_id = 0; }
    const loaned_days_int = loaned_days === "current day" ? 0 : parseInt(loaned_days);
    toolsApi.getAllLoanedToolsbyDays(loaned_days_int, tool_positon, employee_id, order_by_text, ascending, tooLong).then(res => {
      setLoanedOutTools(res);
    });
  }
  const closeAllowedDaysDialog = (status: boolean) => {
    if (status === true) {
      selected_row.days_allowed = parseInt(days_allowed);
      toolsApi.editDaysAllowed(selected_row).then(res => {
        refreshData(loaned_days, tool_positon, employee_id, order_by_text, ascending);
      });
    }
    setAllowedDaysDialogOpen(false);
  }
  return (
    <Grid xs={12} item>
      <Typography text={`Loaned out tools overview (${loaned_out_tools.length})`} variant={'h2'} align={"center"} />
      <Typography text={"Press Esc to clear filters"} variant={'h5'} align={'center'} />
      <Grid container className={classes.selectContainer}>
        <Grid item xs={2}> <Select onSelectedItem={(e: any) => onSelectedLoanedDays(e.target.value)} selectedItem={loaned_days.toString()} width={200} filter_by_text={"Loaned day's ago"} options={["current day", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "20", "30", "40", "50", "100"]} /> </Grid>
        <Grid item xs={2}> <Select onSelectedItem={(e: any) => onSelectToolPosition(e.target.value)} selectedItem={tool_positon} width={200} filter_by_text={"Tools position"} options={["All positions", "Bay 1", "Bay 2", "Bay 3", "Bay 4", "Bay 5","Bay 6", "Bay 7", "Line", "Shops", "885 Hanger", "Other", "World Trips"]} /> </Grid>
        <Grid container xs={2}>
          <Grid item xs={4}>
            <Typography text="Late Returns" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
          </Grid>
          <Grid item xs={4} >
            <Switch
              checked={!tooLong}
              onChange={() => setTooLong(!tooLong)}
              color="secondary"
              name="checkedB"
            />
          </Grid>
          <Grid item xs={3} >
            <Typography text="Full List" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
          </Grid>
        </Grid>
        <Grid item xs={4}> <EmployeeSearch place_holder={"Write employees name"} onSelectedEemployee={(employee_id: number) => setEmployeeId(employee_id)} /> </Grid>
      </Grid>
      <Table
        headers={["Name", "Tail number", "Description", "Bay", "Area", "Serial", "Loaned out", "Allowed Days"]}
        rows={loaned_out_tools}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        pagination={false}
        OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
      />
      <EditAllowedDays
        visible={allowed_days_dialog_open}
        tool_description={selected_row.description}
        closeAllowedDaysDialog={(status: any) => closeAllowedDaysDialog(status)}
        days_allowed={days_allowed}
        onDaysAllowedChange={(days: number) => onDaysAllowedChange(days)}
      />
    </Grid>
  );
};
export default LoanedOutTools