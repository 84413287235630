
import { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Input, Typography, Table } from '../../MaterialUiComponents';
import { Grid, IconButton, TableRow, TableCell } from '@mui/material'
import EditColumn from '../Security/EditColumn'
import { AddCircle } from '@mui/icons-material';
import { Request, CapabilitySubAssys } from '../../types/capabilityList';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

interface SubAssyProps {
    request: Request,
    viewMode: boolean,
    setRequest: Function,
}

const SubAssy: FunctionComponent<SubAssyProps> = ({ request, setRequest, viewMode }) => {

    const classes = useStyles();
    const [subAssy, setSubAssy] = useState<string>("");
    const [acceptedText, setAcceptedText] = useState<string>("");
    const [subAssyEditIndex, setSubAssyEditIndex] = useState<number>(-1);

    const generateTableBody = (row: CapabilitySubAssys, index: number) => {
        const onModifieSubAssy = () => {
            if (index === subAssyEditIndex) {
                const updatedcapability = request.capability_sub_assys.map((obj, idx) => {
                    if (index - 1 === idx) {
                        return { ...obj, part_number: subAssy, accepted_text: acceptedText }; // Replace the object with updated text
                    }
                    return obj; // Keep the rest of the objects unchanged
                });

                setRequest((prevState: Request) => ({
                    ...prevState,
                    capability_sub_assys: updatedcapability,
                }));
                setSubAssyEditIndex(-1);
            }
        }
        const onDeleteSubAssy = () => {
            const updatedcapability = request.capability_sub_assys.map((obj, idx) => {
                if (index - 1 === idx) {
                    return { ...obj, is_deleted: true }; // Replace the object with updated text
                }
                return obj; // Keep the rest of the objects unchanged
            });

            setRequest((prevState: Request) => ({
                ...prevState,
                capability_sub_assys: updatedcapability,
            }));
            setSubAssyEditIndex(-1);
        }
        //This is a hack to hide the deleted rows so I don't have to filter out the deleted rows before I send the whole thing to the backend
        // Then in the backend I will filter out the deleted rows before I save it to the database based on is_deleted.
        if (row.is_deleted === true) {
            return null;
        }
        else {
            return (
                subAssyEditIndex === index ?
                    <TableRow className={index % 2 ? classes.evenRows : ''} >
                        <TableCell>
                            <Input
                                multiline={true}
                                help_text={""}
                                label_text={"Part number"}
                                value={subAssy}
                                onTextChange={(text: string) => setSubAssy(text)}
                                type="text"
                                width={100}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                multiline={true}
                                help_text={""}
                                label_text={"Accepted text"}
                                value={acceptedText}
                                onTextChange={(text: string) => setAcceptedText(text)}
                                type="text"
                                width={100}
                            />
                        </TableCell>
                        <TableCell> </TableCell>
                        <EditColumn
                            abort={true}
                            index={index}
                            saveChanges={() => onModifieSubAssy()}
                            deleteRow={() => onDeleteSubAssy()}
                            editRow={subAssyEditIndex}
                            setEditRow={(editRow: number) => { setSubAssyEditIndex(-1) }}
                        />
                    </TableRow>
                    :
                    <TableRow className={index % 2 ? classes.evenRows : ''} >
                        <TableCell>{row.part_number}</TableCell>
                        <TableCell>{row.accepted_text}</TableCell>
                        <TableCell>{row.created_date_text}</TableCell>
                        {!viewMode && <EditColumn
                            abort={true}
                            index={index}
                            saveChanges={() => { setSubAssyEditIndex(-1); setSubAssy(row.part_number); setAcceptedText(row.accepted_text) }}
                            deleteRow={() => { onDeleteSubAssy(); }}
                            editRow={subAssyEditIndex}
                            setEditRow={(editRow: number) => { setSubAssyEditIndex(index); setSubAssy(row.part_number); setAcceptedText(row.accepted_text) }}
                        />
                        }
                    </TableRow>
            )
        }
    }

    const onAddSubAssy = () => {
        const updatedObject: Request = {
            ...request, // Spread the current state object
            capability_sub_assys: [...request.capability_sub_assys, { part_number: "", accepted_text: "", is_deleted: false }],
        };
        setRequest(updatedObject);
        setSubAssyEditIndex(request.capability_sub_assys.length + 1);
        setSubAssy("");
        setAcceptedText("");
    }

    return (
        <Grid container justifyContent="center" direction="column">
            <Grid item>
                <Typography color='primary' variant='h2' text={"Sub Assy"} align='center' />
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item style={{ width: '80%' }}>
                    <Table
                        headers={["Sub Assy", "Accepted Text", "Date"]}
                        rows={request.capability_sub_assys.filter(s => s.is_deleted === false)}
                        generateBody={(row: any, index: number) => generateTableBody(row, index)}
                        pagination={false}
                        skipIndexCol={true}
                    />
                </Grid>
            </Grid>
            {!viewMode && <Grid container direction="column" alignItems="center" style={{ marginTop: '28px' }}>
                <IconButton onClick={() => onAddSubAssy()}>
                    <AddCircle color='success' />
                </IconButton>
            </Grid>}
        </Grid>
    )
}
export default SubAssy;
