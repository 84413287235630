import { settings } from '../config';
import { Event, Part, MyLineTask } from '../types/tasks';
import { getToken } from './../global/TokenValidator';

class Tasks {
  GetTasksOnCalendar = async (woNumber: string): Promise<{ status: Boolean, data: Event[] }> => {
    return fetch(`${settings.HOST}/ScheduleTasks/GetTasksOnCalendar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apikey': settings.KONG_KEY,
        'token': await getToken(),
      },
      body: JSON.stringify({
        wo_number: woNumber
      })
    }).then(x => {
      return x.json()
    })
  }
  GetLineTasksForMe = async (): Promise<{ status: Boolean, data: MyLineTask[] }> => {
    return fetch(`${settings.HOST}/Line/GetLineTasksForMe `, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'apikey': settings.KONG_KEY,
        'token': await getToken(),
      },
    }).then(x => {
      return x.json()
    })
  }
  GetAllBayTasksForMe = async (): Promise<{ status: Boolean, message: Boolean, data: Event[] }> => {
    return fetch(`${settings.HOST}/ScheduleTasks/GetAllBayTasksForMe`, {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        'apikey': settings.KONG_KEY,
        'token': await getToken(),
      },
    }).then(x => {
      return x.json()
    })
  }
  GetAllPartsByTaskBarcode = async (barcode: string): Promise<{ status: Boolean, data: Part[] }> => {
    return fetch(`${settings.HOST}/Parts/GetAllPartsByTaskBarcode/${barcode}`, {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        'apikey': settings.KONG_KEY,
        'token': await getToken(),
      },
    }).then(x => {
      return x.json()
    })
  }
  GetPartsByWoBarcode = async (wo_number: string): Promise<{ data: Part[] }> => {
    return fetch(`${settings.HOST}/packet/GetPartsByWoBarcode/${wo_number}`, {
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
        'apikey': settings.KONG_KEY,
        'token': await getToken(),
      },
    }).then(x => {
      return x.json()
    })
  }
  GetAllTailNumbers = async (): Promise<{ status: Boolean, data: string[] }> => {
    return fetch(`${settings.HOST}/ScheduleTasks/GetAllTailNumbers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'apikey': settings.KONG_KEY,
        'token': await getToken(),
      },
    }).then(x => {
      return x.json()
    })
  }
}
const TasksApi = new Tasks();
export default TasksApi;