import { FunctionComponent, useState, useEffect } from 'react';
import { Grid, TableCell, TableRow } from '@mui/material';
import { Typography, Table, } from '../../MaterialUiComponents';
import TasksApi from '../../api/tasks';
import { Part } from '../../types/tasks';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

type PartDetailsProps = {
    taskBarcode: string
}

const PartDetails: FunctionComponent<PartDetailsProps> = ({ taskBarcode }) => {

    const [parts, setParts] = useState<Part[]>([]);
    useEffect(() => {
        TasksApi.GetAllPartsByTaskBarcode(taskBarcode).then(res => {
            if (res.status === true)
                setParts(res.data);
            else {
                setParts([]);
            }
        })
    }, [taskBarcode])

    const generateTableBody = (row: Part, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{index}</TableCell>
                <TableCell>{row.part_request_barcode}</TableCell>
                <TableCell>{row.part_number}</TableCell>
                <TableCell>{row.part_description}</TableCell>
                <TableCell>{row.part_serial_number}</TableCell>
                <TableCell>{row.part_requested_amount}</TableCell>
                <TableCell>{row.part_status}</TableCell>
                <TableCell>{row.estimated_arrival_date_string}</TableCell>
            </TableRow>
        )
    }

    const classes = useStyles();
    return (
        <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
            <Grid>
                <Typography text={`Parts(${parts.length})`} variant='h2' align='center' margin={[2, 0, 2, 0]} />
                {parts.length > 0 &&
                    <Table
                        headers={["Req", "PN", "Description", "SN", "Qty", "Status", "ETA"]}
                        rows={parts}
                        generateBody={(row: any, index: number) => generateTableBody(row, index)}
                        pagination={false}
                        skipIndexCol={false}
                    />
                }
            </Grid>
        </Grid>
    );
};
export default PartDetails;
