import { FunctionComponent, useState, useEffect } from 'react';
import { Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducer';
import { TableRow, TableCell } from '@mui/material';
import { ToolData } from '../../../types/tool';
import { Typography, Snackbar, Dialog, Table, Select } from './../../../MaterialUiComponents';
import toolsApi from './../../../api/tools';
import taskApi from './../../../api/tasks';
//E2806810000000395110F235
//E280116060000207B7F499DF RFID example,E280116060000207B7F4A13C, 

const useStyles = makeStyles(theme => ({
  TypographyContainer: {
    marginTop: "1%",
    marginLeft: "2%"
  },
  deleteIcon: {
    color: "red",
    cursor: "pointer",
    marginTop: theme.spacing(-2)
  },
  daysAllowedStyle: {
    cursor: "pointer"
  }
}));

type ToolRegisterListProps = {
  tabValue: number,
  onChangeTabValue: Function
}

//TODO: perhaps this should be in the database
//When CDATA is missing
const exampleTails: string[] = [
  'TF-FIA',
  'TF-FIC',
  'TF-FIG',
  'TF-FIH',
  'TE-FIK',
  'TE-FIN',
  'TF-FIO',
  'TF-FIP',
  'TF-FIU',
  'TF-FIV',
  'TF-FIX',
  'TF-FXG',
  'TE-EXH',
  'TE-ICA',
  'TE-ICB',
  'TE-ICC',
  'TE-ICD',
  'TE-ICE',
  'TE-ICG',
  'TE-ICI',
  'TE-ICL',
  'TE-ICM',
  'TE-ICN',
  'TE-ICO',
  'TE-ICP',
  'TE-ICR',
  'TF-ICS',
  'TF-ICU',
  'TF-ICY',
  'TE-ISH',
  'TF-ISJ',
  'TE-ISN',
  'TF-ISO',
  'TE_ISR',
  'TF-ISV',
  'TE_ISW',
  'TF-ISX',
  'TE-LLL',
]

const ToolRegisterList: FunctionComponent<ToolRegisterListProps> = ({ tabValue, onChangeTabValue }) => {
  const [scanInput, setScanInput] = useState("");
  const [selected_tools, setSelectedTools] = useState(Array<ToolData>());
  const [extended_tools, setExtendedTools] = useState(Array<ToolData>());
  const [refresh, setRefresh] = useState(false);
  const [snackbar_message, setSnackbarMessage] = useState("");
  const [extended_tools_dialog_visible, setExtendedToolsDialog] = useState(false);
  const [showAreYouSureDialog, setShowAreYouSureDialog] = useState(false);
  const [employeeUserId, setEmployeeUserId] = useState(0);
  const classes = useStyles();
  const store = useSelector((state: RootState) => state);
  const [tails, setTails] = useState<string[]>(exampleTails);
  const [selectedTail, setSelectedTail] = useState<string>("");
  const [selectTailDisabled, setSelectTailDisabled] = useState<boolean>(true);

  useEffect(() => {
    const escFunction = (event: any) => {
      if (event.keyCode === 27) {
        setSelectedTools([]);
        setScanInput("");
      }
      // F9 toggles click event for Tools Out
      if (event.keyCode === 120) { onChangeTabValue(0) }
      // F10 toggles click event for Return Tool Box
      if (event.keyCode === 121) { event.preventDefault(); onChangeTabValue(1) }
      if (event.keyCode === 13) { document.getElementById("press-Enter")?.click() }
    }
    //const url = new URL(window.location.href.replace("#",""));
    //const numb = url.searchParams.get("value");
    //onChangeTabValue(numb)
    document.addEventListener('keydown', escFunction, false);
    setSelectedTools([]);
    taskApi.GetAllTailNumbers().then(res => {
      setTails(res.data);
    })
  }, [onChangeTabValue]);

  const handlerToLoanTools = (extending_tools: boolean, givenEmployeeUserId: number | null, tail: string | null) => {
    toolsApi.loanToolsOut(selected_tools, givenEmployeeUserId, tail, extending_tools, "verkf@icelandair.is").then(res => {
      setSnackbarMessage(res.message);
      if (res.status === false) {
        if (res.borrowed_tools) {
          //user is not allowed to take these tools
          setExtendedTools(res.borrowed_tools);
          setExtendedToolsDialog(true);
        } else {
          //If the plane is not in hangar, reenable the select tail function
          setSelectTailDisabled(false);
        }
      } else {
        setSelectedTools([]);
      }
    });
  }
  const handleEnterPressed = () => {
    const tempScanInput = scanInput.trim();
    if (tempScanInput.length === 3 || tempScanInput.length === 4 || tempScanInput.length === 5 || tempScanInput.length === 6) {
      //User
      const user = store.employees.all_employees.filter(s => s.user_card_code === tempScanInput);
      if (user.length === 0) { alert("This user is not a registered user") }
      if (user.length > 0 && selected_tools.length > 0) {
        setEmployeeUserId(user[0].Id);
        handlerToLoanTools(false, user[0].Id, null);
      }
      setRefresh(!refresh);
      setScanInput("");
    }
    else if (tempScanInput.length === 24) {
      const tool = store.tools.mxi_tools.filter(s => s.rf_id === tempScanInput);
      if (tool.length > 0) {
        const selectedTool = selected_tools.filter(s => s.rf_id === tool[0].rf_id);
        if (selectedTool.length === 0) {
          selected_tools.push(tool[0]);
          setSelectedTools(selected_tools);
          setSelectTailDisabled(false);
        }
        if (tabValue === 1) {
          //Return tool
          toolsApi.loanToolIn(tool[0].mxi_inv_no_id, "verkf@icelandair.is").then(res => {
            if (res.status === false) {
              alert(res.message);
            } else {
              const temp_selected_tools = selected_tools;
              setTimeout(function () {
                temp_selected_tools.shift();
                setRefresh(!refresh);
                if (temp_selected_tools.length === 0) { setSelectedTools([]) }
                setSelectedTools(temp_selected_tools);
              }, 3000);
              setSelectTailDisabled(true);
            }
          });
        }
        setRefresh(!refresh);
        setScanInput("");
      }
      else {
        alert("No Tool whith this RFID");
        setScanInput("");
      }
    }
  }
  const handleModifeRFID = (e: any) => {
    const value = e.target.value.toString().toUpperCase();
    if (value.length >= 25) {
      setScanInput("");
    } else {
      setScanInput(value);
    }
  }

  setInterval(function () {
    if (window.location.href.includes("/tool-register")) {
      try {
        const returnInput = document.getElementById('text-Box-Loan');
        returnInput?.focus();
      } catch { }
    }
  }, 1000);

  const handleDeleteIconClick = (row: ToolData) => {
    const tools = selected_tools.filter(s => s.mxi_inv_no_id !== row.mxi_inv_no_id);
    setSelectedTools(tools);
    if (tools.length <= 0) {
      setSelectTailDisabled(true);
    }
    setRefresh(!refresh);
  }

  const closeExtendedToolsDialog = (status: boolean) => {
    if (status === true) {
      //loan tools out but they will get marked as extended tools. 
      handlerToLoanTools(true, employeeUserId, null);
    }
    setExtendedToolsDialog(false);
    setSelectedTools([]);
    setSelectTailDisabled(true);
  }

  const closeAreYouSureDialog = (status: boolean) => {
    if (status === true) {
      //loan tools out if user approves the "are you sure" dialog
      handlerToLoanTools(false, null, selectedTail);
      setSelectedTail("");
      setSelectTailDisabled(true);
    }
    setShowAreYouSureDialog(false);
    setSelectedTail("");
  }

  const onSelectTail = (tail: string) => {
    setSelectedTail(tail);
    setShowAreYouSureDialog(true);
  }

  const generateTableBody = (row: ToolData, index: number) => {
    return (
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.out_date_text}</TableCell>
        <TableCell>{row.days_allowed} days</TableCell>
      </TableRow>
    )
  }
  return (
    <Grid xs={12} item>
      <br />
      {tabValue === 0 && <Select options={tails} selectedItem={selectedTail} widthPercentage width={10} onSelectedItem={(e: any) => onSelectTail(e.target.value)} filter_by_text="Select Tail" disabled={selectTailDisabled} />}
      <Typography variant='h1' align='center' text={store.tools.mxi_tools.length === 0 ? 'Loading ...' : 'Please Scan your tool'} color="textPrimary"></Typography>
      <Typography variant='h5' align='center' text={"Press Esc to remove list"}></Typography>
      <Typography variant='h6' align='center' color='error' text={scanInput} />
      <input id="text-Box-Loan" value={scanInput} onChange={handleModifeRFID} style={{ position: "absolute", top: 0 }}></input>
      <button id="press-Enter" onClick={() => handleEnterPressed()} style={{ position: "absolute", top: 0 }}></button>
      <Grid>
        {
          selected_tools.map((data, index) => (
            <Grid container className={classes.TypographyContainer}>
              <Typography text={(index + 1).toString()} variant="h3" align="left" color="textPrimary" fontWeight={'bolder'} margin={[0, 2, 0, 0]} />
              <Typography text={"Description : "} variant="h3" align="left" color="textPrimary" fontWeight={'bolder'} margin={[0, 2, 0, 0]} />
              <Typography text={" " + data.description + " "} variant="h3" align="left" color="textPrimary" fontWeight={'normal'} margin={[0, 2, 0, 0]} />
              <Grid className={classes.daysAllowedStyle} onClick={() => { }}><Typography text={"Days allowed : "} variant="h3" align="left" color="textPrimary" fontWeight={'bolder'} margin={[0, 1, 0, 0]} /></Grid>
              <Typography text={data.days_allowed.toString()} variant="h3" align="left" color="textPrimary" fontWeight={'normal'} margin={[0, 5, 0, 0]} />
              <IconButton onClick={() => handleDeleteIconClick(data)}> <DeleteIcon className={classes.deleteIcon} /></IconButton>
            </Grid>
          ))
        }
      </Grid>
      {snackbar_message && snackbar_message.length > 0 && <Snackbar onSnackBarClose={() => setSnackbarMessage("")} open={true} message={snackbar_message} severity='success' autoHide={true} horizontalPosition='right' />}
      <Dialog
        visible={extended_tools_dialog_visible}
        max_width={"md"} title={"Error !!  Tools to long in circulation"}
        context={
          <Table
            headers={["Name", "loaned out", "Allowed days"]}
            rows={extended_tools}
            generateBody={(row: any, index: number) => generateTableBody(row, index)}
            defaultRowsPerPage={100}
            OnOrderByTitles={(order_by_text: string) => alert("REMOVE ?")}
          />
        }
        onClose={(status: any) => closeExtendedToolsDialog(status)}
        strict={true}
        show_options={true}
      />
      <Dialog
        visible={showAreYouSureDialog}
        max_width={"lg"}
        title={"Are you sure?"}
        context={
          <Grid>
            <Typography text={"You are now placing these tools onto " + selectedTail} align="left" color="textPrimary" fontWeight={'bolder'} margin={[0, 2, 0, 0]} />
            {selected_tools.map((data, index) => (
              <Grid container className={classes.TypographyContainer}>
                <Typography text={(index + 1).toString()} variant="h3" align="left" color="textPrimary" fontWeight={'bolder'} margin={[0, 2, 0, 0]} />
                <Typography text={"Description : "} variant="h3" align="left" color="textPrimary" fontWeight={'bolder'} margin={[0, 2, 0, 0]} />
                <Typography text={" " + data.description + " "} variant="h3" align="left" color="textPrimary" fontWeight={'normal'} margin={[0, 2, 0, 0]} />
                <Grid className={classes.daysAllowedStyle} onClick={() => { }}><Typography text={"Days allowed : "} variant="h3" align="left" color="textPrimary" fontWeight={'bolder'} margin={[0, 1, 0, 0]} /></Grid>
                <Typography text={data.days_allowed.toString()} variant="h3" align="left" color="textPrimary" fontWeight={'normal'} margin={[0, 5, 0, 0]} />
              </Grid>
            ))}
          </Grid>
        }
        onClose={(status: any) => closeAreYouSureDialog(status)}
        strict={true}
        show_options={true}
      />
    </Grid>
  );
};
export default ToolRegisterList;