import { FunctionComponent, useEffect, useState } from 'react';
import { Grid, TableRow, TableCell, IconButton } from '@mui/material';
import { Typography, Dialog, Table } from './../../MaterialUiComponents';
import OverviewApi from './../../api/overview';
import { PackageInformationObj } from './../../types/overview';
import { redirectToMxi } from './../../config/mxiProvider';
import { AircraftInHangar } from '../../types/security';
import { Search, Link } from '@mui/icons-material';
import PackageInformation from './PackageInformation';

type SmallPackageInformationprops = {
  packageInformation: AircraftInHangar
}

const SmallPackageInformation: FunctionComponent<SmallPackageInformationprops> = ({ packageInformation }) => {
  const [workpackageStatistic, setWorkPackageStatistic] = useState(Array<PackageInformationObj>());
  const [workpackageDialogOpen, setWorkpackageDialogOpen] = useState(false);
  const [selectedWoNumber, setSelectedWoNumber] = useState("");

  useEffect(() => {
    if (packageInformation.tail_number !== "") {

      OverviewApi.GetPackageInformationByTailNumber(packageInformation.tail_number, ["IN WORK"], false).then(res => {
        if (res.status === true) {
          setWorkPackageStatistic(res.data);
        }
      })
    }
  }, []);

  const generateTableBody = (row: PackageInformationObj, index: number) => {
    return (
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell onClick={() => redirectToMxi(row.wo_barcode)}><IconButton><Link color="primary" /></IconButton></TableCell>
        <TableCell>{row.wo_name}</TableCell>
        <TableCell>{row.wo_actual_start_date_text === "" ? row.wo_schedule_start_date_text : row.wo_actual_start_date_text}</TableCell>
        <TableCell>{row.wo_actual_stop_date_text === "" ? row.wo_schedule_stop_date_text : row.wo_actual_stop_date_text}</TableCell>
        <TableCell onClick={() => { setSelectedWoNumber(row.wo_number); setWorkpackageDialogOpen(true) }}><IconButton><Search color="primary" /></IconButton></TableCell>
      </TableRow>
    )
  }
  return (
    <Grid container>
      <Grid xs={12} item>
        <Typography variant='h2' text={packageInformation.bay_name} align='center' />
      </Grid>
      <Grid xs={12} item>
        <Typography variant='h3' text={packageInformation.tail_number} align='center' />
      </Grid>
      <Grid xs={12} item>
        <Typography text={packageInformation.reason} align='center' />
      </Grid>
      <Grid style={{ margin: 'auto' }} xl={12} lg={12} md={12} xs={12} item>
        {workpackageStatistic.length > 0 &&
          <Table
            headers={["", "Name", "Start", "End", "More"]}
            rows={workpackageStatistic}
            generateBody={(row: any, index: number) => generateTableBody(row, index)}
            defaultRowsPerPage={5}
          />
        }
      </Grid>
      <Dialog
        visible={workpackageDialogOpen}
        max_width={"xl"} title={""}
        context={
          <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
            <PackageInformation woNumber={selectedWoNumber} />
          </Grid>
        }
        onClose={(status: any) => { setWorkpackageDialogOpen(false); setSelectedWoNumber("") }}
        strict={false}
        show_options={false}
      />
    </Grid>
  );
};
export default SmallPackageInformation