import { useState, useEffect, FunctionComponent, } from 'react';
import { Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from '../../MaterialUiComponents';
import { Dictionary } from '../../components';
import ExtraPaymentsAPI from '../../api/extraPayments';
import { paymentType } from '../../types/extraPayments';

const useStyles = makeStyles(theme => ({
    createButton: {
        backgroundColor: '#407BAC',
        color: 'white',
        fontSize: '1em',
    },
}));

// Based on the dictionary page, but currently backend doesn't include all the same features as other dictionary pages. So no delete reason, no description and deleted are not displayable
const EditPaymentTypes: FunctionComponent = () => {
    const [paymentTypes, setPaymentTypes] = useState(Array<paymentType>());
    const [newPaymentType, setNewPaymentType] = useState('');
    const [showActive, setShowActive] = useState(true);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refresh = () => {
        ExtraPaymentsAPI.GetAllSlippsPayments().then(res => {
            setPaymentTypes(res._payments);
        })
    }
    const addPaymentType = () => {
        ExtraPaymentsAPI.AddTypeOfPayment(newPaymentType).then(res => {
            setNewPaymentType("");
            refresh();
        });
    }
    const confirmDelete = (id: number) => {
        ExtraPaymentsAPI.DeleteExistingPayment(id).then(res => {
            refresh();
        });
    }

    const classes = useStyles();
    return (
        <Grid container item justifyContent="center">
            <Grid xs={12} container alignItems="flex-start" justifyContent="center" >
                <Grid item xs={6}>
                    <Grid xs={10} item>
                        {
                            paymentTypes !== undefined && paymentTypes.filter(type => type.visible === showActive).map(row => (
                                <Dictionary name={row.type_name} object={row} deleted={showActive === true ? false : true} description={showActive === true ? '' : ''} id={row.id} deleteProperty={(row: paymentType) => confirmDelete(row.id)}></Dictionary>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid container xs={5}>
                    <Grid item xs={12}>
                        <br />
                        <Input
                            multiline={false}
                            label_text="Payment Type Name"
                            help_text="Please type in a name for the new payment type"
                            type="text"
                            value={newPaymentType}
                            width={50}
                            onTextChange={(value: string) => setNewPaymentType(value)}
                        />
                    </Grid>
                    <br />
                    <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addPaymentType()} >
                        Add Payment Type
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default EditPaymentTypes;
