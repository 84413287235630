import { useEffect, useState } from 'react';
import { Grid } from '@mui/material'
import lineApi from '../../api/line'
import { LinearProgress } from '../../MaterialUiComponents';
import { FlightSchedule } from '../../types/line';
import FlightScheduleComponent from '../../components/Line/FlightScheduleComponent';

function FlightScheduleView() {
    const [flights, setFlights] = useState(Array<FlightSchedule>());
    const [loading, setLoading] = useState<Boolean>(false);

    useEffect(() => {
        document.getElementById("header")!.style.display = "none";
        setLoading(true);
        lineApi.getFlightSchedule().then(res => {
            setFlights(res.data);
            setLoading(false);
        });
    }, []);

    return (
        <Grid>
            {loading === true &&
                <Grid>
                    <LinearProgress />
                </Grid>
            }
            <FlightScheduleComponent flights={flights} isMonitor={true} />
        </Grid>
    );
};
export default FlightScheduleView;