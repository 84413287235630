import { Grid, Paper } from "@mui/material";
import { ReactNode } from "react";

type Props = {
    children: ReactNode | Array<ReactNode>;
    historyElement?: boolean; //Determines whether to color background white og orange
}

const TicketListItem = ({ children, historyElement }: Props) => {
    return (
        <Grid item xs={8} margin={1}>
            <Paper variant="elevation" elevation={4} sx={{ borderRadius: 8, backgroundColor: historyElement === false ? "#f5b53f" : "white" }}>
                <Grid container xs={12} justifyContent="space-between" sx={{ justifyContent: { xs: "center", sm: "center", md: "center", lg: "space-between", xl: "space-between" } }}>
                    {children}
                </Grid>
            </Paper>
        </Grid>
    );
}

export default TicketListItem;