import { FunctionComponent } from 'react';
import { Badge } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    badge: {
        '& .MuiBadge-badge': {
            right: 0,
            top: 7,
            border: `1px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }
}));

type PartAvailableBadgeProps = {
    partAmounts: number,
    isPartNotAvailable: boolean
}

const PartAvailableBadge: FunctionComponent<PartAvailableBadgeProps> = ({ partAmounts, isPartNotAvailable }) => {
    const classes = useStyles();
    return (
        <Badge badgeContent={partAmounts} color="primary" className={classes.badge}>
            <ShoppingCart color={isPartNotAvailable ? "warning" : "primary"} />
        </Badge>
    );
};
export default PartAvailableBadge