import { useEffect } from 'react';

export default function useGlobalKeydownForTools(isToolRegisteruser: boolean) {
    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key === 'F9') {
                event.preventDefault();
                window.location.href = "/#/tool-register?value=0";
            }
            else if (event.key === 'F10') {
                event.preventDefault();
                window.location.href = "/#/tool-register?value=1";
            }
        };
        //Only add event listener if the user has access to the tool register.
        if (isToolRegisteruser) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
}
