import { useEffect, useState } from 'react';
import { pdf, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import IcelandairLogo from '../../assets/IcelandairNewLogo.png';
import { actionItem, warrantyMxiData, warrantyRequest } from '../../types/warranty';
import { CloudDownload } from '@mui/icons-material';

type WarrantyPDFProps = {
    mxiData: warrantyMxiData[],
    warrantyRequest: warrantyRequest,
    actionItems?: actionItem[]
}

// This component displays a download pdf button and also generates the pdf data and styling
const WarrantyPDF: FunctionComponent<WarrantyPDFProps> = ({ mxiData, warrantyRequest, actionItems }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const styles = StyleSheet.create({
        page: {
            paddingTop: 40,
            paddingRight: 40,
            paddingLeft: 40,
            paddingBottom: 60
        },
        container: {
            flexDirection: 'row',
            marginTop: 0,
            marginBottom: 10
        },
        logo: {
            width: 120,
            top: -5,
            right: -20,
            zIndex: 1,
            position: 'absolute'
        },
        pageNumbers: {
            position: 'absolute',
            top: 20,
            left: 20,
            fontSize: 12,
            color: 'gray'
        },
        h1: {
            fontSize: 18,
            textAlign: 'center',
            width: '100%',
            fontFamily: 'Times-Bold',
        },
        footer: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        },
        h2: {
            fontSize: 14,
            textAlign: 'left',
            marginTop: 20,
            fontFamily: 'Times-Bold'
        },
        body: {
            fontSize: 12,
            textAlign: 'left',
            paddingRight: 5
        },
        italic: {
            fontSize: 10,
            textAlign: 'left',
            paddingRight: 5
        },
        underline: {
            fontSize: 12,
            textDecoration: "underline",
            marginTop: 10,
            paddingRight: 5
        },
        partHeader: {
            fontSize: 12,
            fontFamily: 'Times-Bold',
            marginTop: 10,
            marginBottom: 5,
            paddingRight: 5,
            textAlign: 'center'
        },
        bold: {
            fontSize: 12,
            paddingRight: 5,
            fontFamily: 'Times-Bold',

        },
        row: {
            flexDirection: 'row',
        },
        twoCol: {
            width: '50 %',
            marginRight: 15
        },
        threeCol: {
            width: '33%',
            marginRight: 15
        },
        fourCol: {
            width: '22%',
            marginRight: 15
        },
        fullWidth: {
            width: '100%',
        },
        totalSum: {
            textAlign: 'right',
            fontSize: 14,
            fontFamily: 'Times-Bold',
            paddingRight: 40,
            marginTop: 20
        }
    });

    const ratePerHour = 100;
    const [totalPartSum, setTotalPartSum] = useState<string>("");
    const [totalSum, setTotalSum] = useState<string>("");
    const currentDate = new Date();

    const usdFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'usd',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    useEffect(() => {
        let tempPartSum = 0;
        let checkedPartNo: string[] = [];
        mxiData.forEach(part => {
            if (!checkedPartNo.includes(part.part_no)) {
                checkedPartNo.push(part.part_no);
                let tempCount: number = mxiData.filter((tempPart) => tempPart.part_no === part.part_no).length;
                if (part !== undefined && tempCount !== undefined) {
                    tempPartSum = tempPartSum + (part.unit_price * tempCount);
                }
            }
        })
        setTotalPartSum(usdFormatter.format(tempPartSum));
        setTotalSum(usdFormatter.format(tempPartSum + warrantyRequest.shipping_claimed_cost + (warrantyRequest.actual_manhours_worked_total * ratePerHour)));
    }, [mxiData, warrantyRequest])

    // PDF Content is created here, looping through the object arrays
    const PDFContent = () => (
        <Document>
            {/* This parts is for the header. Page numbers are fixed in top left corner of each page */}
            <Page style={styles.page} wrap size="A4">
                <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} of ${totalPages}`
                )} fixed />
                <View style={styles.container}><Text style={styles.h1}>Warranty Claim Request</Text>
                    <Image src={IcelandairLogo} style={styles.logo} />
                </View>
                {/* This part contains general info, first part of the pdf, claim number, company info, bank info etc */}
                <View style={styles.container}>
                    <View style={styles.row}>
                        <View style={styles.twoCol}>
                            <View style={styles.row}>
                                <View style={styles.fullWidth}>
                                    <Text style={styles.bold}>Claim No. {warrantyRequest.warranty_request_id}</Text>
                                    <Text style={styles.italic}>(please refer to this number in any case of response)</Text>
                                </View>
                            </View>
                            <View style={styles.container}>
                                <View style={styles.row}>
                                    <View style={styles.fullWidth}>
                                        <Text style={styles.body}>Icelandair ehf</Text>
                                        <Text style={styles.body}>Reykjavik Airport - 101 Reykjavik</Text>
                                        <Text style={styles.body}>Email: warrantyunit@icelandair.is</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.container}>
                                <View style={styles.row}>
                                    <View style={styles.fullWidth}>
                                        <Text style={styles.underline}>USD Bank information</Text>
                                        <Text style={styles.bold}>Icelandair ehf</Text>
                                        <Text style={styles.bold}>HSBC Bank USA N.A.</Text>
                                        <Text style={styles.body}>452 Fifth Avenue, New York, NY10018</Text>
                                        <Text style={styles.body}>FED ABA# 021001088</Text>
                                        <Text style={styles.body}>Chips ABA# 0108</Text>
                                        <Text style={styles.body}>Swift: MRMDUS33</Text>
                                        <Text style={styles.body}>Account: 000143545</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={styles.twoCol}>
                            <View style={styles.row}>
                                <View style={styles.twoCol}>
                                    <Text style={styles.bold}>Place of issue</Text>
                                    <Text style={styles.body}>Reykjavík</Text>
                                </View>
                                <View style={styles.twoCol}>
                                    <Text style={styles.bold}>Date of issue</Text>
                                    <Text style={styles.body}>{currentDate.toLocaleDateString()}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View>
                                    <Text style={styles.underline}>Accounting Department (credit and cheques)</Text>
                                    <Text style={styles.body}>VAT-ID-NO: 77630</Text>
                                </View>
                            </View>
                            <View style={{ marginTop: '12px' }}>
                                <View style={styles.row}>
                                    <View style={styles.fullWidth}>
                                        <Text style={styles.underline}>EUR Bank information</Text>
                                        <Text style={styles.bold}>Icelandair ehf</Text>
                                        <Text style={styles.bold}>Danske Bank</Text>
                                        <Text style={styles.body}>Holmens Kanal 2, DK-1090 Copenhagen</Text>
                                        <Text style={styles.body}>Denmark</Text>
                                        <Text style={styles.body}>Iban: DK54 3000 4073 0422 08</Text>
                                        <Text style={styles.body}>Swift: DABADKKK</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                {/* This part is for part data info. Loops through all parts and displays data for each one */}
                <View style={styles.row}>
                    <View style={styles.threeCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                                <Text style={styles.bold}>Removal A/C</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.threeCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                                <Text style={styles.bold}>Removal Date</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.threeCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                                <Text style={styles.bold}>A/C Manufactured Date</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.threeCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                                <Text style={styles.body}>{mxiData[0].aircraft}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.threeCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                                <Text style={styles.body}>{mxiData[0].fault_start_date_text}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.threeCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                                <Text style={styles.body}>{mxiData[0].aircraft_manufactured_date_text}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.fullWidth}>
                        <Text style={styles.h2}>Warranty Claim Invoice</Text>

                        {mxiData.map(part => (
                            <View>
                                <Text style={styles.partHeader}>{part.part_description}</Text>
                                <View style={styles.container}>
                                    <View style={styles.twoCol}>
                                        <Text style={styles.body}>Part Number: {part.part_no} </Text>
                                        <Text style={styles.body}>ATA-Chapter: {part.ata_chapter}</Text>
                                        <Text style={styles.body}>TT/TSN (Hours): {part.time_since_new_flight_hours}</Text>
                                    </View>
                                    <View style={styles.twoCol}>
                                        <Text style={styles.body}>Purchase Order: {part.purchase_order_no}</Text>
                                        <Text style={styles.body}>Serial number: {part.part_serial_no}</Text>
                                    </View>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.fullWidth}>
                        {/* This part contains reason for removal text, and also actions taken for fault */}
                        <View style={styles.container}>
                            <View style={styles.row}>
                                <View style={styles.fullWidth}>
                                    <Text style={styles.underline}>Reason for Removal</Text>
                                    <Text style={styles.body}>{warrantyRequest.reason_for_removal}</Text>
                                    <Text style={styles.underline}>Findings, action taken</Text>
                                    {actionItems !== undefined && actionItems.map(action => (
                                        <Text style={styles.body}>{"• " + action.action_text}</Text>
                                    ))}
                                </View>
                            </View>
                        </View>
                        <Text style={styles.underline}>Manhours required to rectify defect</Text>
                        {/* This part contains billiing for manhours worked in fault*/}
                        <View style={styles.container}>
                            <View style={styles.row}>
                                <View style={styles.threeCol}>
                                    <Text style={styles.bold}>Action Item</Text>
                                    <Text style={styles.body}>Manhours</Text>
                                    <Text style={styles.body}>Shipping Cost</Text>
                                </View>
                                <View style={styles.fourCol}>
                                    <Text style={styles.bold}>Quantity</Text>
                                    <Text style={styles.body}>{warrantyRequest.actual_manhours_worked_total_hrs + "hrs, " + warrantyRequest.actual_manhours_worked_total_min + " min"}</Text>
                                    <Text style={styles.body}></Text>
                                </View>
                                <View style={styles.fourCol}>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.bold}>Rate/Hour</Text>
                                            <Text style={styles.body}>{usdFormatter.format(Number(warrantyRequest.mechanic_hourly_rate))}</Text>
                                            <Text style={styles.body}>{usdFormatter.format(warrantyRequest.shipping_claimed_cost)}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.bold}>Total Labour</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.fourCol}>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.bold}>Value</Text>
                                            <Text style={styles.body}>{usdFormatter.format(warrantyRequest.actual_manhours_worked_total * warrantyRequest.mechanic_hourly_rate)}</Text>
                                            <Text style={styles.body}>{usdFormatter.format(warrantyRequest.shipping_claimed_cost)}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.bold}>{usdFormatter.format(warrantyRequest.actual_manhours_worked_total * warrantyRequest.mechanic_hourly_rate + warrantyRequest.shipping_claimed_cost)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* This part contains billing info for each part. Useeffect function contains logic that sums parts together by part number and adds a counter for it*/}
                        {/* Headers for part billing */}
                        <Text style={styles.underline}>Parts required to rectifiy defect</Text>
                        <View style={styles.container}>
                            <View style={styles.row}>
                                <View style={styles.threeCol}>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.bold}>Part num. (serial)</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.fourCol}>
                                    <Text style={styles.bold}>Quantity/Unit</Text>
                                </View>
                                <View style={styles.fourCol}>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.bold}>Value/Unit</Text>
                                        </View>
                                    </View>

                                </View>
                                <View style={styles.fourCol}>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.bold}>Value</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* Data rows for part billing */}
                        {mxiData?.map(part => (
                            <View style={styles.row}>
                                <View style={styles.threeCol}>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.body}>{part.part_no + ' (' + part.part_serial_no + ')'}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.fourCol}>
                                    <Text style={styles.body}>1</Text>
                                </View>
                                <View style={styles.fourCol}>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.body}>{usdFormatter.format(part.unit_price)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.fourCol}>
                                    <View style={styles.row}>
                                        <View style={styles.fullWidth}>
                                            <Text style={styles.body}>{usdFormatter.format(part.unit_price)}</Text>
                                        </View>
                                    </View>

                                </View>
                            </View>
                        ))}
                    </View>
                </View>
                {/* Total sum for both work and parts */}
                <View style={styles.row}>
                    <View style={styles.threeCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                            </View>
                        </View>
                    </View>
                    <View style={styles.fourCol}>
                    </View>
                    <View style={styles.fourCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                                <Text style={styles.bold}>Total parts:</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.fourCol}>
                        <View style={styles.row}>
                            <View style={styles.fullWidth}>
                                <Text style={styles.bold}>{totalPartSum}</Text>
                            </View>
                        </View>

                    </View>
                </View>
                <View style={styles.container}>
                    <View style={styles.row}>
                        <View style={styles.fullWidth}>
                            <Text style={styles.totalSum}>Amount to be paid: {totalSum}</Text>
                        </View>
                    </View>
                </View>
                {/* Footer fixed on each page of the pdf */}
                <View style={styles.footer} fixed>
                    <Text>Icelandair | Reykjavik Airport, 101 Reykjavik | 20.10.2021</Text>
                </View>
            </Page>
        </Document>
    );
    // Set the content as doc variable. Create blob with the content, that can take a while with much data. Then saved as document
    const generatePDF = async () => {
        setLoading(true);
        const doc = <PDFContent />;
        const blob = await pdf(doc).toBlob();
        saveAs(blob, 'Warranty Claim Request.pdf');
        setLoading(false);
    }
    return (
        <Button startIcon={<CloudDownload />} variant="contained" color="primary" size="medium" onClick={() => generatePDF()}>{loading === false ? "Download PDF" : "Please wait..."}</Button>
    );
};
export default WarrantyPDF;
