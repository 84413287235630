import { useState, useEffect, FunctionComponent, useRef } from 'react';
import { Grid, TableRow, TableCell, Button } from '@mui/material';
import { Table, Typography } from './../../MaterialUiComponents';
import { CertificateData, Certificates, RatingsDifference } from './../../types/certificates'
import { RemoveCircle } from '@mui/icons-material';
import Certificate from './Certificate';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import { makeStyles } from '@mui/styles';
import CertificatesApi from './../../api/certificates';
import ProfileImg from '../../components/Images/ProfileImg';

const useStyles = makeStyles(theme => ({
    userPhoto: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: "50%"
    },
    rejected: {
        marginRight: theme.spacing(1),
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    }
}));

type CertificateProps = {
    setLoading: Function,
}

const WaitingForApproval: FunctionComponent<CertificateProps> = ({ setLoading }) => {
    const [certificatesPending, setCertificatesPending] = useState(Array<Certificates>());
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateData>();
    const [order_by_text, setOrderByText] = useState("created_date");
    const [ascending, setAscending] = useState(true);
    const [approver, setApprover] = useState<boolean>(true);
    const ratingsDifference = useRef<RatingsDifference>({ oldRatings: [], newRatings: [], userName: ""});

    const store = useSelector((state: RootState) => state);
    const userGroups = store.user.me.user_clearence_groups;
    const classes = useStyles();
    const users = store.employees.all_employees;

    useEffect(() => {
        if (userGroups !== undefined) {
            if (userGroups.includes("Certificates Approver")) {
                setApprover(true);
            }
            else {
                setApprover(false);
            }
        }
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userGroups]);

    const refresh = () => {
        setLoading(true);
        CertificatesApi.GetAllCertificateWaitingApproval().then(res => {
            setCertificatesPending(res.data);
            setLoading(false);
        })
    }

    const generateTableBody = (row: Certificates, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} onClick={() => onRowClick(row)} >
                <TableCell>{index}</TableCell>
                <TableCell >
                    <Grid container>
                        <ProfileImg imgKey={index} userName={users?.find(s => s.Id === row.mechanic_employee_id)?.userName?.split('@')[0]} />
                        <Typography margin={[1, 0, 0, 2]} align="left" text={row.mechanic_full_name}></Typography>
                    </Grid>
                </TableCell>
                <TableCell>{row.created_date_text}</TableCell>
                <TableCell>
                    {row.declined_reason !== '' &&
                        <Grid container> <RemoveCircle className={classes.rejected} color="error" />  <Typography paragraph={false} text={row.declined_reason} />  </Grid>}
                </TableCell>
                <TableCell>
                    {row.declined_reason === '' &&
                        <Button variant="contained" onClick={() => onRowClick(row)}>{approver ? "Review" : "Edit"}</Button>
                    }
                </TableCell>
            </TableRow>
        )
    }
    const onRowClick = (row: Certificates) => {
        CertificatesApi.ReturnDifferenceBetweenTwoCertificates(row.certificate_id).then(res => {
            setSelectedCertificate(res.data);
            ratingsDifference.current.newRatings = res.newRatingIds;
            ratingsDifference.current.oldRatings = res.oldRatingIds;
            ratingsDifference.current.userName = store.employees?.all_employees?.find(emp => emp.Id === row.mechanic_employee_id)?.userName.split('@')[0] || "";
        })
    }
    const closeCertificate = () => {
        refresh();
        setSelectedCertificate(undefined);
    }
    const OnOrderByTitles = (givenOrderByText: string) => {
        let tempAscending = true;
        if (order_by_text === givenOrderByText) { tempAscending = ascending === false ? true : false; }
        setAscending(tempAscending);
        setOrderByText(givenOrderByText);
    }

    return (
        <Grid>
            {selectedCertificate === undefined ? <Grid>
                <Grid>
                    {certificatesPending !== undefined ?
                        <Table
                            headers={["Employee Name", "Approval requested date", "Declined Reason", ""]}
                            rows={certificatesPending}
                            generateBody={(row: any, index: number) => generateTableBody(row, index)}
                            OnOrderByTitles={(orderByText: string) => OnOrderByTitles(orderByText)}
                            pagination={false}
                        />
                        :
                        <Typography text="No certificates are currently pending approval" variant="h3" fontWeight="bold" align="center" />
                    }
                </Grid>
            </Grid>
                :
                <Grid>
                    <Certificate
                        setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)}
                        selectedCertificate={selectedCertificate}
                        ratingsDifference={ratingsDifference.current}
                        closeCertificate={() => closeCertificate()}
                        viewType={approver ? 'approval' : 'edit'}
                        userType={"CertAdmin"}
                    />
                </Grid>
            }
        </Grid>
    );
};
export default WaitingForApproval;
