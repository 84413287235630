import { FunctionComponent, useState, useEffect } from "react";
import { Grid, TableCell, TableRow } from '@mui/material';
import AccessControlApi from "./../../api/accessControl";
import { Employee } from "../../types/user";
import { LinearProgress, Table, Typography } from "../../MaterialUiComponents";
import { makeStyles } from '@mui/styles';
import { EmployeeSearch, ProfileImg } from "../../components";
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';

const useStyles = makeStyles(theme => ({
    userPhoto: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: "50%"
    },
}),
);

const UsersWithNoAccess: FunctionComponent = () => {
    const [allEmployees, setAllEmployees] = useState(Array<Employee>());
    const [filteredEmployees, setFilteredEmployees] = useState(Array<Employee>());
    const [isLoading, setisLoading] = useState(true);
    const [selectedEmployeeID, setSelectedEmployeeId] = useState(0);
    const classes = useStyles();
    const store = useSelector((state: RootState) => state);
    const users = store.employees.all_employees.filter(s => s.visible === true);

    useEffect(() => {
        AccessControlApi.GetAllUsersWithNoAccess().then(res => {
            if (res.status) {
                setFilteredEmployees(res.data);
                setAllEmployees(res.data);
            }
            setisLoading(false);
        })
    }, [])
    useEffect(() => {
        if (selectedEmployeeID === 0) {
            setFilteredEmployees(allEmployees);
        } else {
            const employee = allEmployees.filter(s => s.id === selectedEmployeeID);
            setFilteredEmployees(employee);
        }
    }, [selectedEmployeeID])

    const generateTableBody = (row: Employee, index: number) => {
        return (
            <TableRow >
                <TableCell>{index}</TableCell>
                <TableCell>
                    <ProfileImg userName={users.find(s => s.Id === row.id)?.userName?.split('@')[0]} />
                    <Typography text={row.name} align='left' variant='body1' />
                </TableCell>
                <TableCell>{row.job_title}</TableCell>
                <TableCell>{row.department}</TableCell>
            </TableRow>
        )
    }
    return (
        <Grid >
            {isLoading && <LinearProgress />}
            <Grid container justifyContent={'center'}>
                <EmployeeSearch
                    customCloseFunction={() => setSelectedEmployeeId(0)}
                    place_holder={"Write employee name"}
                    onSelectedEemployee={(employeeId: number) => setSelectedEmployeeId(employeeId)}
                    inputWidth={50}
                    disabled={isLoading}
                />
            </Grid>
            <Table
                headers={["Employee", "Job Title", "Department"]}
                rows={filteredEmployees}
                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                pagination={false}
                skipIndexCol={false}
            />
        </Grid>
    )
}
export default UsersWithNoAccess;