import { settings } from './../config';
import { getToken } from './../global/TokenValidator';
class Courses {
    getAllCoursesSchedule = async (isCourseManager: boolean): Promise<{ status: boolean, message: string, data: any, errors: any, distinctCourses: Array<string>, distinctDepartments: Array<string> }> => {
        return fetch(`${settings.HOST}/Courses/getAllCoursesSchedule/${isCourseManager}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
                'apikey': settings.KONG_KEY,
            },
        }).then(x => {
            return x.json()
        })
    }
}
const CoursesApi = new Courses();
export default CoursesApi;