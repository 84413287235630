import { AllEmployeeBase64Images } from "../types/actions";

type Image = {
	image: string,
	userName: string
}
const initialState : { all_images: Image[] } = { all_images : [] };

export const imageReducer = (state = { ...initialState }, action: AllEmployeeBase64Images) => {
	switch (action.type) {
		case 'GET_ALL_IMAGES': 
			return { ...state, all_images: action.all_images };
		default:
			return state;
	}
}