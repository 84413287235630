import { Duration } from "luxon";

export const formatFloatNumberToHoursMinString = (floatingNumber: number | undefined) => {

    if(floatingNumber)
    {
        const hours = Math.floor(floatingNumber);
        const minutes = Math.round((floatingNumber - hours) * 60);
        const duration = Duration.fromObject({ hours, minutes });
    
        return duration.toFormat('hh:mm');
    }
};
