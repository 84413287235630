import { settings } from '../config';
import { ToolData } from '../types/tool';
import { User } from '../types/user';
import { WorldTrip, destinations } from '../types/worldTools'
import { getToken } from './../global/TokenValidator';

class WorldTools {
    GetAllWorldTrips = async (startDate: Date | null, endDate: Date | null, locationId: number, status: string | null, tailNumber: string | null): Promise<{ status: boolean, message: string, data: WorldTrip[] }> => {
        return fetch(`${settings.HOST}/Worldtrips/getAllWorldtrips`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "start_date": startDate,
                "end_date": endDate,
                "location_id": locationId,
                "status": status,
                "tail_number": tailNumber
            })
        }).then(x => {
            return x.json()
        })
    }
    GetWorldTripById = async (worldTripId: number): Promise<{ status: boolean, message: string, data: WorldTrip }> => {
        return fetch(`${settings.HOST}/Worldtrips/GetWorldTripById?worldTripId=${worldTripId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllWorldTripLocations = async (): Promise<{ status: boolean, message: string, data: destinations[] }> => {
        return fetch(`${settings.HOST}/Worldtrips/ReturnAllWorldTripLocations`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    CreateNewWorldTrip = async (destination: string, reason: string, startDate: Date, endDate: Date, employeeIds: User[], toolIds: ToolData[], tripName: string, tailNumber: string | null): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Worldtrips/CreateNewWorldTrip`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "location_id": destination,
                "reason": reason,
                "start_date": startDate,
                "end_date": endDate,
                "assigned_employees": employeeIds,
                "tool_ids": toolIds,
                "trip_name": tripName,
                "tail_number": tailNumber
            })
        }).then(x => {
            return x.json()
        })
    }
    CreateWorldTripLocation = async (destination: string, createdByEmployeeId: number): Promise<{ status: boolean }> => {
        return fetch(`${settings.HOST}/Worldtrips/AddWorldTripLocation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                location: destination,
                created_by_employee_id: createdByEmployeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    UpdateWorldTrip = async (selectedTrip: WorldTrip): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Worldtrips/UpdateWorldTrip`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                general_info: selectedTrip.general_info,
                assigned_employees: selectedTrip.assigned_employees,
                loaned_out_tools: selectedTrip.loaned_out_tools
            })
        }).then(x => {
            return x.json()
        })
    }
    RemoveWorldTrip = async (worldTripId: number, employeeId: number): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/WorldTrips/RemoveWorldTrip/${worldTripId}/${employeeId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    RemoveWorldTripLocation = async (destinations: destinations): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/WorldTrips/RemoveWorldTripLocation/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(destinations)
        }).then(x => {
            return x.json()
        })
    }
}
const WorldToolsApi = new WorldTools();
export default WorldToolsApi;