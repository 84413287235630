import { useState, useEffect, } from 'react';
import { Grid, Button, Switch, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Input, Dialog } from '../../MaterialUiComponents';
import { Dictionary } from '../../components';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import worldTools from '../../api/worldTools';
import { destinations } from '../../types/worldTools';

const useStyles = makeStyles(theme => ({
  createButton: {
    backgroundColor: '#407BAC',
    color: 'white',
    fontSize: '1em',
  },
}));

// Basic dictionary page using the dictionary component so easy to change style of all dictionary pages if needed.
function EditDestinations() {
  const [destinations, setDestinations] = useState(Array<destinations>());
  const [newPropertyInput, setNewPropertyInput] = useState('');
  const store = useSelector((state: RootState) => state);
  const currentUser = store.user.me;
  const [showActive, setShowActive] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [selectedDestination, setSelectedDestination] = useState<destinations | undefined>(undefined);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    worldTools.GetAllWorldTripLocations().then(res => {
      setDestinations(res.data);
    });
  }

  const onReasonEdit = (value: string) => {
    setDeleteReason(value);
  }
  const addDestination = (newProperty: string) => {
    worldTools.CreateWorldTripLocation(newProperty, currentUser.user_id).then(res => {
      refresh();
      setNewPropertyInput('');
    });
  }
  const deleteDestination = (destination: destinations) => {
    setSelectedDestination(destination);
    setDeleteDialogOpen(true);
  }
  const confirmDelete = () => {
    if (selectedDestination !== undefined) {
      selectedDestination.reason = deleteReason
      worldTools.RemoveWorldTripLocation(selectedDestination).then(res => {
        setSelectedDestination(undefined);
        refresh();
      });
    }
    setDeleteDialogOpen(false);
    setDeleteReason('');
  }

  const classes = useStyles();
  return (
    <Grid>
      <Grid item xs={12} container justifyContent="center" >
        <Grid item xs={6}>
          <Grid container xs={12}>
            <Grid item xs={4} lg={8}></Grid>
            <Grid item xs={2} lg={1}>
              <Typography text="Active" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Switch
                checked={!showActive}
                onChange={() => setShowActive(!showActive)}
                color="secondary"
                name="checkedB"
              />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Typography text="Deleted" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
            </Grid>
          </Grid>
          <Grid xs={10} item>
            {
              destinations.filter(type => type.visible === showActive).map(row => (
                <Dictionary name={row.location} object={row} deleted={showActive === true ? false : true} description={showActive === true ? '' : row.reason} id={row.id} deleteProperty={(row: destinations) => deleteDestination(row)}></Dictionary>
              ))
            }
          </Grid>
        </Grid>
        <Dialog
          visible={deleteDialogOpen}
          max_width={"md"} title={"Are you sure you want to delete this?"}
          context={
            <div>
              <Box display="flex" justifyContent="center" width="100%" m={1} p={1}>
                <Input
                  label_text={"Type a reason"} value={deleteReason} help_text=""
                  onTextChange={(value: string) => onReasonEdit(value)} type="text" width={50} multiline={false} />
              </Box>

              <Box display="flex" justifyContent="center" m={1} p={1}>
                <Box p={1} >
                  <Button variant="contained" color="primary" onClick={() => confirmDelete()}>Confirm delete</Button>
                </Box>
                <Box p={1}>
                  <Button variant="contained" color="secondary" onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                </Box>
              </Box>
            </div>
          }
          onClose={(status: any) => setDeleteDialogOpen(false)}
          strict={false}
          show_options={false}
        />
        <Grid item xs={5}>
          <Grid item xs={12}>
            <br />
            <Input
              multiline={false}
              label_text="New Property"
              help_text=""
              type="text"
              value={newPropertyInput}
              width={50}
              onTextChange={(value: string) => setNewPropertyInput(value)}
            />
          </Grid>
          <br />
          <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addDestination(newPropertyInput)} >
            Add Property
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EditDestinations;
