import { useState, useEffect, FunctionComponent, } from 'react';
import { Grid, Button, Switch, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Input, Dialog } from '../../MaterialUiComponents';
import { Dictionary } from '../../components';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import WarrantyApi from '../../api/warranty';
import { dictionary } from '../../types/general';

const useStyles = makeStyles(theme => ({
  createButton: {
    backgroundColor: '#407BAC',
    color: 'white',
    fontSize: '1em',
  },
}));

type CageCodeProps = {
  refreshCageCodes: Function,
}

// Basic dictionary page using the dictionary component so easy to change style of all dictionary pages if needed.
const EditCageCodes: FunctionComponent<CageCodeProps> = ({ refreshCageCodes }) => {
  const [cageCodes, setCageCodes] = useState(Array<dictionary>());
  const [newCageCodeName, setNewCageCodeName] = useState('');
  const [newCageCodeDescription, setNewCageCodeDescription] = useState('');
  const [newCageCodeEmail, setNewCageCodeEmail] = useState('');
  const store = useSelector((state: RootState) => state);
  const currentUser = store.user.me;
  const [showActive, setShowActive] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [selectedCageCode, setSelectedCageCode] = useState<dictionary | undefined>(undefined);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    WarrantyApi.getAllCageCodes().then(res => {
      setCageCodes(res.data);
      refreshCageCodes();
    });
  }
  const onReasonEdit = (value: string) => {
    setDeleteReason(value);
  }
  const addCageCode = (newCageCodeName: string, newCageCodeDescription: string) => {
    WarrantyApi.addNewCageCode(newCageCodeName, newCageCodeDescription, currentUser.user_id, newCageCodeEmail).then(res => {
      refresh();
      setNewCageCodeName('');
      setNewCageCodeDescription('');
      setNewCageCodeEmail('');
    });
  }
  const deleteCageCode = (cageCode: dictionary) => {
    setSelectedCageCode(cageCode);
    setDeleteDialogOpen(true);
  }
  const confirmDelete = () => {
    if (selectedCageCode !== undefined) {
      selectedCageCode.deleted_reason = deleteReason;
      WarrantyApi.deleteCageCode(selectedCageCode).then(res => {
        setSelectedCageCode(undefined);
        refresh();
      });
    }
    setDeleteDialogOpen(false);
    setDeleteReason('');
  }
  const classes = useStyles();

  return (
    <Grid>
      <Grid xs={12} container alignItems="flex-start" justifyContent="center" >
        <Grid item xs={6}>
          <Grid container xs={12}>
            <Grid item xs={4} lg={8}></Grid>
            <Grid item xs={2} lg={1}>
              <Typography text="Active" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Switch
                checked={!showActive}
                onChange={() => setShowActive(!showActive)}
                color="secondary"
                name="checkedB"
              />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Typography text="Deleted" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
            </Grid>
          </Grid>
          <Grid xs={10} item>
            {
              cageCodes !== undefined && cageCodes.filter(type => type.visible === showActive).map(row => (
                <Dictionary name={row.name} object={row} deleted={showActive === true ? false : true} description={showActive === true ? row.description : row.deleted_reason} other={row.other} id={row.id} deleteProperty={(row: dictionary) => deleteCageCode(row)}></Dictionary>
              ))
            }
          </Grid>
        </Grid>
        <Dialog
          visible={deleteDialogOpen}
          max_width={"md"} title={"Are you sure you want to delete this?"}
          context={
            <div>
              <Box display="flex" justifyContent="center" width="100%" m={1} p={1}>
                <Input
                  label_text={"Type a reason"} value={deleteReason} help_text=""
                  onTextChange={(value: string) => onReasonEdit(value)} type="text" width={50} multiline={false} />
              </Box>

              <Box display="flex" justifyContent="center" m={1} p={1}>
                <Box p={1} >
                  <Button variant="contained" color="primary" onClick={() => confirmDelete()}>Confirm delete</Button>
                </Box>
                <Box p={1}>
                  <Button variant="contained" color="secondary" onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                </Box>
              </Box>
            </div>
          }
          onClose={(status: any) => setDeleteDialogOpen(false)}
          strict={false}
          show_options={false}
        />
        <Grid container xs={5}>
          <Grid item xs={12}>
            <br />
            <Input
              multiline={false}
              label_text="Name of new Cage code"
              help_text="Please type in a name for your new Cage code"
              type="text"
              value={newCageCodeName}
              width={50}
              onTextChange={(value: string) => setNewCageCodeName(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              multiline={false}
              label_text="Description for new Cage code"
              help_text="Please type in a description for your new Cage Code"
              type="text"
              value={newCageCodeDescription}
              width={50}
              onTextChange={(value: string) => setNewCageCodeDescription(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              multiline={false}
              label_text="Email address for new Cage code"
              help_text="Please type in an email address for your new Cage Code"
              type="text"
              value={newCageCodeEmail}
              width={50}
              onTextChange={(value: string) => setNewCageCodeEmail(value)}
            />
          </Grid>
          <br />
          <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addCageCode(newCageCodeName, newCageCodeDescription)} >
            Add Property
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EditCageCodes;
