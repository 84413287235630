import { useState, useEffect } from 'react';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Typography, Table, Dialog } from './../../../MaterialUiComponents';
import { ToolData } from '../../../types/tool';
import { makeStyles } from '@mui/styles';
import toolsApi from './../../../api/tools';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducer';
import ProfileImg from '../../../components/Images/ProfileImg';

const useStyles = makeStyles(theme => ({
  userPhoto: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: "50%",
    marginRight: "4%"
  },
}));

function DefectiveReports() {
  const [defective_tools, setDefectiveTools] = useState(Array<ToolData>());
  const [order_by_text, setOrderByText] = useState("empty");
  const [ascending, setAscending] = useState(false);
  const [defective_tool_dialog_open, setDefectiveToolDialogOpen] = useState(false);
  const classes = useStyles();
  const users = useSelector((state: RootState) => state.employees.all_employees);

  useEffect(() => {
    refresh(order_by_text, ascending);
  }, [order_by_text, ascending]);
  const generateTableBody = (row: ToolData, index: number) => {
    return (
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell >
          <Grid container>
            <ProfileImg imgKey={index} userName={users.find(s => s.Id === row.employee_id)?.userName.split('@')[0]} />
            <Typography margin={[1, 0, 0, 0]} align="left" text={row.employee_name}></Typography>
          </Grid>
        </TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.bay}</TableCell>
        <TableCell>{row.area}</TableCell>
        <TableCell>{row.out_date_text}</TableCell>
        <TableCell>{row.created_date_text}</TableCell>
        <TableCell>{row.reason}</TableCell>
      </TableRow>
    )
  }
  const OnOrderByTitles = (given_order_by_text: string) => {
    let temp_ascending = true;
    if (order_by_text === given_order_by_text) { temp_ascending = ascending === false ? true : false; }
    setAscending(temp_ascending);
    setOrderByText(given_order_by_text);
    refresh(given_order_by_text, temp_ascending);
  }
  const refresh = (order_by_text: string, ascending: boolean) => {
    toolsApi.getAllDefectiveTools(order_by_text, ascending).then(res => {
      setDefectiveTools(res);
    })
  }

  return (
    <Grid item xs={12}>
      <Typography text={`Total defective tools (${defective_tools.length})`} align="center" variant={"h2"} />
      <Table
        headers={["Last used employee", "Tool", "Bay", "Area", "Loaned out", "Reported", "Reason"]}
        rows={defective_tools}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={100}
        OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
      />
      <Dialog
        visible={defective_tool_dialog_open}
        max_width={"xl"} title={'asdasd'}
        context={
          <Grid xs={12} alignContent={"center"} alignItems={"center"}>
            <p></p>
          </Grid>
        }
        onClose={(status: any) => setDefectiveToolDialogOpen(false)}
        strict={false}
        show_options={true}
      />
    </Grid>
  );
};
export default DefectiveReports;