import { FunctionComponent, useEffect, useState } from 'react';
import { Grid, TableRow, TableCell, Button } from '@mui/material';
import { SelectMultiple, Select, Input, Table, Typography } from '../../MaterialUiComponents';
import taskApi from '../../api/tasks';
import lineApi from '../../api/line';
import { HandoverReportSearchObj } from '../../types/line';

interface AircraftIssueHistoryProps { }

const departmentTypes = [
    { id: 0, name: "Line" },
    { id: 1, name: "Lite" },
];

const AircraftIssueHistory: FunctionComponent<AircraftIssueHistoryProps> = ({ }) => {
    const [allTails, setAllTails] = useState<string[]>([]);
    const [selectedTailNumber, setSelectedTailNumber] = useState<string>("");
    const [searchText, setSearchText] = useState("");
    const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
    const [allIsues, setAllIsues] = useState(Array<HandoverReportSearchObj>());
    const [filteredIssues, setFiltereissues] = useState(Array<HandoverReportSearchObj>());

    useEffect(() => {
        taskApi.GetAllTailNumbers().then(res => {
            setAllTails(res.data);
        })
    }, []);

    useEffect(() => {
        refresh();
    }, [selectedDepartments, selectedTailNumber]);

    useEffect(() => {
        filterByText(searchText, allIsues);
    }, [searchText]);

    const filterByText = (txt: string, tempAllIssues: Array<HandoverReportSearchObj>) => {
        if (txt.length === 0) {
            setFiltereissues(tempAllIssues);
        } else {
            setFiltereissues(tempAllIssues.filter(s => s.issue.toUpperCase().includes(txt.toUpperCase())));
        }
    }

    const refresh = () => {
        const tempDepartments: string[] = [];
        selectedDepartments.map(row => (
            tempDepartments.push(departmentTypes[row].name)
        ));
        lineApi.GetHandoverReportsByTail(selectedTailNumber, tempDepartments).then(res => {
            if (res.status) {
                setAllIsues(res.data);
                filterByText(searchText, res.data);
            }
        });
    }

    const generateTableBody = (row: HandoverReportSearchObj, index: number) => {
        return (
            <TableRow>
                <TableCell>{index}</TableCell>
                <TableCell>{row.created_date_text}</TableCell>
                <TableCell>{row.tail_number}</TableCell>
                <TableCell>{row.issue}</TableCell>
                <TableCell>{row.created_by_name}</TableCell>
                <TableCell>{row.department}</TableCell>
            </TableRow>
        )
    }
    const onClearFilters = () => {
        setSelectedDepartments([]);
        setSelectedTailNumber("");
        setSearchText("");
    }
    return (
        <Grid>
            <Grid container>
                <Grid xs={2}>
                    <Select
                        width={100}
                        widthPercentage
                        filter_by_text="Tail number"
                        onSelectedItem={(e: any) => setSelectedTailNumber(e.target.value)}
                        options={allTails}
                        selectedItem={selectedTailNumber}
                    />
                </Grid>
                <Grid xs={2}></Grid>
                <Grid xs={5}>
                    <Input
                        multiline={false}
                        label_text="Search by text"
                        help_text=""
                        type="text"
                        value={searchText}
                        width={75}
                        onTextChange={(value: string) => setSearchText(value)}
                    />
                </Grid>
                <Grid xs={2}>
                    <SelectMultiple
                        optionsObject={departmentTypes}
                        onSelectedItem={(e: any) => setSelectedDepartments(e.target.value)}
                        selectedItems={selectedDepartments}
                        width={200}
                        filter_by_text={"Filter by Departments"}
                    />
                </Grid>
                <Grid xs={1}>
                    <Button color='primary' onClick={() => { onClearFilters() }}>Clear filters</Button>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Typography variant={"h4"} text={"issues(" + filteredIssues.length + ")"} align='center' margin={[1, 0, 1, 0]} />
            </Grid>
            <Grid>
                <Table
                    headers={["Created Date", "Tail Number", "Issue", "Created By", "Department"]}
                    rows={filteredIssues}
                    generateBody={(row: any, index: number) => generateTableBody(row, index)}
                    pagination={true}
                    defaultRowsPerPage={100}
                    skipIndexCol={false}
                />
            </Grid>
        </Grid>
    )
};
export default AircraftIssueHistory;