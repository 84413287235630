import React, { FunctionComponent } from 'react';
import { Snackbar, Grid, Alert, AlertColor } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Close } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: "1px",
    top: "1px",
    color: 'white'
  },
  icon: {
    cursor: 'pointer'
  }
}));

type SnackbarUIProps = {
  message: string,
  autoHide: Boolean,
  context?: undefined | React.ReactNode,
  open: boolean,
  onSnackBarClose: Function,
  horizontalPosition: 'right' | 'left' | 'center',
  severity: AlertColor,
  showClose?: boolean,
  children?: React.ReactNode;
}

const SnackbarUI: FunctionComponent<SnackbarUIProps> = ({ message, autoHide, context, open, onSnackBarClose, horizontalPosition, severity, showClose = true }) => {
  const classes = useStyles();
  return (
    <Snackbar
      key={44}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: horizontalPosition,
      }}
      open={open}
      autoHideDuration={autoHide === true ? 4000 : 99999}
      onClose={() => onSnackBarClose()}
      message={message}
      action={
        <Grid container>
          {context}
          {showClose && <Close className={classes.icon} onClick={() => onSnackBarClose()} />}
        </Grid>
      }>
      <Alert severity={severity} onClose={() => onSnackBarClose()} sx={{ width: '100%' }} >
        {message}
      </Alert>
    </Snackbar>
  );
};
export default SnackbarUI