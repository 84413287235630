import { FunctionComponent } from 'react';
import { Tabs, Tab, Grid, IconButton } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { AddCircle, Close } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  tabContainer: {
    height: '60px'
  },
  // Not active tab - gray background
  backgroundTabContainer: {
    borderTopLeftRadius: theme.spacing(4),
    borderTopRightRadius: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[400],
    paddingBottom: theme.spacing(5)
  },
  // Active tab - blue background, white font
  selectedBackgroundTabContainer: {
    borderTopLeftRadius: theme.spacing(4),
    borderTopRightRadius: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(5)
  }
}));

// This component is for tabs that can be added or closed. See example by opening more then one workpackage in Production Plan (Workpackages.tsx)
type ActionTabsProps = {
  tabNames: string[],
  tabValue: number,
  onChangeTabValue: Function,
  variant?: "scrollable" | "fullWidth" | "standard",
  closeIcon?: boolean,
  onCloseTab: Function,
  onAddTab?: Function,
  addTabStatus?: "active" | "disabled" | "hidden"
}

const ActionTabs: FunctionComponent<ActionTabsProps> = ({
  tabNames,
  onChangeTabValue,
  tabValue,
  variant = "standard",
  closeIcon = false,
  onCloseTab,
  onAddTab,
  addTabStatus = "active"
}) => {
  const classes = useStyles();

  const handleChange = (event: any, new_value: number) => {
    onChangeTabValue(new_value);
  };

  return (
    <Grid container>
      <Grid item xs={addTabStatus === "hidden" ? 12 : 11}>
        <Tabs
          className={classes.tabContainer}
          value={tabValue}
          indicatorColor="primary"
          textColor="inherit"
          onChange={handleChange}
          variant={variant}
        >
          {
            tabNames.map((name, index) => (
              <Tab
                className={tabValue !== index ? classes.backgroundTabContainer : classes.selectedBackgroundTabContainer}
                label={name}
                icon={closeIcon ? <IconButton color="inherit" onClick={(event) => onCloseTab(index)}><Close fontSize="small" /></IconButton> : ''}
                iconPosition="end"
              />
            ))
          }
        </Tabs>
      </Grid>
      {addTabStatus !== "hidden" &&
        < Grid item xs={1} marginTop={2}>
          <IconButton disabled={addTabStatus === "disabled" ? true : false} onClick={() => { onAddTab !== undefined && onAddTab() }}>
            <AddCircle color={addTabStatus === "disabled" ? "disabled" : "primary"} />
          </IconButton>
        </Grid>
      }
    </Grid >
  );
};
export default ActionTabs;
