import { Divider, Grid, IconButton, Paper } from "@mui/material";
import { useState } from "react";
import { Input, Typography } from "../../MaterialUiComponents";
import { CommentsObj } from "../../types/messages"
import { Add, Check, Close, CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";

type MessagesProps = {
    onAddComment: Function,
    onDelete: Function,
    allComments: Array<CommentsObj>
    isEditMode: Boolean
}

const Messages = ({ onAddComment, onDelete, allComments, isEditMode }: MessagesProps) => {
    const [addComment, setAddComment] = useState(false)
    const [message, setMessage] = useState("")
    const store = useSelector((state: RootState) => state);
    const currentUserId = store.user.me.user_id;

    return (
        <Grid xs={12} display="flex" justifyContent="center">
            <Grid xs={12} maxHeight={"40vh"} sx={{ overflowY: "auto" }} >
                <Grid xs={12} padding={2}>
                    {isEditMode && <Grid>
                        {!addComment
                            ?
                            <Grid xs={12} marginTop={2} >
                                <IconButton color="info" onClick={() => setAddComment(true)}>
                                    <Add />
                                </IconButton>
                                <Divider light />
                            </Grid>
                            :
                            <Grid xs={12} marginTop={2} >
                                <Grid xs={12} display="flex" paddingBottom={2} alignSelf="center">
                                    <Grid xs={10} padding={1}>
                                        <Paper variant="outlined">
                                            <Grid xs={12} padding={1} alignSelf="center">
                                                <Input
                                                    required
                                                    multiline={false}
                                                    label_text={"Insert Comment"}
                                                    value={message}
                                                    help_text="comment"
                                                    onTextChange={(value: string) => setMessage(value)}
                                                    type="text"
                                                    width={100}
                                                />
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid alignSelf="center">
                                        <Grid>
                                            <IconButton color="success" onClick={() => { onAddComment(message); setMessage("") }}>
                                                <Check />
                                            </IconButton>
                                            <IconButton color="error" onClick={() => { setAddComment(false); setMessage("") }}>
                                                <CloseOutlined />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider light />
                            </Grid>
                        }
                    </Grid>}
                    {
                        allComments && allComments.map(el => {
                            return (
                                <Grid xs={12} marginTop={2} >
                                    <Grid xs={12} display="flex" paddingBottom={2} justifyContent="space-between" alignSelf="center">
                                        <Paper variant="outlined">
                                            <Grid display="flex" justifyContent="space-between" alignSelf="center" padding={1}>
                                                <Grid alignSelf="center">
                                                    <Typography text={el.text} />
                                                </Grid>
                                                <Grid>
                                                    {
                                                        !el.is_system_generated && el.created_by_employee_id === currentUserId && isEditMode &&
                                                        <Grid >
                                                            <IconButton onClick={() => onDelete(el.comment_id)}>
                                                                <Close color="error" />
                                                            </IconButton>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <Grid marginLeft={2}>
                                            <Grid xs={12}>
                                                <Typography text={el.created_date_text} variant="caption" />
                                            </Grid>
                                            <Grid xs={12}>
                                                <Typography text={el.created_by_employee_name} variant="caption" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider light />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Messages;