import { FunctionComponent } from 'react';
import { Input, Typography } from '../../MaterialUiComponents';
import { Grid } from '@mui/material';
import { School, Accessibility } from '@mui/icons-material';
import { Request } from '../../types/capabilityList';

interface TrainingAndManualsProps {
    request: Request,
    viewMode: boolean,
    setRequest: Function,
}

const TrainingAndManuals: FunctionComponent<TrainingAndManualsProps> = ({ request, setRequest, viewMode }) => {

    const onUpdateTrainingAndManuals = (field: keyof Request, value: string) => {
        setRequest((prevState: Request) => ({
            ...prevState,
            [field]: value,
        }));
    };

    return (
        <Grid container justifyContent="center" style={{ marginTop: '28px' }}>
            <Typography color='primary' variant='h2' text={"Training & Manuals"} align='center' />
            <Grid container justifyContent="center" style={{ marginTop: '18px' }}>
                <Grid item xs={5} sm={5} lg={4} xl={4}>
                    <Input icon={<School />} disabled={viewMode} multiline={true} help_text={""} label_text={"Special training"} value={request.special_training} onTextChange={(text: string) => { onUpdateTrainingAndManuals("special_training", text) }} type="text" width={100} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} sm={5} lg={4} xl={4} justifyContent={"center"}>
                    <Input icon={<Accessibility />} disabled={viewMode} multiline={true} help_text={""} label_text={"Manuals needed by shop"} value={request.manuals_needed_by_shop} onTextChange={(text: string) => { onUpdateTrainingAndManuals("manuals_needed_by_shop", text) }} type="text" width={100} />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default TrainingAndManuals;