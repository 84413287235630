import { Card, CardContent, Grid } from '@mui/material';
import { ToolData } from '../../types/tool';
import { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '../../MaterialUiComponents';

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: '#DCDCDC',
    },
    innerCard: {
        backgroundColor: '#f1f5f9',
        marginBottom: '1rem',
    },
}));

type ToolsListProps = {
    tools: ToolData[],
}

const ToolsList: FunctionComponent<ToolsListProps> = ({ tools }) => {
    const classes = useStyles();
    return (
        <Card className={classes.background}>
            <CardContent>
                <Typography align="center" color="textPrimary" text={"Tools assigned to trip"} variant="h2" margin={[0, 0, 1, 0]} />
                {tools.map((tool, index) => (
                    <Card key={index} className={classes.innerCard}>
                        <CardContent>
                            <Grid container direction="row" alignItems="center" justifyContent="space-evenly" >
                                <Grid item >
                                    <Typography align="center" color="textPrimary" text={tool.description} variant="h4" />
                                    <Typography align="center" color="textPrimary" text={tool.in_loan ? "Tool is in loan" : "Tool has been returned"} variant="h5" margin={[1, 0, 0, 0]} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
            </CardContent>
        </Card>
    );
}
export default ToolsList;