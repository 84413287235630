import { FunctionComponent, useState } from "react";
import { AccessControlGroup, AccessGroupUserGroupRelation, UserAccessInfo } from '../../types/accessControl';
import { Grid, Button, Divider, TableCell, TableRow, IconButton } from '@mui/material';
import { Typography, Input, Dialog, Table, Tooltip } from './../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { Edit, Delete } from '@mui/icons-material';
import AccessControlApi from '../../api/accessControl';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import ProfileImg from "../../components/Images/ProfileImg";

type GroupComponentProps = {
    setLoading: Function,
    group: AccessControlGroup,
    updateGroupDetails: Function,
    setSelectedGroupId: Function,
    selectedGroupId: number | undefined,
    refresh: Function,
    tabValue: number,
    groupsRelation: AccessGroupUserGroupRelation[],
}

const useStyles = makeStyles(theme => ({
    Card: {
        justifyContent: "space-between",
        width: '100%',
        "&:hover": {
            backgroundColor: "#e5e7eb"
        },
        marginBottom: '8px',
        cursor: "pointer",
    },
    smallIcon: {
        cursor: 'pointer',
        height: '20px'
    },
    Button: {
        marginBottom: theme.spacing(4),
    },
    userPhoto: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: "50px",
        marginRight: "8px"
    },
}))

const Group: FunctionComponent<GroupComponentProps> = ({ groupsRelation, tabValue, refresh, group, updateGroupDetails, setSelectedGroupId, selectedGroupId, setLoading }) => {
    const classes = useStyles();
    const [editDialog, setEditDialog] = useState<boolean>(false);
    const [groupName, setGroupName] = useState<string>("");
    const [groupPermission, setGroupPermission] = useState<string>("");
    const [isUserGroupModal, setIsUserGroupModal] = useState<boolean>(false);
    const [permissions, setPermissions] = useState<UserAccessInfo[]>([]);
    const [selectedUserGroup, setSelectedUserGroup] = useState<string>("");
    const [areYouSureDialogOpen, setAreYouSureDialogOpen] = useState(false);
    const [accesGroupRemovePermissionReason, setAccesGroupRemovePermissionReason] = useState("");
    const [selectedUserAccessGroupPermission, setSelectedUserGroupAccessGroupPermission] = useState<AccessGroupUserGroupRelation | null>();
    const store = useSelector((state: RootState) => state);
    const users = store.employees.all_employees.filter(s => s.visible === true);
    const isDeveloper = store.user.me.user_clearence_groups.includes("Developers");
    const admin = store.user.me.user_clearence_groups.includes("Access Control Admin");

    const onEditGroup = () => {
        const tempName = groupName === "" ? group.group_name : groupName;
        const tempPermission = groupPermission === "" ? group.group_permission_text : groupPermission;
        if (tabValue === 0) {
            setLoading(true);
            AccessControlApi.EditAccessGroup(group.id, tempName, tempPermission).then(res => {
                refresh();
                setEditDialog(false);
                setLoading(false);
            });
        }
        else if (tabValue === 1) {
            setLoading(true)
            AccessControlApi.EditUserGroup(group.id, tempName, tempPermission).then(res => {
                refresh();
                setEditDialog(false);
                setLoading(false);
            });
        }
    }

    const onUserGroupClicked = (e: any, id: number, groupName: string) => {
        e.stopPropagation();
        AccessControlApi.GetAllUsersByGroupId(id, false).then(res => {
            const users = res.data.filter(user => user.inGroup === true);
            setPermissions(users);
            setSelectedUserGroup(groupName);
            setIsUserGroupModal(true);
        });
    }

    const generateTableBody = (row: UserAccessInfo, index: number) => {
        return (
            <TableRow>
                <TableCell >
                    <Grid container>
                        <ProfileImg imgKey={index} userName={users.find(s => s.Id === row.employee_id)?.userName?.split('@')[0]} />
                    </Grid>
                </TableCell>
                <TableCell> {row.name} </TableCell>
                <TableCell> {row.job_title} </TableCell>
                <TableCell> {row.starts_at} </TableCell>
                <TableCell> {`${tabValue === 0 ? row.expiration_date : "No exp date for user groups"}`} </TableCell>
            </TableRow>
        )
    }
    const onRemoveUserAccessGroupPermission = (status: boolean) => {
        if (status) {
            AccessControlApi.RemoveUserGroupFromAccessGroup(selectedUserAccessGroupPermission!.user_groups_access_permissions_id, accesGroupRemovePermissionReason).then(res => {
                setAreYouSureDialogOpen(false);
                setAccesGroupRemovePermissionReason("");
                setSelectedUserGroupAccessGroupPermission(null);
                refresh();
            })
        }
    }
    const onDeleteGroup = () => {
        AccessControlApi.DeleteUserGroup(group.id).then(res => {
            refresh();
            setEditDialog(false);
            setLoading(false);
        })
    }
    const hasGroupsRelations = groupsRelation.filter(s => s.user_group_id === group.id).length > 0;
    return (
        <Grid style={{ cursor: "pointer", padding: '4px' }}>
            <Grid onClick={() => { setSelectedGroupId(group.id); updateGroupDetails(group) }}>
                <Paper elevation={9} >
                    <Grid container direction="column" padding={[2, 2, 2, 2]} className={classes.Card} sx={{ backgroundColor: `${selectedGroupId !== undefined && selectedGroupId === group.id ? "#f3f4f6" : "#f9fafb"}` }}>
                        <Grid container direction="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item >
                                <Typography customFontSize={22} cursor="pointer" variant="h3" fontWeight="normal" margin={[1, 0, 0, 1]} text={group.group_name} />
                                {(selectedGroupId !== undefined && selectedGroupId === group.id) &&
                                    <Typography cursor="pointer" margin={[1, 0, 0, 1]} text={group.group_permission_text} />
                                }
                            </Grid>
                            {(( tabValue === 1 && admin) || (isDeveloper)) &&
                                <Grid item>
                                    <IconButton onClick={(e: any) => { setEditDialog(true); setGroupName(group.group_name); setGroupPermission(group.group_permission_text); e.stopPropagation(); }} id="drawerButton">
                                        <Edit className={classes.smallIcon} onClick={() => setEditDialog(true)} />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                        <Grid>
                            {tabValue === 0 &&
                                <Grid>
                                    {(selectedGroupId !== undefined && selectedGroupId === group.id) &&
                                        <Grid>
                                            <Divider sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" }, marginTop: 2 }} orientation="horizontal" variant="middle" flexItem />
                                            {
                                                (groupsRelation !== undefined && groupsRelation.length > 0) &&
                                                <Grid>
                                                    <Typography cursor="pointer" margin={[1, 0, 0, 1]} text={` `} />
                                                    {groupsRelation.map(el => {
                                                        if (el.access_group_id === group.id) {
                                                            return (
                                                                <Grid>
                                                                    <Tooltip type="primary" title={`${el.user_group_permission_text}`} placement="top-start">
                                                                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <IconButton onClick={(e: any) => onUserGroupClicked(e, el.user_group_id, el.user_group_name)} id="drawerButton">
                                                                                <Typography cursor="pointer" text={el.user_group_name} />
                                                                            </IconButton>
                                                                            <IconButton onClick={() => { setAreYouSureDialogOpen(true); setSelectedUserGroupAccessGroupPermission(el); }}>
                                                                                <Delete color="error" />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                </Grid>
                                                            )
                                                        }
                                                    })}
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            }
                            {tabValue === 1 &&
                                <Grid>
                                    {(selectedGroupId !== undefined && selectedGroupId === group.id) &&
                                        <Grid>
                                            <Divider sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" }, marginTop: 2 }} orientation="horizontal" variant="middle" flexItem />
                                            {
                                                (groupsRelation !== undefined && groupsRelation.length > 0) &&
                                                <Grid>
                                                    <Typography customFontSize={18} cursor="pointer" margin={[1, 0, 0, 1]} text={'Parent groups:'} />
                                                    {groupsRelation.map(el => {
                                                        if (el.user_group_id === group.id) {
                                                            return (
                                                                <Grid>
                                                                    <Tooltip title={`${el.access_group_permission_text}`} type='primary' placement="top-start">
                                                                        <Typography cursor="pointer" margin={[1, 0, 0, 1]} text={el.access_group_name} />
                                                                    </Tooltip>
                                                                </Grid>
                                                            )
                                                        }
                                                    })}
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Dialog
                visible={editDialog}
                max_width={"sm"}
                title={"Edit group"}
                context={
                    <Grid container direction="row" alignContent={'center'}>
                        <Grid item xs={6}>
                            <Input
                                multiline={false}
                                label_text="Group name"
                                help_text="Please type in a new name for the group"
                                type="text"
                                value={groupName}
                                width={80}
                                onTextChange={(value: string) => setGroupName(value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                multiline={true}
                                label_text="Group permission"
                                help_text="Please type in new permisson text for the group"
                                type="text"
                                value={groupPermission}
                                width={80}
                                onTextChange={(value: string) => setGroupPermission(value)}
                            />
                        </Grid>
                        <Grid container xs={12} justifyContent={'space-around'}>
                            <Button variant="contained" color='primary' className={classes.Button} onClick={() => onEditGroup()}>Submit</Button>
                            {tabValue === 1 &&
                                <Tooltip title={hasGroupsRelations ? "Please remove all access before deleting" : ""} type='primary' placement="top-start">
                                    <Button style={{ cursor: 'pointer' }} disabled={hasGroupsRelations} variant="contained" color='error' className={classes.Button} onClick={() => onDeleteGroup()}>Delete</Button>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                }
                onClose={(status: any) => setEditDialog(false)}
                strict={false}
                show_options={false}
            />
            <Dialog
                visible={isUserGroupModal}
                max_width={"md"}
                title={`Employees in user group: ${selectedUserGroup}`}
                context={
                    <Table
                        headers={["IMAGE", "FULL NAME", "JOB TITLE", "STARTS AT", "EXPIRES AT"]}
                        rows={permissions}
                        generateBody={(row: any, index: number) => generateTableBody(row, index)}
                        pagination={true}
                        defaultRowsPerPage={10}
                        skipIndexCol={true}
                    />
                }
                onClose={(status: any) => setIsUserGroupModal(status)}
                strict={false}
                show_options={false}
            />
            <Dialog
                visible={areYouSureDialogOpen}
                max_width={"md"}
                title={`Are you sure you want to remove  ${selectedUserAccessGroupPermission?.user_group_name}  from ${selectedUserAccessGroupPermission?.access_group_name}`}
                context={
                    <Grid item>
                        <Input
                            multiline={false}
                            label_text="Reason"
                            help_text="Please provide a reason why you are removing permission"
                            type="text"
                            value={accesGroupRemovePermissionReason}
                            width={100}
                            onTextChange={(value: string) => setAccesGroupRemovePermissionReason(value)}
                        />
                    </Grid>
                }
                onClose={(status: any) => onRemoveUserAccessGroupPermission(status)}
                strict={false}
                show_options={true}
            />
        </Grid >
    )
}
export default Group;