import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { LinearProgress, Tabs } from '../../MaterialUiComponents';
import EditProperties from './EditProperties';
import AllCertificates from './AllCerfiticates';
import WaitingForApproval from './WaitingForApproval';
import History from './History';
import { CertificateData } from '../../types/certificates';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import EditCompanies from './EditCompanies';
import EditSubcontracts from './Edit Subcontracts';

function Certificates() {
  const store = useSelector((state: RootState) => state);
  const [userType, setUserType] = useState('view');
  const userGroups = store.user.me.user_clearence_groups;
  const userId = store.user.me.user_id;
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userGroups !== undefined) {
      if (userGroups.includes("Certificates Create") || userGroups.includes("Certificates Approver")) {
        setUserType('CertAdmin');
      } else {
        setUserType("view");
      }
    }
  }, [userGroups, userId]);

  const changeTab = (tabValue: number) => {
    setTabValue(tabValue);
  }

  return (
    <Grid>
      {loading === true && <LinearProgress />}
      < Tabs
        tabNames={userType === 'view' ? ["All Certificates"] : ["All Certificates", "Waiting for Approval", "History", "Edit properties", "Edit Companies", "Edit Subcontracts"]}
        onChangeTabValue={(tabValue: number) => changeTab(tabValue)}
        tabValue={tabValue}
      />
      {
        userType === "view" ?
          <Grid xs={12} item>
            {tabValue === 0 && <AllCertificates userType={userType} setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
          </Grid>
          :
          <Grid xs={12} item>
            {tabValue === 0 && <AllCertificates userType={userType} setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
            {tabValue === 1 && userType === 'CertAdmin' && <WaitingForApproval setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
            {tabValue === 2 && userType === 'CertAdmin' && <History setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
            {tabValue === 3 && userType === 'CertAdmin' && <EditProperties setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
            {tabValue === 4 && userType === 'CertAdmin' && <EditCompanies setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
            {tabValue === 5 && userType === 'CertAdmin' && <EditSubcontracts setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
          </Grid>
      }
      {loading === true &&
        <div></div>
      }
    </Grid>
  );
};
export default Certificates;