import { FunctionComponent, useEffect, useState } from 'react';
import { Button, Grid, TableCell, TableRow, TextField, IconButton } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { DatePicker, Input, Table, Typography } from './../../MaterialUiComponents';
import { Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { reportSearchResults } from '../../types/security';
import SecurityApi from '../../api/security';
import { EmployeeSearch } from '../../components';

const useStyles = makeStyles(theme => ({
    topContainer: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    resultsAmount: {
        marginTop: theme.spacing(1),
        color: 'gray'
    },
    resetButton: {
        height: '30px',
        marginTop: theme.spacing(2)
    },
    resultsTable: {
        width: '100%'
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

type ReportInfoProps = {
    openReport: Function,
    tails: string[]
}

const ReportInfo: FunctionComponent<ReportInfoProps> = ({ openReport, tails }) => {

    const [selectedDateFrom, setSelectedDateFrom] = useState<Date | null>(null);
    const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date());
    const [selectedTail, setSelectedTail] = useState<string | null>("");
    const [searchWord, setSearchWord] = useState<string>("");
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(-1);
    const clearEmployee = false;
    const [searchResults, setSearchResults] = useState<reportSearchResults[]>();

    useEffect(() => {
        tails.sort();
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDateFrom, selectedDateTo, selectedTail, searchWord, selectedEmployeeId]);


    const refresh = () => {
        SecurityApi.SearchRequests(selectedDateFrom, selectedDateTo, selectedTail !== null ? selectedTail.toString() : "", searchWord, selectedEmployeeId).then(res => {
            setSearchResults(res);
        });
    }

    const generateTableBody = (row: reportSearchResults, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{row.general_info.created_date_text_with_no_hours} {row.is_day_shift === true ? ' - Day' : ' - Night'}</TableCell>
                <TableCell>{row.general_info.created_by_employee_name}</TableCell>
                <TableCell>{row.amount_movements}</TableCell>
                <TableCell>{row.amount_call_outs}</TableCell>
                <TableCell>{row.amount_walk_arounds}</TableCell>
                <TableCell>{row.amount_other_incidents}</TableCell>
                <TableCell>{row.general_info.last_modified_date_by_employee_name} </TableCell>
                <TableCell>{row.general_info.last_modified_date_text} </TableCell>
                <TableCell><IconButton onClick={() => onRowClick(row.security_report_id)}><Search color="primary" /></IconButton> </TableCell>
            </TableRow>
        )
    }
    const onRowClick = (reportId: number) => {
        openReport(reportId);
    }

    const onSelectedTail = (tail: string | null) => {
        setSelectedTail(tail);
    }
    const onDateChange = (date: Date, type: string) => {
        if (type === 'from') {
            setSelectedDateFrom(date);
        }
        if (type === 'to') {
            setSelectedDateTo(date);
        }
    }

    const onSelectedEmployeeRow = (employeeId: number) => {
        setSelectedEmployeeId(employeeId);
    }
    const onResetFields = () => {
        setSelectedDateFrom(null);
        setSelectedDateTo(new Date());
        setSelectedTail("");
        setSearchWord("");
        setSelectedEmployeeId(-1);
    }
    const classes = useStyles();
    // Header of the report. Submit button is only displayed when in edit mode
    return (
        <Grid className={classes.topContainer} spacing={2} container xs={12}>
            <Grid container item sm={10} lg={2}>
                <EmployeeSearch reset={clearEmployee} place_holder={"Created by"} onSelectedEemployee={(employee_id: number) => onSelectedEmployeeRow(employee_id)} />
            </Grid>
            <Grid container item sm={5} lg={2}>
                <DatePicker onDateChange={(date: Date) => onDateChange(date, 'from')} type={"Date"} label="Created from" selected={selectedDateFrom} />
            </Grid>
            <Grid container item sm={5} lg={2} >
                <DatePicker onDateChange={(date: Date) => onDateChange(date, 'to')} type={"Date"} label="Created to" selected={selectedDateTo} />
            </Grid>
            <Grid container item sm={5} lg={2} >
                <Autocomplete
                    fullWidth
                    noOptionsText="No tails found"
                    options={tails}
                    value={selectedTail}
                    clearOnEscape
                    autoHighlight
                    onChange={(e: any, newValue: string | null) => onSelectedTail(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} label="Tails" variant="standard" />
                    )} />
            </Grid>
            <Grid container item sm={5} lg={2} >
                <Input multiline={true} help_text={""} label_text={"Search"} value={searchWord} onTextChange={(text: string) => setSearchWord(text)} type="text" width={100} />
            </Grid>
            <Grid container item sm={5} lg={2} >
                <Button variant="text" className={classes.resetButton} color="primary" size="small" onClick={() => onResetFields()}>Reset filters</Button>
            </Grid>
            <Grid container xs={12}>
                <Grid item lg={2} xs={6} className={classes.resultsAmount}>
                    <Typography text={searchResults !== undefined ? searchResults.length + " reports found" : '0 reports found'} />
                </Grid>
            </Grid>
            <Grid className={classes.resultsTable}>
                {searchResults !== undefined &&
                    <Table
                        headers={["Created date", "Created by", "Movements", "Callouts", "Walkarounds", "Other Incidents", "Last modified by", "Last modified date"]}
                        skipIndexCol={true}
                        rows={searchResults}
                        generateBody={(row: any, index: number) => generateTableBody(row, index)}
                        defaultRowsPerPage={25}
                    />
                }
            </Grid>
        </Grid>
    );
};
export default ReportInfo;
