import { FunctionComponent, useState } from 'react';
import { ATAChapter, Request } from '../../types/capabilityList';
import { Typography } from '../../MaterialUiComponents';
import { Grid, Button } from '@mui/material';
import CapabilityRequest from './CapabilityRequest';

interface RequestProps {
    requestHistory: Request[];
    setHistory: Function,
    setRequestHistory: Function,
    ATAChapters: ATAChapter[],
    setRequest: Function,
    setViewMode: Function,
    setTabValue: Function,
}

const RequestHistory: FunctionComponent<RequestProps> = ({ requestHistory, setHistory, setRequestHistory, ATAChapters, setRequest, setViewMode, setTabValue }) => {
    const [currentRequest, setCurrentRequest] = useState<Request>(requestHistory[requestHistory.length - 1]);
    const [currentVersion, setCurrentVersion] = useState<number>(requestHistory.length - 1);

    const onSelectVersion = (version: number) => {
        setCurrentRequest(requestHistory[version]);
        setCurrentVersion(version);
    }
    const onGoBackAndResetHistory = () => {
        setHistory(false);
        setRequestHistory([]);
    }

    return (
        <Grid>
            <Grid container direction="row" sm={12}>
                <Grid style={{ marginRight: '8px', marginTop: '4px' }} >
                    <Button variant='contained' color={'primary'} onClick={() => onGoBackAndResetHistory()}>Back</Button>
                </Grid>
                {requestHistory?.map((request, index) =>
                    <Grid style={{ marginRight: '8px', marginTop: '4px' }} key={index} >
                        <Button variant='contained' color={index === currentVersion ? 'primary' : 'secondary'} key={index} onClick={() => onSelectVersion(index)}>{index + 1}</Button>
                    </Grid>
                )
                }
            </Grid>
            {currentRequest !== undefined &&
                <Grid>
                    <Typography color='primary' variant='h1' text={`Version ${currentVersion + 1}`} align='center' />
                    <Typography variant='h4' text={`Created at ${currentRequest?.created_date_text}`} align='center' />
                    <Typography margin={[0, 0, 2, 0]} color='primary' variant='h2' text={` ${currentRequest?.auto_generated_accepted_text?.replace('Changes:', '')}`} align='center' />
                    <CapabilityRequest
                        requestId={-1}
                        setTabValue={setTabValue}
                        ATAChapters={ATAChapters}
                        request={currentRequest}
                        viewMode={true}
                        setRequest={setRequest}
                        setViewMode={setViewMode}
                    />
                </Grid>
            }
        </Grid>
    )
}

export default RequestHistory;