import { StaffMtpEmployee } from "../types/staffManagement";
import { User } from "../types/user";

function isUser(obj: any): obj is User {
    return 'userName' in obj;
}

function isStaffMtpEmployee(obj: any): obj is StaffMtpEmployee {
    return 'user_name' in obj;
}

// Type guard. Since the employee/user object in staffmanagement can be of two types, we need to check which type it is.
// The backend returns different kinds of objects depending on if its the search list of or the groups.
export default function getUserName(userObj: User | StaffMtpEmployee): string {
    if (isUser(userObj)) {
        return userObj?.userName?.split('@')[0];
    } else if (isStaffMtpEmployee(userObj)) {
        return userObj?.user_name
    } else {
        throw new Error('Unknown type');
    }
}