
import { colors } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import { typography } from './typography';

declare module '@mui/styles' {
	interface DefaultTheme extends Theme { }
}
const theme = createTheme({
	components: {
		MuiDrawer: {
			styleOverrides: {
				paper: {
					background: 'linear-gradient(253.36deg, #003A7D 8.56%, #3192C3 83.97%)',
				}
			}
		},
	},
	palette: {
		background: {
			default: '#FAFAFA',
			paper: colors.common.white
		},
		primary: {
			main: '#123A7D'
		},
		secondary: {
			main: '#F5B53F',
		},
		text: {
			primary: '#000',
			secondary: '#9D9D9D',
		},
		error: {
			main: '#B43938'
		},
		warning: {
			main: '#F5B53F'
		},
		info: {
			main: '#44A1D0'
		},
		success: {
			main: '#497523'
		},
	},
	typography
});
export default theme;