import { useState, useEffect, FunctionComponent, } from 'react';
import { Grid, Button, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Input } from '../../MaterialUiComponents';
import { Dictionary } from '../../components';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import WarrantyApi from '../../api/warranty';
import { dictionary } from '../../types/general';

const useStyles = makeStyles(theme => ({
  createButton: {
    backgroundColor: '#407BAC',
    color: 'white',
    fontSize: '1em',
  },
}));

type HourlyRateProps = {
  refreshHourlyRates: Function,
}

// Basic dictionary page using the dictionary component so easy to change style of all dictionary pages if needed.
const EditHourlyRate: FunctionComponent<HourlyRateProps> = ({ refreshHourlyRates }) => {
  const [hourlyRates, setHourlyRates] = useState(Array<dictionary>());
  const [newHourlyRate, setNewHourlyRate] = useState('');
  const store = useSelector((state: RootState) => state);
  const currentUser = store.user.me;
  const [showActive, setShowActive] = useState(true);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    WarrantyApi.getAllHourlyRates().then(res => {
      setHourlyRates(res.data);
      refreshHourlyRates();
    });
  }

  const addHourlyRate = () => {
    let tempHourlyRate = newHourlyRate.replace(',', '.')
    WarrantyApi.addHourlyRate(tempHourlyRate, currentUser.user_id).then(res => {
      refresh();
      setNewHourlyRate('');
    });
  }

  const classes = useStyles();
  return (
    <Grid>
      <Grid xs={12} container alignItems="flex-start" justifyContent="center" >
        <Grid item xs={6}>
          <Grid container xs={12}>
            <Grid item xs={4} lg={7}></Grid>
            <Grid item xs={4} lg={1}>
              <Typography text="Current" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Switch
                checked={!showActive}
                onChange={() => setShowActive(!showActive)}
                color="secondary"
                name="checkedB"
              />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Typography text="History" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
            </Grid>
          </Grid>
          <Grid xs={10} item>
            {
              hourlyRates !== undefined && hourlyRates.filter(type => type.visible === showActive).map(row => (
                <Dictionary deleteButton={false} name={row.name} object={row} deleted={showActive === true ? false : true} description={row.description} id={row.id} deleteProperty={(row: dictionary) => { }}></Dictionary>
              ))
            }
          </Grid>
        </Grid>
        <Grid container xs={5}>
          <Grid item xs={12}>
            <br />
            <Input
              multiline={false}
              label_text="Hourly Rate"
              help_text="Please type in updated hourly rate"
              type="text"
              value={newHourlyRate}
              width={50}
              onTextChange={(value: string) => setNewHourlyRate(value)}
            />
          </Grid>
          <br />
          <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addHourlyRate()} >
            Add Property
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EditHourlyRate;
