import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './../../reducer';
import { makeStyles } from '@mui/styles';
import { Button, Grid, IconButton, TableCell, TableRow } from '@mui/material';
import { Link, Edit, Delete } from '@mui/icons-material';
import { Table, Typography, Dialog, Input, Snackbar } from '../../MaterialUiComponents';
import TimeControlBarCodesApi from './../../api/timeControlBarcodes';
import { TimeControlBarcode, EditTimeControlBarcode, CreateTimeControlBarcode } from '../../types/timeControlBarcodes';
import { redirectToMxi } from './../../config/mxiProvider';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    }
}));

const TimeControlBarcodes = () => {
    const store = useSelector((state: RootState) => state);
    const isTimeControlAdmin = store.user.me.user_clearence_groups.includes("Time Barcodes Edit");
    const [timeControlBarcodes, setTimeControlBarcodes] = useState<TimeControlBarcode[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [showEditBarcodeDialog, setShowEditBarcodeDialog] = useState<boolean>(false);
    const [showCreateNewBarcodeDialog, setShowCreateNewBarcodeDialog] = useState<boolean>(false);
    const [showRemoveBarcodeDialog, setShowRemoveBarcodeDialog] = useState<boolean>(false);
    const [selectedTimeControlBarcode, setSelectedTimeControlBarcode] = useState<TimeControlBarcode>();
    const [barcodeFormValues, setBarcodeFormValues] = useState({ time_control_name: "", task_barcode: "", task_description: "" });
    const [updated, setUpdated] = useState<boolean>(true);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    useEffect(() => {
        //Only reload if a time control barcode has been edited/created
        if (updated) {
            refresh();
        }
    }, [updated]);

    const refresh = () => {
        setLoading(true);
        TimeControlBarCodesApi.getAllBarcodes().then(res => {
            if (res !== undefined) {
                if (res.status === true) {
                    setTimeControlBarcodes(res.data);
                    setLoading(false);
                    setUpdated(false);
                } else if (res.status === false) {
                    setSnackbarMessage(res.message);
                }
            }
        });
    }

    const openEditBarcodeDialog = (barcode: TimeControlBarcode) => {
        setSelectedTimeControlBarcode(barcode);
        setBarcodeFormValues({ time_control_name: barcode.time_control_name, task_barcode: barcode.task_barcode, task_description: barcode.task_description });
        setShowEditBarcodeDialog(true);
    }

    const openRemoveBarcodeDialog = (barcode: TimeControlBarcode) => {
        setSelectedTimeControlBarcode(barcode);
        setBarcodeFormValues({ time_control_name: barcode.time_control_name, task_barcode: barcode.task_barcode, task_description: barcode.task_description });
        setShowRemoveBarcodeDialog(true);
    }

    const onCloseEditBarcodeDialog = (status: boolean) => {
        //If confirmed in dialog -> create new object with new data -> send it in the edit request
        if (status === true) {
            if (selectedTimeControlBarcode!.id !== undefined) {
                const editedBarcode: EditTimeControlBarcode = {
                    id: selectedTimeControlBarcode!.id,
                    last_modified_by_employee_id: store.user.me.user_id,
                    time_control_name: barcodeFormValues.time_control_name,
                    task_barcode: barcodeFormValues.task_barcode,
                    task_description: barcodeFormValues.task_description,
                }
                TimeControlBarCodesApi.editTimeControlBarcode(editedBarcode).then(res => {
                    if (res.status === true) {
                        setUpdated(true);
                        setBarcodeFormValues({ time_control_name: "", task_barcode: "", task_description: "" });
                        setShowEditBarcodeDialog(false);
                    } else if (res.status === false) {
                        setSnackbarMessage(res.message);
                    }
                })
            } else {
                setSnackbarMessage("No barcode selected");
                setBarcodeFormValues({ time_control_name: "", task_barcode: "", task_description: "" });
                setShowEditBarcodeDialog(false);
            }
        } else {
            setBarcodeFormValues({ time_control_name: "", task_barcode: "", task_description: "" });
            setShowEditBarcodeDialog(false);
        }
    }

    const onCloseCreateNewBarcodeDialog = (status: boolean) => {
        if (status === true) {
            const newBarcode: CreateTimeControlBarcode = {
                created_by_employee_id: store.user.me.user_id,
                time_control_name: barcodeFormValues.time_control_name,
                task_barcode: barcodeFormValues.task_barcode,
                task_description: barcodeFormValues.task_description,
            }
            TimeControlBarCodesApi.createTimeControlBarcode(newBarcode).then(res => {
                if (res.status === true) {
                    setUpdated(true);
                    setBarcodeFormValues({ time_control_name: "", task_barcode: "", task_description: "" });
                    setShowCreateNewBarcodeDialog(false);
                } else if (res.status === false) {
                    setSnackbarMessage(res.message);
                }
            })
        } else {
            setBarcodeFormValues({ time_control_name: "", task_barcode: "", task_description: "" });
            setShowCreateNewBarcodeDialog(false);
        }
    }

    const onCloseRemoveBarcodeDialog = (status: boolean) => {
        if (status === true) {
            const id = selectedTimeControlBarcode!.id
            TimeControlBarCodesApi.removeTimeControlBarcodeById(id!).then(res => {
                if (res.status === true) {
                    setUpdated(true);
                    setBarcodeFormValues({ time_control_name: "", task_barcode: "", task_description: "" });
                    setShowRemoveBarcodeDialog(false);
                } else if (res.status === false) {
                    setSnackbarMessage(res.message);
                }
            })
        } else {
            setBarcodeFormValues({ time_control_name: "", task_barcode: "", task_description: "" });
            setShowRemoveBarcodeDialog(false);
        }
    }

    const generateTableBody = (row: TimeControlBarcode, index: number) => {
        if (row.visible === true) {
            return (
                <TableRow key={index} className={index % 2 ? classes.evenRows : ''}>
                    <TableCell>{row.time_control_name}</TableCell>
                    <TableCell><IconButton onClick={() => redirectToMxi(row.task_barcode)}><Link color="primary" /></IconButton></TableCell>
                    <TableCell>{row.task_description}</TableCell>
                    {isTimeControlAdmin && <TableCell><IconButton onClick={() => openEditBarcodeDialog(row)}><Edit color="primary" /></IconButton></TableCell>}
                    {isTimeControlAdmin && <TableCell><IconButton onClick={() => openRemoveBarcodeDialog(row)}><Delete color="error" /></IconButton></TableCell>}
                </TableRow>
            )
        }
    }

    const classes = useStyles();
    return (
        <Grid>
            <Typography variant={'h1'} align={'center'} text={"Time Control Barcodes"} />
            {timeControlBarcodes === undefined && loading === true ?
                <Grid container sx={{ visibility: loading ? "hidden" : "" }}></Grid>
                :
                <Grid container justifyContent="center">
                    <Table
                        headers={["Name", "Barcode", "Description"]}
                        rows={timeControlBarcodes}
                        generateBody={(row: any, index: number) => generateTableBody(row, index)}
                        pagination={false}
                        skipIndexCol={true}
                        loading={loading}
                    />
                </Grid>
            }
            {isTimeControlAdmin && <Grid container>
                <Grid item md={10}></Grid>
                <Grid item md={2}>
                    <Button onClick={() => setShowCreateNewBarcodeDialog(true)} color="primary">
                        Create New
                    </Button>
                </Grid>
            </Grid>}
            <Dialog
                visible={showEditBarcodeDialog}
                max_width={"md"}
                title={`Edit Barcode named: ${selectedTimeControlBarcode?.time_control_name}`}
                context={
                    <Grid container direction={"column"} rowSpacing={4} justifyContent={"space-evenly"} >
                        <Grid item>
                            <Input multiline={true} help_text={""} error={false} label_text={"Edit Name"} value={barcodeFormValues.time_control_name} onTextChange={(text: string) => setBarcodeFormValues((previous) => ({ ...previous, time_control_name: text }))} type="text" width={100} />
                        </Grid>
                        <Grid item>
                            <Input multiline={true} help_text={""} error={false} label_text={"Edit Barcode"} value={barcodeFormValues.task_barcode} onTextChange={(text: string) => setBarcodeFormValues((previous) => ({ ...previous, task_barcode: text }))} type="text" width={100} />
                        </Grid>
                        <Grid item>
                            <Input multiline={true} help_text={""} error={false} label_text={"Edit Description"} value={barcodeFormValues.task_description} onTextChange={(text: string) => setBarcodeFormValues((previous) => ({ ...previous, task_description: text }))} type="text" width={100} />
                        </Grid>
                    </Grid>
                }
                onClose={(status: boolean) => onCloseEditBarcodeDialog(status)}
                strict={false}
                show_options={true}
            />
            <Dialog
                visible={showCreateNewBarcodeDialog}
                max_width={"md"}
                title={`Create New Barcode`}
                context={
                    <Grid container direction={"column"} rowSpacing={4} justifyContent={"space-evenly"} >
                        <Grid item>
                            <Input multiline={true} help_text={""} error={false} label_text={"Edit Name"} value={barcodeFormValues.time_control_name} onTextChange={(text: string) => setBarcodeFormValues((previous) => ({ ...previous, time_control_name: text }))} type="text" width={100} />
                        </Grid>
                        <Grid item>
                            <Input multiline={true} help_text={""} error={false} label_text={"Edit Barcode"} value={barcodeFormValues.task_barcode} onTextChange={(text: string) => setBarcodeFormValues((previous) => ({ ...previous, task_barcode: text }))} type="text" width={100} />
                        </Grid>
                        <Grid item>
                            <Input multiline={true} help_text={""} error={false} label_text={"Edit Description"} value={barcodeFormValues.task_description} onTextChange={(text: string) => setBarcodeFormValues((previous) => ({ ...previous, task_description: text }))} type="text" width={100} />
                        </Grid>
                    </Grid>
                }
                onClose={(status: boolean) => onCloseCreateNewBarcodeDialog(status)}
                strict={false}
                show_options={true}
            />
            <Dialog
                visible={showRemoveBarcodeDialog}
                max_width={"xs"}
                title={`Are you sure you want to remove the barcode?`}
                context={
                    <Grid container direction={"row"} rowSpacing={2} justifyContent={"space-evenly"} color="#000" >
                        <Grid item xs={3} > Name: </Grid><Grid item xs={9} >{selectedTimeControlBarcode?.time_control_name}</Grid>
                        <Grid item xs={3} > Barcode: </Grid><Grid item xs={9} >{selectedTimeControlBarcode?.task_barcode}</Grid>
                        <Grid item xs={3} > Description: </Grid><Grid item xs={9} >{selectedTimeControlBarcode?.task_description}</Grid>
                    </Grid>

                }
                onClose={(status: boolean) => onCloseRemoveBarcodeDialog(status)}
                strict={false}
                show_options={true}
            />
            <Snackbar onSnackBarClose={() => setSnackbarMessage("")} open={snackbarMessage.length > 0} message={snackbarMessage} autoHide={true} severity='error' horizontalPosition='right' />
        </Grid>
    )
};
export default TimeControlBarcodes;