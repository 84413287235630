import { useState, FunctionComponent } from "react";
import { Grid, Button, TableCell, TableRow, IconButton } from '@mui/material';
import { Input, Typography, Table, Select, Tabs, Dialog } from '../../MaterialUiComponents';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector } from 'react-redux';
import { RootState } from './../../reducer';
import { Link } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import MaintenenceReportsApi from '../../api/maintenanceReports';
import { Report, ActionInfo, WorkpackageInfo, MroTasksInfo } from '../../types/maintenanceReports';
import { Part } from "../../types/tasks";
import { redirectToMxi } from './../../config/mxiProvider';
import { DateTime } from "luxon";

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: "#e5e7eb"
    },
    oddRows: {
        backgroundColor: "#f3f4f6"
    },
    Button: {
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(2),
        minWidth: '150px',
    },
    aog: {
        color: "red",
        marginRight: "8px",
    },
    actionRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fullWidthInput: {
        width: '100%',
        '@media (min-width: 600px)': {
            width: 'auto',
        },
    },
    fixedWidthItem: {
        width: '130px',
        marginLeft: '8px',
    },
}));

type MaintenanceReportsDetailsProps = {
    selectedMro: Report;
    setLoading: Function;
    onRowClick: Function;
    isMroAdmin: boolean;
    setSelectedMro: Function;
}

const MaintenanceReportsDetails: FunctionComponent<MaintenanceReportsDetailsProps> = ({ isMroAdmin, selectedMro, setSelectedMro, setLoading, onRowClick }) => {
    const classes = useStyles();
    const [actionComment, setActionCoomment] = useState<string>("");
    const [barcode, setBarcode] = useState<string>("");
    const [hours, setHours] = useState<string>("");
    const [closeMroDialogOpen, setCloseMroDialogOpen] = useState<boolean>(false);
    const store = useSelector((state: RootState) => state);

    const generateActionsTableBody = (row: ActionInfo, index: number) => {
        const empName = store.employees.all_employees.find(s => s.Id === row.created_by_employee_id)?.employee_name;
        return (
            <TableRow className={index % 2 ? classes.evenRows : classes.oddRows} >
                <TableCell>{index}</TableCell>
                <TableCell>{empName}</TableCell>
                <TableCell>{DateTime.fromJSDate(new Date(row.created_date)).toFormat('yyyy.LL.dd HH:mm')}</TableCell>
                <TableCell>{(row.time_of_next_update === null || row.time_of_next_update === undefined) ? "" : DateTime.fromJSDate(new Date(row.time_of_next_update)).toFormat('yyyy.LL.dd HH:mm')}</TableCell>
                <TableCell>{row.comment}</TableCell>
            </TableRow >
        )
    }

    const onAddAction = () => {
        setLoading(true);
        MaintenenceReportsApi.CreateAction(selectedMro.id, actionComment, hours).then((res) => {
            if (res.status === true) {
                setLoading(false);
                onRowClick(selectedMro);
            }
        });
    }

    const onAddWorkPackage = (barcode: string) => {
        setLoading(true);
        MaintenenceReportsApi.AddWorkPackageToMro(barcode, selectedMro.id).then((res) => {
            setLoading(false);
            onRowClick(selectedMro);
        });
    }

    const generatePartsTableBody = (row: Part, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : classes.oddRows} >
                <TableCell>{index}</TableCell>
                <TableCell>{row.PartDescription}</TableCell>
                <TableCell>{(row.schedual_start_date.toString() !== "0001-01-01T00:00:00") ? DateTime.fromJSDate(new Date(row.schedual_start_date)).toFormat('yyyy.LL.dd HH:mm') : ""}</TableCell>
                <TableCell>{(row.due_date.toString() !== "0001-01-01T00:00:00") ? DateTime.fromJSDate(new Date(row.due_date)).toFormat('yyyy.LL.dd HH:mm') : ""}</TableCell>
                <TableCell>{row.Status}</TableCell>
            </TableRow >
        )
    }

    //Todo: Swap dummy data with real data, will add when data is available
    const generateTasksTableBody = (row: MroTasksInfo, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : classes.oddRows} >
                <TableCell>{index}</TableCell>
                <TableCell>{row.line_no}</TableCell>
                <TableCell>{row.task_name}</TableCell>
                <TableCell> {row.task_status} </TableCell>
                <TableCell>{row.fault_severity}</TableCell>
            </TableRow>
        )
    }

    const updateWorkPackageTabValue = (tabValue: number, id: number) => {
        setSelectedMro((prevState: Report) => {
            const newState = { ...prevState };
            newState.work_packages.forEach((workPackage: WorkpackageInfo) => {
                if (workPackage.id === id) {
                    workPackage.tabValue = tabValue;
                }
            });
            return newState;
        });
    }

    const onCloseMro = () => {
        setLoading(true);
        MaintenenceReportsApi.CloseMro(selectedMro.id).then(() => {
            setLoading(false);
            setCloseMroDialogOpen(false);
            onRowClick(selectedMro);
        });
    }
    return (
        <Grid xs={12} >
            {selectedMro.aog &&
                <Grid container justifyContent="center" alignItems="center">
                    <Typography margin={[0, 2, 0, 0]} color='error' variant="h1" text={'AOG'} align="center" />
                    <AirplanemodeInactiveIcon sx={{ fontSize: 30 }} className={classes.aog} />
                </Grid>
            }
            <Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography margin={[0, 2, 0, 0]} color='primary' variant="h1" text={selectedMro.fault_description} align="center" />
                    <Typography margin={[0, 2, 0, 0]} color='primary' variant="h1" text={selectedMro.tail_number} align="center" />
                </Grid>
            </Grid>
            {(isMroAdmin && !selectedMro.is_closed) ?
                <Grid container justifyContent="center" alignItems="center" style={{ display: 'flex' }}>
                    <Grid style={{ width: "30%", marginTop: '24px' }}>
                        <Input
                            multiline={false}
                            label_text="Add maintenance update"
                            help_text="Please type in a comment"
                            type="text"
                            value={actionComment}
                            width={100}
                            onTextChange={(value: string) => setActionCoomment(value)}
                        />
                    </Grid>
                    <Select
                        filter_by_text={`Next reporting hours`}
                        options={["1", "2", "3", "4", "5", "6", "8", "10", "12", "24", "48", "72"]}
                        onSelectedItem={(e: any) => { setHours(e.target.value) }}
                        disabled={false}
                        widthPercentage={true}
                        width={16}
                    />
                    <Button variant="contained" color='primary' className={classes.fixedWidthItem} onClick={() => onAddAction()}>Add Action</Button>
                    <Button variant="contained" color='primary' className={classes.fixedWidthItem} onClick={() => setCloseMroDialogOpen(true)}>Close report</Button>
                </Grid>
                : ""
            }
            <Typography margin={[0, 0, 1, 0]} color='primary' variant="h2" text={'Actions'} align="center" />
            <Grid container justifyContent="center" alignItems="center" style={{ marginBottom: '18px' }}>
                <Card style={{ width: '75%', backgroundColor: '#f8fafc' }} >
                    <CardContent>
                        <Table
                            headers={["Created By", "Created Date", "Next update", "comment"]}
                            rows={selectedMro.actions}
                            generateBody={(row: any, index: number) => generateActionsTableBody(row, index)}
                            pagination={true}
                            defaultRowsPerPage={10}
                            skipIndexCol={false}
                        />
                    </CardContent>
                </Card>
            </Grid>
            {(isMroAdmin && !selectedMro.is_closed) ?
                <Grid container justifyContent="center" alignItems="center">
                    <Input
                        multiline={false}
                        label_text="Add workpackage"
                        help_text="Please type in a barcode"
                        type="text"
                        value={barcode}
                        width={50}
                        onTextChange={(value: string) => setBarcode(value)}
                    />
                    <Button style={{ marginTop: '18px', marginLeft: '18px' }} variant="contained" color='primary' className={classes.Button} onClick={() => onAddWorkPackage(barcode)}>Add workpackage</Button>
                </Grid>
                : ""
            }
            <Typography margin={[0, 0, 1, 0]} color='primary' variant="h2" text={'Workpackages'} align="center" />
            {selectedMro.work_packages.length > 0 ? selectedMro.work_packages.map((element: WorkpackageInfo) => (
                <Grid container justifyContent="center" alignItems="center" style={{ marginBottom: '18px' }}>
                    <Card style={{ width: '75%', backgroundColor: '#f1f5f9' }} >
                        <CardContent>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid >
                                    <IconButton onClick={() => redirectToMxi(element.barcode)}><Link color="primary" /></IconButton>
                                </Grid>
                                <Grid >
                                    <Typography margin={[0, 4, 1, 0]} color='primary' variant="h4" text={"Name"} align="center" />
                                    <Typography margin={[0, 4, 1, 0]} color='primary' variant="h5" text={element.wo_name} align="center" />
                                </Grid>
                                <Grid>
                                    <Typography margin={[0, 4, 1, 0]} color='primary' variant="h4" text={"Start"} align="center" />
                                    <Typography margin={[0, 4, 1, 0]} color='primary' variant="h5" text={(DateTime.fromJSDate(new Date(element.start_date)).toFormat('yyyy.LL.dd HH:mm') === "0001.01.01 00:00") ? "" : DateTime.fromJSDate(new Date(element.start_date)).toFormat('yyyy.LL.dd HH:mm')} align="center" />
                                </Grid>
                                <Grid>
                                    <Typography margin={[0, 4, 1, 0]} color='primary' variant="h4" text={"Due Date"} align="center" />
                                    <Typography margin={[0, 4, 1, 0]} color='primary' variant="h5" text={(DateTime.fromJSDate(new Date(element.due_date)).toFormat('yyyy.LL.dd HH:mm') === "0001.01.01 00:00") ? "" : DateTime.fromJSDate(new Date(element.due_date)).toFormat('yyyy.LL.dd HH:mm')} align="center" />
                                </Grid>
                                <Grid>
                                    <Typography margin={[0, 4, 1, 0]} color='primary' variant="h4" text={"Status"} align="center" />
                                    <Typography margin={[0, 4, 1, 0]} color='primary' variant="h5" text={element.status} align="center" />
                                </Grid>
                            </Grid>
                            <Tabs
                                tabNames={["Parts", "Tasks"]}
                                onChangeTabValue={(tabValue: number) => updateWorkPackageTabValue(tabValue, element.id)}
                                tabValue={element.tabValue}
                            />
                            {element.tabValue === 0 ?
                                <Grid>
                                    <Typography margin={[3, 0, 1, 0]} color='primary' variant="h3" text={'Parts'} align="center" />
                                    <Table
                                        headers={["Part", "Start", "End", "Status"]}
                                        rows={element.parts}
                                        generateBody={(row: any, index: number) => generatePartsTableBody(row, index)}
                                        pagination={true}
                                        defaultRowsPerPage={10}
                                        skipIndexCol={false}
                                    />
                                </Grid>
                                :
                                <Grid>
                                    <Typography margin={[3, 0, 1, 0]} color='primary' variant="h3" text={'Tasks'} align="center" />
                                    <Table
                                        headers={["Line No", "Task name", "Task status", "Fault severity"]}
                                        rows={element.tasks}
                                        generateBody={(row: any, index: number) => generateTasksTableBody(row, index)}
                                        pagination={true}
                                        defaultRowsPerPage={10}
                                        skipIndexCol={false}
                                    />
                                </Grid>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            ))
                : "No workpackage info"
            }
            <Dialog
                visible={closeMroDialogOpen}
                max_width={"md"}
                title={"Are you sure you want to close this maintenance report"}
                context={
                    <Grid>

                    </Grid>
                }
                onClose={(status: any) => onCloseMro()}
                strict={false}
                show_options={true}
            />
        </Grid>
    )
}

export default MaintenanceReportsDetails;