import { useState, useEffect } from 'react';
import { Grid, Tooltip } from '@mui/material';
import ToolRegisterList from './ToolRegisterList';
import TopRow from './TopRow';

function ToolRegister() {
  const [tabValue, setTabValue] = useState(0);

  const onChangeTabValue = (value: number) => {
    setTabValue(value);
  }

  useEffect(() => {
    const url = new URL(window.location.href.replace("#", ""));
    const numb = url.searchParams.get("value");
    if (numb !== null) {
      setTabValue(parseInt(numb));
    }
  }, []);

  return (
    <Grid xs={12} item>
      <Grid xs={12} item container>
        <Tooltip title="Press F9 to toogle">
          <Grid item xs={6} onClick={() => setTabValue(0)}>
            <TopRow tabValue={tabValue} staticTabValue={0} text={'Tool Out'} />
          </Grid>
        </Tooltip>
        <Tooltip title="Press F10 to toogle">
          <Grid item xs={6} onClick={() => setTabValue(1)} >
            <TopRow tabValue={tabValue} staticTabValue={1} text={'Tool In'} />
          </Grid>
        </Tooltip>
      </Grid>
      <Grid>
        <ToolRegisterList tabValue={tabValue} onChangeTabValue={(value: number) => onChangeTabValue(value)} />
      </Grid>
    </Grid>
  );
};
export default ToolRegister