import { FunctionComponent, useState } from 'react';
import { Grid, TableRow, TableCell, Box, IconButton } from '@mui/material';
import { Table, Typography, Dialog } from '../../MaterialUiComponents';
import { FlightSchedule, LineWorkPackages } from '../../types/line';
import { makeStyles } from '@mui/styles';
import { FlightTakeoff, Link } from '@mui/icons-material';
import { redirectToMxi } from './../../config/mxiProvider';

const useStyles = makeStyles(theme => ({
    inAir: {
        backgroundColor: 'lightyellow',
    },
    inWork: {
        backgroundColor: 'lightblue',
    },
    closedReadyForWork: {
        backgroundColor: '#63AB62',
    },
    noPackagesOpen: {
        backgroundColor: 'orange',
    },
    AOG: {
        backgroundColor: 'orangered',
    },
    marginRight: {
        marginRight: '24px'
    },
    rightBorder: {
        borderTop: "2px dashed gray",
        borderBottom: "2px dashed gray",
        borderRight: "2px dashed gray",
    },
    middleBorder: {
        borderTop: "2px dashed gray",
        borderBottom: "2px dashed gray",
    }
}));

type FlightScheduleScheduleProps = {
    flights: FlightSchedule[],
    isMonitor: boolean
}

const FlightScheduleComponent: FunctionComponent<FlightScheduleScheduleProps> = ({ flights, isMonitor }) => {
    const classes = useStyles();
    const [paperWorkDialogOpen, setPapaerWorkDialogOpen] = useState(false);
    const [paperWorkPackages, setPaperWorkPackages] = useState(Array<LineWorkPackages>());
    const [tailNumber, setTailNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const generateTablePaperworkBody = (row: LineWorkPackages, index: number) => {
        return (
            <TableRow hover>
                <TableCell>{index}</TableCell>
                <TableCell onClick={() => redirectToMxi(row.wo_barcode)}><IconButton><Link color="primary" /></IconButton></TableCell>
                <TableCell>{row.wo_status}</TableCell>
                <TableCell>{row.wo_name}</TableCell>
                <TableCell>{row.wo_number}</TableCell>
                <TableCell>{row.start_date_text}</TableCell>
            </TableRow>
        )
    }
    const tableCellFontSize = (isMonitor: boolean) => {
        if (isMonitor === true) {
            return 'h2';
        } else {
            return 'h5';
        }
    }
    const generateTableBody = (row: FlightSchedule, index: number) => {
        const dotted = row.wo_status_for_aircraft.status === "Incomplete paperwork";
        return (
            <TableRow
                className={(row.wo_status_for_aircraft.status === "In Air" || row.wo_status_for_aircraft.status === "Incomplete paperwork") ? classes.inAir :
                    row.wo_status_for_aircraft.status === "In Work" ? classes.inWork :
                        row.wo_status_for_aircraft.status === "Closed" ? classes.closedReadyForWork :
                            row.wo_status_for_aircraft.status === "AOG" ? classes.AOG : ""
                }
            >
                <TableCell className={dotted ? classes.middleBorder : ""} onClick={() => onTailNumberClicked(row)}><Typography text={row.tail_number} variant={tableCellFontSize(isMonitor)} /></TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}>{row.current_location === "IN-AIR" ? <FlightTakeoff style={{ height: '30px' }} /> : <Typography text={row.current_location} variant={tableCellFontSize(isMonitor)} />}</TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}><Typography text={row.aircraft_stand_location} variant={tableCellFontSize(isMonitor)} /></TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}><Typography text={`${row.inbound_flight_number} ${row.inbound_flight_number && " - "} ${row.inbound_departure_airport_code}`} variant={tableCellFontSize(isMonitor)} /></TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}><Typography text={row.arrival_date_text} variant={tableCellFontSize(isMonitor)} /></TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}><Typography text={row.departure_date_text} variant={tableCellFontSize(isMonitor)} /></TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""}><Typography text={`${row.outbound_flight_number} - ${row.outbound_arrival_airport_code}`} variant={tableCellFontSize(isMonitor)} /></TableCell>
                <TableCell className={dotted ? classes.middleBorder : ""} >
                    <Grid container direction="row" alignItems="center"  >
                        <Box display="flex" alignItems="center" justifyContent="center" >
                            <Grid>
                                {(row.assigned_mechanics.length > 0) &&
                                    row.assigned_mechanics.map((item, idx) => (
                                        <Grid key={idx}>
                                            <Typography text={item.abbreviation ? item.abbreviation : "?"} variant='h3' />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Box>
                    </Grid>
                </TableCell>
                <TableCell className={dotted ? classes.rightBorder : ""}><Typography text={row.remarks} variant={tableCellFontSize(isMonitor)} /></TableCell>
            </TableRow>
        )
    }
    const onTailNumberClicked = (row: FlightSchedule) => {
        if (row.wo_status_for_aircraft.lineWorkpackages?.length > 0) {
            setPapaerWorkDialogOpen(true);
            setTailNumber(row.tail_number);
            setErrorMessage("");
            setPaperWorkPackages(row.wo_status_for_aircraft.lineWorkpackages);

        } else {
            setPapaerWorkDialogOpen(true);
            setTailNumber(row.tail_number);
            setErrorMessage("No packages are scheduled on aircraft");
            setPaperWorkPackages(Array<LineWorkPackages>());
        }
    }
    return (
        <Grid>
            <Table
                headers={["Tail", "Curr Airport", "Stand", "Arr Fl, nr.", "Arrival", "Departure", "Dep Fl. nr.", "Mechanics", "Remarks"]}
                rows={flights}
                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                pagination={false}
                skipIndexCol={true}
            />
            <Dialog
                visible={paperWorkDialogOpen}
                max_width={"md"}
                title={`Paperwork for : ${tailNumber}`}
                context={
                    <Grid item>
                        {
                            errorMessage !== "" ? <Typography text={errorMessage} variant='h2' align='center' />
                                :
                                <Table
                                    headers={["Mxi", "Status", "Wo name", "Wo number", "start"]}
                                    rows={paperWorkPackages}
                                    generateBody={(row: any, index: number) => generateTablePaperworkBody(row, index)}
                                    pagination={false}
                                    skipIndexCol={false}
                                />
                        }
                    </Grid>
                }
                onClose={(status: any) => { setPapaerWorkDialogOpen(false); setPaperWorkPackages([]) }}
                strict={false}
                show_options={false}
            />
        </Grid>
    );
};
export default FlightScheduleComponent;