import { useState, useEffect } from 'react';
import { Grid, LinearProgress, TableCell, TableRow, Button, IconButton } from '@mui/material';
import { Table, Typography, DatePicker, Select, SelectMultiple } from '../../MaterialUiComponents';
import CoursesApi from '../../api/courses';
import { SelectObject } from './../../types/certificates';
import { User } from '../../types/user';
import { course } from '../../types/course';
import { EmployeeSearch } from '../../components';
import { makeStyles } from '@mui/styles';
import { CheckCircle, RemoveCircle, Close } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  evenRows: {
    backgroundColor: theme.palette.action.hover
  },
  resultsAmount: {
    color: 'gray'
  },
}));

function Courses() {
  const classes = useStyles();
  const [datePickerStartDate, setDatePickerStartDate] = useState<Date | null>(null);
  const [datePickerEndDate, setDatePickerEndDate] = useState<Date | null>(null);
  const [selectedEmployeeSsn, setSelectedEmployeeSsn] = useState<string | null>();
  const [selectedCourseOption, setSelectedCourseOption] = useState<string>();
  const [courses, setCourses] = useState<course[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<course[]>([]);
  const [courseOptions, setCourseOptions] = useState<string[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<SelectObject[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const isCourseAdmin = window.location.href.includes("courses");

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    filterCourses();
  }, [datePickerStartDate, datePickerEndDate, selectedEmployeeSsn, selectedCourseOption, selectedDepartments]);

  const filterCourses = () => {
    let tempCourses = courses;
    if (datePickerStartDate) {
      tempCourses = tempCourses.filter((course: course) => new Date(course.startDate) >= new Date(datePickerStartDate));
    }
    if (datePickerEndDate) {
      const end = new Date();
      end.setUTCHours(23, 59, 59, 999);
      tempCourses = tempCourses.filter((course: course) => new Date(course.endDate) <= new Date(new Date(datePickerEndDate.setHours(23, 59, 59, 999)).toString().split('GMT')[0] + ' UTC'));
    }
    if (selectedEmployeeSsn) {
      tempCourses = tempCourses.filter((course: course) => course.employeeNumber === selectedEmployeeSsn);
    }
    if (selectedCourseOption) {
      if (selectedCourseOption !== "All Courses") {
        tempCourses = tempCourses.filter((course: course) => course.learningCourseName === selectedCourseOption);
      }
    }
    if (selectedDepartments.length > 0) {
      const departments = [] as Array<string>;
      selectedDepartments.map(item => {
        departments.push(departmentOptions[item].name);
      })
      tempCourses = tempCourses.filter((course: course) => departments.includes(course.employee!.departmentName));
    }
    setFilteredCourses(tempCourses);
  }

  const redirectToEloomiCourse = () => {
    window.open("https://icelandair.eloomi.com", '_blank')?.focus();
  }
  const generateTableBody = (row: course, index: number) => {
    return (
      <TableRow className={index % 2 ? classes.evenRows : ''} key={row.notDBId}>
        <TableCell width="20%">{row.employee?.firstName} {row.employee?.middleName} {row.employee?.lastName}</TableCell>
        <TableCell width="30%" onClick={() => setSelectedCourseOption(row.learningCourseName)}>{row.learningCourseName}</TableCell>
        <TableCell width="20%">{row.employee?.departmentName}</TableCell>
        <TableCell width="15%">{row.start_date_text}</TableCell>
        <TableCell width="15%">{row.end_date_text}</TableCell>
        <TableCell width="10%">{row.hasCancelled === false ? <CheckCircle color="success" /> : <RemoveCircle color="error" />}</TableCell>
      </TableRow>
    )
  }

  const refresh = async () => {
    await CoursesApi.getAllCoursesSchedule(isCourseAdmin).then(res => {
      const tempDepartmentOptions: Array<SelectObject> = [];
      res.distinctDepartments.map((data, index) => {
        tempDepartmentOptions.push({ name: data, id: index })
      });
      setDepartmentOptions(tempDepartmentOptions);
      setCourseOptions(res.distinctCourses);
      setCourses(res.data);
      setFilteredCourses(res.data);
    });
    setLoading(false);
  }
  return (
    <Grid>
      {loading === true && <Grid> <LinearProgress /> </Grid>}
      <Grid>
        <Typography variant='h1' align='center' text={'Courses'} />
        {isCourseAdmin &&
          <Grid container columnSpacing={2}>
            <Grid item sm={5} lg={2} container>
              <Grid xs={8} spacing={1}>
                <DatePicker type="Date" selected={datePickerStartDate} label="Starts" onDateChange={(date: Date) => setDatePickerStartDate(date)} />
              </Grid>
              <Grid xs={3} style={{ marginTop: "10px" }}>
                {datePickerStartDate !== null && <IconButton onClick={() => setDatePickerStartDate(null)}><Close color='error' /> </IconButton>}
              </Grid>
            </Grid>
            <Grid item sm={5} lg={2} container>
              <Grid xs={8} spacing={1}>
                <DatePicker type="Date" selected={datePickerEndDate} label="Ends" onDateChange={(date: Date) => setDatePickerEndDate(date)} />
              </Grid>
              <Grid xs={3} style={{ marginTop: "10px" }}>
                {datePickerEndDate !== null && <IconButton onClick={() => setDatePickerEndDate(null)}><Close color='error' /> </IconButton>}
              </Grid>
            </Grid>
            <Grid item sm={5} lg={3}>
              <EmployeeSearch place_holder={"Write employee name"} fullUser={true} onSelectedEemployee={(selectedEmployee: User) => setSelectedEmployeeSsn(selectedEmployee?.UserSsn)} customCloseFunction={() => setSelectedEmployeeSsn(null)} />
            </Grid>
            <Grid item sm={5} lg={3}>
              <Select options={courseOptions} selectedItem={selectedCourseOption} widthPercentage width={80} onSelectedItem={(e: any) => setSelectedCourseOption(e.target.value)} filter_by_text="Select Course" />
            </Grid>
            <Grid item sm={5} lg={2} >
              <SelectMultiple
                optionsObject={departmentOptions}
                onSelectedItem={(e: any) => setSelectedDepartments(e.target.value)}
                selectedItems={selectedDepartments}
                width={200}
                filter_by_text={"Filter by Departments"}
              />
            </Grid>*
            <Grid item sm={5} lg={2} >
              <Button variant="text" color="primary" size="small" onClick={() => redirectToEloomiCourse()}>Open Eloomi</Button>
            </Grid>
            <Grid item sm={12} lg={12} className={classes.resultsAmount}>
              <Typography text={filteredCourses !== undefined ? "Showing " + filteredCourses.length + " registrations" : '0 registrations found'} />
            </Grid>
          </Grid>
        }
        {
          courses.length === 0 && loading === false ?
            <Typography variant='h3' align='center' text='No Upcoming courses' />
            :
            <Grid container>
              <Grid item sm={12} lg={12}>
                <Table
                  headers={["Employee name", "Course", "Department", "Start", "End", "Status"]}
                  rows={filteredCourses}
                  generateBody={(row: any, index: number) => generateTableBody(row, index)}
                  pagination={false}
                  skipIndexCol={true}
                  defaultRowsPerPage={100}
                  loading={loading}
                />
              </Grid>
            </Grid>
        }
      </Grid>
    </Grid>
  );
}
export default Courses;