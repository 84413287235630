import { useState, useEffect } from 'react';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Typography, Table } from '../../../MaterialUiComponents';
import toolsApi from '../../../api/tools';
import { ToolData } from '../../../types/tool';
import { makeStyles } from '@mui/styles';
import { ReturnNameData } from '../../../components/Tools';

const useStyles = makeStyles(theme => ({
    selectContainer: {
        justifyContent: "space-evenly"
    },
    toolToLongInCirculation: {
        backgroundColor: 'red'
    }
}));

function ToolsBayTwoMonitor() {
    const [loaned_out_tools, setLoanedOutTools] = useState(Array<ToolData>());
    const classes = useStyles();

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        toolsApi.getAllLoanedToolsbyDays(0, "Bay 2", 0, "emty", true, false).then(res => {
            setLoanedOutTools(res);
        });
    }

    const generateTableBody = (row: ToolData, index: number) => {
        return (
            <TableRow className={row.tool_to_long_in_circulations === true ? classes.toolToLongInCirculation : ""}>
                <TableCell>{index}</TableCell>
                <TableCell>
                    <ReturnNameData
                        employee_id={row.employee_id}
                        tail_number={row.tail_number}
                        world_trip_name={row.world_trip_name}
                        employee_image={row.employee_image}
                        employee_name={row.employee_name}
                    />
                </TableCell>
                <TableCell>{row.tail_number}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.bay}</TableCell>
                <TableCell>{row.area}</TableCell>
                <TableCell>{row.serial}</TableCell>
                <TableCell>{row.out_date_text}</TableCell>
                <TableCell>{row.days_allowed}</TableCell>
            </TableRow>
        )
    }

    return (
        <Grid xs={12} item>
            <Typography text={`Loaned out tools overview (${loaned_out_tools.length})`} variant={'h2'} align={"center"} />
            <Table
                headers={["Name", "Tail number", "Description", "Bay", "Area", "Serial", "Loaned out", "Allowed Days"]}
                rows={loaned_out_tools}
                generateBody={(row: ToolData, index: number) => generateTableBody(row, index)}
                pagination={false}
            />
        </Grid>
    );
};
export default ToolsBayTwoMonitor;