import { settings } from '../config';
import { Part } from '../types/tasks';
import { AllLineHandoverReports, HandoverReportSearchObj, LineHandoverReturnReportObj, MxiEventsForUsersPackageOverviewByTailNUmber, LinePackageOverviewObj, FlightSchedule, LineExtraTaskInWOrkpackage } from '../types/line';
import { Event } from '../types/tasks';
import { CommentsObj } from '../types/messages';
import { User } from '../types/user';
import { getToken } from './../global/TokenValidator';

class Line {
    GetHandoverReportsByTail = async (tail_number: string, departments: Array<string>): Promise<{ status: Boolean, data: HandoverReportSearchObj[] }> => {
        return fetch(`${settings.HOST}/Line/GetHandoverReportsByTail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                tail_number: tail_number,
                departments: departments
            })
        }).then(x => {
            return x.json()
        })
    }
    GetLinePacketsForCalendar = async (woStatus: string[]): Promise<{ status: Boolean, data: Event[] }> => {
        return fetch(`${settings.HOST}/LinePlanner/GetLinePacketsForCalendar`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                wo_status: woStatus,
            })
        }).then(x => {
            return x.json()
        })
    }

    GetTasksByLinePackageWoNumber = async (woNumber: string): Promise<{ status: Boolean, data: LineExtraTaskInWOrkpackage[] }> => {
        return fetch(`${settings.HOST}/Line/GetTasksByLinePackageWoNumber/${woNumber}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    GetLinePacketsOverview = async (from: Date, to: Date, status: Array<string>, aircraft: string, locations: Array<string>, due_date_hour_checked: boolean, due_date_exceeds_fifteen: boolean, showTasksInWorkpackages: boolean):
        Promise<{ status: Boolean, message: string, data: Array<LinePackageOverviewObj> }> => {
        return fetch(`${settings.HOST}/Line/GetLinePacketsOverview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "from": from,
                "to": to,
                "aircraft": aircraft,
                "status": status,
                "locations": locations,
                "due_date_hour_checked": due_date_hour_checked,
                "due_date_exceeds_fifteen": due_date_exceeds_fifteen,
                "show_tasks_in_workpackages": showTasksInWorkpackages
            })
        }).then(x => {
            return x.json()
        })
    }

    GetLinePacketsHangarPlan = async (from: Date, 
                                        to: Date, 
                                        status: Array<string>, 
                                        locations: Array<string>):
        Promise<{ status: Boolean, message: string, data: Array<LinePackageOverviewObj> }> => {
        return fetch(`${settings.HOST}/Line/GetLinePacketsHangarPlan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "from": from,
                "to": to,
                "status": status,
                "locations": locations
            })
        }).then(x => {
            return x.json()
        })
    }

    getAllLineHandoverReports = async (): Promise<{ status: Boolean, message: string, data: Array<AllLineHandoverReports>, total: number }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/getAllLineHandoverReports`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    getFlightSchedule = async (): Promise<{ status: Boolean, data_fetched_when: string, data: Array<FlightSchedule> }> => {
        return fetch(`${settings.HOST}/getFlightSchedule `, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
            },
        }).then(x => {
            return x.json()
        })
    }

    GetMxiEventsForShift = async (reportID: number, isTurnCheck: boolean): Promise<{ status: Boolean, message: string, data: Array<MxiEventsForUsersPackageOverviewByTailNUmber> }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/GetMxiEventsForShift/${reportID}/${isTurnCheck}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetLineMessagesByWoBarcode = async (woBarcode: string): Promise<{ status: Boolean, message: string, data: Array<CommentsObj> }> => {
        return fetch(`${settings.HOST}/LinePlanner/GetLineMessagesByWoBarcode/${woBarcode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    CreateLineMessage = async (woBarcode: string, message: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/LinePlanner/CreateLineMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "woBarcode": woBarcode,
                "text": message

            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteLineMessage = async (linePlannedWoPackageId: number): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/LinePlanner/DeleteLineMessage`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "line_planed_wo_packages_id": linePlannedWoPackageId
            })
        }).then(x => {
            return x.json()
        })
    }
    getLineHandoverReportByID = async (reportId: number): Promise<{ status: Boolean, message: string, data: LineHandoverReturnReportObj, }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/getLineHandoverReportByID/${reportId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    GetAllOutstandingMaterialsByWo = async (from: Date, to: Date, woBarcodes: string[]): Promise<{ data: Part[], status: Boolean }> => {
        return fetch(`${settings.HOST}/Line/GetAllOutstandingMaterialsByWo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "from": from,
                "to": to,
                "wo_barcodes": woBarcodes
            })
        }).then(x => {
            return x.json()
        })
    }

    AssignEMployeeFlightSchedule = async (employee: User, tailNumber: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Line/AssignEMployeeFlightSchedule`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "mechanic_employee_id": employee.Id,
                "tail_number": tailNumber,
            })
        }).then(x => {
            return x.json()
        })
    }

    UpdateFlightSchedule = async (remarks: string, tailNumber: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Line/UpdateFlightSchedule`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "remarks": remarks,
                "tail_number": tailNumber,
            })
        }).then(x => {
            return x.json()
        })
    }

    RemoveAllofLineShiftSupervisorEmployees = async (): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/Line/RemoveAllofLineShiftSupervisorEmployees`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    RemoveEmployeeFlightSchedule = async (tail_number: string, mechanic_employee_id: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Line/RemoveEmployeeFlightSchedule`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "tail_number": tail_number,
                "mechanic_employee_id": mechanic_employee_id,
            })
        }).then(x => {
            return x.json()
        })
    }
    CloseLineHandoverReport = async (): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/CloseLineHandoverReport`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    AddIssue = async (reportId: number, issue: string, tailNumber: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/AddIssue`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "report_id": reportId,
                "issue": issue,
                "tail_number": tailNumber
            })
        }).then(x => {
            return x.json()
        })
    }
    CreateNewLineHandoverReport = async (): Promise<{ status: boolean, message: string, data: number }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/CreateNewLineHandoverReport`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    UpdateIssue = async (issueId: number, issue: string, tailNumber: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/UpdateIssue`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": issueId,
                "issue": issue,
                "tail_number": tailNumber
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteIssue = async (issueId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/DeleteIssue`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": issueId
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteLabour = async (reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/DeleteLabour/${reportId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "Id": employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    AddLabour = async (reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/AddLabour/${reportId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "Id": employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteLabourMC = async (reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/DeleteLabourMC/${reportId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "Id": employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
    AddLabourMC = async (reportId: number, employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/LineHandOverReport/AddLabourMC/${reportId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "Id": employeeId
            })
        }).then(x => {
            return x.json()
        })
    }
}
const LineApi = new Line();
export default LineApi;