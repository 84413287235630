import { useState, useEffect, FunctionComponent } from 'react';
import { Grid, TableRow, TableCell, Tooltip } from '@mui/material';
import { Typography, Table, Dialog } from './../../MaterialUiComponents';
import { clothingData } from './../../types/clothRequests';
import { makeStyles } from '@mui/styles';
import clothingRequestsApi from './../..//api/clothingRequests';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import ProfileImg from '../../components/Images/ProfileImg';

const useStyles = makeStyles(theme => ({
  userPhoto: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: "50%"
  },
}));

type ClothingProps = {
  setLoading: Function
  searched_user_id: number,
}

const DefectiveReports: FunctionComponent<ClothingProps> = ({ setLoading, searched_user_id }) => {
  const [all_clothing_requests, setAllCLothingRequests] = useState(Array<clothingData>());
  const [all_clothing_requests_for_user, setAllCLothingRequestsForUser] = useState(Array<clothingData>());
  const [selected_row, setSelectedRow] = useState({} as clothingData);
  const [order_by_text, setOrderByText] = useState("created_date");
  const [ascending, setAscending] = useState(true);
  const [employee_request_dialog_open, setEMployeeRequestDialogOpen] = useState(false);
  const classes = useStyles();
  const store = useSelector((state: RootState) => state);
  const users = store.employees.all_employees;

  useEffect(() => {
    //If searched for user then update, otherwise ignore this request
    if (searched_user_id > 0) {
      searchForUserByID(order_by_text, ascending, searched_user_id);
    } else {
      refresh(order_by_text, ascending);
    }
  }, [order_by_text, ascending, searched_user_id]);

  const generateTableBody = (row: clothingData, index: number) => {
    return (
      <TableRow hover onClick={() => onRowClick(row)}>
        <TableCell>{index}</TableCell>
        <TableCell >
          <Grid container>
            <ProfileImg imgKey={index} userName={users?.find(s => s.Id === row.employee_id)?.userName?.split('@')[0]} />
            <Typography margin={[1, 0, 0, 2]} align="left" text={row.employee_name}></Typography>
          </Grid>
        </TableCell>
        <TableCell>{row.created_date_text}</TableCell>
        <TableCell>{row.reason}</TableCell>
      </TableRow>
    )
  }

  const generateTableBodyForUser = (row: clothingData, index: number) => {
    return (
      <Tooltip title={row.reason} color="secondary">
        <TableRow hover onClick={() => onRowClick(row)}>
          <TableCell>{index}</TableCell>
          <TableCell>{row.created_date_text}</TableCell>
          <TableCell >
            <Grid container>
              <ProfileImg imgKey={index} userName={users?.find(s => s.Id === row.employee_id)?.userName?.split('@')[0]} />
              <Typography margin={[1, 0, 0, 2]} align="left" text={row.employee_name}></Typography>
            </Grid>
          </TableCell>
          <TableCell>{row.clothing_type_text}</TableCell>
          <TableCell>{row.amount}</TableCell>
        </TableRow>
      </Tooltip>
    )
  }


  const searchForUserByID = (order_by_text: string, ascending: boolean, user_id: number) => {
    setLoading(true);
    clothingRequestsApi.GetClothingRequestByEmployeeId(order_by_text, ascending, searched_user_id).then(res => {
      if (res.data.length > 0) {
        setAllCLothingRequests([res.data[res.data.length - 1]]);
      }
      else {
        setAllCLothingRequests(res.data);
      }
    });
    setLoading(false);
  }

  const onRowClick = (row: clothingData) => {
    setEMployeeRequestDialogOpen(true);
    setSelectedRow(row);
    clothingRequestsApi.GetClothingRequestByEmployeeId(order_by_text, ascending, row.employee_id).then(res => {
      setAllCLothingRequestsForUser(res.data);
    })
  }
  const OnOrderByTitles = (given_order_by_text: string) => {
    let temp_ascending = true;
    if (order_by_text === given_order_by_text) { temp_ascending = ascending === false ? true : false; }
    setAscending(temp_ascending);
    setOrderByText(given_order_by_text);
    refresh(given_order_by_text, temp_ascending);
  }
  const OnOrderByTitlesForUser = (given_order_by_text: string) => {
    let temp_ascending = true;
    if (order_by_text === given_order_by_text) { temp_ascending = ascending === false ? true : false; }
    setAscending(temp_ascending);
    setOrderByText(given_order_by_text);
    refresh(given_order_by_text, temp_ascending);
  }
  const refresh = (order_by_text: string, ascending: boolean) => {
    setLoading(true);
    if (employee_request_dialog_open === true) {
      clothingRequestsApi.GetClothingRequestByEmployeeId(order_by_text, ascending, selected_row.employee_id).then(res => {
        setAllCLothingRequestsForUser(res.data);
        setLoading(false);
      })
    } else {
      clothingRequestsApi.GetAllClothingRequests(order_by_text, ascending).then(res => {
        setAllCLothingRequests(res.data);
        setLoading(false);
      })
    }
  }

  return (
    <Grid item xs={12}>
      <Typography color='primary' variant='h2' text={"All clothing requests"} align='center' />
      <Table
        headers={["Name", "last clothing request", "Reason"]}
        rows={all_clothing_requests}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={100}
        OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
      />
      <Dialog
        visible={employee_request_dialog_open}
        max_width={"md"} title={`Clothing requests for ${selected_row.employee_name}`}
        context={
          <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
            <Table
              headers={["Created date", "Name", "Clothing type", "Amount"]}
              rows={all_clothing_requests_for_user}
              generateBody={(row: any, index: number) => generateTableBodyForUser(row, index)}
              defaultRowsPerPage={100}
              OnOrderByTitles={(order_by_text: string) => OnOrderByTitlesForUser(order_by_text)}
            />
          </Grid>
        }
        onClose={(status: any) => setEMployeeRequestDialogOpen(false)}
        strict={false}
        show_options={false}
      />
    </Grid>
  );
};
export default DefectiveReports;