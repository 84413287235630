
const extraPaymentStatusOptions = {
  confirmed: "Confirmed",
  declined: "DECLINED",
  waitingForSupervisor: "Waiting for supervisor",
  waitingForTimeControl: "Waiting for Time control",
} as const;

const extraPaymentSupervisorStatusOptions = {
  confirmed: "Confirmed",
  declined: "DECLINED",
  waitingForSupervisor: "Waiting for supervisor",
} as const;

  export {
    extraPaymentStatusOptions,
    extraPaymentSupervisorStatusOptions,
}