import { ToolData } from "../types/tool";
import { ToolActions } from "../types/actions";

const initialState : { mxi_tools: ToolData[],loaned_out_tools:ToolData[] } = { mxi_tools : [],loaned_out_tools : [] };

export const toolsReducer = (state = { ...initialState }, action: ToolActions) => {
	switch (action.type) {
		case 'ON_MXI_TOOLS': 
			return { ...state, mxi_tools: action.tools };		
		case 'ON_ALL_LOANED_OUT_TOOLS': 
			return { ...state, loaned_out_tools: action.tools };		
		default:
			return state;
	}
}