import { settings } from '../config';
import { ATAChapter, Request } from '../types/capabilityList';
import { getToken } from './../global/TokenValidator';

class CapabilityList {
    GetAllAtaChaptersRatings = async (): Promise<{ status: boolean, message: string, data: ATAChapter[] }> => {
        return fetch(`${settings.HOST}/CapabilityList/GetAllAtaChaptersRatings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }

    GetAllCapabilityRequests = async (): Promise<{ status: boolean, message: string, data: Request[] }> => {
        return fetch(`${settings.HOST}/CapabilityList/GetAllCapabilityRequests`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }

    GetRequestHistoryById = async (requestId: number): Promise<{ status: boolean, message: string, data: Request[] }> => {
        return fetch(`${settings.HOST}/CapabilityList/GetRequestHistoryById/${requestId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }

    CreateRequest = async (capability: Request): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/CapabilityList/CreateRequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(capability)
        }).then(x => {
            return x.json()
        })
    }
    DeleteRequest = async (requestId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/CapabilityList/DeleteRequest/${requestId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetRequestById = async (requestId: number): Promise<{ status: boolean, message: string, data: Request }> => {
        return fetch(`${settings.HOST}/CapabilityList/GetRequestById/${requestId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    AddTool = async (requestId: number, toolName: string, partNumber: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/CapabilityList/AddTool`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "capability_request_id": requestId,
                "tool_name": toolName,
                "part_number": partNumber,
            })
        }).then(x => {
            return x.json()
        })
    }
    UpdateRequest = async (request: Request): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/CapabilityList/UpdateRequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(request),
        }).then(x => {
            return x.json()
        })
    }
}
const CapabilityListApi = new CapabilityList();
export default CapabilityListApi;