import { useEffect, useState, FunctionComponent } from 'react';
import { Grid, TableRow, TableCell, IconButton, Button } from '@mui/material';
import VacationApi from './../../api/vacation';
import { VacationRequest } from './../../types/vacation';
import { makeStyles } from '@mui/styles';
import { Dialog, Table, Typography, DatePicker, Select, Input, SelectMultiple } from '../../MaterialUiComponents';
import { EmployeeSearch } from '../../components';
import { Check, Clear, Search } from '@mui/icons-material';
import { DateRange } from '@mui/lab/DateRangePicker';

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'flex',
    margin: 'auto'
  },
  magnifierIcon: {
    cursor: "pointer"
  },
  modalZoom: {
    zoom: "75%"
  }
}));

const TimeControlVacationRequests: FunctionComponent = () => {
  const classes = useStyles();
  const statusOptions: { name: string, id: number }[] = [{ name: 'Waiting for response', id: 1 }, { name: 'Approved', id: 2 }, { name: 'Rejected', id: 3 }];
  const [vacationRequests, setVacationRequests] = useState(Array<VacationRequest>());
  const [errorMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([1]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(0);
  const [approveRequestDialogOpen, setApproveRequestDialogOpen] = useState(false);
  const [rejectRequestDialogOpen, setRejectRequestDialogOpen] = useState(false);
  const [reopenDialogOpen, setReopenDialogOpen] = useState(false);
  const [selectedVacationRequestRow, setSelectedVacationRequestRow] = useState<VacationRequest>();
  const [timeControlReason, setTimeControlReason] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState(Array<VacationRequest>());
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
  const [selectedStatusString, setSelectedStatusString] = useState("");

  useEffect(() => {
    refresh();
  }, [startDate, endDate, selectedStatus, selectedEmployeeId, rejectRequestDialogOpen, approveRequestDialogOpen, reopenDialogOpen]);

  const refresh = () => {
    const statusArray = Array<string>();
    selectedStatus.map(row => (
      statusArray.push(statusOptions.find(s => s.id === row)!.name)
    ));

    VacationApi.GetAllVacationRequest(statusArray, selectedEmployeeId, startDate, endDate).then(res => {
      if (res.status === true) {
        setVacationRequests(res.data);
        setErrorMessage("");
      } else {
        setVacationRequests(Array<VacationRequest>());
        setErrorMessage(res.message);
      }
    });
  }
  const generateTableBody = (row: VacationRequest, index: number) => {
    return (
      <TableRow key={index} hover style={{ background: row.half_day_checked ? '#f2e54f' : undefined }}>
        <TableCell>{index}</TableCell>
        <TableCell>{row.created_date_text}</TableCell>
        <TableCell>{row.created_by_employee_name}</TableCell>
        <TableCell>{row.mechanic_reason}</TableCell>
        <TableCell>{row.vacation_from_date_string}</TableCell>
        <TableCell>{row.vacation_to_date_string}</TableCell>
        <TableCell>{row.time_control_last_modified_date_string} - {row.last_modified_by_employee_name}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.department_name}</TableCell>
        <TableCell>{row.time_control_reason}</TableCell>
        <TableCell>
          <Grid container>
            {row.status === "Waiting for response" && <IconButton onClick={() => { setApproveRequestDialogOpen(true); setSelectedVacationRequestRow(row); }}><Check style={{ color: 'green' }} /></IconButton>}
            {row.status === "Waiting for response" && <IconButton onClick={() => { setRejectRequestDialogOpen(true); setSelectedVacationRequestRow(row); }}><Clear style={{ color: 'red' }} /></IconButton>}
          </Grid>
        </TableCell>
        <TableCell><Search className={classes.magnifierIcon} onClick={() => onRowClick(row)} /></TableCell>
      </TableRow>
    )
  }
  const onSelectedEmployeeRow = (employeeId: number) => {
    setSelectedEmployeeId(employeeId);
  }
  const onTimeControlEdit = (status: boolean, timeControlAnswer: string) => {
    if (status === true) {
      VacationApi.TimeControlUpdateVacationRequest(selectedVacationRequestRow!.vacation_request_id, timeControlAnswer, timeControlReason).then(res => {
        setTimeControlReason("");
        setApproveRequestDialogOpen(false);
        setRejectRequestDialogOpen(false);
      });
    } else {
      setApproveRequestDialogOpen(false);
      setRejectRequestDialogOpen(false);
    }
  }

  const onRowClick = (row: VacationRequest) => {
    setCurrentRow([row]);
    setShowDialog(true);
  }

  const onPrintRow = () => {
    const tempVacationRequests: VacationRequest[] = vacationRequests;
    setVacationRequests([]);
    //Waiting for the state to update and clear vacation list so that the print function only prints the selected request, a little hacky but works
    //Otherwise we end up with empty pages to print.
    setTimeout(() => {
      window.print();
      setVacationRequests(tempVacationRequests);
    }, 200);
  }

  const generateTimeControlRowTableBody = (row: VacationRequest, index: number) => {
    return (
      <TableRow hover style={{ background: row.half_day_checked ? '#f2e54f' : undefined }}>
        <TableCell>{row.created_date_text}</TableCell>
        <TableCell>{row.created_by_employee_name}</TableCell>
        <TableCell>{row.mechanic_reason}</TableCell>
        <TableCell>{row.vacation_from_date_string}</TableCell>
        <TableCell>{row.vacation_to_date_string}</TableCell>
        <TableCell>{row.time_control_last_modified_date_string} - {row.last_modified_by_employee_name}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.department_name}</TableCell>
        <TableCell>{row.time_control_reason}</TableCell>
        <TableCell>{row.half_day_checked}</TableCell>
      </TableRow>
    )
  }

  const onSetSelectedDates = (dates: DateRange<Date>) => {
    if (dates[1] === null) { dates[1] = dates[0]; }
    setSelectedDates([dates[0]!, dates[1]!]);
  }

  const reopenVacationRequest = (toDate: Date, fromDate: Date, timeControlReason: string, status: string) => {
    onSetSelectedDates([toDate, fromDate]);
    setTimeControlReason(timeControlReason);
    setSelectedStatusString(status);
    setReopenDialogOpen(true);
  }

  const onTimeControlUpdateApprovedVacationRequest = (status: boolean) => {
    if (status === true) {
      VacationApi.TimeControlUpdateApprovedVacationRequest(currentRow[0].vacation_request_id, selectedStatusString, timeControlReason, selectedDates[0], selectedDates[1]).then(res => {
        setTimeControlReason("");
        setShowDialog(false);
        setReopenDialogOpen(false);
      });
    } else {
      setShowDialog(false);
      setReopenDialogOpen(false);
    }
  }

  return (
    <Grid>
      <Typography text="Time Control Vacation Requests" align='center' variant='h1' />

      <Grid item container xs={12} justifyContent={'center'}>
                        <span style={{ backgroundColor:'#f2e54f',
                                        width: '16px', 
                                        height: '16px', 
                                        display: 'inline-block',
                                        marginRight: "5px",
                                      marginTop: "5px"}}>
                        </span><span> Half day</span>
      </Grid>

      <Grid item container xs={12} >
        <Grid item sm={3} xs={12}>
          <EmployeeSearch place_holder={"Write employees name"} onSelectedEemployee={(employeeId: number) => onSelectedEmployeeRow(employeeId)} />
        </Grid>
        <Grid item sm={1} xs={12}></Grid>
        <Grid item sm={2} xs={5}>
          <DatePicker type="Date" selected={startDate} label="Start date" onDateChange={(date: Date) => setStartDate(date)} />
        </Grid>
        <Grid item sm={1} xs={2}></Grid>
        <Grid item sm={2} xs={5}>
          <DatePicker type="Date" selected={endDate} label="End date" onDateChange={(date: Date) => setEndDate(date)} />
        </Grid>
        <Grid item sm={1} xs={12}></Grid>
        <Grid item sm={2} xs={12}>
          <SelectMultiple optionsObject={statusOptions} selectedItems={selectedStatus} width={200} onSelectedItem={(e: any) => setSelectedStatus(e.target.value)} filter_by_text="Status" />
        </Grid>
      </Grid>
      <Table
        headers={["Created date", "Mechanic", "Mechanic reason", "from", "to", "Time control Modified", "Status", "Work area", "Time control reason", "", "Details"]}
        rows={vacationRequests}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={5}
        OnOrderByTitles={(order_by_text: string) => ""}
      />
      <br />
      {errorMessage !== "" && <Typography text={errorMessage} align='center' variant='h3' />}
      <Dialog
        visible={approveRequestDialogOpen}
        max_width={"xs"} title={"Are you sure you want to Approve"}
        context={
          <Grid item xs={12} style={{ justifyContent: 'center' }}>
            <IconButton className={classes.icon}><Check fontSize='large' style={{ color: 'green' }} /></IconButton>
            <Input multiline={true} help_text={""} error={false} label_text={"Reason for approving request"} value={timeControlReason} onTextChange={(text: string) => setTimeControlReason(text)} type="text" width={100} />
          </Grid>
        }
        onClose={(status: any) => onTimeControlEdit(status, "Approved")}
        strict={false}
        show_options={true}
      />
      <Dialog
        visible={rejectRequestDialogOpen}
        max_width={"xs"} title={"Are you sure you want to Reject"}
        context={
          <Grid item xs={12} style={{ justifyContent: 'center' }}>
            <IconButton className={classes.icon}><Clear fontSize='large' style={{ color: 'red' }} /></IconButton>
            <Input multiline={true} help_text={""} error={false} label_text={"Reason for reject request"} value={timeControlReason} onTextChange={(text: string) => setTimeControlReason(text)} type="text" width={100} />
          </Grid>
        }
        onClose={(status: any) => onTimeControlEdit(status, "Rejected")}
        strict={false}
        show_options={true}
      />
      <Dialog
        visible={showDialog}
        max_width={"lg"}
        title={`Time Control Vacation Request for ${currentRow[0]?.created_by_employee_name}`}
        print_icon_visible={true}
        onPrint={() => onPrintRow()}
        context={
          <Grid>
            <Grid className={classes.modalZoom}>
              {currentRow !== undefined &&
                <Table
                  skipIndexCol={true}
                  headers={["Created date", "Mechanic", "Mechanic reason", "from", "to", "Time control Modified", "Status", "Work area", "Time control reason"]}
                  rows={currentRow}
                  generateBody={(row: VacationRequest, index: number) => generateTimeControlRowTableBody(currentRow[0], index)}
                />
              }
            </Grid>
            {currentRow[0]?.status !== "Waiting for response" && <Button onClick={() => reopenVacationRequest(currentRow[0].vacation_from_date, currentRow[0].vacation_to_date, currentRow[0].time_control_reason, currentRow[0].status)} color="primary">
              Reopen
            </Button>}
          </Grid>
        }
        onClose={(status: any) => setShowDialog(false)}
        strict={false}
        show_options={false}
      />
      <Dialog
        visible={reopenDialogOpen}
        max_width={"xs"}
        title={`Reopen Vacation Request for ${currentRow[0]?.created_by_employee_name}`}
        context={
          <Grid container direction={"column"} rowSpacing={4} justifyContent={"space-evenly"} >
            <Grid item>
              <DatePicker width={100} type="DateRange" selected={null} selectedRange={[selectedDates[0], selectedDates[1]]} label="Select a date" onDateChange={(newDates: DateRange<Date>) => onSetSelectedDates(newDates)} />
            </Grid>
            <Grid item>
              <Select onSelectedItem={(e: any) => setSelectedStatusString(e.target.value)} selectedItem={selectedStatusString} width={100} widthPercentage={true} filter_by_text={"Update Request Status"} options={["Approved", "Rejected"]} />
            </Grid>
            <Grid item>
              <Input multiline={true} help_text={"[Optional] "} error={false} label_text={"Update comment"} value={timeControlReason} onTextChange={(text: string) => setTimeControlReason(text)} type="text" width={100} />
            </Grid>
          </Grid>
        }
        onClose={(status: boolean) => onTimeControlUpdateApprovedVacationRequest(status)}
        strict={false}
        show_options={true}
      />
    </Grid>
  );
}
export default TimeControlVacationRequests;