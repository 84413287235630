import { useState, useEffect, FunctionComponent } from "react";
import { Search } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from './../../reducer';
import { Grid, Button, TableCell, TableRow } from '@mui/material';
import { Tabs, Dialog, LinearProgress, Input, Table, Typography, Select } from '../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import MaintenenceReportsApi from '../../api/maintenanceReports';
import { Report } from '../../types/maintenanceReports';
import MaintenanceReportsDetails from "./MaintenanceReportDetails";
import { AirplanemodeInactive } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    Button: {
        marginBottom: theme.spacing(4),
    },
    magnifierIcon: {
        cursor: "pointer"
    },
    aog: {
        color: "red",
        marginRight: "8px"
    },
    toggles: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    },
}));

const MaintenanceReports: FunctionComponent = () => {
    const classes = useStyles();
    const store = useSelector((state: RootState) => state);
    const isMroAdmin = store.user.me.user_clearence_groups.includes("Maintenance Reports Edit");
    const [isLoading, setLoading] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const [maintenanceReports, setMaintenanceReports] = useState<Report[]>([]);
    const [maintenanceReportsCopy, setMaintenanceReportsCopy] = useState<Report[]>([]);
    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
    const [barcode, setBarcode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [selectedMro, setSelectedMro] = useState<Report>();
    const [selectedReportFilter, setSelectedReportFilter] = useState<string>("All active reports");

    useEffect(() => {
        if (tabValue === 0) {
            refresh();
        }
    }, [tabValue]);


    useEffect(() => {
        onSelectReportFilter("All active reports");
    }, [maintenanceReportsCopy]);


    const refresh = () => {
        setLoading(true);
        MaintenenceReportsApi.GetAllMaintenanceReports().then((res) => {
            if (res.data !== undefined && res.data.length > 0) {
                setMaintenanceReports(res.data);
                setMaintenanceReportsCopy(res.data);
                setCreateDialogOpen(false);
                setLoading(false);
            }
        });
    }

    const onRowClick = (row: Report) => {
        setLoading(true);
        MaintenenceReportsApi.GetMroById(row.id).then((res) => {
            if (res.status === true) {
                const report: Report = res.data;
                report.work_packages.forEach(workPackage => {
                    workPackage.tabValue = 0;
                });
                setSelectedMro(report);
                setTabValue(1);
                setLoading(false);
            }
        });
    }

    const generateTableBody = (row: Report, index: number) => {
        const empName = store.employees.all_employees.find(s => s.Id === row.created_by_employee_id)?.employee_name;
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{index}</TableCell>
                <TableCell>{row.tail_number}</TableCell>
                <TableCell> {empName}</TableCell>
                <TableCell>{row.created_date_text}</TableCell>
                <TableCell>
                    {row.aog === true ? <Grid container alignItems="center"> <AirplanemodeInactive sx={{ fontSize: 30 }} className={classes.aog} /> <Typography text="AOG" variant="subtitle2" color="textPrimary" margin={[0, 0, 0, 0]} /></Grid> : ""}
                </TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell> {row.estimated_release_text}</TableCell>
                <TableCell>{row.work_packages_count}</TableCell>
                <TableCell>{row.fault_description}</TableCell>
                <TableCell>{row.actions_count}</TableCell>
                <TableCell><Search className={classes.magnifierIcon} onClick={() => onRowClick(row)} /></TableCell>
            </TableRow >
        )
    }

    const onCreateMaintenanceReport = (status: boolean) => {
        if (status === true) {
            setLoading(true);
            MaintenenceReportsApi.CreateMaintenanceReport(barcode, location).then((res) => {
                if (res.status === true) {
                    refresh();
                }
                setLoading(false);
            });
        }
        else {
            setCreateDialogOpen(false);
        }
    }

    const onSelectReportFilter = (filter: string) => {
        setSelectedReportFilter(filter);
        switch (filter) {
            case "AOG":
                setMaintenanceReports(maintenanceReportsCopy.filter(mro => mro.aog === true && mro.is_closed === false));
                break;
            case "All active reports":
                setMaintenanceReports(maintenanceReportsCopy.filter(mro => mro.is_closed === false));
                break;
            case "Archived":
                setMaintenanceReports(maintenanceReportsCopy.filter(mro => mro.is_closed === true));
                break;
        }
    }

    return (
        <Grid xs={12}>
            {isLoading && <LinearProgress />}
            <Grid>
                <Tabs
                    tabNames={tabValue === 0 ? ["Maintenance reports"] : ["Maintenance reports", "Maintenance report"]}
                    onChangeTabValue={(tabValue: number) => tabValue !== 1 && setTabValue(tabValue)}
                    tabValue={tabValue}
                />
                {(tabValue === 0) &&
                    <Grid xs={12}>
                        <Grid>
                            <Select
                                width={20}
                                options={["AOG", "All active reports", "Archived"]}
                                selectedItem={selectedReportFilter}
                                widthPercentage onSelectedItem={(e: any) => onSelectReportFilter(e.target.value)}
                                filter_by_text="Select filter"
                            />
                        </Grid>
                        <Table
                            headers={["Tail", "Created By", "Created Date", "Type", "Location", "Est.Release", "WO", "Foult description", "Actions", ""]}
                            rows={maintenanceReports}
                            generateBody={(row: any, index: number) => generateTableBody(row, index)}
                            pagination={true}
                            defaultRowsPerPage={10}
                            skipIndexCol={false}
                        />
                        {isMroAdmin ?
                            <Grid item style={{ marginTop: '18px' }}>
                                <Button variant="contained" color='primary' className={classes.Button} onClick={() => setCreateDialogOpen(true)}>Create</Button>
                            </Grid>
                            : ""
                        }
                    </Grid>
                }
                {(tabValue === 1 && selectedMro !== undefined) &&
                    <MaintenanceReportsDetails isMroAdmin={isMroAdmin} onRowClick={onRowClick} selectedMro={selectedMro} setLoading={setLoading} setSelectedMro={setSelectedMro} />
                }
                <Dialog
                    visible={createDialogOpen}
                    max_width={"md"}
                    title={"Create new maintenance report"}
                    context={
                        <Grid>
                            <Grid>
                                <Input
                                    multiline={false}
                                    label_text="Barcode"
                                    help_text="Please type in barcode"
                                    type="text"
                                    value={barcode}
                                    width={50}
                                    onTextChange={(value: string) => setBarcode(value)}
                                />
                            </Grid>
                            <Grid>
                                <Select
                                    width={100}
                                    widthPercentage
                                    filter_by_text="Location"
                                    onSelectedItem={(e: any) => setLocation(e.target.value)}
                                    options={["Bay 1","Bay 2", "Bay 3", "Bay 4", "Bay 5","Bay 6", "Bay 7", "KEF/LINE", "Airside"]}
                                    selectedItem={location}
                                />
                            </Grid>
                        </Grid>
                    }
                    onClose={(status: any) => onCreateMaintenanceReport(status)}
                    strict={false}
                    show_options={true}
                />
            </Grid>
        </Grid>
    )
}

export default MaintenanceReports;