import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Image from '../../types/image';
import { RootState } from '../../reducer';

const useStyles = makeStyles(theme => ({
  userPhotoSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.2)
  },
  userPhotoMedium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.2)
  },
  userPhotoLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.2)
  }
}));

interface ProfileImgProps {
  userName: string| undefined;
  imgKey?: number | string;
  imgSize?:  'small' | 'medium' | 'large' ;
}

const ProfileImg: React.FC<ProfileImgProps> = ({ userName, imgKey, imgSize}) => {
  const classes = useStyles();
  const store = useSelector((state: RootState) => state);
  const imgSrc = store.image?.all_images.find((s: Image) => s.userName === userName)?.image;
  
  let imgCss = classes.userPhotoSmall;
  imgSize === 'small' ? imgCss = classes.userPhotoSmall : imgSize === 'medium' ? imgCss = classes.userPhotoMedium : imgSize === 'large' ? imgCss = classes.userPhotoLarge : imgCss = classes.userPhotoSmall;
  
  return (
      <img  src={imgSrc !== undefined ? imgSrc : ""} key={imgKey} className={imgCss} />
  );
}

export default React.memo(ProfileImg);