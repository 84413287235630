import { settings } from './../config';
import { ToolData, Tools, LoanedToolsForMe, UpdateTool } from '../types/tool';
import { getToken } from './../global/TokenValidator';

class Tool {
    GetToolByPartNumber = async (partNumber: string): Promise<{ data: ToolData[] }> => {
        return fetch(`${settings.HOST}/Tools/GetToolByPartNumber/${partNumber}`, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    getLastUserOnTool = async (mxi_inv_no_id: Number): Promise<ToolData> => {
        return fetch(`${settings.HOST}/Tools/getLastUserOnTool/${mxi_inv_no_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getAllDefectiveTools = async (order_by_text: string, ascending: boolean): Promise<ToolData[]> => {
        return fetch(`${settings.HOST}/Tools/getAllDefectiveTools/${order_by_text}/${ascending}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getLateToolReturns = async (order_by_text: string, ascending: boolean, employee_id: Number): Promise<ToolData[]> => {
        return fetch(`${settings.HOST}/Tools/getLateToolReturns/${order_by_text}/${ascending}/${employee_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getLateToolReturnsByEmployee = async (order_by_text: string, ascending: boolean, employee_id: Number): Promise<ToolData[]> => {
        return fetch(`${settings.HOST}/Tools/getLateToolReturnsByEmployee/${order_by_text}/${ascending}/${employee_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetToolsForMe = async (): Promise<{ status: boolean, message: string, data: LoanedToolsForMe[] }> => {
        return fetch(`${settings.HOST}/Tools/GetToolsForMe`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetToolsByEmployeeId = async (id: number): Promise<{ status: boolean, message: string, data: LoanedToolsForMe[] }> => {
        return fetch(`${settings.HOST}/Tools/GetToolsByEmployeeId/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getAllToolsFromMxi = async (order_by_text: string, ascending: boolean): Promise<ToolData[]> => {
        return fetch(`${settings.HOST}/Tools/getAllToolsFromMxi/${order_by_text}/${ascending}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getSingleToolsHistory = async (mxi_inv_no_id: Number, employee_id: Number, order_by_text: string, ascending: boolean): Promise<ToolData[]> => {
        return fetch(`${settings.HOST}/Tools/getSingleToolsHistory/${mxi_inv_no_id}/${employee_id}/${order_by_text}/${ascending}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getAllLoanedToolsbyDays = async (days: number, tool_position: string, employee_user_id: number, order_by_text: string, ascending: boolean, tooLong: boolean): Promise<ToolData[]> => {
        return fetch(`${settings.HOST}/Tools/getAllLoanedToolsbyDays/${days}/${tool_position}/${employee_user_id}/${order_by_text}/${ascending}/${tooLong}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,

            },
        }).then(x => {
            return x.json()
        })
    }
    loanToolsOut = async (tools: ToolData[], employee_user_id: number | null, tail: string | null, extending_tools: boolean, stationUserName: string): Promise<Tools> => {
        return fetch(`${settings.HOST}/Tools/loanToolsOut/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "registered_tools": tools,
                "employee_user_id": employee_user_id,
                "tail_number": tail,
                "extending_tools": extending_tools,
                "station_user_name": stationUserName
            })
        }).then(x => {
            return x.json()
        })
    }
    loanToolIn = async (mxi_inv_no_id: number, stationUserName: string): Promise<Tools> => {
        return fetch(`${settings.HOST}/Tools/loanToolIn/${mxi_inv_no_id}/${stationUserName}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    UpdateUserCardCodes = async (employeeId: number, userCardCode: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Tools/UpdateUserCardCodes`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "employee_id": employeeId,
                "user_card_code": userCardCode
            })
        }).then(x => {
            return x.json()
        })
    }
    editDaysAllowed = async (tool: ToolData): Promise<Tools> => {
        return fetch(`${settings.HOST}/Tools/editDaysAllowed`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(tool)
        }).then(x => {
            return x.json()
        })
    }
    CreateDefectiveTool = async (tool: ToolData): Promise<Tools> => {
        return fetch(`${settings.HOST}/Tools/CreateDefectiveTool/${tool.mxi_inv_no_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(tool)
        }).then(x => {
            return x.json()
        })
    }
    UpdateToolImage = async (partNumber: string, imageString: string): Promise<Tools> => {
        return fetch(`${settings.HOST}/Tools/UpdateToolImage`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "part_number": partNumber,
                "tool_image": imageString
            })
        }).then(x => {
            return x.json()
        })
    }
    UpdateToolsLocation = async (): Promise<{ status: boolean, message: string, addedTools: UpdateTool[], updatedTools: UpdateTool[] }> => {
        return fetch(`${settings.HOST}/Tools/UpdateToolsLocation`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
}
const ToolApi = new Tool();
export default ToolApi;