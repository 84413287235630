import { FunctionComponent, useEffect, useState } from 'react';
import { myPayment } from '../../types/extraPayments';
import PaymentTable from './PaymentTable';
import { Grid } from '@mui/material';
import { SelectMultiple, Typography } from '../../MaterialUiComponents';
import ExtraPaymentsAPI from '../../api/extraPayments';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { EmployeeSearch } from '../../components';
import { extraPaymentStatusOptions, extraPaymentSupervisorStatusOptions } from '../../properties/extraPaymentsProperties';

type MyExtraPaymentsProps = {
    viewType: "user" | "timeControl" | "supervisor" | "all",
    isSupervisor?: boolean,
    isTimeControl?: boolean,
    setLoading: Function
}

const MyExtraPayments: FunctionComponent<MyExtraPaymentsProps> = ({ viewType, isSupervisor = false, isTimeControl = false, setLoading }) => {

    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const [myPayments, setMyPayments] = useState<myPayment[]>([]); //Too keep track of all payments from the request
    const [filterPayments, setFilterPayments] = useState<myPayment[]>([]); //Keep track of filtered payments from the request
    const statusOptions = Object.values(extraPaymentStatusOptions);
    const supervisorStatusOptions = Object.values(extraPaymentSupervisorStatusOptions);
    const store = useSelector((state: RootState) => state);
    const allUsers = store.employees.all_employees;
    const [selectedMechanic, setSelectedMechanic] = useState<number>();
    const [selectedSupervisor, setSelectedSupervisor] = useState<number>();
    const [selectedSupervisorStatus, setSelectedSupervisorStatus] = useState<string[]>([]);

    
    // Setting default status values based on page. Approval page only displays pending requests (either supervisor or approver based on user)
    // View type all, selects all statuses by default. This is done on initial render of component only.
    useEffect(() => {
        if (viewType === "timeControl") {
            setSelectedStatus([extraPaymentStatusOptions.waitingForTimeControl]);
        }
        else if (viewType === "all") {
            setSelectedStatus([extraPaymentStatusOptions.waitingForSupervisor]);
        }
        else if (viewType === "supervisor") {
            setSelectedSupervisorStatus([extraPaymentSupervisorStatusOptions.waitingForSupervisor]);
        }
        else {
            setSelectedStatus(["Waiting for supervisor"]);
        }
    }, []);

    // This is updated when selected status array changes and calls refresh function to make sure data is always updated
    useEffect(() => {
        refreshPayments();
        // eslint-disable-next-line
    }, [selectedStatus, selectedSupervisorStatus]);

    //When Mechanic or Supervisor is selected in the EmployeeSearch, filter and update the table
    useEffect(() => {
        let tempFilteredPayments = myPayments;

        tempFilteredPayments.filter(payment => selectedStatus.includes(payment.status));

        //First filter by the Supervisor
        if (selectedSupervisor! > 0) {
            const selectedEmployee = allUsers.filter(s => s.user_id === selectedSupervisor);
            tempFilteredPayments = tempFilteredPayments.filter(s => s.supervisor_name === selectedEmployee[0].name);
        }
        //First filter by the Mechanic
        if (selectedMechanic! > 0) {
            const selectedEmployee = allUsers.filter(s => s.user_id === selectedMechanic);
            tempFilteredPayments = tempFilteredPayments.filter(s => s.employee_name === selectedEmployee[0].name);
        }
        setFilterPayments(tempFilteredPayments);
    }, [selectedMechanic, selectedSupervisor, allUsers, myPayments]);

    // Refresh the payments based on what statuses are selected
    const refreshPayments = () => {
        if (selectedStatus.length > 0) {
            setLoading(true);
            if (viewType === "user") {
                ExtraPaymentsAPI.getAllMyExtraPaymentsForMechanic(selectedStatus).then(res => {
                    if (res.status === true) {
                        setMyPayments(res.data);
                        setFilterPayments(res.data);
                    }
                    else {
                        setMyPayments([]);
                        setFilterPayments([]);
                    }
                    setLoading(false);
                })
            }
            if (viewType === "timeControl" || (viewType === "all" && isTimeControl === true)) {
                ExtraPaymentsAPI.GetAllExtraPaymentsForTimeControl(selectedStatus).then(res => {
                    if (res.status === true) {
                        setMyPayments(res.data);
                        setFilterPayments(res.data);
                    }
                    else {
                        setMyPayments([]);
                        setFilterPayments([]);
                    }
                    setLoading(false);
                })
            }
        }
        if (selectedSupervisorStatus.length > 0) {
            if (viewType === "supervisor" || (viewType === "all" && isSupervisor === true)) {
                ExtraPaymentsAPI.getAllMyExtraPaymentsForSupervisor(selectedSupervisorStatus).then(res => {
                    if(res.data)
                    {
                        if (res.status === true) {
                            setMyPayments(res.data);
                            setFilterPayments(res.data);
                        }
                        else {
                            setMyPayments([]);
                            setFilterPayments([]);
                        }
                    }
                    setLoading(false);
                })
            }
        }
    }

    //Set the employeeId from mechanic or supervisor searches to the employeeId that is being filtered
    const onSelectedEmployeeRow = (employeeId: number, type: string) => {
        if (type === "Supervisor") {
            setSelectedSupervisor(employeeId);
        }
        if (type === "Mechanic") {
            setSelectedMechanic(employeeId);
        }
    }

    //Clearing X in mechanic search resets the filter payments to the payments from the request
    const customOnCloseMechanicSearch = () => {
        setSelectedMechanic(0);
        setFilterPayments(myPayments);
    }

    //Clearing X in supervisor search resets the filter payments to the payments from the request
    const customOnCloseSupervisorSearch = () => {
        setSelectedSupervisor(0);
        setFilterPayments(myPayments);
    }

    return (
        <Grid container>
            <Grid container item justifyContent="center">
                <Typography variant={'h1'} align={'center'} margin={[0, 0, 2, 0]} text={viewType === "all" ? "All extra payments" : viewType === "user" ? "My extra payments" : "Payment approvals"} />
            </Grid>
            {(viewType === "user" || viewType === "all") &&
                <Grid container>
                    <Grid item lg={3} xs={8}>
                        <Grid item marginBottom={0}>
                            <SelectMultiple
                                options={statusOptions}
                                required
                                onSelectedItem={(e: any) => setSelectedStatus(e.target.value)}
                                selectedItemsString={selectedStatus}
                                width={100}
                                widthPercentage
                                filter_by_text={"Choose Request Status"}
                            />
                        </Grid>
                        <Grid xs={12} marginBottom={4}>
                            <Typography variant={'body1'} align={'left'} color="primary" text={selectedStatus.toString().split(',').join(" || ")} />
                        </Grid>
                    </Grid>
                    <Grid item lg={1}></Grid>
                    <Grid item lg={3} xs={8}>
                        <Grid item >
                            <EmployeeSearch place_holder={"Write mechanic name"} onSelectedEemployee={(employeeId: number) => onSelectedEmployeeRow(employeeId, "Mechanic")} customCloseFunction={customOnCloseMechanicSearch} />
                        </Grid>
                    </Grid>
                    <Grid item lg={1}></Grid>
                    <Grid item lg={3} xs={8}>
                        <Grid item >
                            <EmployeeSearch place_holder={"Write supervisor name"} onSelectedEemployee={(employeeId: number) => onSelectedEmployeeRow(employeeId, "Supervisor")} customCloseFunction={customOnCloseSupervisorSearch} />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {(viewType === "supervisor") &&
            <Grid container>
                <Grid item lg={3} xs={8} marginBottom={0}>
                <SelectMultiple
                                options={supervisorStatusOptions}
                                required
                                onSelectedItem={(e: any) => setSelectedSupervisorStatus(e.target.value)}
                                selectedItemsString={selectedSupervisorStatus}
                                width={100}
                                widthPercentage
                                filter_by_text={"Choose Request Status"}
                            />
                </Grid>
                <Grid xs={12} marginBottom={4}>
                            <Typography variant={'body1'} align={'left'} color="primary" text={selectedSupervisorStatus.toString().split(',').join(" || ")} />
                        </Grid>
            </Grid>
            }
            <PaymentTable payments={filterPayments} refresh={() => refreshPayments()} viewType={viewType} />
        </Grid>
    );
};
export default MyExtraPayments;