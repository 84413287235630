import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';
import { DatePicker, Input, Tabs, Typography, LinearProgress, SelectMultiple } from '../../MaterialUiComponents';
import Overview from './Overview';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import Warranty from './Warranty';
import EditCageCodes from './EditCageCodes';
import { dictionary, warrantyRequest } from '../../types/warranty';
import WarrantyApi from '../../api/warranty';
import EditHourlyRate from './EditHourlyRate';
import taskApi from './../../api/tasks';
import { useLocation } from 'react-router';
import { SelectObject } from '../../types/certificates';
import { createOptionsFromArray, toggleSelectAll } from '../../utils/selectMultiple';
import { claimClassificationOptions, claimStatusOptions } from '../../properties/warrantiesProperties';
 
const useStyles = makeStyles(theme => ({
    resultsAmount: {
        marginTop: theme.spacing(1),
        color: 'gray'
    },
    resetButton: {
        height: '30px',
        marginTop: theme.spacing(2)
    },
}));
 
// Main Warranty page. Includes the search bar on top, tab control and displays sub-components based on tabValue
function Warranties() {
    const store = useSelector((state: RootState) => state);
    const isWarrantyAdmin = store.user.me.user_clearence_groups.includes("Warranty Edit");
    const [tabValue, setTabValue] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [faultBarcode, setFaultBarcode] = useState<string>("");
    const [urlBarcode, setUrlBarcode] = useState<string>("");
    const [cageCodes, setCageCodes] = useState<dictionary[]>([]);
 
    const [warrantiesResults, setWarrantiesResults] = useState<warrantyRequest[]>([]);
    const [selectedWarrantyId, setSelectedWarrantyId] = useState<number | null>(null);
 
    const [selectedstatus, setSelectedstatus] = useState<string[]>([]);
 
    const [selectedClassification, setSelectedClassification] = useState<string[]>([]);
 
    //For Tails select and toggle
    const [tailOptions, setTailOptions] = useState<SelectObject[]>([]);
    const [selectedTails, setSelectedTails] = useState<number[]>([]);
    const [toggleSelectTails, setToggleSelectTails] = useState(false);
 
    //For cage codes select and toggle
    const [cageCodeOptions, setCageCodesOptions] = useState<SelectObject[]>([]);
    const [selectedCageCodes, setSelectedCageCodes] = useState<number[]>([]);
    const [toggleSelectedCageCodes, setToggleSelectedCageCodes] = useState(false);
 
    const [partNumber, setPartNumber] = useState<string>("");
    const location = useLocation();
 
    // Fetches cage codes on render if cageCodes array is empty
    useEffect(() => {
        refresh();
    }, [startDate, endDate, selectedstatus, faultBarcode, selectedTails, partNumber, selectedClassification, selectedCageCodes]);
 
    useEffect(() => {
        taskApi.GetAllTailNumbers().then(res => {
            const newTailOptions = createOptionsFromArray(res.data);
            setTailOptions(newTailOptions);
        });
        getBarcodeFromUrl();
    }, [])
 
    const refresh = () => {
        setLoading(true);
        WarrantyApi.SearchForAllWarrantyRequests(
            startDate,
            endDate,
            selectedstatus,
            faultBarcode,
            selectedTails.map(index => tailOptions[index]?.name).filter(Boolean),
            partNumber,
            selectedClassification,
            selectedCageCodes.map(index => cageCodeOptions[index]?.name).filter(Boolean)
        ).then(res => {
            if (res.status === true) {
                setWarrantiesResults(res.data);
                setLoading(false);
            }
        });
        WarrantyApi.getAllCageCodes().then(res => {
            const newCageCodeOptions = createOptionsFromArray(res.data.map(obj => obj.name));
            setCageCodesOptions(newCageCodeOptions);
            setCageCodes(res.data.filter(cageCode => cageCode.visible === true));
        });
    }
    const changeTab = (tabValue: number) => {
        if (tabValue !== 3) {
            setSelectedWarrantyId(null);
        }
        if (tabValue === 0) {
            refresh();
        }
        setTabValue(tabValue)
    }
    const onResetFields = () => {
        setStartDate(null);
        setEndDate(new Date());
        setSelectedstatus([]);
        setFaultBarcode('');
        setSelectedTails([]);
        setSelectedClassification([]);
        setSelectedCageCodes([]);
    }
    const openWarranty = (warrantyId: number) => {
        setSelectedWarrantyId(warrantyId);;
        setTabValue(3);
    }
    const returnToOverview = () => {
        refresh();
        changeTab(0);
    }
    const getBarcodeFromUrl = () => {
        let barcode;
        let loc;
        let url = location.search;
        if (url.includes("=")) {
            loc = url.split("=");
            barcode = loc[loc.length - 1];
            setUrlBarcode(barcode);
            setTabValue(3);
        }
    }
 
    const classes = useStyles();
    const tabNames = isWarrantyAdmin ? ["Overview", "Warranty Defender","Warranty Stats & Estimates", "Create new Warranty Request", "Edit Cage Codes", "Edit Hourly Rate"] : ["Overview"];
    return (
        <Grid item xs={12}>
            {loading === true && <LinearProgress />}
            <Tabs
                tabNames={tabNames}
                onChangeTabValue={(tabValue: number) => changeTab(tabValue)}
                tabValue={tabValue}
            />
            {tabValue === 0 && warrantiesResults !== undefined &&
                <Grid item xs={12}>
                    <Grid container >
                        <Grid container item xs={12} sm={6} md={4} lg={3}>
                            <Input
                                multiline={false}
                                label_text="Fault barcode"
                                help_text="Please type/paste in barcode for a fault"
                                type="text"
                                value={faultBarcode}
                                width={90}
                                onTextChange={(value: string) => setFaultBarcode(value)}
                            />
                        </Grid>
                        <Grid container item xs={12} sm={2} md={2} lg={1.5}>
                            <Input multiline={false} help_text={""} label_text={"Part number"} value={partNumber} onTextChange={(text: string) => { setPartNumber(text) }} type="text" width={80} />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={1.5}>
                            <SelectMultiple customSelectedText=" tail's select" optionsObject={tailOptions} selectedItems={selectedTails} width={200} onSelectedItem={(e: any) => { setSelectedTails(e.target.value); toggleSelectAll(e.target.value, tailOptions, setSelectedTails, toggleSelectTails, setToggleSelectTails); }} filter_by_text="Tails" />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={1.5}>
                            <SelectMultiple customSelectedText=" cage codes selected" optionsObject={cageCodeOptions} selectedItems={selectedCageCodes} width={200} onSelectedItem={(e: any) => { setSelectedCageCodes(e.target.value); toggleSelectAll(e.target.value, cageCodeOptions, setSelectedCageCodes, toggleSelectedCageCodes, setToggleSelectedCageCodes); }} filter_by_text="Cage codes" />
                        </Grid>
                        <Grid container item xs={12} sm={2} md={2} lg={1.5}>
                            <DatePicker type="Date" selected={startDate} label="Created date from" onDateChange={(date: Date) => setStartDate(date)} />
                        </Grid>
                        <Grid container item xs={12} sm={2} md={2} lg={1.5}>
                            <DatePicker type="Date" selected={endDate} label="Created date to" onDateChange={(date: Date) => setEndDate(date)} />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={1.5}>
                            <SelectMultiple
                                customSelectedText=" status's select"
                                options={Object.values(claimStatusOptions)}
                                selectedItemsString={selectedstatus}
                                width={200}
                                onSelectedItem={(e: any) => { setSelectedstatus(e.target.value); }}
                                filter_by_text="Status"
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={1.5}>
                            <SelectMultiple
                                customSelectedText=" classes selected"
                                options={Object.values(claimClassificationOptions)}
                                selectedItemsString={selectedClassification}
                                width={200}
                                onSelectedItem={(e: any) => { setSelectedClassification(e.target.value); }}
                                filter_by_text="Classifications"
                            />
                        </Grid>
                        <Grid container item xs={12} sm={2} md={2} lg={1.5} >
                            <Button variant="text" className={classes.resetButton} color="primary" size="small" onClick={() => onResetFields()}>Reset filters</Button>
                        </Grid>
                        <Grid container item xs={12} sm={2} md={2} lg={1.5} >
                            <Button variant="text" className={classes.resetButton} color="primary" size="small" onClick={() => onResetFields()}>Detailed View</Button>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={1.5} className={classes.resultsAmount}>
                            <Typography text={warrantiesResults !== undefined ? warrantiesResults.length + " reports found" : '0 reports found'} />
                        </Grid>
                    </Grid>
                    <Overview selectWarranty={(warrantyId: number) => { openWarranty(warrantyId) }} allWarranties={warrantiesResults} setWarrantiesResults={setWarrantiesResults} isWarrantyAdmin={isWarrantyAdmin} />
                </Grid>
            }
            {tabValue === 1 && <Grid>
                <iframe id="warranty-defender" title="Warranty Defender" width="100%" height="1200px" src={process.env.REACT_APP_WARRANTY_DEFENDER_BI} allowFullScreen={true}></iframe> 
            </Grid>}
            {tabValue === 2 && <Grid>
                <iframe id="warranty-stats-and-estimates" title="Warranty Stats & Estimates" width="100%" height="1200px" src={process.env.REACT_APP_WARRANTY_STATS_AND_ESTIMATES_BI} allowFullScreen={true}></iframe>
            </Grid>}
            {(tabValue === 3) &&
                <Grid item xs={12}>
                    <Warranty refreshOverview={refresh} setTabValue={setTabValue} urlBarcode={urlBarcode} loading={loading} setLoading={(loadStatus: boolean) => setLoading(loadStatus)} returnToOverview={() => returnToOverview()} cageCodes={cageCodes} warrantyId={selectedWarrantyId} />
                </Grid>
            }
            {tabValue === 4 &&
                <Grid item xs={12}>
                    <EditCageCodes refreshCageCodes={() => refresh()} />
                </Grid>
            }
            {tabValue === 5 &&
                <Grid item xs={12}>
                    <EditHourlyRate refreshHourlyRates={() => refresh()} />
                </Grid>
            }
        </Grid>
    );
}
export default Warranties;