import { FunctionComponent } from 'react';
import { makeStyles } from "@mui/styles";
import { Grid, Paper, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Typography } from '../../MaterialUiComponents';

type DictionaryProps = {
  object: any,
  name: string,
  id: number,
  deleteProperty: Function,
  descriptionStrings?: string[],
  description?: string,
  other?: string,
  deleted: boolean,
  deleteButton?: boolean
}

const Dictionary: FunctionComponent<DictionaryProps> = ({ object, name, id, deleteProperty, descriptionStrings = [], description = '', deleted = false, other = '', deleteButton = true }) => {
  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: '2%',
      marginBottom: '2%'
    },
    deletedPaper: {
      marginTop: '2%',
      marginBottom: '2%',
      backgroundColor: theme.palette.secondary.main
    },
    deleteButton: {
      position: "relative",
      left: theme.spacing(2)
    }
  }));

  const classes = useStyles();
  return (
    <Grid xs={10} item>
      <Paper key={id} elevation={10} variant="elevation" className={deleted ? classes.deletedPaper : classes.paper}>
        <Grid container alignContent="center" alignItems="center" xs={12}>
          <Grid item xs={10}>
            <Typography align='center' variant='h2' text={name} margin={[1, 0, 0, 0]} />
            {description !== '' && <Typography align='center' variant='body1' text={description} margin={[0, 0, 0, 0]} />}
            {other !== '' && <Typography align='center' variant='body1' text={other} margin={[0, 0, 0, 0]} />}
            {descriptionStrings.map(string => (
              <Typography align='center' variant='body1' text={string} margin={[0, 0, 0, 0]} />
            ))}
          </Grid>
          {deleted === false && deleteButton === true &&
            <Grid>
              <Grid item xs={2}>
                <IconButton className={classes.deleteButton} onClick={() => deleteProperty(object)} >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          }
        </Grid>
      </Paper>
    </Grid>
  );
};
export default Dictionary