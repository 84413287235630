import { FunctionComponent } from "react";
import { Grid, Button } from '@mui/material';
import { Dialog, Input } from '../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';

type CreateGroupProps = {
    isOpen: boolean,
    setIsOpen: Function,
    groupName: string,
    setGroupName: Function,
    groupPermission: string,
    setPermission: Function,
    onCreateGroup: Function,
}

const useStyles = makeStyles(theme => ({
    Button: {
        marginBottom: theme.spacing(4),
    }
}));

const CreateGroup: FunctionComponent<CreateGroupProps> = ({ isOpen, setIsOpen, groupName, setGroupName, groupPermission, setPermission, onCreateGroup }) => {
    const classes = useStyles();
    return (
        <Dialog
            visible={isOpen}
            max_width={"md"}
            title={"Create group"}
            context={
                <Grid container direction="column">
                    <Grid item>
                        <Grid container direction="row">
                            <Grid item style={{ marginRight: '120px' }} >
                                <Input
                                    multiline={false}
                                    label_text="Group name"
                                    help_text="Please type in a name for the new group"
                                    type="text"
                                    value={groupName}
                                    width={150}
                                    onTextChange={(value: string) => setGroupName(value)}
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    multiline={false}
                                    label_text="Group permission"
                                    help_text="Please type in permisson for the new group"
                                    type="text"
                                    value={groupPermission}
                                    width={200}
                                    onTextChange={(value: string) => setPermission(value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: '18px' }}>
                        <Button variant="contained" color='primary' className={classes.Button} onClick={() => onCreateGroup()}>Submit</Button>
                    </Grid>
                </Grid>
            }
            onClose={(status: any) => setIsOpen(false)}
            strict={false}
            show_options={false}
        />
    )
}
export default CreateGroup