import { settings } from '../config';
import { WorkpackageStatistic, MaintenerUsage, PackageInformationObj } from '../types/overview';
import { LongtermObj } from '../types/longterm';
import { getToken } from './../global/TokenValidator';

class Overview {
  GetWorkpackageStatistic = async (WoNumber: string): Promise<{ status: Boolean, data: WorkpackageStatistic }> => {
    return fetch(`${settings.HOST}/PackageOverview/GetWorkpackageStatistic/${WoNumber}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'apikey': settings.KONG_KEY,
        'token': await getToken(),
      },
    }).then(x => {
      return x.json()
    })
  }
  GetPackageInformationByTailNumber = async (tail: string, status: string[], isOnlyHangarWork: Boolean): Promise<{ status: Boolean, data: PackageInformationObj[] }> => {
    return fetch(`${settings.HOST}/ScheduleTasks/GetPackageInformationByTailNumber`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apikey': settings.KONG_KEY,
        'token': await getToken(),
      },
      body: JSON.stringify({
        "tail_number": tail,
        "wo_status": status,
        "is_only_hangar_work": isOnlyHangarWork
      })
    }).then(x => {
      return x.json()
    })
  }
}
const OverviewApi = new Overview();
export default OverviewApi;