import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, TableCell, TableRow } from '@mui/material';
import { WorldTrip } from '../../types/worldTools';
import { Dialog, Input, Table, Typography } from '../../MaterialUiComponents';
import { Close, Search } from '@mui/icons-material';
import worldTools from '../../api/worldTools';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    }
}));

type OverviewProps = {
    allWorldTrips: WorldTrip[],
    openTrip: Function,
    userId: number,
    refresh: Function
}

// Overview Page. Contains the table displaying all world trips, and functions as props to open a trip or refresh list after a trip is deleted.
const Overview: FunctionComponent<OverviewProps> = ({ allWorldTrips, openTrip, userId, refresh }) => {
    const store = useSelector((state: RootState) => state);
    const isWorldToolAdmin = store.user.me.user_clearence_groups.includes("World Tools Edit");
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [errorStatus, setErrorStatus] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<WorldTrip>();
    const [nameCheck, setNameCheck] = useState<string>("");
    const [helpText, setHelpText] = useState<string>("Write name of the World trip you wish to delete");

    const generateTableBody = (row: WorldTrip, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''}>
                <TableCell>{row.trip_name}</TableCell>
                <TableCell>{row.destination}</TableCell>
                <TableCell>{row.employee_count}</TableCell>
                <TableCell>{row.tool_count}</TableCell>
                <TableCell>{row.tail_number}</TableCell>
                <TableCell>{row.start_date_text}</TableCell>
                <TableCell>{row.end_date_text}</TableCell>
                <TableCell>{row.progress}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>
                    <IconButton onClick={() => openTrip(index)}><Search color="primary" /></IconButton>
                    {isWorldToolAdmin && <IconButton onClick={() => onDeleteConfirmation(row)}><Close color="error" /></IconButton>}
                </TableCell>
            </TableRow>
        )
    }
    const onDeleteConfirmation = (trip: WorldTrip) => {
        setSelectedRow(trip);
        setConfirmationDialogOpen(true);
    }
    // Logic for delete confirmation dialog.
    const onDialogExit = (result: boolean) => {
        if (result === true) {
            if (nameCheck === selectedRow?.trip_name) {
                setErrorStatus(false);
                setHelpText("Write name of the World trip you wish to delete");
                onDeleteTrip();
                setConfirmationDialogOpen(false);
            }
            else {
                setErrorStatus(true);
                setHelpText("Name does not match with item to be deleted.");
            }
        }
        if (result === false) {
            setErrorStatus(false);
            setHelpText("Write name of the World trip you wish to delete");
            setConfirmationDialogOpen(false);
        }
    }

    const onDeleteTrip = () => {
        if (selectedRow !== undefined) {
            worldTools.RemoveWorldTrip(selectedRow.id, userId).then(res => {
                refresh();
            })
        }
    }

    const classes = useStyles();
    return (
        <Grid>
            <Table
                headers={["Name", "Destination", "Employees", "Tools", "Tail", "Start Date", "End Date", "Progress", "Status"]}
                rows={allWorldTrips}
                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                pagination={true}
                skipIndexCol={true}
                defaultRowsPerPage={25}
            />
            <Dialog
                visible={confirmationDialogOpen}
                show_options={true}
                max_width="md"
                onClose={(status: boolean, buttonClicked: boolean) => { onDialogExit(status) }}
                strict={false}
                title="Better safe then sorry!"
                context={
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12}><Typography text={`Are you sure you want to delete this World Trip: ${selectedRow?.trip_name}`} align="center" variant="body1" /></Grid>
                        <Grid item xs={12}><Typography text={`Please confirm by writing in the name of the World Trip below:`} align="center" variant="body1" /></Grid>
                        <Grid item xs={2} />
                        <Grid item xs={8} >
                            <Input
                                label_text="World Trip Name"
                                help_text={helpText}
                                type="text"
                                width={100}
                                multiline={false}
                                error={errorStatus}
                                value={nameCheck}
                                onTextChange={(e: string) => { setNameCheck(e) }}
                            />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                }
            />
        </Grid>
    );
};
export default Overview;
