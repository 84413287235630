import { useState, useEffect, FunctionComponent, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { Grid, Button } from '@mui/material';
import { Dialog, Select, Input, Typography } from './../../MaterialUiComponents';
import clothingApi from './../../api/clothingRequests';
import { makeStyles } from '@mui/styles';
import { clothingType } from '../../types/clothRequests';
import RingLoader from "react-spinners/RingLoader";

const useStyles = makeStyles(theme => ({
  selectContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row"
  },
  confirmButton: {
    marginLeft: "48%"
  },
  reasonContainer: {
    marginLeft: "36%"
  }
}));

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red'
};

type ClothingProps = {
  setLoading: Function
}

const AddNewClothingRequests: FunctionComponent<ClothingProps> = ({ setLoading }) => {
  const store = useSelector((state: RootState) => state);
  const [all_clothing_types, setAllClothingTypes] = useState(Array<clothingType>());
  const [selected_clothing_type, setSelectedClothingType] = useState(Array<clothingType>());
  const [confirm_dialog_open, setConfirmDialogOpen] = useState(false);
  const [given_user_id_number, setGivenUserIdNUmber] = useState("");
  const [reason, setReason] = useState("");
  const [refresh, setRefresh] = (useState(false));
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    clothingApi.GetAllClothingDictionariesTypes().then(res => {
      setAllClothingTypes(res.types);
      setLoading(false);
    })
  }, []);

  const onSelectedClothingChange = (amount: number, type: string, id: number) => {
    const temp_selected_object = { "type": type, "amount": amount, "id": id };
    selected_clothing_type.push(temp_selected_object);
    setAllClothingTypes(all_clothing_types);
    setSelectedClothingType(selected_clothing_type);
    setRefresh(!refresh);
  }

  const returnCorrectDataToSelect = (type: string) => {
    const filtered = selected_clothing_type.find(s => s.type === type);
    if (filtered === undefined) {
      return "0";
    } else {
      return filtered.amount.toString();
    }
  }

  const closeConfirmDialogOpen = (status: boolean) => {
    setConfirmDialogOpen(false);

  }
  const onUserIdNumberChange = (e: any) => {
    const value = e.target.value;
    setGivenUserIdNUmber(value);
    if (value.length === 3 || value.length === 4 || value.length === 5 || value.length === 6) {
      const user = store.employees.all_employees.find(s => s.user_card_code === value);
      if (user !== undefined) {
        const cloths = {
          "reason": reason,
          "employee_id": user.user_id,
          "clothing_types": selected_clothing_type,
        }
        setLoading(true);
        clothingApi.AddNewClothingRequests(cloths).then(res => {
          setGivenUserIdNUmber("");
          setConfirmDialogOpen(false);
          setReason("");
          setSelectedClothingType([]);
          setLoading(false);
        })
      }
    }
    if (value.length > 6) {
      setGivenUserIdNUmber("");
    }
  }
  const onConfirnDialogOpen = () => {
    if (selected_clothing_type.length > 0) {
      setConfirmDialogOpen(true);
      setInterval(function () {
        const returnInput = document.getElementById('user_id_number');
        returnInput?.focus();
      }, 1000);
    }
  }


  return (
    <Grid>
      <Typography color='primary' variant='h2' text={"Add new Clothing request"} align='center' />
      <br />
      <br />
      <Grid>
        {
          all_clothing_types.map(res => (
            <Grid item xs={12} className={classes.selectContainer}>
              <Select
                onSelectedItem={(e: any) => onSelectedClothingChange(e.target.value, res.type, res.id)}
                selectedItem={returnCorrectDataToSelect(res.type)}
                width={200}
                filter_by_text={res.type}
                options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]} />
              <br />
              <br />
              <br />
            </Grid>
          ))
        }
      </Grid>
      <br />
      <Grid className={classes.reasonContainer}><Input multiline={true} help_text={"Reason"} label_text={"Is there a reason for this "} value={reason} onTextChange={(text: string) => setReason(text)} type="text" width={50} /></Grid>
      <Button autoFocus color="primary" className={classes.confirmButton} onClick={() => onConfirnDialogOpen()}>Confirm</Button>
      <Dialog
        visible={confirm_dialog_open}
        max_width={"sm"} title={"Please make sure employee scans his cards"}
        context={
          <Grid>
            <RingLoader color={"#36D7B7"} loading={true} cssOverride={override} />
            <input id="user_id_number" placeholder={"Write user card code"} value={given_user_id_number} onChange={onUserIdNumberChange} ></input>
          </Grid>
        }
        onClose={(status: any) => closeConfirmDialogOpen(status)}
        strict={false}
        show_options={false}
      />
    </Grid>
  );
};
export default AddNewClothingRequests;