import { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { CertificateData } from '../../types/certificates';
import Certificate from '../Certificates/Certificate';
import { Typography } from '../../MaterialUiComponents';
import { NotInterested } from '@mui/icons-material';

type CertificateProps = {
    validCert: CertificateData | undefined
}

const MyCertificates: FunctionComponent<CertificateProps> = ({ validCert }) => {
    return (
        <Grid xs={12} item>
            {validCert !== undefined ?
                <Certificate
                    setLoading={() => { }}
                    selectedCertificate={validCert}
                    closeCertificate={() => { }}
                    viewType='view'
                    userType='normal'
                    shouldBeAbleToClose={false}
                />
                :
                <Grid>
                    <Grid container justifyContent={'center'}>
                        <NotInterested color='error' style={{ width: 60, height: 60 }} />
                    </Grid>
                    <Typography align='center' variant='h3' margin={[2, 0, 0, 0]} text={"No Certificate"} />
                </Grid>
            }
        </Grid>
    )
}

export default MyCertificates;