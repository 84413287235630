import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import { Grid } from '@mui/material';
import { LinearProgress, Tabs } from '../../MaterialUiComponents';
import taskApi from './../../api/tasks';
import lineApi from './../../api/line';
import LineWorkPackageOverview from '../../components/Line/LineWorkPackageOverview';
import { lineOrLiteEnum } from '../../components/Line/LineWorkPackageOverview';
import { FlightSchedule, LineWorkPackages } from '../../types/line';
import FlightScheduleView from './FlightSchedule';
import LiteHandover from './LiteHandover';

function Lite() {
    const [allTails, setAllTails] = useState(Array<string>());
    const [tabValue, setTabValue] = useState(0);
    const [FlightScheduleObj, setFlightSchedule] = useState(Array<FlightSchedule>());
    const [data_fetched_when, set_data_fetched_when] = useState("");
    const [loading, setLoading] = useState(false);
    const [orderByText, setOrderByText] = useState("Arrival");
    const [ascending, setAscending] = useState(false);
    const store = useSelector((state: RootState) => state);
    const isLineSupervisorAdmin = store.user.me.user_clearence_groups.includes("Line Supervisor Edit");

    useEffect(() => {
        taskApi.GetAllTailNumbers().then(res => {
            setAllTails(res.data);
        });
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        lineApi.getFlightSchedule().then(res => {
            setLoading(false);
            sortByColumn(orderByText, res.data, !ascending);
            set_data_fetched_when(res.data_fetched_when);
        });
    }
    const sortByColumn = (columnName: string, flights: LineWorkPackages[], tempAscending: boolean) => {
        if (columnName === orderByText) {
            setAscending(!tempAscending);
        }
        else {
            setAscending(true);
            setOrderByText(columnName);
        }
        switch (columnName) {
            case "Tail":
                tempAscending ?
                    flights.sort(function (a, b) { return b.tail_number > a.tail_number ? 1 : -1 })
                    :
                    flights.sort(function (a, b) { return a.tail_number > b.tail_number ? 1 : -1 })
                break;
            case "Curr Airport":
                ascending ?
                    flights.sort(function (a, b) { return b.current_location > a.current_location ? 1 : -1 })
                    :
                    flights.sort(function (a, b) { return a.current_location > b.current_location ? 1 : -1 })
                break;
            case "Stand":
                tempAscending ?
                    flights.sort(function (a, b) { return b.aircraft_stand_location > a.aircraft_stand_location ? 1 : -1 })
                    :
                    flights.sort(function (a, b) { return a.aircraft_stand_location > b.aircraft_stand_location ? 1 : -1 })
                break;
            case "Arr Fl, nr.":
                tempAscending ?
                    flights.sort(function (a, b) { return b.inbound_departure_airport_code > a.inbound_departure_airport_code ? 1 : -1 })
                    :
                    flights.sort(function (a, b) { return a.inbound_departure_airport_code > b.inbound_departure_airport_code ? 1 : -1 })
                break;
            case "Arrival":
                tempAscending ?
                    flights.sort(function (a, b) { return new Date(a.arrival_date).getTime() - new Date(b.arrival_date).getTime() })
                    :
                    flights.sort(function (b, a) { return new Date(a.arrival_date).getTime() - new Date(b.arrival_date).getTime() })
                break;
            case "Departure":
                tempAscending ?
                    flights.sort(function (a, b) { return new Date(a.departure_date).getTime() - new Date(b.departure_date).getTime() })
                    :
                    flights.sort(function (b, a) { return new Date(a.departure_date).getTime() - new Date(b.departure_date).getTime() })
                break;
            case "Dep Fl. nr.":
                tempAscending ?
                    flights.sort(function (a, b) { return b.outbound_arrival_airport_code > a.outbound_arrival_airport_code ? 1 : -1 })
                    :
                    flights.sort(function (b, a) { return a.outbound_arrival_airport_code > b.outbound_arrival_airport_code ? 1 : -1 })
                break;
            case "Mechanics":
                tempAscending ?
                    flights.sort(function (a, b) { return b.assigned_mechanics > a.assigned_mechanics ? 1 : -1 })
                    :
                    flights.sort(function (a, b) { return a.assigned_mechanics > b.assigned_mechanics ? 1 : -1 })
                break;
            case "Remarks":
                tempAscending ?
                    flights.sort(function (a, b) { return b.remarks > a.remarks ? 1 : -1 })
                    :
                    flights.sort(function (b, a) { return a.remarks > b.remarks ? 1 : -1 })
                break;
        }
        setFlightSchedule(flights);
    }

    return (
        <Grid>
            {loading && <LinearProgress />}
            < Tabs
                tabNames={["Lite Handover", "Flight schedule", "Workpackage overview"]}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                tabValue={tabValue}
            />
            {tabValue === 0 && < LiteHandover allTails={allTails} workPlace={"LITE"} />}
            {tabValue === 1 && < FlightScheduleView refresh={() => refresh()} flights={FlightScheduleObj} dataFetched={data_fetched_when}
                sortByColumn={(columnName: string, flights: LineWorkPackages[], ascending: boolean) => sortByColumn(columnName, flights, ascending)}
                ascending={ascending} orderByText={orderByText} isLineSupervisorAdmin={isLineSupervisorAdmin}
            />
            }
            {tabValue === 2 && < LineWorkPackageOverview allTails={allTails} setLoadingProp={(loading: boolean) => setLoading(loading)} lineOrLite={lineOrLiteEnum.lite} />}
        </Grid>
    );
};
export default Lite;