import { settings } from '../config';
import { StaffManagementBays, MxiStaffManagementEmployee } from '../types/staffManagement';
import { getToken } from './../global/TokenValidator';

class Staff {
    GetAllMembersFromMyCrew = async (): Promise<{ status: boolean, message: string, data: MxiStaffManagementEmployee[] }> => {
        return fetch(`${settings.HOST}/StaffManagement/GetAllMembersFromMyCrew`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetAllStaffManagement = async (): Promise<{ status: boolean, message: string, data: StaffManagementBays[], bays: Array<string> }> => {
        return fetch(`${settings.HOST}/StaffManagement/GetAllStaffManagement`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    GetEmployeeLocationByEmployeeID = async (employeeID: number): Promise<{ status: boolean, message: string, data: MxiStaffManagementEmployee }> => {
        return fetch(`${settings.HOST}/StaffManagement/GetEmployeeLocationByEmployeeID/${employeeID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    DeleteUserFromGroup = async (userName: string, department: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/StaffManagement/DeleteUserFromGroup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "userName": userName,
                "department": department
            })
        }).then(x => {
            return x.json()
        })
    }
    AssignUserToGroup = async (userName: string, department: string): Promise<{ status: boolean, message: string, isPageRefresh: boolean }> => {
        return fetch(`${settings.HOST}/StaffManagement/AssignUserToGroup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "userName": userName,
                "department": department
            })
        }).then(x => {
            return x.json()
        })
    }
}
const StaffApi = new Staff();
export default StaffApi;