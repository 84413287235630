import { settings } from '../config';
import { dictionary } from '../types/general';
import { User } from './../types/user'
import { getToken } from './../global/TokenValidator';

class SubContracts {
    GetAllCompanies = async (): Promise<{ status: boolean, data: dictionary[] }> => {
        return fetch(`${settings.HOST}/SubContracts/GetAllCompanies`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    AddNewSubContract = async (name: string, jobTitle: string, department: string, nationalIdentifier: string, hrEmployeeNumber: string, companyId: number, lastModifiedBy: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/SubContracts/AddNewSubContract`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "name": name,
                "job_title": jobTitle,
                "department": department,
                "national_identifier": nationalIdentifier,
                "hr_employee_number": hrEmployeeNumber,
                "company_id": companyId,
                "last_modified_by": lastModifiedBy,
            })
        }).then(x => {
            return x.json()
        })
    }
    AddNewCompany = async (createdById: number, name: string, description: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/SubContracts/AddNewCompany`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "created_by_employee_id": createdById,
                "description": description,
                "name": name
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteCompany = async (companyToDelete: dictionary): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/SubContracts/DeleteCompany`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(companyToDelete)
        }).then(x => {
            return x.json()
        })
    }
    DeleteSubContract = async (employee: User): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/SubContracts/DeleteSubContract`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(employee)
        }).then(x => {
            return x.json()
        })
    }
}
const SubContractsApi = new SubContracts();
export default SubContractsApi;