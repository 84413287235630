import { useState, useEffect, CSSProperties } from 'react';
import { Grid } from '@mui/material';
import { Typography, Dialog } from './../../../MaterialUiComponents';
import { EmployeeSearch } from './../../../components';
import toolsApi from './../../../api/tools';
import RingLoader from "react-spinners/RingLoader";

const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
};

function UserCards() {
    const [userCardDialopgOpen, setUserCardDialogOpen] = useState(false);
    const [givenUserCardCode, setGivenUserCardCode] = useState("");
    const [employeeSearch, setemployeeSearch] = useState<number>(-1);

    useEffect(() => {
        const escFunction = (event: any) => {
            if (event.keyCode === 13) { document.getElementById("press-Enter")?.click() }
        }
        document.addEventListener('keydown', escFunction, false);
    }, [])

    const onConfirmEmployee = (employeeId: number) => {
        if (employeeId !== 0) {
            setemployeeSearch(employeeId);
            setUserCardDialogOpen(true);
            setInterval(function () {
                const returnInput = document.getElementById('user_id_number');
                returnInput?.focus();
            }, 1000);
        }
    }
    const onUSerCardCodeChange = (code: string) => {
        setGivenUserCardCode(code);
    }
    const handleEnterPressed = () => {
        const userCardCode = givenUserCardCode.trim();
        setUserCardDialogOpen(false);
        toolsApi.UpdateUserCardCodes(employeeSearch, userCardCode).then(res => {
            setGivenUserCardCode("");
        });
    }
    return (
        <Grid item xs={12}>
            <Typography text={"Employee User Cards"} align="center" variant="h2" />
            <Grid xs={12} lg={6} container justifyContent={'center'} style={{ margin: 'auto' }}>
                <EmployeeSearch
                    inputWidth={90}
                    place_holder={"Write employee name"}
                    onSelectedEemployee={(employeeId: number) => onConfirmEmployee(employeeId)}
                />
            </Grid>
            <button id="press-Enter" onClick={() => handleEnterPressed()} style={{ position: "absolute", top: 0 }}></button>
            <Dialog
                visible={userCardDialopgOpen}
                max_width={"sm"} title={"Please make sure employee scans his cards"}
                context={
                    <Grid>
                        <RingLoader color={"#36D7B7"} loading={true} cssOverride={override} />
                        <input id="user_id_number" placeholder={"Write user card code"} value={givenUserCardCode} onChange={(e) => { onUSerCardCodeChange(e.target.value) }} ></input>
                    </Grid>
                }
                onClose={(status: any) => setUserCardDialogOpen(false)}
                strict={false}
                show_options={false}
            />
        </Grid>
    );
};
export default UserCards;