import React, {DragEvent, useCallback, useMemo} from "react";
import getUserName from "../../utils/staffmanagement";
import { StaffManagementAreas, StaffManagementBays, StaffMtpEmployee } from "../../types/staffManagement";
import { User } from "../../types/user";
import { Avatar, Badge, Grid, IconButton } from "@mui/material";
import { CheckCircleRounded, Delete, HighlightOffRounded, NightlightRound, ScheduleOutlined } from "@mui/icons-material";
import { ProfileImg } from "../../components";
import { Typography } from "../../MaterialUiComponents";
import { v4 as uuid } from 'uuid';
interface Props {
  employee: StaffMtpEmployee | User;
  bay: StaffManagementBays;
  group: StaffManagementAreas | undefined;
  empMappedKey: number;
  handleDragStart: (employee: User | StaffMtpEmployee, bay: StaffManagementBays | undefined, group: StaffManagementAreas | undefined, empMappedKey: number, areaIndex: number) => void;
  setDragging: Function;
  handleDragOver: (e: DragEvent<HTMLDivElement>) => void;
  handleAboutEmployeeDialog: Function;
  deleteEmployeeFromGroup: (employee: StaffMtpEmployee, group: StaffManagementAreas) => void;
  isStmAdmin: boolean;

}

const EmpCard = ({employee, bay, group, empMappedKey, handleDragStart, setDragging, handleDragOver, handleAboutEmployeeDialog, deleteEmployeeFromGroup, isStmAdmin}: Props) => {
  const getEmployeeAvatarUrl = useCallback((employee: StaffMtpEmployee | User): string => {
    let userName2 = "";
    if (employee !== null) {
        userName2 = getUserName(employee);
    }
    return userName2;
}, [employee]);
const empGridId: string = useMemo(() => uuid(), []);
const employeeAvatarUrl = useMemo(()=> getEmployeeAvatarUrl(employee), [getEmployeeAvatarUrl]);
const attendanceIcon = (employeeStatus: string) => {
  let code = <div></div>;
  switch (employeeStatus) {
      case ("Away"):
          code = <Avatar style={{ backgroundColor: "orange", height: "15px", width: "15px" }}>
              <ScheduleOutlined style={{ height: "12px", width: "12px" }} />
          </Avatar>
          break;
      case ("Night Shift"):
          code = <Avatar style={{ backgroundColor: "blue", height: "15px", width: "15px" }}>
              <NightlightRound style={{ height: "12px", width: "12px" }} />
          </Avatar>
          break;
      case ("Vacation"):
          code = <Avatar style={{ backgroundColor: "pink", height: "15px", width: "15px" }}>
              <HighlightOffRounded style={{ height: "12px", width: "12px" }} />
          </Avatar>
          break;
      default:
          code = <Avatar style={{ backgroundColor: "green", height: "15px", width: "15px" }}>
              <CheckCircleRounded style={{ height: "12px", width: "12px" }} />
          </Avatar>
  }
  return code;
}

  return (
  <Grid
    id={empGridId}
    draggable={isStmAdmin}
    onDragStart={() => { handleDragStart(employee, bay, group, empMappedKey, bay.areas.findIndex((area) => area.area === group?.area)); setDragging(true); }}
    onDragOver={(e: DragEvent<HTMLDivElement>) => handleDragOver(e)}
    style={{ width: '100%', backgroundColor: '#e5e7eb', borderRadius: '5px', border: '0.5px solid #d1d5db', margin: '6px' }}
    key={empGridId}
  >
    <Grid container xs={12}>
        <Grid item xs={2} justifyContent={"start"} container onClick={() => { (handleAboutEmployeeDialog(true, employee)) }}>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    attendanceIcon(employee.employee_attendance_status)
                }
            >
                <ProfileImg key={empGridId} imgKey={empGridId} userName={employeeAvatarUrl} />
            </Badge>
        </Grid>
        <Grid item xs={7} container onClick={() => { (handleAboutEmployeeDialog(true, employee)) }}>
            <Typography variant='body1' text={`${employee.employee_name} ${employee.employee_abbreviation ? employee.employee_abbreviation : ""}`} />
        </Grid>
        <Grid item container justifyContent={'end'} xs={2}>
            {(group !== undefined && isStmAdmin) &&
                <IconButton onClick={() => deleteEmployeeFromGroup(employee as StaffMtpEmployee, group as StaffManagementAreas)}><Delete color='error' /></IconButton>
            }
        </Grid>
    </Grid>
  </Grid>)
}

export default React.memo(EmpCard);