import { createStore, applyMiddleware} from "redux";
import socket from './middleware/socket';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from "./reducer";

const middleware = [socket];

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(...middleware)
    )
);

export default store;