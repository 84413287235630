import { FunctionComponent, ReactElement } from 'react';
import { Grid, IconButton, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Check, Edit, Delete, Close } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    save: {
        color: 'green',
        padding: '6px',
    },
    delete: {
        color: 'red',
        padding: '6px',
        paddingRight: '0px'
    },
    editCell: {
        paddingRight: '0px',
        width: '120px'
    },
    icon: {
        fontSize: '28px'
    },
    close: {
        color: 'black',
        padding: '6px',
        paddingRight: '0px'
    }
}));

type EditColumnProps = {
    index: number,
    editRow: number,
    setEditRow: Function,
    saveChanges: Function,
    deleteRow: Function,
    customIcon?: ReactElement,
    abort?: boolean,
}

const EditColumn: FunctionComponent<EditColumnProps> = ({ index, editRow, setEditRow, saveChanges, deleteRow, customIcon, abort }) => {

    const classes = useStyles();
    // Displays Edit Icon not in edit mode (editRow and index not the same)
    // Displays Save and Delete Icon Buttons when in edit mode
    return (
        <TableCell className={classes.editCell}>
            {editRow !== index ?
                <IconButton onClick={() => setEditRow(index)} color="default" >
                    {customIcon === undefined ?
                        <Edit />
                        :
                        customIcon
                    }
                </IconButton>
                :
                editRow === index &&
                <Grid container direction="row"  >
                    <Grid item>
                        <IconButton onClick={() => saveChanges()} className={classes.save} >
                            <Check className={classes.icon} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => deleteRow()} className={classes.delete} >
                            <Delete className={classes.icon} />
                        </IconButton>
                    </Grid>
                    {abort === true &&
                        <Grid item>
                            <IconButton onClick={() => setEditRow()} className={classes.close} >
                                <Close className={classes.icon} />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            }
        </TableCell>
    );
};
export default EditColumn;
