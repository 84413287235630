import { FunctionComponent } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Typography } from './../../MaterialUiComponents';
import IcelandairLogo from '../../assets/icelandairLogo.svg';
import { makeStyles } from '@mui/styles';
import { securityResult } from '../../types/security';

const useStyles = makeStyles(theme => ({
    certInfo: {
        marginTop: '1%'
    },
    icelandairLogo: {
        height: '50px',
        bottom: '5px',
        position: 'relative',
    },
    infoTop: {
        marginBottom: theme.spacing(2)
    }
}));

type ReportInfoProps = {
    selectedReport: securityResult,
    closeReport: Function,
    submitReport: Function,
    isSecurityAdmin: Boolean
}

const ReportInfo: FunctionComponent<ReportInfoProps> = ({ selectedReport, closeReport, submitReport, isSecurityAdmin }) => {

    const classes = useStyles();
    // Header of the report. Submit button is only displayed when in edit mode
    return (
        <Grid className={classes.infoTop}>
            <Grid container xs={12}>
                <Grid item xs={7}>
                    <Typography variant='h1' text={"Security Report"} align='left' />
                </Grid>
                <Grid item xs={4}>
                    <img src={IcelandairLogo} className={classes.icelandairLogo} alt="Logo" />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => closeReport()} color="default" >
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
            <hr />
            <Grid container xs={10} className={classes.certInfo} >
                {isSecurityAdmin && !selectedReport.report.closed &&
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={() => submitReport()}>
                            Submit Report
                        </Button>
                    </Grid>
                }
                <Grid item lg={2} xs={6}><Typography paragraph={true} fontWeight='bold' text='Report created by:' /></Grid>
                <Grid item lg={10} xs={6}>{selectedReport.report.general_info.created_by_employee_name} at {selectedReport.report.general_info.created_date_text} </Grid>
                <Grid item lg={2} xs={6}><Typography paragraph={true} fontWeight='bold' text='Last modified by:' /></Grid>
                <Grid item lg={10} xs={6}>{selectedReport.report.general_info.last_modified_date_by_employee_name !== "" && selectedReport.report.general_info.last_modified_date_by_employee_name + ' at ' + selectedReport.report.general_info.last_modified_date_text}</Grid>
            </Grid>
        </Grid>
    );
};
export default ReportInfo;
