import { useState, useEffect } from 'react';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Typography, Table } from './../../../MaterialUiComponents';
import { ToolData } from '../../../types/tool';
import { makeStyles } from '@mui/styles';
import { EmployeeSearch, ProfileImg } from './../../../components';
import toolsApi from './../../../api/tools';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducer';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    marginLeft: "35%"
  },
  userPhoto: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: "50%"
  },
  userContainer: {
    display: "flex"
  },
}));

function UserHistory() {
  const [filtered_tools, setFilteredTools] = useState(Array<ToolData>())
  const [order_by_text, setOrderByText] = useState("Loaned out");
  const [ascending, setAscending] = useState(false);
  const [employee_id, setEmployeeId] = useState(0);
  const classes = useStyles();
  const users = useSelector((state: RootState) => state.employees.all_employees);

  useEffect(() => {
    const returnInput = document.getElementById('textfield');
    returnInput?.focus();
  },);

  const generateTableBody = (row: ToolData, index: number) => {
    return (
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell className={classes.userContainer}>
          <ProfileImg imgKey={index} userName={users.find(s => s.Id === row.employee_id)?.userName.split('@')[0]} />
          <Typography align="left" text={row.employee_name}></Typography>
        </TableCell>
        <TableCell>{row.bay}</TableCell>
        <TableCell>{row.area}</TableCell>
        <TableCell>{row.out_date_text}</TableCell>
        <TableCell>{row.in_date_text}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.serial}</TableCell>
      </TableRow>
    )
  }

  const refresh = (mxi_inv_no_id: number, employee_id: number, order_by_text: string, ascending: boolean) => {
    toolsApi.getSingleToolsHistory(mxi_inv_no_id, employee_id, order_by_text, ascending).then(res => {
      setFilteredTools(res);
    });
  }

  const OnOrderByTitles = (given_order_by_text: string) => {
    let temp_ascending = true;
    if (order_by_text === given_order_by_text) { temp_ascending = ascending === false ? true : false; }
    setAscending(temp_ascending);
    setOrderByText(given_order_by_text);
    refresh(0, employee_id, given_order_by_text, temp_ascending);
  }

  const onSelectedEemployeeRow = (employee_id: number) => {
    setEmployeeId(employee_id);
    refresh(0, employee_id, order_by_text, ascending);
  };

  return (
    <Grid item xs={12}>
      <Typography text={"Search for users history"} align="center" variant="h2" />
      <Typography text={`Total available (${filtered_tools.length})`} align="center" variant={"body1"} />
      <Grid container xs={4} className={classes.searchContainer}>
        <EmployeeSearch place_holder={"Write employees name"} onSelectedEemployee={(employee_id: number) => onSelectedEemployeeRow(employee_id)} />
      </Grid>
      <Table
        headers={["Name", "Bay", "Area", "Loaned out", "Returned", "Description", "Serial"]}
        rows={filtered_tools}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={100}
        OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
      />
    </Grid>
  );
};
export default UserHistory;