import { FunctionComponent, useEffect, useState } from 'react';
import { Grid, TableRow, TableCell, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Tabs, Typography, Table, Select, Input, Dialog } from '../../MaterialUiComponents';
import EditColumn from '../Security/EditColumn';
import lineApi from '../../api/line';
import { AddCircle, Edit } from '@mui/icons-material';
import { EmployeeSearch, Collection } from '../../components';
import { LineHandoverIssues, LineHandoverReturnReportObj } from '../../types/line';
import { User } from '../../types/user';
import LineHandoverMxiPackageInformation from './LineHandoverMxiPackageInformation';

const useStyles = makeStyles(theme => ({
  evenRows: {
    backgroundColor: theme.palette.action.hover
  },
  save: {
    color: 'green'
  },
}));

interface HandoverDialogContextProps {
  allTails: Array<string>,
  lineHandoverReport: LineHandoverReturnReportObj,
  refresh: Function,
  onClose: Function,
  isLineSupervisorAdmin: Boolean
}

const HandoverDialogContext: FunctionComponent<HandoverDialogContextProps> = ({ allTails, lineHandoverReport, refresh, onClose, isLineSupervisorAdmin }) => {
  const [tabValue, setTabValue] = useState(0);
  const [editIndex, setEditIndex] = useState(-1);
  const [selectedTailNumber, setSelectedTailNumber] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");
  const [areYouSureDialogOpen, setAreYouSureDialogOpen] = useState(false);
  const classes = useStyles();

  const generateTableBody = (row: LineHandoverIssues, index: number) => {
    return (
      editIndex === index ?
        <TableRow className={index % 2 ? classes.evenRows : ''} >
          <TableCell>{index}</TableCell>
          <TableCell>{row.created_date_text}</TableCell>
          <TableCell>
            <Select width={100} widthPercentage filter_by_text="Tail number" onSelectedItem={(e: any) => setSelectedTailNumber(e.target.value)} options={allTails} selectedItem={selectedTailNumber} />
          </TableCell>
          <TableCell>   <Input multiline={true} help_text={""} label_text={"Issue"} value={selectedIssue} onTextChange={(text: string) => setSelectedIssue(text)} type="text" width={100} /></TableCell>
          <EditColumn
            index={index}
            saveChanges={() => onModifieIssue(row.id)}
            deleteRow={() => onDeleteIssue(row.id)}
            editRow={index}
            setEditRow={(editRow: number) => { }} />
        </TableRow>
        :
        <TableRow className={index % 2 ? classes.evenRows : ''} >
          <TableCell>{index}</TableCell>
          <TableCell>{row.created_date_text}</TableCell>
          <TableCell>{row.tail_number}</TableCell>
          <TableCell>{row.issue}</TableCell>
          {lineHandoverReport.report.submitted === false && <TableCell><IconButton onClick={() => onSelectRow(row, index)}><Edit /></IconButton> </TableCell>}
        </TableRow>
    )
  }
  const onSelectRow = (row: LineHandoverIssues, index: number) => {
    setEditIndex(index);
    setSelectedIssue(row.issue);
    setSelectedTailNumber(row.tail_number);
  }
  const onAddIssue = () => {
    lineApi.AddIssue(lineHandoverReport.report.id, "", "").then(res => {
      setEditIndex(lineHandoverReport.report.issues.length + 1);
      setSelectedTailNumber("");
      setSelectedIssue("");
      refresh(lineHandoverReport.report.id);
    });
  }
  const onDeleteIssue = (issueId: number) => {
    lineApi.DeleteIssue(issueId).then(res => {
      refresh(lineHandoverReport.report.id);
      setSelectedTailNumber("");
      setSelectedIssue("");
      setEditIndex(-1);
    });
  }
  const onModifieIssue = (issueId: number) => {
    lineApi.UpdateIssue(issueId, selectedIssue, selectedTailNumber).then(res => {
      refresh(lineHandoverReport.report.id);
      setSelectedTailNumber("");
      setEditIndex(-1);
    });
  }
  const onAddEmployeeLabour = (user: User) => {
    lineApi.AddLabour(lineHandoverReport.report.id, user.user_id).then(res => {
      refresh(lineHandoverReport.report.id);
    });
  }
  const onDeleteEmployeeLabour = (labourID: number) => {
    lineApi.DeleteLabour(lineHandoverReport.report.id, labourID).then(res => {
      refresh(lineHandoverReport.report.id);
    });
  }
  const onAddEmployeeLabourMC = (user: User) => {
    lineApi.AddLabourMC(lineHandoverReport.report.id, user.user_id).then(res => {
      refresh(lineHandoverReport.report.id);
    });
  }
  const onDeleteEmployeeLabourMC = (labourID: number) => {
    lineApi.DeleteLabourMC(lineHandoverReport.report.id, labourID).then(res => {
      refresh(lineHandoverReport.report.id);
    });
  }
  const onAreYouDialogClicked = (status: boolean) => {
    if (status === true) {
      lineApi.CloseLineHandoverReport().then(res => {
        setAreYouSureDialogOpen(false);
        onClose();
      });
    } else {
      setAreYouSureDialogOpen(false);
      onClose();
    }
  }
  return (
    <Grid>
      <Grid>
        {lineHandoverReport.report.submitted === false && isLineSupervisorAdmin &&
          <Grid container justifyContent={'center'}>
            <Button onClick={() => setAreYouSureDialogOpen(true)} color='primary'>Finish report</Button>
          </Grid>}
        <Grid>
          <Grid container>
            <Typography text={"Created by : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
            <Typography text={lineHandoverReport.report.created_by_employee_name} />
          </Grid>
          <Grid container>
            <Typography text={"Created date : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
            <Typography text={lineHandoverReport.report.created_date_text} />
          </Grid>
          <Grid container>
            <Typography text={"shift : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
            <Typography text={lineHandoverReport.report.shift} />
          </Grid>
          <Grid container>
            <Typography text={"Type : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
            <Typography text={lineHandoverReport.report.is_day_shift === true ? "Day" : "Night"} />
          </Grid>
        </Grid>
        <Typography text={'Issues'} align={'center'} variant='h2' />
        <Table
          headers={["Created date", "Aircraft", "Issue", ""]}
          rows={lineHandoverReport.report.issues}
          generateBody={(row: any, index: number) => generateTableBody(row, index)}
          defaultRowsPerPage={25}
          pagination={false}
          skipIndexCol={false}
        />
        {lineHandoverReport.report.submitted === false && isLineSupervisorAdmin &&
          <Grid container direction="column" alignItems="center">
            <IconButton onClick={() => onAddIssue()} className={classes.save} >
              <AddCircle />
            </IconButton>
          </Grid>}
      </Grid>
      <Grid container>
        <Grid xs={6}>
          <Grid >
            {lineHandoverReport.report.submitted === false && isLineSupervisorAdmin && <EmployeeSearch place_holder={"Line employees"} clearOnSelect fullUser={true} onSelectedEemployee={(employee: User) => onAddEmployeeLabour(employee)} inputWidth={95} />}
            <Collection edit={lineHandoverReport.report.submitted === false && isLineSupervisorAdmin === true} background={false} collectionList={lineHandoverReport.report.assigned_employees} title="Line employees" nameProperty="name" idProperty="Id" imageProperty="user_image" removeItem={(itemId: number) => onDeleteEmployeeLabour(itemId)} />
          </Grid>
        </Grid>
        <Grid xs={6}>
          <Grid >
            {lineHandoverReport.report.submitted === false && isLineSupervisorAdmin && <EmployeeSearch place_holder={"Mc employees"} clearOnSelect fullUser={true} onSelectedEemployee={(employee: User) => onAddEmployeeLabourMC(employee)} inputWidth={95} />}
            <Collection edit={lineHandoverReport.report.submitted === false && isLineSupervisorAdmin === true} background={false} collectionList={lineHandoverReport.report.mc_employees} title="MC employees" nameProperty="name" idProperty="Id" imageProperty="user_image" removeItem={(itemId: number) => onDeleteEmployeeLabourMC(itemId)} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
      </Grid>
      <Grid container justifyContent={'center'}>
        <Tabs
          tabNames={["Hidden", "Line Package", "Turn Checks"]}
          onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
          tabValue={tabValue}
        />
      </Grid>
      <LineHandoverMxiPackageInformation tabValue={tabValue} reportId={lineHandoverReport.report.id} />
      <Dialog
        visible={areYouSureDialogOpen}
        max_width={"md"} title={"Are you sure you want to finish this report"}
        context={
          <div></div>
        }
        onClose={(status: any) => onAreYouDialogClicked(status)}
        strict={false}
        show_options={true}
      />
    </Grid>
  );
};
export default HandoverDialogContext;