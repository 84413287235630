import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../../../reducer';
import { Grid, TableRow, TableCell } from '@mui/material';
import { Typography, Table, Input, Dialog, Select } from './../../../MaterialUiComponents';
import { ToolData } from '../../../types/tool';
import { makeStyles } from '@mui/styles';
import ToolsApi from './../../../api/tools';
import { EditAllowedDays, ReturnNameData } from './../../../components';
import { Delete, Search } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  toolSearchContainer: {
    marginLeft: "35%"
  },
  toolImage: {
    height: theme.spacing(7),
    width: theme.spacing(8),
    borderRadius: "10%"
  },
  fullScreenContainer: {
    textAlign: "center"
  },
  fullScreenImage: {
    borderRadius: "8%",
    width: "60%"
  },
  userContainer: {
    display: "flex"
  },
  test: {
  }
}));

function ToolsOnStock() {
  const store = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const user = store.user;
  const isToolAdmin = user.me.user_clearence_groups.includes("Tool Stock");
  let all_tools = store.tools.mxi_tools.filter(tool => tool.shelf_location !== "SCRAP");
  const [filtered_tools, setFilteredTools] = useState(all_tools);
  const [historyTools, setHistorTools] = useState<ToolData[]>([]);
  const [orderByText, setOrderByText] = useState("Loaned out");
  const [ascending, setAscending] = useState(true);
  const [toolSearchString, setToolSearchString] = useState("");
  const [daysAllowed, setDaysAllowed] = useState("");
  const [allowed_days_dialog_open, setAllowedDaysDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({} as ToolData);
  const [brokenLostDialogOpen, setBrokenLostDialogOpen] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedBrokenLost, setSelectedBrokeLost] = useState("");
  const [brokenLostReason, setBrokenLostReason] = useState("");
  const [historyToolDialogOpen, setHistoryDialogOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const returnInput = document.getElementById('textfield');
    returnInput?.focus();
  }, []);

  const onDaysAllowedChange = (days: number) => { setDaysAllowed(days.toString()); }

  const generateTableBody = (row: ToolData, index: number) => {
    return (
      <TableRow className={classes.test}>
        <TableCell >{index}</TableCell>
        <TableCell >{row.description}</TableCell>
        <TableCell>{row.serial}</TableCell>
        <TableCell>{row.shelf_location}</TableCell>
        <TableCell>{row.user}</TableCell>
        <TableCell onClick={() => onSelectedDaysAllowed(row)}>{row.days_allowed}</TableCell>
        <TableCell onClick={() => onDescriptionClick(row)}><Search style={{ cursor: 'pointer' }} /></TableCell>
        {isToolAdmin && <TableCell onClick={() => onBrokenLostToolSelected(row)}><Delete /></TableCell>}
      </TableRow>
    )
  }
  const getBase64 = (partNumber: string, event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      ToolsApi.UpdateToolImage(partNumber, reader.result!.toString()).then(res => {
        refresh(orderByText, ascending);
      });
    };
  }

  const onBrokenLostToolSelected = (row: ToolData) => {
    ToolsApi.getLastUserOnTool(row.mxi_inv_no_id).then(res => {
      setSelectedRow(res);
    });
    setBrokenLostDialogOpen(true);
  }

  const onSelectedDaysAllowed = (row: ToolData) => {
    if (isToolAdmin) {
      setDaysAllowed(row.days_allowed.toString());
      setSelectedRow(row);
      setAllowedDaysDialogOpen(true);
    }
  }

  const OnOrderByTitles = (givenOrderBytext: string) => {
    let tempAscending = true;
    if (orderByText === givenOrderBytext) { tempAscending = ascending === false ? true : false; }
    setAscending(tempAscending);
    setOrderByText(givenOrderBytext);
    refresh(givenOrderBytext, tempAscending);
  }

  const OnToolSearchString = (value: string) => {
    setToolSearchString(value);
    if (value.length > 1) {
      value = value.trim();
      if (value.length === 24) {
        const filtered_tools = all_tools.filter(s => s.rf_id === value.toUpperCase());
        setFilteredTools(filtered_tools);
        setToolSearchString("");
      } else {
        const filtered_tools = all_tools.filter(s => s.description.toUpperCase().includes(value.toUpperCase()));
        setFilteredTools(filtered_tools);
      }
    }
  }
  const closeAllowedDaysDialog = (status: boolean) => {
    if (status === true) {
      selectedRow.days_allowed = parseInt(daysAllowed);
      ToolsApi.editDaysAllowed(selectedRow).then(res => {
        refresh(orderByText, ascending);
      });
    }
    setAllowedDaysDialogOpen(false);
  }

  const closeBrokenLostDialog = (status: boolean) => {
    if (status === true) {
      const toolData = {} as ToolData;
      toolData.loaned_out_tool_id = selectedRow.id;
      toolData.reason = brokenLostReason;
      toolData.type = selectedBrokenLost;
      toolData.mxi_inv_no_id = selectedRow.mxi_inv_no_id;
      toolData.report_owner_employee_id = user.me.user_id;
      ToolsApi.CreateDefectiveTool(toolData).then(res => { });
    }
    setBrokenLostDialogOpen(false);
  }
  const onSelectedBrokenLost = (value: string) => {
    setFilteredTools([]);
    setSelectedBrokeLost(value);
  }
  const refresh = (orderByText: string, ascending: boolean) => {
    ToolsApi.getAllToolsFromMxi(orderByText, ascending).then(res => {
      setImageDialogOpen(false);
      dispatch({ type: 'ON_MXI_TOOLS', tools: res });
      all_tools = res;
      setFilteredTools(res);
    });
  }
  const onDescriptionClick = (row: ToolData) => {
    ToolsApi.getSingleToolsHistory(row.mxi_inv_no_id, row.employee_id, orderByText, ascending).then(res => {
      setHistorTools(res);
      setHistoryDialogOpen(true);
      setSelectedRow(row);
    });
  }
  const generateTableHistoryBody = (row: ToolData, index: number) => {
    return (
      <TableRow className={classes.test}>
        <TableCell>{index}</TableCell>
        <TableCell className={classes.userContainer}>
          <ReturnNameData
            employee_id={row.employee_id}
            tail_number={row.tail_number}
            world_trip_name={row.world_trip_name}
            employee_image={row.employee_image}
            employee_name={row.employee_name}
          />
        </TableCell>
        <TableCell>{row.bay}</TableCell>
        <TableCell>{row.area}</TableCell>
        <TableCell>{row.out_date_text}</TableCell>
        <TableCell>{row.in_date_text}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.serial}</TableCell>
      </TableRow>
    )
  }
  return (
    <Grid item xs={12} className={classes.test}>
      <Typography text={"Search for tools on stock"} align="center" variant="h1" />
      <Typography text={"Press Description to see tool history"} align="center" variant={"body1"} />
      <Typography text={`Total available (${all_tools.length})`} align="center" variant={"body1"} />
      <Grid className={classes.toolSearchContainer}>
        <Input
          label_text={"Search for tools by Description, part, rfid & serial number"} value={toolSearchString} help_text="Write text to search"
          onTextChange={(value: string) => OnToolSearchString(value)} type="text" width={50} multiline={false} />
      </Grid>
      <Table
        headers={["Description", "Serial", "Shelf location", "Status", "Days allowed", "Details"]}
        rows={filtered_tools}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={25}
        OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
        pagination={true}
      />
      <EditAllowedDays
        visible={allowed_days_dialog_open}
        tool_description={selectedRow.description}
        closeAllowedDaysDialog={(status: any) => closeAllowedDaysDialog(status)}
        days_allowed={daysAllowed}
        onDaysAllowedChange={(days: number) => onDaysAllowedChange(days)}
      />
      <Dialog
        visible={brokenLostDialogOpen}
        max_width={"sm"} title={"Broken & lost tool"}
        context={
          <Grid item xs={12}>
            <Typography text={`Last used by : ${store.employees.all_employees.find(s => s.user_id === selectedRow.employee_id)?.name}`} />
            <Grid><Select onSelectedItem={(e: any) => onSelectedBrokenLost(e.target.value)} selectedItem={selectedBrokenLost} width={200} filter_by_text={"Choose Broken or Lost"} options={["Broken", "Lost"]} /></Grid>
            <Input multiline={true} help_text={"Make sure to write a good accurate report"} label_text={"What happened to this tool"} value={brokenLostReason} onTextChange={(reason: string) => setBrokenLostReason(reason)} type="text" width={100} />
          </Grid>
        }
        onClose={(status: any) => closeBrokenLostDialog(status)}
        strict={false}
        show_options={true}
      />
      <Dialog
        visible={imageDialogOpen}
        max_width={"xl"} title={selectedRow.description}
        context={
          <Grid className={classes.fullScreenContainer}>
            <Typography text={"Change picture"} align="center" variant="h5" />
            <input type="file" id="toolImage" onChange={(e) => getBase64(selectedRow.part_number, e)} />
            <br />
            <br />
            <Grid >
              <img src={selectedRow.tool_image!} className={classes.fullScreenImage} alt="user" />
            </Grid>
          </Grid>
        }
        onClose={(status: any) => setImageDialogOpen(false)}
        strict={false}
        show_options={true}
      />
      <Dialog
        visible={historyToolDialogOpen}
        max_width={"xl"} title={`History for ${selectedRow.description}`}
        context={
          <Grid className={classes.fullScreenContainer}>
            <Table
              headers={["Name", "Bay", "Area", "Loaned out", "Returned", "Description", "Serial"]}
              rows={historyTools}
              generateBody={(row: any, index: number) => generateTableHistoryBody(row, index)}
              defaultRowsPerPage={100}
              OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
            />
          </Grid>
        }
        onClose={(status: any) => { setHistoryDialogOpen(false); setToolSearchString(""); }}
        strict={false}
        show_options={false}
      />
    </Grid>
  );
};
export default ToolsOnStock;