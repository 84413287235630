import { SelectObject } from "../types/certificates";

// Generic function that creates optionsObjects from an array of strings, we use this to create the options for our SelectMultiple component.
// Whenever we have options that should not be hardcoded we can use this function. Sometimes new options are added to the database and we want to display them in the SelectMultiple component, e.g tails, cage codes and more.
export const createOptionsFromArray = (strings: string[]): SelectObject[] => {
    const newOptions: SelectObject[] = [];

    // Add the first object from the original Options array
    newOptions.push({ name: 'Select/Unselect All', id: 0 });

    // Iterate through the array of strings
    for (let i = 0; i < strings.length; i++) {
        const name = strings[i];
        const id = i + 1; // Assigning incremental ids starting from 1

        // Create a new Option object and add it to the newOptions array
        const newOption: SelectObject = { name, id };
        newOptions.push(newOption);
    }
    return newOptions;
}

// generic function that can toggle all options for our SelectMultiple component.
export const toggleSelectAll = (
    event: number[],
    selectedOptions: SelectObject[],
    setSelectedOptions: Function,
    toggleSelected: boolean,
    setToggleSelected: Function
) => {
    if (event.includes(0)) {
        setSelectedOptions(selectedOptions.map((option) => option.id));
        setToggleSelected(!toggleSelected);
    }
    if (event.includes(0) && toggleSelected) {
        setToggleSelected(!toggleSelected);
        setSelectedOptions([]);
    }
};