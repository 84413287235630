import { useState, useEffect, FunctionComponent } from "react";
import { useSelector } from 'react-redux';
import { RootState } from './../../reducer';
import { Grid, Button, IconButton } from '@mui/material';
import { Tabs, Dialog, LinearProgress, Input, Select, Typography } from '../../MaterialUiComponents';
import GroupsAndPermissions from './GroupsAndPermissions';
import UserHistory from './UserHistory';
import AccessControlApi from '../../api/accessControl';
import { AccessControlGroup, AccessControlPermission, EmployeeInUserGroupPermission, AccessGroupUserGroupRelation } from '../../types/accessControl';
import { makeStyles } from '@mui/styles';
import AllUsers from "./AllUsers";
import CreateGroup from "./CreateGroupComponent";
import { AllInclusive } from "@mui/icons-material";
import { Datepicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import UsersWithNoAccess from "./UsersWithNoAccess";

const useStyles = makeStyles(theme => ({
    Button: {
        marginBottom: theme.spacing(4),
    }
}));

const AccessControl: FunctionComponent = () => {
    const store = useSelector((state: RootState) => state);
    const isDeveloper = store.user.me.user_clearence_groups.includes("Developers");
    const classes = useStyles();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);

    const [groupsRelation, setGroupsRelation] = useState<AccessGroupUserGroupRelation[]>([]); // object that incluted which user_groups are assigned to access_groups
    const [accessControlGroups, setAccessControlGroups] = useState<AccessControlGroup[]>([]);
    const [accessControlPermissions, setAccessControlPermissions] = useState<AccessControlPermission[]>([]);

    const [userGroups, setUserGroups] = useState<AccessControlGroup[]>([]);
    const [empInUserGroupsPermissions, setEmpInUserGroupsPermissions] = useState<EmployeeInUserGroupPermission[]>([]);

    const [isAccessGroupDialog, setIsAccessGroupDialog] = useState<boolean>(false);
    const [isUserGroupDialog, setIsUserGroupDialog] = useState<boolean>(false);

    //for creating an access group
    const [accessGroupName, setAccessGroupName] = useState<string>("");
    const [accessGroupPermission, setAccessGroupPermission] = useState<string>("");

    //for creating a user group
    const [userGroupName, setUserGroupName] = useState<string>("");
    const [userGroupPermission, setUserGroupPermission] = useState<string>("");

    //for creating new user group permision, in this case a user group is assigned to an access group. employees are assigned to user a group. 
    //Employees are in user_groups and user_groups are in access_groups)
    const [userGroupPermissionDialog, setUserGroupPermissionDialog] = useState<boolean>(false);
    const [userGroupPermissionReason, setUserGroupPermissionReason] = useState<string>("");
    const [expirationDate, setExpirationDate] = useState<Date>(new Date());
    const [startsAt, setStartsAt] = useState<Date>(new Date());

    //For selecting groups when adding user group to access group.
    const [selectedUserGroup, setSelectedUserGroup] = useState<string>("Select user group");
    const [userGroupsForSelect, setUserGroupsForSelect] = useState<string[]>([]);
    const [selectedAccessGroup, setSelectedAccessGroup] = useState<string>("Select access group");
    const [accessGroupsForSelect, setAccessGroupsForSelect] = useState<string[]>([]);

    useEffect(() => {
        refresh();
    }, [])

    const refresh = () => {
        setLoading(true);
        AccessControlApi.GetAllGroups().then(res => {
            setAccessControlGroups(res.accessControlGroups);
            setUserGroups(res.userGroups);
            setLoading(false);
            setAccessGroupsForSelect(res.accessControlGroups.map(x => x.group_name));
            setUserGroupsForSelect(res.userGroups.map(x => x.group_name));
        });
        setLoading(true);
        AccessControlApi.GetAllPermissions().then(res => {
            setAccessControlPermissions(res.accessControlPermissions);
            setGroupsRelation(res.accessGroupUserGroupRelation);
            setLoading(false);
        });
        setLoading(true);
        AccessControlApi.GetAllUserGroupEmployeePermissions().then(res => {
            setEmpInUserGroupsPermissions(res.data);
            setLoading(false);
        });
    }

    const onCreateAccessGroup = () => {
        setLoading(true);
        AccessControlApi.CreateAccessGroup(accessGroupName, accessGroupPermission).then(res => {
            setIsAccessGroupDialog(false);
            refresh();
            setLoading(false);
        })
    }

    const onCreateUserGroup = () => {
        setLoading(true);
        AccessControlApi.CreateUserGroup(userGroupName, userGroupPermission).then(res => {
            setIsUserGroupDialog(false);
            refresh();
            setLoading(false);
        })
    }

    const onCreateUserGroupPermission = () => {
        setLoading(true);
        if ((userGroupPermissionReason !== "" && userGroupPermissionReason !== undefined) && (expirationDate !== undefined)) {
            AccessControlApi.CreateUserGroupPermission(selectedAccessGroup, selectedUserGroup, userGroupPermissionReason, expirationDate, startsAt).then(res => {
                setIsAccessGroupDialog(false);
                refresh();
                setUserGroupPermissionDialog(false);
                setLoading(false);
            })
        }
    }

    return (
        <Grid>
            {isLoading && <LinearProgress />}
            <Tabs
                fixedTabs
                tabNames={["ACCESS GROUPS", "USER GROUPS", "USER HISTORY", "INSERT NEW ONE", "Users with no accees"]}
                tabValue={tabValue}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
            />
            {tabValue === 0 || tabValue === 1 && <Grid container direction="row">
                {isDeveloper && <Grid item style={{ marginRight: "8px" }}>
                    <Button variant="contained" color='primary' className={classes.Button} onClick={(e: any) => setIsAccessGroupDialog(true)}>Create Access group</Button>
                </Grid>}
                {<Grid item style={{ marginRight: "8px" }}>
                    <Button variant="contained" color='primary' className={classes.Button} onClick={(e: any) => setIsUserGroupDialog(true)}>Create user group</Button>
                </Grid>}
                <Grid item>
                    <Button variant="contained" color='primary' className={classes.Button} onClick={(e: any) => setUserGroupPermissionDialog(true)}>Create user group permission</Button>
                </Grid>
            </Grid>}
            {(tabValue === 0 || tabValue === 1) &&
                <GroupsAndPermissions
                    groupsRelation={groupsRelation}
                    empInUserGroupsPermissions={empInUserGroupsPermissions}
                    tabValue={tabValue}
                    accessControlPermissions={accessControlPermissions}
                    accessGroups={isDeveloper ? accessControlGroups : accessControlGroups.filter(x => x.group_name !== "Developers")}
                    userGroups={userGroups}
                    setLoading={setLoading}
                    refresh={refresh}
                />
            }
            {tabValue === 2 && <UserHistory setLoading={setLoading} />}
            {tabValue === 3 && <AllUsers setLoading={setLoading} refresh={refresh} />}
            {tabValue === 4 && <UsersWithNoAccess />}
            {/* Create Access Group */}
            <CreateGroup
                isOpen={isAccessGroupDialog}
                setIsOpen={setIsAccessGroupDialog}
                groupName={accessGroupName}
                setGroupName={setAccessGroupName}
                groupPermission={accessGroupPermission}
                setPermission={setAccessGroupPermission}
                onCreateGroup={onCreateAccessGroup}
            />
            {/* Create User Group */}
            <CreateGroup
                isOpen={isUserGroupDialog}
                setIsOpen={setIsUserGroupDialog}
                groupName={userGroupName}
                setGroupName={setUserGroupName}
                groupPermission={userGroupPermission}
                setPermission={setUserGroupPermission}
                onCreateGroup={onCreateUserGroup}
            />
            {/* Create a user group permission, that mean add a user group to an existing access group */}
            <Dialog
                visible={userGroupPermissionDialog}
                max_width={"md"}
                title={"Create user group permission"}
                context={
                    <Grid>
                        <Grid container direction="row">
                            <Grid item style={{ width: '100%' }} >
                                <Select options={userGroupsForSelect} selectedItem={selectedUserGroup} widthPercentage width={40} onSelectedItem={(e: any) => setSelectedUserGroup(e.target.value)} filter_by_text="Select user group" />
                            </Grid>
                            <Grid item style={{ width: '100%' }} >
                                <Select options={accessGroupsForSelect} selectedItem={selectedAccessGroup} widthPercentage width={40} onSelectedItem={(e: any) => setSelectedAccessGroup(e.target.value)} filter_by_text="Select access group" />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Input
                                multiline={false}
                                label_text="Reason"
                                help_text="Please provide a reason for the new permission"
                                type="text"
                                value={userGroupPermissionReason}
                                width={100}
                                onTextChange={(value: string) => setUserGroupPermissionReason(value)}
                            />
                        </Grid>
                        <Grid container direction="row" style={{ marginTop: '20px' }}>
                            <Grid item>
                                <Typography margin={[2.5, 0, 0, 0]} text={"Expiration date"} />
                            </Grid>
                            <Grid item>
                                <Datepicker
                                    controls={['calendar']}
                                    inputProps={{
                                        placeholder: 'Select date'
                                    }}
                                    display='anchored'
                                    touchUi={true}
                                    value={expirationDate}
                                    onChange={(e: any) => setExpirationDate(e.value)}
                                    themeVariant='light'
                                    dateFormat='DD.MM.YYYY'
                                />
                            </Grid>
                            <IconButton onClick={() => { setExpirationDate(new Date("01.01.2099")); setUserGroupPermissionReason("Permanent permission"); }}><AllInclusive /></IconButton>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item>
                                <Typography margin={[2.5, 0, 0, 0]} text={"Starts at"} />
                            </Grid>
                            <Datepicker
                                controls={['calendar']}
                                inputProps={{
                                    placeholder: 'Select date'
                                }}
                                display='anchored'
                                touchUi={true}
                                value={startsAt}
                                onChange={(e: any) => setStartsAt(e.value)}
                                themeVariant='light'
                                dateFormat='DD.MM.YYYY'
                            />
                        </Grid>
                        <Grid style={{ marginTop: '16px' }}>
                            <Button variant="contained" color='primary' className={classes.Button} onClick={() => onCreateUserGroupPermission()}>Submit</Button>
                        </Grid>
                    </Grid>
                }
                onClose={(status: any) => setUserGroupPermissionDialog(false)}
                strict={false}
                show_options={false}
            />
        </Grid>
    )
}

export default AccessControl;