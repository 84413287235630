import { useEffect, useState, FunctionComponent } from 'react';
import { Grid, Avatar, TextField, Paper, IconButton } from '@mui/material';
import { Typography } from '../../MaterialUiComponents';
import certificateApi from '../../api/certificates'
import { Send, Visibility } from '@mui/icons-material';
import { Comments } from '../../types/messages';

type MessageBoxProps = {
    id: number | null,
    editMode: Boolean,
    type: 'Shop' | 'Certificate',
}

const MessageBox: FunctionComponent<MessageBoxProps> = ({ id, editMode, type }) => {
    const [allComments, setAllComments] = useState(Array<Comments>())
    const [message, setMessage] = useState("");
    useEffect(() => {
        refresh()
        //Todo: Remove this perhaps?
        const escFunction = (event: any) => {
            const keyCode = event.keyCode;
        }
        document.addEventListener('keydown', escFunction, false)
    }, [])
    const refresh = () => {
        if (id !== null) {
            if (type === "Certificate") {
                certificateApi.GetMessagesCertificateId(id).then(res => {
                    if (res.status === true) {
                        setAllComments(res.data)
                        const element = document.getElementById("message-box-container")!;
                        element.scrollTop = element.scrollHeight;
                    } else {
                        setAllComments([])
                    }
                })
            }


        } else {
            //Create Ticket
        }
    }
    const onSendMessage = () => {
        if (type === "Certificate") {
            certificateApi.AddMessage(id!, message).then(res => {
                refresh()
                setMessage("")
                const element = document.getElementById("message-box-container")!;
                element.scrollTop = element.scrollHeight;
            })
        }
    }
    return (
        <Grid >
            <Grid id="message-box-container" style={{ overflowY: 'scroll', maxHeight: '500px' }}>
                {
                    allComments.map(row => {
                        return (
                            <Grid >
                                {
                                    row.is_my_message === true ?
                                        <Grid container style={{ marginTop: '1%', justifyContent: 'end' }} >
                                            <Grid>
                                                <Typography margin={[0, 2, 0, 2]} fontWeight='bold' text={row.created_by_employee_name} />
                                                <Typography margin={[-1, 2, 0, 2]} text={row.created_date_text} />
                                            </Grid>
                                            <Avatar alt="user profile pic" src={row.created_by_employee_user_image} style={{ marginBottom: '1%' }} />
                                            <Grid xs={9}>
                                                <Paper elevation={16} style={{ backgroundColor: 'rgb(0, 132, 255)' }}>
                                                    <Typography padding={[1, 1, 1, 1]} textColor='white' text={row.message} />
                                                </Paper>
                                            </Grid>
                                            {row.seen_by_employee_name != null &&
                                                <Grid xs={12} container style={{ justifyContent: 'end' }}>
                                                    <Visibility />
                                                    <Typography text={`${row.seen_date_text} ${row.seen_by_employee_name}`} />
                                                </Grid>
                                            }
                                        </Grid>
                                        :
                                        <Grid container style={{ marginTop: '1%' }}>
                                            <Avatar alt="user profile pic" src={row.created_by_employee_user_image} style={{ marginBottom: '1%' }} />
                                            <Grid>
                                                <Typography margin={[0, 2, 0, 2]} fontWeight='bold' text={row.created_by_employee_name} />
                                                <Typography margin={[-1, 2, 0, 2]} text={row.created_date_text} />
                                            </Grid>
                                            <Grid xs={9}>
                                                <Paper elevation={16} style={{ backgroundColor: 'rgb(229,230,235)' }}>
                                                    <Typography padding={[1, 1, 1, 1]} text={row.message} />
                                                </Paper>
                                            </Grid>
                                            {row.seen_by_employee_name != null &&
                                                <Grid xs={12} container style={{ marginLeft: '10px' }}>
                                                    <Visibility />
                                                    <Typography text={`${row.seen_date_text} ${row.seen_by_employee_name}`} />
                                                </Grid>
                                            }

                                        </Grid>
                                }
                            </Grid>
                        )
                    })
                }
                <br />
            </Grid>
            {editMode === true && <Grid container xs={12}>
                <Grid xs={11}>

                    <Paper>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Aa"
                            multiline
                            maxRows={40}
                            value={message}
                            fullWidth={true}
                            onChange={(e: any) => setMessage(e.target.value)}
                        />
                    </Paper>
                </Grid>
                <Grid xs={1}>
                    <IconButton id="send-message" onClick={() => onSendMessage()}>
                        <Send style={{ height: '30px', width: '30px', marginTop: '7px', marginLeft: '3px' }} color='primary' />
                    </IconButton>
                </Grid>
            </Grid>}
        </Grid>
    );
};
export default MessageBox;