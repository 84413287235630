import { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { myPayment, AllCarRides } from '../../types/extraPayments';
import { Button, Grid, TableRow, TableCell, Autocomplete, TextField } from '@mui/material';
import { Dialog, Input, Typography, Table } from '../../MaterialUiComponents';
import NewPayment from './NewPayment';
import ExtraPaymentsAPI from '../../api/extraPayments';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import EditIcon from '@mui/icons-material/Edit';

type PaymentDialogProps = {
    payment: myPayment,
    visible: boolean,
    onClose: Function,
    viewType: "user" | "timeControl" | "supervisor" | "all"
    displaySnackbar: Function
}

const useStyles = makeStyles(theme => ({
    smallIcon: {
        cursor: 'pointer',
        height: '20px'
    },
}))


// Dialog for displaying payment line, option to go to edit mode when user and approve/reject as supervisor or time control
const PaymentDialog: FunctionComponent<PaymentDialogProps> = ({ payment, viewType, visible, onClose, displaySnackbar }) => {
    const [declineReason, setDeclineReason] = useState<string>("");
    const [editMode, setEditMode] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [toggleSelect, setToggleSelect] = useState<boolean>(false);

    //Todo: Change to regular const no need for state
    const options = ["Waiting for time control", "Declined", "Confirmed"];
    const [selectedStatus, setSelectedStatus] = useState<string>("");
    const store = useSelector((state: RootState) => state);
    const classes = useStyles();
    const user = store.user.me;
    const isTimeControl = user.user_clearence_groups.find(x => x === "Extra Payment Admin");

    const onChangePaymentStatus = () => {
        if (isTimeControl && (payment.status === "DECLINED" || payment.status === "Confirmed")) {
            if (selectedStatus === "Waiting for time control") {
                ExtraPaymentsAPI.UpdateExtraPaymentStatus(payment.id).then(res => {
                    displaySnackbar("Payment status has successfully been changed to 'Waiting for Time Control'");
                    onClose(true);
                });
            }
            else if (selectedStatus === "Declined") {
                declinePayment();
            }
            else if (selectedStatus === "Confirmed") {
                supervisorApprove();
            }
        }
    }

    // Decline Payment. Both for supervisor and time control. Validation that reason is required
    const declinePayment = () => {
        if (declineReason.length === 0) {
            setValidationMessage("You must type a reason to decline");
        }
        else {
            setValidationMessage("")
            ExtraPaymentsAPI.DeclineSlip(payment.id, declineReason).then(res => {
                if (res.status === true) {
                    setDeclineReason("");
                    displaySnackbar("Payment has successfully been declined");
                    onClose(true);
                }
            })
        }
    }
    // Approve function for supervisor approval. Display Snackbar message triggers snackbar in parent component
    const supervisorApprove = () => {
        setValidationMessage("");
        setDeclineReason("");
        ExtraPaymentsAPI.SupervisorAccepted(payment.id).then(res => {
            if (res.status === true) {
                displaySnackbar("Payment has successfully been approved");
                onClose(true);
            }
        })
    }
    // Approve function for time control approval. Display Snackbar message triggers snackbar in parent component
    const timeControlApprove = () => {
        setValidationMessage("");
        setDeclineReason("");
        ExtraPaymentsAPI.TimeControlEmployeeAccepts(payment.id).then(res => {
            if (res.status === true) {
                displaySnackbar("Payment has successfully been approved");
                onClose(true);
            }
        })
    }
    // When confirming changes to payment. Display Snackbar message triggers snackbar in parent component
    const onCloseNewPayment = () => {
        setEditMode(false);
        displaySnackbar("Payment request has been successfully changed");
        onClose(true);
    }
    const generateCarRideTableBody = (row: AllCarRides, index: number) => {
        return (
            <TableRow>
                <TableCell>{index}</TableCell>
                <TableCell>{row.from_location}</TableCell>
                <TableCell>{row.to_location}</TableCell>
                <TableCell>{row.trip_date_text}</TableCell>
                <TableCell>{row.reason}</TableCell>
                <TableCell>{(row.passengers !== null && row.passengers != undefined) && row.passengers.map(x => x.passengerName).join(', ')}</TableCell>
                {viewType === "timeControl" && <TableCell>{row.price} kr</TableCell>}
            </TableRow>
        )
    }
    return (
        <div>
            {/* {editMode === true &&
                <Dialog
                    visible={visible}
                    max_width={"xl"} title={""}
                    context={
                        <Grid container item spacing={0} marginBottom={2} marginTop={4} xs={12}>
                            <NewPayment type="edit" paymentToEdit={payment} closeNewPayment={(value: number) => onCloseNewPayment()} />
                        </Grid>
                    }
                    onClose={(status: any) => { onClose(false); setEditMode(false) }}
                    strict={false}
                    show_options={false}
                />
            } */}
            {editMode === false &&
                <Dialog
                    visible={visible}
                    max_width={"xl"} title={"Extra Payment Request"}
                    context={
                        <Grid container item justifyContent="center">
                            <Grid container item spacing={0} marginBottom={2} xs={12}>
                                <Grid item lg={4} xs={12}>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            {editMode}
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Status:"} />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row">
                                                <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.status} />
                                                {isTimeControl && (payment.status === "DECLINED" || payment.status === "Confirmed") && <EditIcon className={classes.smallIcon} onClick={() => setToggleSelect(!toggleSelect)} />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        {toggleSelect &&
                                            <Grid item style={{ width: '200px' }}>
                                                <Autocomplete
                                                    fullWidth
                                                    noOptionsText="No Status Selected"
                                                    options={options}
                                                    value={selectedStatus}
                                                    clearOnEscape
                                                    autoHighlight
                                                    onChange={(e: any, newValue: string | null) => { setSelectedStatus(newValue === null ? "" : newValue); setDeclineReason("Declined by admin"); }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Status" variant="standard" />
                                                    )} />
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        {(toggleSelect && selectedStatus) &&
                                            <Grid style={{ marginTop: '4px' }}>
                                                <Button variant="contained" color="primary" onClick={() => onChangePaymentStatus()}>Change Status</Button>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Date"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.in_date_text_short} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"In at:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.in_date_text_clock} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Out at:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.out_date_text_clock} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Owner:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.employee_name} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Duration"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.duration_text} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Payment types:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.payment_types.toString()} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Description:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.description} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Created:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.created_date_text} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"WO / Item No:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.wo_number + " / " + payment.item_no} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Supervisor:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.supervisor_accepted_date !== null ? payment.supervisor_name + " - " + payment.supervisor_accepted_date_text : "N/A"} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Time control:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.time_controller_employee_name !== null ? payment.time_controller_employee_name + " - " + payment.time_controller_accepted_date_text : "N/A"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {payment.status === "DECLINED" &&
                                    <Grid item container justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Typography variant={'body1'} color="error" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Declined reason:"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={'body1'} color="error" align={'center'} text={payment.declined_text ? payment.declined_text : ""} />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            {payment.all_car_rides.length > 0 && <Grid xs={12} style={{ marginBottom: "20px" }}>
                                <Typography text={"Car rides"} align='center' variant='h3' fontWeight='bolder' textColor='black' margin={[0, 0, 2, 0]} />
                                <Table
                                    headers={["From", "To", "Time", "Reason", "Passengers", ""]}
                                    rows={payment.all_car_rides}
                                    generateBody={(row: any, index: number) => generateCarRideTableBody(row, index)}
                                    defaultRowsPerPage={5}
                                />
                            </Grid>}
                            {/* Only display edit button when approval is pending  */}
                            {/* {viewType === "user" && editMode === false && payment.status !== "DECLINED" && payment.status !== "Confirmed" &&
                                <Grid container item justifyContent="center" spacing={0} marginBottom={0} xs={12}>
                                    <Grid item>
                                        <Button variant="contained" color="primary" size="medium" onClick={() => setEditMode(true)} >Edit request</Button>   Will be implementer later 
                                    </Grid>
                                </Grid>
                            } */}
                            {/* Display declined reason when declined */}
                            {payment.status === "Declined" &&
                                <Grid container item justifyContent="center" spacing={0} marginBottom={5} xs={12}>
                                    <Grid item>
                                        <Typography variant={'body1'} color="error" fontWeight="bold" align={'center'} margin={[0, 2, 0, 0]} text={"Declined:"} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={'body1'} color="textPrimary" align={'center'} text={payment.declined_text} />
                                    </Grid>
                                </Grid>
                            }
                            {/* Buttons to approve reject as time control  */}
                            {viewType === "timeControl" && payment.status === "Waiting for Time control" &&
                                <Grid xs={12}>
                                    <Grid container item justifyContent="center" spacing={0} marginBottom={0} xs={12}>
                                        <Grid item marginRight={2}>
                                            <Button variant="contained" color="primary" size="medium" onClick={() => timeControlApprove()} >Pay out extra payment ticket</Button>
                                        </Grid>
                                        <Grid item marginRight={2}>
                                            <Button variant="contained" color="error" size="medium" onClick={() => declinePayment()} >Decline payment</Button>
                                        </Grid>

                                    </Grid>
                                    <Grid container item justifyContent="center" spacing={0} marginBottom={5} xs={12}>
                                        <Grid item lg={6} xs={10}>
                                            <Input
                                                multiline={true}
                                                label_text={"Reason to decline"}
                                                value={declineReason}
                                                help_text=""
                                                onTextChange={(value: string) => setDeclineReason(value)}
                                                type="text"
                                                width={100}
                                                textAlign="left"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {/* Buttons to approve reject as supervisor  */}
                            {viewType === "supervisor" && payment.status === "Waiting for supervisor" &&
                                <Grid xs={12}>
                                    <Grid container item justifyContent="center" spacing={0} marginBottom={2} xs={12}>
                                        <Grid item marginRight={2}>
                                            <Button variant="contained" color="primary" size="medium" onClick={() => supervisorApprove()} >Confirm extra payment</Button>
                                        </Grid>
                                        <Grid item marginRight={2}>
                                            <Button variant="contained" color="error" size="medium" onClick={() => declinePayment()} >Decline payment</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container item justifyContent="center" spacing={0} marginBottom={2} xs={12}>
                                        <Grid item lg={6} xs={10}>
                                            <Input
                                                multiline={true}
                                                label_text={"Reason to decline"}
                                                value={declineReason}
                                                help_text=""
                                                onTextChange={(value: string) => setDeclineReason(value)}
                                                type="text"
                                                width={100}
                                                textAlign="left"
                                            />
                                        </Grid>
                                    </Grid>
                                    {validationMessage.length > 0 &&
                                        <Grid container item justifyContent="center" marginBottom={0} spacing={0} xs={12}>
                                            <Grid item lg={6} xs={10}>
                                                <Typography variant={'body1'} color="error" fontWeight="bold" align={'center'} text={validationMessage} />
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                    }
                    onClose={(status: any) => { onClose(false) }}
                    strict={false}
                    show_options={false}
                />
            }
        </div>
    );
};
export default PaymentDialog;