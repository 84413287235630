import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Grid, Paper, Button, TableRow, TableCell, IconButton, Divider, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, Input, Select, SelectMultiple, Table, Typography, Snackbar} from '../../MaterialUiComponents';
import { EmployeeSearch } from '../../components';
import { postPayment, paymentType, myPayment, CarRideLocations, CarRidePrices, CreateExtraPaymentCarRides, CreateExtraPaymentObj, CarRidePassenger } from '../../types/extraPayments';
import { addDays } from 'date-fns';
import ExtraPaymentsAPI from '../../api/extraPayments';
import { User } from '../../types/user';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { Delete } from '@mui/icons-material';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover,
    },
    formContainer: {
        width: '90%',
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    },
    newPaymentRow: {
        padding: theme.spacing(1, 2, 1, 2)
    },
}));

type NewPaymentProps = {
    closeNewPayment: Function,
    type: "edit" | "new",
    paymentToEdit?: myPayment,
}

const NewPayment: FunctionComponent<NewPaymentProps> = ({ closeNewPayment, type, paymentToEdit }) => {
    const [supervisor, setSupervisor] = useState<User>();
    const [supervisorId, setSupervisorId] = useState<number>(0);
    const [clearEmployee, setClearEmployee] = useState<boolean>(false);
    const [date, setDate] = useState<Date | null>(null);
    const [inDate, setInDate] = useState<Date | null>(null);
    const [outDate, setOutDate] = useState<Date | null>(null);

    const [overnight, setOvernight] = useState<boolean>(false);
    const [updateOvernight, setUpdateOvernight] = useState<boolean>(false);
    const [selectedPaymentType, setSelectedPaymentType] = useState<string[]>([]);
    const [selectedPaymentTypeIds, setSelectedPaymentTypeIds] = useState<number[]>([28]);
    const [selectedWO, setSelectedWO] = useState<string>();
    const [selectedItemNo, setSelectedItemNo] = useState<string>();
    const [description, setDescription] = useState<string>("");
    const [paymentTypes, setPaymentTypes] = useState<string[]>([]);
    const [paymentTypesObjects, setPaymentTypesObjects] = useState<paymentType[]>([]);
    const [woPaymentsIds, setWoPaymentsIds] = useState<number[]>([]);
    const [paymentRows, setPaymentRows] = useState<postPayment[]>([]);
    const [dirtyDisplay, setDirtyDisplay] = useState<boolean>(false);
    const [validationText, setValidationText] = useState<string>("");
    const [paymentToEditId, setPaymentToEditId] = useState<number>(0);
    const [selectedRowType, setSelectedRowType] = useState("");
    const [carTravelComponentOpen, setCarTravelComponentOpen] = useState(false);
    const [companyCarTravelWithPassengerSelected, setCompanyCarTravelWithPassengerSelected] = useState(false);
    const [passengers, setPassengers] = useState<User[]>([]);
    const [clearPassenger, setClearPassenger] = useState<boolean>(false);

    //Collection variables for all valid, registered routes as well as valid destinations for a selected origin location
    const [validatedDestinations, setValidatedDestinations] = useState<CarRideLocations[]>([]); //All destination points available for a selected origin location
    const [validTrips, setValidTrips] = useState<CarRidePrices[]>([]); //All registered routes which are marked as visible

    //Variables for new Trip
    const [tripValidationText, setTripValidationText] = useState<string>(""); //Variable for validation message when adding a new trip
    //Todo: can we remove this state variable? It's not used anywhere
    const [, setSelectedTripId] = useState<CreateExtraPaymentCarRides>(); //ID used to identify a specific registered route based on input - When user chooses origin and destination the ID of that particular route is identified and set in this variable
    const [tripLocations, setTripLocations] = useState<CarRideLocations[]>([]); //Collection of possible locations to choose from when adding a new trip
    const [tripIds, setTripsIds] = useState<CreateExtraPaymentCarRides[]>([]); ////lean>(false); //Controller flag for UI - Opens/Closes input form for new trips
    const [addTrip, setAddTrip] = useState<boolean>(false); //Controller flag for UI - Opens/Closes input form for new trips
    //Object variables which make up the new Trip entry in to the array
    const [tripDestination, setTripDestination] = useState<number>(-1); //Destination of trip - The ID of the location is used in the final object
    const [tripOrigin, setTripOrigin] = useState<number>(-1); //Origin of trip - The ID of the location is used in the final object
    const [tripReason, setTripReason] = useState<string>(""); //Reasoning for the trip being registered - To be continued in backend!  
    const [tripTime, setTripTime] = useState<Date | null>(null); //Starting time of the trip - To be continued in backend!  

    //Used to open Dialog box when user clicks 'Number of Trips' column
    const [, setSelectedPaymentTripInfo] = useState<CreateExtraPaymentCarRides[]>([]);

    const [snackbarMessage, setSnackbarMessage] = useState<{message:string, status: boolean}>({message:"", status: false});

    const store = useSelector((state: RootState) => state);
    const userId = store.user.me.user_id;
    const allUsers = store.employees.all_employees;

    // This runs on inital load and when receiving paymentToEdit props.
    // Sets input values for edit view and gets all information needed, supervisors and payment types
    useEffect(() => {
        if (type === "edit" && paymentToEdit !== undefined) {
            let tempUser = allUsers.find(x => x.Id === paymentToEdit.supervisor_employee_id);
            if (tempUser !== undefined) {
                onSelectedEmployeeRow(tempUser);
            }
            setDate(paymentToEdit.given_date);
            setInDate(paymentToEdit.in_date);
            setOutDate(paymentToEdit.out_date);
            setSelectedWO(paymentToEdit.wo_number.toString());
            setSelectedItemNo(paymentToEdit.item_no.toString());
            setDescription(paymentToEdit.description);
            setPaymentToEditId(paymentToEdit.id);
            const temp = [...tripIds, ...paymentToEdit.all_car_rides.map(row => {
                return {
                    car_ride_price_id: row.extra_payment_price_id,
                    reason: row.reason,
                    date: row.trip_date.toString(),
                    passengers: row.passengers
                };
            })];
            setTripsIds(temp);
            setSelectedPaymentTripInfo(temp);
        }
        ExtraPaymentsAPI.GetAllSlippsPayments().then(res => {
            let tempPaymentTypes: string[] = [];
            res._payments.map(paymentType => {
                tempPaymentTypes.push(paymentType.type_name)
            });
            setPaymentTypesObjects(res._payments);
            setPaymentTypes(tempPaymentTypes);
            setWoPaymentsIds(res.woPaymentsIds);
        });
        ExtraPaymentsAPI.GetAllCarRideLocation().then(res => {
            setTripLocations(res.data.filter(el => el.visible));
        });
        ExtraPaymentsAPI.GetAllCarRidePrices().then(res => {
            setValidTrips(res.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentToEdit]);

    // updateOvernight boolean is used to trigger this so that dates are corrected based on if time goes over midnight.
    // also triggered by paymentToEditId because that makes sure this runs after all information from prop payment to edit is received and state updated 
    useEffect(() => {
        if (outDate !== null && inDate !== null) {
            const tempInDate = new Date(inDate);
            const tempOutDate = new Date(outDate);
            
            if (tempOutDate.getHours() < tempInDate.getHours()) {
                if (addDays(tempInDate, 1).getUTCDate() !== tempOutDate.getUTCDate()) {
                    fixOvernightDate(tempOutDate, 1);
                }
                setOvernight(true);
            }
            else if (tempInDate.getHours() < tempOutDate.getHours()) {
                if (addDays(tempInDate, 1).getUTCDate() === tempOutDate.getUTCDate()) {
                    fixOvernightDate(tempOutDate, -1);
                }
                setOvernight(false);
            }
        }
    }, [updateOvernight, paymentToEditId]);
    

    // Runs when paymentobjects are recived and also after props are received to make sure everything is updated correctly when editing a payment
    useEffect(() => {
        updateTypeIds().then(res => {
            setSelectedPaymentTypeIds(res);
        });
        let tempTypes: string[] = [];
        if (paymentToEdit !== undefined) {
            paymentToEdit.payment_types.toString().split(',').map((tempPay: string) => {
                if (tempPay !== "") {
                    tempTypes.push(tempPay);
                }
            })
            if (tempTypes !== undefined) {
                onSelectPaymentType(tempTypes);
            }
        }
    }, [paymentTypesObjects, woPaymentsIds]);

    //Helper function which filters out unavailable destinations for a selected origin
    //Prevents invalid trips and is more aesthetically pleasing than a drop-down and more intuitive than a banner
    useEffect(() => {
        if (tripOrigin !== -1) {

            //Filters out all routes which do not originate in the chosen locations
            let tripsOriginatingFrom = validTrips.filter(el => el.visible && el.from_location_id === tripOrigin);

            //Create return array
            let newValidDestinations: CarRideLocations[] = [];

            //Runs through all valid routes and finds each location with a corresponding ID to the destinationID of particular available, valid route
            tripsOriginatingFrom.forEach(el => {

                //Finds if the location collection has an entry with the same ID as the to_location_id of the element being iterated through
                let singleValidDestination = tripLocations.find(elx => elx.car_ride_location_id === el.to_location_id);

                //If it is a valid object it's added to the return array
                if (singleValidDestination !== undefined) {
                    newValidDestinations.push(singleValidDestination);
                }
            });

            setValidatedDestinations(newValidDestinations);
        } else {
            setValidatedDestinations([]);
        }
    }, [tripOrigin]);

    const fixOvernightDate = (tempDate: Date, value: number) => {
        setOutDate(addDays(tempDate, value));
    }
    // Select employee function with validation so you cannot select your self as supervisor
    const onSelectedEmployeeRow = (employee: User) => {
        setClearEmployee(false);
        if (employee !== null && employee.user_id === userId) {
            setValidationText("You cannot select yourself as supervisor");
            setClearEmployee(true);
        }
        else if (employee !== null) {
            if (validationText === "You cannot select yourself as supervisor") {
                setValidationText("");
            }
            setSupervisor(employee);
            setSupervisorId(employee.user_id);
        }
    }

    const onAddPassenger = (employee: User) => {
        setClearPassenger(false);
        if (employee !== null) {

            passengers.push(employee);
            setClearPassenger(true);
        }
    }

    // When selecting payments. Changes boolean for dirtyDisplay when two extra input fields need to be displayed
    const onSelectPaymentType = (paymentType: string[]) => {
        let tempDirtyDisplay = false;
        let tempIds: number[] = [];
        if (paymentType.includes("Company Car Travel with Passenger") || paymentType.includes("Car Travel Own Car")) {
            setCarTravelComponentOpen(true);
            if(paymentType.includes("Company Car Travel with Passenger"))
            {
                setCompanyCarTravelWithPassengerSelected(true)
            }
            else
            {
                setCompanyCarTravelWithPassengerSelected(false)
            }
        } else {
            setCarTravelComponentOpen(false);
            setCompanyCarTravelWithPassengerSelected(false)
        }
        paymentType.map(type => {
            let tempPaymentObject = paymentTypesObjects.find(x => x.type_name === type)
            if (tempPaymentObject !== undefined) {
                tempIds.push(tempPaymentObject?.id)
                if (woPaymentsIds.includes(tempPaymentObject.id) === true) {
                    tempDirtyDisplay = true
                }
            }
        });
        setSelectedPaymentTypeIds(tempIds);
        if (tempDirtyDisplay) {
            setDirtyDisplay(true);
        }
        else {
            setDirtyDisplay(false);
        }
        setSelectedPaymentType(paymentType);
    }

    // For selecting correct payment types when editing. Async function so when called (in useEffect) it waits before continuing and setting state values
    const updateTypeIds = async () => {
        let tempIds: number[] = [];
        tempIds = await Promise.all(
            selectedPaymentType.map(type => {
                let tempPaymentObject = paymentTypesObjects.find(x => x.type_name === type);
                if (tempPaymentObject !== undefined) {
                    return tempPaymentObject.id;
                }
                else { return 0; }
            }));
        setSelectedPaymentTypeIds(tempIds);
        return tempIds;
    }
     //For new requests, validating if line can be added, if all fields have value
     const onAddRequest = () => {
        const tempPaymentIds: string[] = [];
        woPaymentsIds.map(payment => {
            tempPaymentIds.push(payment.toString())
        });

        if (dirtyDisplay === true && (selectedWO === undefined || selectedItemNo === undefined)) {
            setValidationText("You must fill out all fields to add line");
        }

        else if (supervisorId === undefined || date === null || inDate === null || outDate === null || selectedPaymentType.length === 0 || description === "") {
            setValidationText("You must fill out all fields to add line");
        }
        else if (selectedWO !== undefined && dirtyDisplay === true) {
            ExtraPaymentsAPI.SearchPacketByFlightNumber(selectedWO).then(res => {
                if (res.length > 0) {
                    let type = res[0].Name.substring(0, 2);
                    if (type !== 'RO') {
                        type = 'WO';
                    }
                    setSelectedRowType(type);
                    confirmAddRequest();
                }
                else {
                    setValidationText("Please provide correct Wo/Ro number");
                }
            });
        }
        else {
            confirmAddRequest()
        }
    }

     // For validation rules when saving changes when in edit mode. Validation to make sure all fields have values
     const onSaveChanges = () => {
        if (dirtyDisplay === true && (selectedWO === undefined || selectedItemNo === undefined)) {
            setValidationText("You must fill out all fields to add line");
        }

        else if (supervisorId === undefined || date === null || inDate === null || outDate === null || selectedPaymentType.length === 0 || description === "") {
            setValidationText("You must fill out all fields to add line");
        }
        else if (selectedWO !== undefined && dirtyDisplay === true) {
            ExtraPaymentsAPI.SearchPacketByFlightNumber(selectedWO).then(res => {
                if (res.length > 0) {
                    let type = res[0].Name.substring(0, 2);
                    if (type !== 'RO') {
                        type = 'WO';
                    }
                    saveChanges();
                }
                else {
                    setValidationText("Please provide correct Wo/Ro number");
                }
            });
        }
        else {
            saveChanges();
        }
    }
    // For saving changes when in edit mode
    const saveChanges = () => {
        if (inDate !== null && outDate !== null && date !== null) {
            let tempOutDate: Date = outDate;
            if (overnight === true) {
                tempOutDate = addDays(outDate, 1);
            }
            if (paymentToEdit !== undefined) {
                let tempPayment: postPayment = {
                    supervisor_employee_id: supervisorId,
                    supervisor_name: supervisor?.name,
                    inDate: inDate,
                    outDate: tempOutDate,
                    selectPaymentTypes: selectedPaymentTypeIds,
                    description: description,
                    date: date,
                    wo: selectedWO,
                    item_no: selectedItemNo,
                    employee_id: userId,
                    selectPaymentTypesName: selectedPaymentType,
                    id: paymentToEditId,
                    selected_car_rides: tripIds
                }
                ExtraPaymentsAPI.UpdateExistingRow(tempPayment).then(res => {
                    if (res.status) {
                        closeNewPayment();
                    }
                });
            }
        }
    }
    // For confirming new line to payment when creating new request
    const confirmAddRequest = () => {
        setCarTravelComponentOpen(false);
        setCompanyCarTravelWithPassengerSelected(false);
        setClearEmployee(true);
        setDate(null);
        setInDate(null);
        setOutDate(null);
        setSelectedPaymentTypeIds([]);
        setSelectedPaymentType([]);
        setDescription("");
        setSelectedWO(undefined);
        setSelectedItemNo(undefined);
        setValidationText("");
        setDirtyDisplay(false);
        clearTripInfo();
        const tempPayments: postPayment[] = [...paymentRows];
        if (inDate !== null && outDate !== null && date !== null) {
            let tempInDate: Date = inDate;
            let tempOutDate: Date = outDate;

            // setting in date as the year month and day of the selected starting date
            tempInDate.setFullYear(date.getFullYear());
            tempInDate.setMonth(date.getMonth());
            tempInDate.setDate(date.getDate());

            // setting out date as the year month and day of the selected starting date
            tempOutDate.setFullYear(date.getFullYear());
            tempOutDate.setMonth(date.getMonth());
            tempOutDate.setDate(date.getDate());

            if (overnight === true) {
                tempOutDate = addDays(tempOutDate, 1);
            }
            tempPayments.push(
                {
                    supervisor_employee_id: supervisorId,
                    supervisor_name: supervisor?.name,
                    inDate: inDate,
                    in_date_string: inDate.toString(),
                    outDate: tempOutDate,
                    out_date_string: tempOutDate.toString(),
                    selectPaymentTypes: selectedPaymentTypeIds,
                    description: description,
                    date: date,
                    wo: selectedWO,
                    item_no: selectedItemNo,
                    employee_id: userId,
                    selectPaymentTypesName: selectedPaymentType,
                    selected_car_rides: tripIds,

                });
            setPaymentRows(tempPayments);
        }
    }

    // When the user is done adding new requests, this function is called to submit all requests
    useEffect(() => {
        if (paymentRows.length > 0) {
            onSubmit();
        }
    }, [paymentRows]);

    // For submitting the request, including all payment lines when creating new request
    const onSubmit = () => {
        if (paymentRows.length > 0) {
            const payments = Array<CreateExtraPaymentObj>();

            paymentRows.map((row, index) => {
                const tempPayment = {
                    in_date_string: row.in_date_string!,
                    out_date_string: row.out_date_string!,
                    supervisor_employee_id: row.supervisor_employee_id,
                    wo_number: row.wo,
                    item_no: row.item_no,
                    description: row.description,
                    selected_payment_types: row.selectPaymentTypes,
                    selected_car_rides: row.selected_car_rides,
                    row_type: selectedRowType,

                } as CreateExtraPaymentObj;
                payments.push(tempPayment);
                //Iterate through payment rows and we are sending object each time to the api not array of objects. 
                if (index === paymentRows.length - 1) {
                    ExtraPaymentsAPI.CreateNewExtraPayments(payments).then(res => {
                        if(res.status){
                            const message = "Your request for extra payment has been submitted";
                            const status = true;
                            setSnackbarMessage({message, status});
                        }
                        else{
                            const message = res.message;
                            const status = false;
                            setSnackbarMessage({message, status});
                        }
                        setPaymentRows([]);
                    });
                }
            })
        }
        else {
            setValidationText("You have to add at least one request to submit");
        }
    }

    // For time change. Boolean updated to trigger useEffect function that handles date fixes based on in and out times
    const onDateChange = (date: Date, type: "in" | "out") => {
        if (type === "in") {
            setInDate(date);
        }
        else {
            setOutDate(date);
        }
        setUpdateOvernight(!updateOvernight);
    }

    //Generation function for tables displaying Trip Information
    const generateTripTableBody = (row: CreateExtraPaymentCarRides, index: number, deletable: boolean) => {
        return (
            <TableRow>
                <TableCell>{(validTrips.find(el => el.car_ride_price_id === row.car_ride_price_id)?.from_location) || "Not Found"}</TableCell>
                <TableCell>{(validTrips.find(el => el.car_ride_price_id === row.car_ride_price_id)?.to_location) || "Not Found"}</TableCell>
                <TableCell>{DateTime.fromJSDate(new Date(row.date)).toFormat('dd.LL.yyyy HH:mm')}</TableCell>
                <TableCell>{row.reason}</TableCell>
                <TableCell>{row.passengers.map(x => x.passengerName).join(', ')}</TableCell>
                {deletable && //Deletable is a flag to prevent user from deleting trips from entry when he clicks on 'number of trips' attribute to see trip details in overview
                    <TableCell className={classes.newPaymentRow}>
                        <IconButton onClick={() => setTripsIds(tripIds.length === 1 ? [] : [...tripIds].splice((index - 1), 1))}> {/* Simple but effective 'delete' function, filters out the unwanted entry */}
                            <Delete color="error" />
                        </IconButton>
                    </TableCell>
                }
            </TableRow>
        )
    }

    //Function called when user selects a location as either origin or destination while adding a new trip
    const onSelectedLocation = (location: string, origin: boolean) => {
        let locationObject = tripLocations.find(x => x.location === location); //Finds the object associated with the location name
        origin && locationObject && setTripOrigin(locationObject.car_ride_location_id); //If the origin flag is set it adds the location ID to the origin variable of the state
        !origin && locationObject && setTripDestination(locationObject.car_ride_location_id); //If the origin flag is not set it adds the location ID to the destination variable of the state
    }

    //Function to confirm new trip. Adds trip to trip collection which gets sent alongside other payments info
    const onTripConfirm = () => {
        if (validateNewTrip()) {
            if (tripDestination !== -1) {
                //Searches for the registered route
                let registeredRoute = validTrips.find(el => el.from_location_id === tripOrigin && el.to_location_id === tripDestination);
                //If it finds it it sets the dedicated state variable of the trip
                if (registeredRoute !== undefined) {
                    const obj = {
                        car_ride_price_id: registeredRoute!.car_ride_price_id,
                        reason: tripReason,
                        date: tripTime?.toString(),
                        passengers: passengers.map(x => ({
                            passengerName: x.employee_name,
                            passengerId: x.user_id
                          } as CarRidePassenger))
                    } as CreateExtraPaymentCarRides;
                    setSelectedTripId(obj);
                    const temp = tripIds;
                    temp.push(obj); 
                    setTripReason(""); 
                    setTripTime(null);
                }
                clearTripInfo(); //Clear info and reset flags
            }
        }
    }

    //Validation function for new Trip - Checks if both fields are filled, if origin and destination are the same and whether or not the selected trip is a registered and valid route
    const validateNewTrip = (): boolean => {

        if (tripOrigin === -1 || tripDestination === -1) {
            setTripValidationText("You must fill out all fields to add trip");
            return false;
        } else if (tripOrigin === tripDestination) {
            setTripValidationText("Trip origin and destination cannot be the same");
            return false;
        } else if (validatedDestinations.find(el => el.car_ride_location_id === tripDestination) === undefined) { //Uses the set tripId to see if the chosen route is registered and valid
            setTripValidationText("Trip between locations has not been registered - Please choose other options");
            return false;
        } else if (tripReason === "") {
            setTripValidationText("You must enter a reason for trip");
            return false;
        } else if (tripTime === null) {
            setTripValidationText("You must enter time of trip");
            return false;
        }
        else if (companyCarTravelWithPassengerSelected && passengers && passengers.length === 0) {
            setTripValidationText("You must add passengers to trip");
            return false;
        }
        else {
            return true;
        }
    }

    //Flag & Info cleaner for trip-related state variables. Resets variables if user confirms new trip or cancels
    const clearTripInfo = () => {
        setTripOrigin(-1);
        setTripDestination(-1);
        setAddTrip(false);
        setTripValidationText("");
        setPassengers([]);
        setSelectedTripId({} as CreateExtraPaymentCarRides);
    }

    function handleDeletePassengerClick(emplId: number): void {
        const updatedPassengers = passengers.filter(x => x.user_id !== emplId);
        setPassengers(updatedPassengers);
    }

    const classes = useStyles();
    return (
        <Grid container>
            <Grid container item justifyContent="center">
                <Paper variant="elevation" elevation={4} className={classes.formContainer}>
                    <Grid container item justifyContent="center">
                        <Typography variant={'h1'} align={'center'} margin={[0, 0, 2, 0]} text={type === "new" ? "Create new extra payment" : "Edit payment request"} />
                    </Grid>
                    <Grid justifyContent="space-evenly" container item spacing={0} marginBottom={5} xs={12}>
                        <Grid item xs={4}>
                            <EmployeeSearch defaultEmployee={type === "edit" ? paymentToEdit?.supervisor_name : ""} absolute fullUser required reset={clearEmployee} place_holder={"Supervisor name"} onSelectedEemployee={(employee: User) => onSelectedEmployeeRow(employee)} />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker disableFuture type="Date" selected={date} label="Choose a starting date *" onDateChange={(date: Date) => { setDate(date) }} />
                        </Grid>
                    </Grid>
                    <Grid justifyContent="space-evenly" container item spacing={0} marginBottom={4} xs={12}>
                        <Grid item xs={4}>
                            <SelectMultiple
                                options={paymentTypes}
                                required
                                onSelectedItem={(e: any) => onSelectPaymentType(e.target.value)}
                                selectedItemsString={selectedPaymentType}
                                width={100}
                                widthPercentage
                                filter_by_text={"Choose Payment Type"}
                            />
                            <Grid justifyContent="space-evenly" container item spacing={0} marginBottom={0} xs={12}>
                                <Typography variant={'caption'} fontWeight="bold" color="primary" align={'center'} text={selectedPaymentType.toString().split(',').join(" || ")} />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Input
                                required
                                multiline={true}
                                label_text={"Description"}
                                value={description}
                                help_text=""
                                onTextChange={(value: string) => setDescription(value)}
                                type="text"
                                width={100}
                                textAlign="left"
                            />
                        </Grid>
                    </Grid>
                    <Grid justifyContent="space-evenly" container item spacing={0} marginBottom={4} xs={12}>
                        <Grid item xs={4}>
                            <DatePicker type="Time" selected={inDate} label="Time started *" onDateChange={(date: Date) => onDateChange(date, "in")} />
                        </Grid>
                        {overnight === true &&
                            <Grid item position="absolute">
                                <Typography variant={'body1'} align={'center'} color="error" fontWeight="bold" margin={[0, 0, 2, 0]} text={"Overnight"} />
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <DatePicker type="Time" selected={outDate} label="Time ended *" onDateChange={(date: Date) => onDateChange(date, "out")} />
                        </Grid>
                    </Grid>
                    {dirtyDisplay &&
                        <Grid justifyContent="space-evenly" container item spacing={0} marginBottom={4} xs={12}>
                            <Grid item xs={4}>
                                <Input
                                    multiline={false}
                                    required
                                    label_text={"Project WO/RO NO"}
                                    value={selectedWO}
                                    help_text=""
                                    onTextChange={(value: string) => setSelectedWO(value)}
                                    type="number"
                                    width={100}
                                    textAlign="center"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Input
                                    required
                                    multiline={false}
                                    label_text={"ITEM NO"}
                                    value={selectedItemNo}
                                    help_text=""
                                    onTextChange={(value: string) => setSelectedItemNo(value)}
                                    type="number"
                                    width={100}
                                    textAlign="center"
                                />
                            </Grid>
                        </Grid>
                    }
                    {
                        tripIds.length !== 0 &&
                        <Grid xs={8} justifyContent="center" margin="auto">
                            <Typography variant="h2" align={'center'} margin={[0, 0, 2, 0]} text={"Trips"} />
                            <Table
                                headers={["Origin", "Destination", "Time of Trip", "Reason", "Passengers", ""]}
                                rows={tripIds}
                                generateBody={(row: any, index: number) => generateTripTableBody(row, index, true)}
                                pagination={false}
                                skipIndexCol={true}
                                emptyTableText="No lines have been added"
                                displayEmptyText
                            />
                        </Grid>

                    }
                    {
                        addTrip &&
                        <>
                            <Divider variant="middle" sx={{ marginTop: 6, marginBottom: 3 }} />

                            <Grid justifyContent="space-evenly" container xs={12}>
                                <Grid item xs={4} >
                                    <Grid>
                                        <Select
                                            options={tripLocations.map(el => el.location)}
                                            required
                                            onSelectedItem={(e: any) => onSelectedLocation(e.target.value, true)}
                                            width={100}
                                            widthPercentage
                                            filter_by_text={"Choose Origin of Trip"}
                                        />
                                    </Grid>
                                    <Grid>
                                        <DatePicker
                                            type="Time"
                                            selected={tripTime}
                                            label="Time started*"
                                            onDateChange={(date: Date) => setTripTime(date)}
                                        />
                                    </Grid>
                                    {companyCarTravelWithPassengerSelected === true &&
                                    <Grid justifyContent="left" >

                                        <EmployeeSearch absolute fullUser required filterByIds={passengers.map(x => x.Id)} 
                                                        reset={clearPassenger} 
                                                        place_holder={"Passenger name"} 
                                                        onSelectedEemployee={(employee: User) => onAddPassenger(employee)} />
                                        <List>
                                            {passengers.map(emp => (
                                                <ListItem>
                                                    <ListItemAvatar >
                                                        <Avatar
                                                            style={{ height: "20px", width: "20px" }}
                                                            src={emp.user_image} />
                                                    </ListItemAvatar>
                                                    <ListItemText>{emp.employee_name}</ListItemText>
                                                    <IconButton onClick={() => handleDeletePassengerClick(emp.Id)}> 
                                                        <Delete color="error" />
                                                    </IconButton>
                                                </ListItem>
                                            ))}
                                        </List> 
                                    </Grid>
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid>
                                        <Select
                                            options={validatedDestinations.map(el => el.location)}
                                            required
                                            onSelectedItem={(e: any) => onSelectedLocation(e.target.value, false)}
                                            width={100}
                                            widthPercentage
                                            filter_by_text={"Choose Destination of Trip"}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Input
                                            required
                                            multiline={false}
                                            label_text={"Reason for trip"}
                                            value={tripReason}
                                            help_text=""
                                            onTextChange={(value: string) => setTripReason(value)}
                                            type="text"
                                            width={100}
                                            textAlign="center"
                                        />
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </>
                    }
                    {
                        addTrip ?
                            <Grid container xs={12} justifyContent="center" marginBottom={3} marginTop={3}>
                                <Grid justifyContent="center" container item marginBottom={1} xs={12}>
                                    <Typography variant={'body1'} fontWeight="bold" color="error" align={'center'} text={tripValidationText} />
                                </Grid>
                                <Grid justifyContent="center" container xs={12}>
                                    <Grid item marginRight={3}>
                                        <Button color="success" variant='contained' onClick={() => onTripConfirm()}> Confirm Trip</Button>
                                    </Grid>
                                    <Grid item marginLeft={1}>
                                        <Button color="error" variant='contained' onClick={() => clearTripInfo()}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <Grid justifyContent="center" container xs={12} marginBottom={3} marginTop={3}>
                                <Grid item>
                                    {carTravelComponentOpen === true && <Button color="info" variant='contained' onClick={() => setAddTrip(true)}> Add car trip</Button>}
                                </Grid>
                            </Grid>
                    }
                    {type === "new" &&
                        <Grid>
                            <Grid justifyContent="center" container item spacing={8} marginBottom={6} xs={12}>
                                <Grid item>
                                    <Button onClick={() => onAddRequest()} variant="contained" color="secondary"> Submit request </Button>
                                </Grid>
                            </Grid>
                            <Grid justifyContent="center" container item spacing={8} marginBottom={4} xs={12}>
                                <Typography variant={'body1'} fontWeight="bold" color="error" align={'center'} margin={[3, 0, 0, 8]} text={validationText} />
                            </Grid>
                        </Grid>
                    }
                    {type === "edit" &&
                        <Grid>
                            <Grid justifyContent="center" container item spacing={8} marginBottom={6} xs={12}>
                                <Grid item>
                                    <Button onClick={() => onSaveChanges()} variant="contained" color="primary"> Save changes</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => { closeNewPayment() }} variant="contained" color="secondary"> Cancel </Button>
                                </Grid>
                            </Grid>
                            <Grid justifyContent="center" container item spacing={8} marginBottom={4} xs={12}>
                                <Typography variant={'body1'} fontWeight="bold" color="error" align={'center'} margin={[6, 0, 0, 0]} text={validationText} />
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </Grid>
            {(snackbarMessage.status===true && snackbarMessage.message.length > 0) && 
                <Snackbar 
                    onSnackBarClose={() => setSnackbarMessage({message:"", status:false})} 
                    open={snackbarMessage.status} 
                    message={snackbarMessage.message} 
                    severity='success' 
                    autoHide={true} 
                    horizontalPosition='right' 
                />
            }
        </Grid>
    );
};
export default NewPayment;