import { AddCarRidePrice, CarRideLocations, CarRidePrices, DeleteCarRidePrice, UpdateCarRidePrice } from "../../types/extraPayments";
import { Button, Divider, Grid, IconButton, Paper, Switch } from "@mui/material";
import { Dialog, Input, Select, Typography } from "../../MaterialUiComponents";
import { useEffect, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import ExtraPaymentsAPI from "../../api/extraPayments";
import TicketListItem from "../../components/TicketList/TicketListItem";

const Routes = () => {

    //Collection variables - Holds all prices registered and all locations available
    const [allLocations, setAllLocations] = useState<CarRideLocations[]>([]);
    const [allPrices, setAllPrices] = useState<CarRidePrices[]>([]);

    //Variables for Edit/Delete functionality
    const [routeToDeleteReason, setRouteToDeleteReason] = useState<string>(""); //Reason for deletion - Added to DeleteObject when process is finalized
    const [routeToDelete, setRouteToDelete] = useState<number>(-1); //ID of Route to delete - Used to determine what route to delete and remove from active list
    const [routeToEdit, setRouteToEdit] = useState<CarRidePrices | null>(null); //Object representation of route to be edited - Objects from AllPrices are injected into tableRow as they're being mapped and set in this variable if user wants to edit route

    //Object variables for registration/editing of a route
    const [price, setPrice] = useState<number>(-1); //Price of route
    const [from, setFrom] = useState<number>(-1); //ID of origin location
    const [to, setTo] = useState<number>(-1); //ID of destination location

    //Visual flags and variables for component display
    const [validationString, setValidationString] = useState<string>(""); //Shows validation errors if they happen
    const [fromString, setFromString] = useState<string>(""); //Visual element for selected 'From' string - Edit functionality caused visual representation to lock on null if edit object wasn't selected
    const [toString, setToString] = useState<string>(""); //Visual element for selected 'To' string - Edit functionality caused visual representation to lock on null if edit object wasn't selected
    const [visible, setVisible] = useState<boolean>(true); //Control flag for history - Used to determine wheter to show visible prices or the 'history' 

    //Runs when component mounts - Fetches all locations and prices
    useEffect(() => {
        refresh();
    }, []);

    //When object is selected for edit - Sets the on-screen strings to the locations being used
    useEffect(() => {
        if (routeToEdit !== null) {
            setFrom(routeToEdit.from_location_id);
            setTo(routeToEdit.to_location_id);
        }
    }, [routeToEdit]);

    //Loading function for data - Fetches data on initial load and when changes take place to update list
    const refresh = () => {
        ExtraPaymentsAPI.GetAllCarRidePrices().then(res => {
            if (res.status)
                setAllPrices(res.data.sort((a, b) => a.from_location.localeCompare(b.from_location))); //Sorts array in alphabetical order based on 'From' location string
            else
                setAllPrices([]); //Redundancy if registered routes table is empty
        });

        ExtraPaymentsAPI.GetAllCarRideLocation().then(res => {
            if (res.status)
                setAllLocations(res.data.sort((a, b) => a.location.localeCompare(b.location))); //Sorts array in alphabetical order based on 'Locations' string
            else
                setAllLocations([]); //Redundancy if location table routes is empty
        });
    }

    //Runs when user presses 'Register Route' button or 'Confirm Changes' button
    //Takes boolean flag to determine whether it should post a new one or update a pre-existing one
    const onSubmit = (edit: boolean) => {

        //Validates inputs and entry is a new Route
        if (!edit && validate()) {
            let newRoute: AddCarRidePrice = {
                from_location_id: from,
                to_location_id: to,
                price: price
            };

            ExtraPaymentsAPI.AddCarRidePrice(newRoute).then(res => {
                refresh(); //Updates master-collection
                clearForm(); //Clears inputs for new entries
            });
        }
        else if (edit && routeToEdit !== null) {

            //Creates updateObject based  on state-variable if entry is flagged for editing
            let newRoute: UpdateCarRidePrice = {
                from_location_id: routeToEdit.from_location_id,
                to_location_id: routeToEdit.to_location_id,
                price: price,
                car_ride_price_id: routeToEdit.car_ride_price_id
            };

            ExtraPaymentsAPI.UpdateCarRidePrice(newRoute).then(res => {
                refresh(); //Updates master-collection
                clearForm(); //Clears inputs for new entries
            });
        }
    }

    //Runs when user deletes route from collection
    //Uses state variables (ID of entry to delete & reason for deletion) to create deleteObject
    const onDelete = () => {
        let routeToDeleteObject: DeleteCarRidePrice = {
            deleted_reason: routeToDeleteReason,
            car_ride_price_id: routeToDelete
        };

        ExtraPaymentsAPI.DeleteCarRidePrice(routeToDeleteObject).then(res => {

            //Refreshes and resets values if deletion is successful, otherwise it sets the validation string to alert user
            if (res.status) {
                refresh();
                setRouteToDelete(-1);
                setRouteToDeleteReason("");
            }
            else {
                setValidationString("Unable to delete entry");
            }
        });
    }

    //Simple set-function called when user presses the edit-icon
    //Pre-sets state variable Price to pre-existing price so user can see what the price is currently - Possibly prevents hassle for user of scrolling down to see what the price is
    const setEditVariables = (newRouteToEdit: CarRidePrices) => {
        setValidationString("");
        setPrice(newRouteToEdit.price);
        setRouteToEdit(newRouteToEdit);
    }

    //Simple set-function called when user picks location from list
    //Sets the on-screen string of location to the user-picked location(see comment on fromString/toString variable initialization) and sets the corresponding ID of the location
    const setVisualStrings = (from: boolean, value: any) => {

        //Theortically find() should always find the corresponding ID but '|| -1' is a redundancy just is case which causes validation error 
        if (from) {
            setFromString(value);
            setFrom(allLocations.find(el => el.location === value)?.car_ride_location_id || -1);
        }
        else if (!from) {
            setToString(value);
            setTo(allLocations.find(el => el.location === value)?.car_ride_location_id || -1);
        }
    }

    //Helper function which clears and resets variables for the input form
    const clearForm = () => {
        setPrice(-1);
        setTo(-1);
        setFrom(-1);
        setRouteToEdit(null);
        setToString("");
        setFromString("");
        setValidationString("");
    }

    //Validation function - Checks if all fields are filled, whether origin and destination is the same, if the route already exists and if the price is negative
    const validate = (): boolean => {
        if (to === -1 || from === -1 || price === -1) {
            setValidationString("You must fill out all fields");
            return false;
        }
        else if (to === from) {
            setValidationString("Origin and destination can't be the same");
            return false;
        } else if (allPrices.find(el => el.from_location_id === from && el.to_location_id === to && el.visible)) {
            setValidationString("This route is already registered");
            return false;
        } else if (price <= 0) {
            setValidationString("Price can't be negative");
            return false;
        }
        setValidationString("");
        return true;
    }

    return (
        <Grid container xs={12} justifyContent="space-evenly">
            {/* Left side of the screen - Input form for user to fill out for either a new route or to edit a route */}
            <Grid xs={6} container item justifyContent="center">
                <Grid xs={10} justifyContent="center" >
                    <Paper variant="outlined" sx={{ padding: 2 }}>
                        <Grid item xs={12} textAlign="center">
                            {routeToEdit === null ? <Typography variant="h2" text="Create New Route" /> : <Typography variant="h2" text="Edit Route" />}
                        </Grid>
                        <Grid item justifyContent="space-evenly" xs={12} display="flex" margin={2}>
                            <Grid xs={6} margin={2}>
                                <Select
                                    disabled={routeToEdit !== null}
                                    selectedItem={routeToEdit === null ? fromString : routeToEdit.from_location}
                                    options={allLocations.filter(el => el.visible).map(el => el.location)}
                                    required={routeToEdit === null}
                                    onSelectedItem={(e: any) => setVisualStrings(true, e.target.value)}
                                    width={100}
                                    widthPercentage
                                    filter_by_text={routeToEdit === null ? "Choose Origin of Trip" : ""}
                                />
                            </Grid>
                            <Grid xs={6} margin={2}>
                                <Select
                                    disabled={routeToEdit !== null}
                                    selectedItem={routeToEdit === null ? toString : routeToEdit.to_location}
                                    options={allLocations.filter(el => el.visible).map(el => el.location)}
                                    required={routeToEdit === null}
                                    onSelectedItem={(e: any) => setVisualStrings(false, e.target.value)}
                                    width={100}
                                    widthPercentage
                                    filter_by_text={routeToEdit === null ? "Choose Destination of Trip" : ""}
                                />
                            </Grid>
                        </Grid>
                        <Grid xs={8} alignSelf="center" margin="auto" justifyContent="center">
                            <Input
                                required
                                multiline={false}
                                label_text={"Price of the route"}
                                value={price === -1 ? "" : price}
                                help_text="Please enter amount in ISK"
                                onTextChange={(value: string) => setPrice(parseInt(value))}
                                type="number"
                                width={100}
                                textAlign="center"
                            />
                        </Grid>
                        <Grid justifyContent="center" container xs={12} marginTop={3}>
                            <Grid justifyContent="center" display="flex" xs={12} item>
                                {
                                    routeToEdit === null
                                        ?
                                        <Button color="primary" variant="contained" onClick={() => onSubmit(false)}> Register route</Button>
                                        :
                                        <>
                                            <Button color="secondary" variant="contained" onClick={() => onSubmit(true)}> Confirm changes</Button>
                                            <Button color="error" variant="contained" sx={{ marginLeft: 2 }} onClick={() => clearForm()}> Cancel </Button>
                                        </>
                                }
                            </Grid>
                            <Grid justifyContent="center" marginTop={2} xs={12} display="flex" item>
                                <Typography color="error" variant="h4" fontWeight="bold" text={validationString} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {/* Right side of screen: Title and the Current/History switch, followed by mapping of all active/history routes divided into items list */}
            <Grid xs={6} container item justifyContent="center">
                <Grid item margin={1} textAlign="center">
                    <Typography variant="h3" text={visible ? "Registered Routes" : "History"} />
                    <Grid item margin={1} display="flex" alignItems="center">
                        <Typography variant="caption" text="Current" />
                        <Switch
                            checked={!visible}
                            onChange={() => setVisible(!visible)}
                            color="secondary"
                            name="checked"
                        />
                        <Typography variant="caption" text="History" />
                    </Grid>
                </Grid>
                <Grid item container overflow="auto" sx={{ height: "75vh" }} justifyContent="center" xs={12}>
                    {
                        //Maps depending on the 'visible' flag to determine whether or not to show the active routes or his
                        allPrices.length !== 0 && allPrices.filter(el => el.visible === visible).map(el => {
                            return (
                                <TicketListItem historyElement={el.visible}>
                                    <Grid xs={5} item margin={[0.5, 0.5, 0.5, 1.5]} >
                                        <Grid item justifyContent="center" sx={{ margin: 1 }}>
                                            <Typography variant="h5" fontWeight="lighter" text={"FROM:"} />
                                            <Typography textColor={visible ? "#4f4f4f" : "#333333"} variant="h4" fontWeight="bold" text={el.from_location} />
                                        </Grid>
                                        <Grid item justifyContent="center" sx={{ margin: 1 }}>
                                            <Typography variant="h5" fontWeight="lighter" text={"TO:"} />
                                            <Typography textColor={visible ? "#4f4f4f" : "#333333"} variant="h4" fontWeight="bold" text={el.to_location} />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" }, marginLeft: 9 }} orientation="vertical" variant="middle" flexItem />
                                    <Grid item xs={4} margin={2} >
                                        <Grid item justifyContent="center" sx={{ margin: 0.5, textAlign: { xs: "justify", sm: "justify", md: "justify", lg: "center", xl: "center" } }} >
                                            <Typography variant="h5" fontWeight="lighter" text={"PRICE:"} />
                                            <Typography variant="h3" fontWeight="bolder" textColor={visible ? "#4f4f4f" : "#333333"} text={new Intl.NumberFormat("is-IS", { style: "currency", currency: "ISK" }).format(el.price)} />
                                        </Grid>
                                        {
                                            visible &&
                                            <Grid item sx={{ textAlign: { xs: "justify", sm: "justify", md: "justify", lg: "center", xl: "center" } }}>
                                                <IconButton onClick={() => setRouteToDelete(el.car_ride_price_id)}>
                                                    <Delete color="error" />
                                                </IconButton>
                                                <IconButton onClick={() => setEditVariables(el)}>
                                                    <Edit color="info" />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </TicketListItem>
                            )
                        })
                    }
                </Grid>
            </Grid>
            {/* Pop-up box for string-input when user wants to delete a route */}
            <Dialog
                visible={routeToDelete !== -1}
                max_width={"sm"}
                title={"Reason for deletion"}
                context={
                    <Grid xs={12} container>
                        <Input
                            required
                            multiline={false}
                            label_text={"Reason for deletion"}
                            value={routeToDeleteReason}
                            help_text=""
                            onTextChange={(value: string) => setRouteToDeleteReason(value)}
                            type="text"
                            width={100}
                            textAlign="center"
                        />
                        <Grid justifyContent="center" marginTop={2} container xs={12}>
                            <Grid item marginRight={3}>
                                <Button color="error" variant="contained" onClick={() => onDelete()}> Delete Route</Button>
                            </Grid>
                            <Grid item marginLeft={1}>
                                <Button color="primary" variant="outlined" onClick={() => setRouteToDelete(-1)}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                onClose={(status: any) => setRouteToDelete(-1)}
                strict={false}
                show_options={false}
            />
        </Grid>
    )
}
export default Routes;