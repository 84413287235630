import { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import { makeStyles } from '@mui/styles';
import ExtraPaymentsApi from '../../api/extraPayments';
import { CarRideLocations } from '../../types/extraPayments';
import { Dictionary } from '../../components';
import { Typography, Input, Dialog } from '../../MaterialUiComponents';
import { Grid, Button, Switch } from '@mui/material';

const useStyles = makeStyles(theme => ({
  createButton: {
    backgroundColor: '#407BAC',
    color: 'white',
    fontSize: '1em',
  }
}));

const Destinations: FunctionComponent = () => {
  const [destination, setDestination] = useState(Array<CarRideLocations>());
  const [newDestination, setNewDestination] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");

  const [showActive, setShowActive] = useState<boolean>(true);
  const [carRideLocationId, setCarRideLocationId] = useState<number>(-1);
  const [valadiationText, setValidationText] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    ExtraPaymentsApi.GetAllCarRideLocation().then(res => {
      setDestination(res.data);
      setCarRideLocationId(-1);
      setReason("");
      setValidationText(false);
    });
  }
  const addDestination = () => {
    ExtraPaymentsApi.AddCarRideLocation(newDestination).then(res => {
      refresh();
    });
  }
  const onOpenDeleteDialog = (row: CarRideLocations) => {
    setIsDialogOpen(true);
    setCarRideLocationId(row.car_ride_location_id);
  }
  const onDeleteDestination = (status: boolean) => {
    if (status === true && carRideLocationId > -1 && reason !== "" && !valadiationText) {
      ExtraPaymentsApi.DeleteCarRideLocation(reason, carRideLocationId).then(res => {
        refresh();
        setIsDialogOpen(false);
      });
    }
    else if (status === true && reason === "") {
      setValidationText(true);
    }
    else if (status === false) {
      setIsDialogOpen(false);
    }
  }

  const onInputChange = (text: string) => {
    setReason(text);
    if (text !== "") {
      setValidationText(false);
    }
  }

  return (
    <Grid>
      <Grid xs={12} container alignItems="flex-start" justifyContent="center" >
        <Grid item xs={6}>
          <Grid container xs={12}>
            <Grid item xs={4} lg={1}>
              <Typography text="Current" variant="subtitle2" color="textPrimary" margin={[2, 0, 0, 0]} />
            </Grid>
            <Grid item xs={2} lg={1} margin={[1, 0, 0, 1]}>
              <Switch
                checked={!showActive}
                onChange={() => setShowActive(!showActive)}
                color="secondary"
                name="checkedB"
              />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Typography text="History" variant="subtitle2" color="textPrimary" margin={[2, 0, 0, 2]} />
            </Grid>
          </Grid>
          <Grid xs={10} item>
            {
              destination !== undefined && destination.filter(type => type.visible === showActive).map(row => (
                <Dictionary deleteProperty={(row: CarRideLocations) => onOpenDeleteDialog(row)} deleteButton={true} name={row.location} object={row} deleted={showActive === true ? false : true} id={row.car_ride_location_id} ></Dictionary>
              ))
            }
          </Grid>
        </Grid>
        <Grid container xs={5}>
          <Grid item xs={12}>
            <br />
            <Input
              multiline={false}
              label_text="Add Destination"
              help_text="Please type in Destination"
              type="text"
              value={newDestination}
              width={50}
              onTextChange={(value: string) => setNewDestination(value)}
            />
          </Grid>
          <br />
          <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addDestination()} >
            Add Destination
          </Button>
        </Grid>
      </Grid>
      <Dialog
        visible={isDialogOpen}
        max_width={"sm"}
        title={"Delete destination"}
        context={
          <Grid>
            <Input
              error={valadiationText}
              multiline={true}
              help_text={valadiationText ? "Reason can not be blank" : ""}
              label_text={"Please provide a reason"}
              value={reason}
              onTextChange={(text: string) => onInputChange(text)}
              type="text"
              width={100}
            />
          </Grid>
        }
        onClose={(status: any) => onDeleteDestination(status)}
        strict={false}
        show_options={true}
      />
    </Grid>
  )
}

export default Destinations;