  
import {PublicClientApplication} from "@azure/msal-browser";
// Msal Configurations
const tenantId = "794aac9a-04b1-4ff7-b1e7-adc3edfd9569";//process.env.tenantId ? process.env.tenantId : '$tenantId'

const msalConfig = {
  auth: {
    clientId: "94ffc015-0312-4130-a3e9-1a698ced81b8",//process.env.clientId ? process.env.clientId: '$clientId',
    authority: `https://login.microsoftonline.com/${tenantId}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};
const authProvider = new PublicClientApplication(msalConfig);

export default authProvider; 