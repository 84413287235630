import { AllEmployeesAction } from "../types/actions";
import { User } from "../types/user";

const initialState : { all_employees: User[] } = { all_employees : [] };

export const employeesReducer = (state = { ...initialState }, action: AllEmployeesAction) => {
	switch (action.type) {
		case 'GET_ALL_EMPLOYEES': 
			return { ...state, all_employees: action.all_employees };
		default:
			return state;
	}
}