import { useState, FunctionComponent, useEffect } from 'react';
import { Grid, TableCell, TableRow, IconButton, Checkbox, TextField } from '@mui/material';
import { Stop, AddCircle } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Table, Typography, Select, Input, DatePicker } from './../../MaterialUiComponents';
import { Timer, EmployeeSearch } from './../../components';
import SecurityApi from '../../api/security';
import { callOuts, movements, otherIncidents, securityResult, walkArounds } from './../../types/security';
import { RootState } from '../../reducer';
import { useSelector } from 'react-redux'
import ReportInfo from './ReportInfo';
import EditColumn from './EditColumn';
import AmountControls from './AmountControls';
import { Autocomplete } from '@mui/material';

const useStyles = makeStyles(theme => ({
    certificate: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(3)
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    save: {
        color: 'green'
    },
    tablesContainer: {
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    table: {
        marginBottom: theme.spacing(2)
    },
    "& TableCell": {
        paddingRight: '0px',
    },
    ".MuiFormControl-root": {
        flexDirection: 'unset'
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

type ReportProps = {
    selectedReport: securityResult,
    bays: { "name": string, id: number }[]
    closeReport: Function,
    refresh: Function,
    tails: string[],
    isSecurityAdmin: Boolean
}

const Report: FunctionComponent<ReportProps> = ({ selectedReport, closeReport, refresh, bays, tails, isSecurityAdmin }) => {
    const [editingRow, setEditingRow] = useState(false);
    const [editMovementsRow, setEditMovementsRow] = useState(-1);
    const [editCallOutRow, setEditCallOutRow] = useState(-1);
    const [editWalkAroundsRow, setEditWalkAroundsRow] = useState(-1);
    const [editIncidentssRow, setEditIncidentsRow] = useState(-1);
    const store = useSelector((state: RootState) => state);
    const user = store.user.me;
    const [tempReason, setTempReason] = useState<string>("");
    const [tempInside, setTempInside] = useState<boolean>(true);
    const [tempOwner, setTempOwner] = useState<string>("");
    const [tempBay, setTempBay] = useState<number>(0);
    const [tempInDate, setTempInDate] = useState<Date>(new Date());
    const [tempOutDate, setTempOutDate] = useState<Date>(new Date());
    const [inputError, setInputError] = useState<boolean>(false);
    const [validationText, setValidationText] = useState<string>("");
    const [tempTail, setTempTail] = useState<string | null>("");

    // Refresh after each edit, and editingRow true closes all other rows for editing
    useEffect(() => {
        refresh();
        if (editMovementsRow === -1 && editCallOutRow === -1 && editWalkAroundsRow === -1 && editIncidentssRow === -1) {
            setEditingRow(false);
        }
        else {
            setEditingRow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMovementsRow, editCallOutRow, editWalkAroundsRow, editIncidentssRow]);

    // Table bodies for all four tables comes here. Lot of logic to determine if row is in edit mode or not. Edit pencil icon only displayed in edit mode
    const generateMovementsBody = (row: movements, index: number) => {
        return (
            <TableRow className={editMovementsRow !== -1 && editMovementsRow !== index ? classes.overlay : index % 2 ? classes.evenRows : ''}>
                <TableCell>
                    {tempOwner !== "" && editMovementsRow === index ? tempOwner : row.general_info.last_modified_date_by_employee_name !== "" ? row.general_info.last_modified_date_by_employee_name : row.general_info.created_by_employee_name}
                </TableCell>
                <TableCell>{editMovementsRow === index ?
                    <Input multiline={true} help_text={validationText} error={inputError} label_text={"Reason"} value={tempReason} onTextChange={(text: string) => onReasonChange(text)} type="text" width={100} /> : row.reason}</TableCell>
                <TableCell width="130">{editMovementsRow === index ?
                    <Autocomplete
                        fullWidth
                        options={tails}
                        value={tempTail}
                        noOptionsText="No tails found"
                        clearOnEscape
                        autoHighlight
                        onChange={(e: any, newValue: string | null) => setTempTail(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Tails" margin="none" variant="standard" size="small" fullWidth />
                        )} /> : row.tail_number}</TableCell>
                <TableCell>{editMovementsRow === index ?
                    <Select
                        selectedItem={tempBay}
                        width={100}
                        filter_by_text={"Bays"}
                        optionsObject={bays}
                        onSelectedItem={(e: any) => setBay(e.target.value)} >
                    </Select> : row.bay_name}
                </TableCell>
                <TableCell width="20">
                    <Checkbox color="primary" disabled={editMovementsRow === index ? false : true} checked={editMovementsRow === index ? tempInside : row.aircraft_moved_inside} onChange={() => onMovementsCheck(index)} />
                </TableCell>
                <TableCell>{row.general_info.last_modified_date !== null ? row.general_info.last_modified_date_text : row.general_info.created_date_text}</TableCell>
                {isSecurityAdmin && !selectedReport.report.closed &&
                    <EditColumn
                        index={index}
                        saveChanges={() => saveChanges("movement", index)}
                        deleteRow={() => deleteRow("movement", index)}
                        editRow={editMovementsRow}
                        setEditRow={(editRow: number) => { onEditRow("movement", index) }} />
                }
            </TableRow>
        )
    }
    const generateCallOutBody = (row: callOuts, index: number) => {
        return (
            <TableRow className={editCallOutRow !== -1 && editCallOutRow !== index ? classes.overlay : index % 2 ? classes.evenRows : ''}>
                <TableCell>{editCallOutRow === index ? <EmployeeSearch defaultEmployee={row.call_out_employee_name} inputWidth={110} place_holder="Enter Name" onSelectedEemployee={(employee_id: number) => onSelectedEmployee(employee_id, index)} /> : row.call_out_employee_name} </TableCell>
                <TableCell>{editCallOutRow === index ? <Input multiline={true} help_text={validationText} error={inputError} label_text={"Reason"} value={tempReason} onTextChange={(text: string) => onReasonChange(text)} type="text" width={100} /> : row.reason}</TableCell>
                <TableCell width={130}>{editCallOutRow === index ? <DatePicker type="DateAndTime" selected={tempInDate} label="Time" onDateChange={(date: Date) => { setTempInDate(date) }} /> : row.employee_in_date_text}</TableCell>
                <TableCell width={130}>{editCallOutRow === index ? <DatePicker type="DateAndTime" selected={tempOutDate} label="Time" onDateChange={(date: Date) => { setTempOutDate(date) }} /> : row.employee_out_date_text}</TableCell>
                <TableCell>{row.general_info.last_modified_date !== null ? row.general_info.last_modified_date_text : row.general_info.created_date_text}</TableCell>
                {isSecurityAdmin && !selectedReport.report.closed &&
                    <EditColumn
                        index={index}
                        saveChanges={() => saveChanges("callOut", index)}
                        deleteRow={() => deleteRow("callOut", index)}
                        editRow={editCallOutRow}
                        setEditRow={(editRow: number) => { onEditRow("callOut", index) }} />
                }
            </TableRow>
        )
    }
    const generateWalkAroundBody = (row: walkArounds, index: number) => {
        return (
            <TableRow className={editWalkAroundsRow !== -1 && editWalkAroundsRow !== index ? classes.overlay : index % 2 ? classes.evenRows : ''}>
                <TableCell>
                    {tempOwner !== "" && editMovementsRow === index ? tempOwner : row.general_info.last_modified_date_by_employee_name !== "" ? row.general_info.last_modified_date_by_employee_name : row.general_info.created_by_employee_name}
                </TableCell>
                <TableCell>{editWalkAroundsRow === index ? <Input multiline={true} help_text={validationText} error={inputError} label_text={"Type a note"} value={tempReason} onTextChange={(text: string) => onReasonChange(text)} type="text" width={100} /> : row.note}</TableCell>
                <TableCell>{row.stop_date_text === "" ? <Timer startTime={row.general_info.created_date} /> : row.general_info.created_date_text?.substring(11, 16)}</TableCell>
                <TableCell>{row.stop_date_text?.substring(11, 16)}</TableCell>
                <TableCell>{row.general_info.last_modified_date !== null ? row.general_info.last_modified_date_text : row.general_info.created_date_text}</TableCell>
                {isSecurityAdmin && !selectedReport.report.closed &&
                    <EditColumn
                        index={index}
                        saveChanges={() => saveChanges("walkAround", index)}
                        customIcon={row.stop_date_text === "" ? <Stop color="error" /> : undefined}
                        deleteRow={() => deleteRow("walkAround", index)}
                        editRow={editWalkAroundsRow}
                        setEditRow={(editRow: number) => { row.stop_date_text === "" ? onEditRow("walkAroundStop", index) : onEditRow("walkAround", index) }} />
                }
            </TableRow>
        )
    }
    const generateIncidentsBody = (row: otherIncidents, index: number) => {
        return (
            <TableRow className={editIncidentssRow !== -1 && editIncidentssRow !== index ? classes.overlay : index % 2 ? classes.evenRows : ''}>
                <TableCell>
                    {tempOwner !== "" && editMovementsRow === index ? tempOwner : row.general_info.last_modified_date_by_employee_name !== "" ? row.general_info.last_modified_date_by_employee_name : row.general_info.created_by_employee_name}
                </TableCell>
                <TableCell>{editIncidentssRow === index ? <Input multiline={true} help_text={validationText} error={inputError} label_text={"Type a description"} value={tempReason} onTextChange={(text: string) => onReasonChange(text)} type="text" width={100} /> : row.incident}</TableCell>
                <TableCell>{row.general_info.last_modified_date !== null ? row.general_info.last_modified_date_text : row.general_info.created_date_text}</TableCell>
                {isSecurityAdmin && !selectedReport.report.closed &&
                    <EditColumn
                        index={index}
                        editRow={editIncidentssRow}
                        saveChanges={() => saveChanges("incident", index)}
                        deleteRow={() => deleteRow("incident", index)}
                        setEditRow={(editRow: number) => { onEditRow("incident", index) }} />
                }
            </TableRow>
        )
    }

    const onSelectedEmployee = (employeeId: number, index: number) => {
        selectedReport.call_outs[index - 1].outcall_employee_id = employeeId;
    }

    // Conditional edit for each table type based on row index number. Temp state of same types re-used for each one, as only 1 row can be edited at a time. 
    // This only sets the edit row and sets values based on that. Each edit is then in seperate functions.
    // For example tempReason is re-used in each type. The edit row is locked until either changes are saved or row deleted.
    const onEditRow = (type: string, index: number) => {
        setInputError(false);
        setValidationText("");

        if (type === "movement" && editingRow === false) {
            let tempRow = selectedReport.movements[index - 1];
            setTempTail(tempRow.tail_number);
            setTempInside(tempRow.aircraft_moved_inside);
            setTempBay(tempRow.bay_id);
            setTempReason(tempRow.reason);
            setEditMovementsRow(index);
        }
        if (type === "callOut" && editingRow === false) {
            let tempRow = selectedReport.call_outs[index - 1];
            setTempInDate(tempRow.employee_in_date);
            setTempOutDate(tempRow.employee_out_date);
            setTempReason(tempRow.reason);
            setEditCallOutRow(index);
        }
        if (type === "walkAround" && editingRow === false) {
            let tempRow = selectedReport.walk_arounds[index - 1];
            setTempReason(tempRow.note);
            setEditWalkAroundsRow(index);
        }
        if (type === "incident" && editingRow === false) {
            let tempRow = selectedReport.other_incidents[index - 1];
            setTempReason(tempRow.incident);
            setEditIncidentsRow(index);
        }
        // Special logic for stopping walkaround
        if (type === "walkAroundStop") {
            let tempRow = selectedReport.walk_arounds[index - 1];
            SecurityApi.WalkAroundFinishedRound(tempRow.walk_around_id, user.user_id, selectedReport.report.report_id).then(res => {
                setEditWalkAroundsRow(index);
            });
        }
        // Owner is always changed to the person that does the last edit on the row
        setTempOwner(user.display_name);
    }
    const onMovementsCheck = (index: number) => {
        setTempInside(!tempInside);
        selectedReport.movements[index - 1].aircraft_moved_inside = !tempInside;
    }
    const setBay = (value: number) => {
        setTempBay(value);
    }
    const onReasonChange = (text: string) => {
        setTempReason(text);
    }
    const submitReport = () => {
        SecurityApi.CloseDailyReport(user.user_id).then(res => {
            refresh();
        });
    }

    // Takes in table type and row index and uses the temp variables to submit changes through API.
    // Validation check for empty text string
    const saveChanges = (type: string, index: number) => {
        if (tempReason === "" || tempReason === undefined) {
            setInputError(true);
            setValidationText("Reason missing");
        }
        else if (type === "movement") {
            const movement = selectedReport.movements[index - 1];
            SecurityApi.AircraftMovementsUpdateRow(movement.movement_id, user.user_id, tempTail !== null ? tempTail : "", tempBay, tempInside, tempReason, selectedReport.report.report_id).then(res => {
                setEditMovementsRow(-1);
            });
        }
        else if (type === "callOut") {
            const callOut = selectedReport.call_outs[index - 1];
            SecurityApi.CallOutUpdateRow(user.user_id, callOut.outcall_employee_id, tempInDate, tempOutDate, tempReason, selectedReport.report.report_id, callOut.call_outs_id).then(res => {
                setEditCallOutRow(-1);
            });
        }
        else if (type === "walkAround") {
            const walkAround = selectedReport.walk_arounds[index - 1];
            SecurityApi.WalkAroundUpdateRow(walkAround.walk_around_id, user.user_id, walkAround.stop_date, walkAround.stop_date_by_employee_id, tempReason, selectedReport.report.report_id).then(res => {
                setEditWalkAroundsRow(-1);
            });
        }
        else if (type === "incident") {
            const incident = selectedReport.other_incidents[index - 1];
            SecurityApi.OtherIncidentsUpdateRow(incident.other_incident_id, user.user_id, tempReason, selectedReport.report.report_id).then(res => {
                setEditIncidentsRow(-1);
            });
        }
    }
    // Takes in table type and creates new item through api with empty reason. That triggers refresh of the report. 
    // New row is set to edit mode so reason must be added before saving or else delete the row. 
    const addRow = (type: string) => {
        setTempReason("");
        setTempBay(0);
        setTempInside(false);
        setInputError(false);
        setValidationText("");
        if (type === "movement") {
            SecurityApi.AircraftMovementsCreateNewRow(user.user_id, "", 1, true, "", selectedReport.report.report_id).then(res => {
                setEditMovementsRow(selectedReport.movements.length + 1);
            });
        }
        if (type === "callOut") {
            SecurityApi.CallOutCreateNewRow(user.user_id, user.user_id, new Date(), new Date(), "", selectedReport.report.report_id).then(res => {
                setTempInDate(new Date());
                setTempOutDate(new Date());
                setEditCallOutRow(selectedReport.call_outs.length + 1);
            });
        }
        // This is not set to Edit row immediately because this set's in effect a timer that runs while walk around is in progress and displays a stop buutton
        // When stop button is clicked the row is set to edit mode
        if (type === "walkAround") {
            SecurityApi.WalkAroundCreateNewRow(user.user_id, "", selectedReport.report.report_id).then(res => {
                refresh();
            });
        }
        if (type === "incident") {
            SecurityApi.OtherIncidentsCreateNewRow(user.user_id, "", selectedReport.report.report_id).then(res => {
                setEditIncidentsRow(selectedReport.other_incidents.length + 1);
            });
        }
    }
    const deleteRow = (type: string, index: number) => {
        if (type === "movement") {
            const movement = selectedReport.movements[index - 1];
            SecurityApi.AircraftMovementsRemoveRow(movement.movement_id, user.user_id).then(res => {
                refresh();
                setEditMovementsRow(-1);
            });
        }
        if (type === "callOut") {
            const callOut = selectedReport.call_outs[index - 1];
            SecurityApi.CallOutRemoveRow(callOut.call_outs_id, user.user_id).then(res => {
                refresh();
                setEditCallOutRow(-1);
            });
        }
        if (type === "walkAround") {
            const walkAround = selectedReport.walk_arounds[index - 1];
            SecurityApi.WalkAroundRemoveRow(walkAround.walk_around_id, user.user_id).then(res => {
                refresh();
                setEditWalkAroundsRow(-1);
            });
        }
        if (type === "incident") {
            const incident = selectedReport.other_incidents[index - 1];
            SecurityApi.OtherIncidentsRemoveRow(incident.other_incident_id, user.user_id).then(res => {
                refresh();
                setEditIncidentsRow(-1);
            });
        }
    }

    const classes = useStyles();
    return (
        <Grid container xs={12} className={classes.certificate}>
            <Grid item xs={12}>
                <ReportInfo submitReport={() => submitReport()} selectedReport={selectedReport} closeReport={() => closeReport()} isSecurityAdmin={isSecurityAdmin} />
            </Grid>
            <AmountControls selectedReport={selectedReport} isSecurityAdmin={isSecurityAdmin} refresh={() => refresh()} user={user.user_id} />
            <Grid container xs={12} spacing={1} className={classes.tablesContainer}>
                <Grid item xs={12} xl={6} className={classes.table}>
                    <Typography text="Aircraft Movements" variant="h2" />
                    <Table
                        headers={["Owner", "Reason", "Tail", "Bay", "In", "Last Modified", ""]}
                        rows={selectedReport.movements}
                        generateBody={(row: any, index: number) => generateMovementsBody(row, index)}
                        pagination={false}
                        skipIndexCol={true}
                    />
                    {isSecurityAdmin && !selectedReport.report.closed &&
                        <Grid container direction="column" alignItems="center">
                            <IconButton onClick={() => addRow("movement")} className={classes.save} >
                                <AddCircle />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} xl={6} className={classes.table}>
                    <Typography text="Other Incidents" variant="h2" />
                    <Table
                        headers={["Owner", "Description", "Last Modified", ""]}
                        rows={selectedReport.other_incidents}
                        generateBody={(row: any, index: number) => generateIncidentsBody(row, index)}
                        pagination={false}
                        skipIndexCol={true}
                    />
                    {isSecurityAdmin && !selectedReport.report.closed &&
                        <Grid container direction="column" alignItems="center">
                            <IconButton onClick={() => addRow("incident")} className={classes.save} >
                                <AddCircle />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} xl={6} className={classes.table}>
                    <Typography text="Walkarounds" variant="h2" />
                    <Table
                        headers={["Owner", "Note", "Started", "Completed", "Last Modified", ""]}
                        rows={selectedReport.walk_arounds}
                        generateBody={(row: any, index: number) => generateWalkAroundBody(row, index)}
                        pagination={false}
                        skipIndexCol={true}
                    />
                    {isSecurityAdmin && !selectedReport.report.closed &&
                        <Grid container direction="column" alignItems="center">
                            <IconButton onClick={() => addRow("walkAround")} className={classes.save} >
                                <AddCircle />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} xl={6} className={classes.table}>
                    <Typography text="Callouts" variant="h2" />
                    <Table
                        headers={["Employee", "Reason", "In", "Out", "Last Modified", ""]}
                        rows={selectedReport.call_outs}
                        generateBody={(row: any, index: number) => generateCallOutBody(row, index)}
                        pagination={false}
                        skipIndexCol={true}
                    />
                    {isSecurityAdmin && !selectedReport.report.closed &&
                        <Grid container direction="column" alignItems="center">
                            <IconButton onClick={() => addRow("callOut")} className={classes.save} >
                                <AddCircle />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};
export default Report;
