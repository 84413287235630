import { FunctionComponent, useState } from 'react';
import { LinearProgress, Dialog } from '../../MaterialUiComponents';
import { Grid, Button } from '@mui/material'
import { Delete } from '@mui/icons-material';
import CapabilityListApi from '../../api/capabilityList';
import { Request, ATAChapter } from '../../types/capabilityList';
import CompareRequest from './CompareRequest';
import Permissions from './Permissions';
import AddTools from './AddToolsComponent';
import SubAssy from './SubAssy';
import TrainingAndManuals from './TrainingAndManuals';
import TextFields from './TextFields';
import DateTextAndJiraLink from './DateTextAndJiraLink';
import ToolsTable from './ToolsTable';

interface RequestProps {
    requestId?: number,
    isCapabilityAdmin?: boolean,
    request: Request,
    setRequest: Function,
    viewMode: boolean,
    setViewMode: Function,
    ATAChapters: ATAChapter[],
    setTabValue: Function,
}

const CapabilityRequest: FunctionComponent<RequestProps> = ({ request, setRequest, requestId, isCapabilityAdmin, viewMode, setViewMode, ATAChapters, setTabValue }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dilogOpen, setDilogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [resetShops, setResetShops] = useState<boolean>(false); //used to reset the shops in the textfields component when the request is changed or canceled, annoying but neccesary.
    const [originalRequest, setOriginalRequest] = useState<Request>(request);

    //Setting the old historical request as the request
    const getOriginalRequest = () => {
        if (requestId !== undefined && requestId !== -1) {
            setLoading(true);
            CapabilityListApi.GetRequestById(requestId).then(res => {
                setOriginalRequest(res.data);
                setLoading(false);
            });
        }
    }

    const onStartSubmitProcedure = () => {
        if (viewMode === false) {
            getOriginalRequest();
            setDilogOpen(true);
        }
        else {
            setDilogOpen(false);
        }
    }

    const onCancelEdit = () => {
        setRequest(originalRequest);
        setViewMode(!viewMode);
        setResetShops(true);
    }

    const onSaveChanges = () => {
        setLoading(true);
        if ((requestId === undefined || requestId === -1) || requestId === null) {
            CapabilityListApi.CreateRequest(request).then(res => {
                setLoading(false);
                setDilogOpen(false);
                setTabValue(0);
            });
        }
        else {
            CapabilityListApi.UpdateRequest(request).then(res => {
                setLoading(false);
                setDilogOpen(false);
                setTabValue(0);
            });
        }
    }

    const onDeleteRequest = () => {
        if (request?.request_id !== undefined) {
            setLoading(true);
            CapabilityListApi.DeleteRequest(request.request_id).then(res => {
                setLoading(false);
                setDeleteDialogOpen(false);
            });
        }
    }

    return (
        <Grid>
            {
                loading ? <LinearProgress /> :
                    <Grid>
                        {!viewMode &&
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button disabled={viewMode} onClick={() => { setDeleteDialogOpen(true) }}>
                                        <Delete color="error" />
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                        <TextFields
                            request={request}
                            setRequest={setRequest}
                            viewMode={viewMode}
                            ATAChapters={ATAChapters}
                            resetShops={resetShops}
                            setResetShops={setResetShops}
                        />
                        <Permissions
                            request={request}
                            viewMode={viewMode}
                            setRequest={setRequest}
                        />
                        <SubAssy
                            request={request}
                            setRequest={setRequest}
                            viewMode={viewMode}
                        />
                        <TrainingAndManuals
                            request={request}
                            setRequest={setRequest}
                            viewMode={viewMode}
                        />
                        <ToolsTable
                            request={request}
                            setRequest={setRequest}
                            viewMode={viewMode}
                        />
                        {
                            !viewMode &&
                            <AddTools
                                setRequest={setRequest}
                                request={request}
                                viewMode={viewMode}
                            />
                        }
                        <DateTextAndJiraLink
                            request={request}
                            setRequest={setRequest}
                            viewMode={viewMode}
                        />
                        {
                            (isCapabilityAdmin) &&
                            <Grid container justifyContent={'center'} style={{ marginTop: "60px" }}>
                                {(viewMode && isCapabilityAdmin) &&
                                    <Button
                                        onClick={() => setViewMode(false)}
                                        variant='contained'
                                        color={'primary'}>
                                        {'Edit Capability'}
                                    </Button>
                                }
                                {!viewMode &&
                                    <Button
                                        onClick={() => onStartSubmitProcedure()}
                                        variant='contained'
                                        color={'primary'}>
                                        {'Save Changes'}
                                    </Button>
                                }
                                {(!viewMode && requestId !== -1) && <Grid style={{ marginLeft: '18px' }}><Button
                                    onClick={() => onCancelEdit()}
                                    variant='contained'
                                    color={'secondary'}>
                                    {'Cancel'}
                                </Button></Grid>}
                            </Grid>
                        }
                    </Grid >
            }
            <Dialog
                visible={dilogOpen}
                max_width={"xl"}
                title={(requestId === undefined || requestId === -1) ? "New request" : "Compare changes from the original request"}
                context={
                    <Grid container direction="column">
                        {requestId !== undefined && requestId !== -1 ?
                            <Grid container spacing={2} justifyContent="center" style={{ marginTop: '8px' }} >
                                <Grid item xs={6}>
                                    {originalRequest !== undefined &&
                                        <CompareRequest request={originalRequest} isChanged={false} />
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    {request !== undefined &&
                                        <CompareRequest request={request} isChanged={true} />
                                    }
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={2} justifyContent="center" style={{ marginTop: '8px' }} >
                                <Grid item xs={12}>
                                    {request !== undefined &&
                                        <CompareRequest request={request} isChanged={true} />
                                    }
                                </Grid>
                            </Grid>
                        }
                        {!viewMode &&
                            <Grid style={{ marginTop: '32px' }} container justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={() => onSaveChanges()}
                                        variant='contained'
                                        color={'primary'}>
                                        Save changes
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                }
                onClose={(status: any) => setDilogOpen(false)}
                strict={false}
                show_options={false}
            />
            <Dialog
                visible={deleteDialogOpen}
                max_width={"md"}
                title={"Are you sure you want to delete this request?"}
                context={
                    <Grid container justifyContent="center" alignItems="center" direction="row" >
                        <Grid item>
                            <Button disabled={viewMode} onClick={() => { onDeleteRequest() }} variant='contained' color={'secondary'}>
                                Delete
                            </Button>
                            <Button style={{ marginLeft: '8px' }} disabled={viewMode} onClick={() => { setDeleteDialogOpen(false) }} variant='contained' color={'primary'}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                }
                onClose={(status: any) => setDeleteDialogOpen(false)}
                strict={false}
                show_options={false}
            />
        </Grid >
    )
}

export default CapabilityRequest