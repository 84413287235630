import { useState } from 'react';
import { pdf, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import IcelandairLogo from '../../assets/IcelandairNewLogo.png';
import { CloudDownload } from '@mui/icons-material';
import { WorldTrip } from '../../types/worldTools';

type WorldToolPdfProps = {
    trip: WorldTrip
}

// This component displays a download pdf button and also generates the pdf data and styling
const WorldToolPdf: FunctionComponent<WorldToolPdfProps> = ({ trip }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const styles = StyleSheet.create({
        page: {
            paddingTop: 40,
            paddingRight: 40,
            paddingLeft: 40,
            paddingBottom: 60
        },
        container: {
            flexDirection: 'row',
            marginTop: 0,
            marginBottom: 10
        },
        logo: {
            width: 120,
            top: -5,
            right: -20,
            zIndex: 1,
            position: 'absolute'
        },
        pageNumbers: {
            position: 'absolute',
            top: 20,
            left: 20,
            fontSize: 12,
            color: 'gray'
        },
        h1: {
            fontSize: 25,
            textAlign: 'center',
            width: '100%',
            fontFamily: 'Times-Bold',
        },
        footer: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        },
        h2: {
            fontSize: 18,
            textAlign: 'left',
            marginTop: 20,
            fontFamily: 'Times-Bold'
        },
        body: {
            fontSize: 12,
            textAlign: 'left',
            paddingRight: 5
        },
        bold: {
            fontSize: 12,
            paddingRight: 5,
            fontFamily: 'Times-Bold',

        },
        row: {
            flexDirection: 'row',
        },
        threeCol: {
            width: '33 %',
            margin: "auto"
        },
        fullWidth: {
            width: '100%',
        },
        textCenter: {
            textAlign: 'center'
        },
        marginBottom: {
            marginBottom: "5%"
        }
    });

    // PDF Content is created here, looping through the object arrays
    const PDFContent = () => (
        <Document>
            {/* This parts is for the header. Page numbers are fixed in top left corner of each page */}
            <Page style={styles.page} wrap size="A4">
                <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} of ${totalPages}`
                )} fixed />
                <View style={styles.container}>
                    <Text style={styles.h1}>{trip.general_info.name}</Text>
                    <Image src={IcelandairLogo} style={styles.logo} />
                </View>
                <View style={{ marginBottom: "10px" }}>
                    <Text style={{ textAlign: 'center' }}>{trip.general_info.tail_number}</Text>
                </View>
                <View style={styles.row}>
                    <View style={styles.threeCol}>
                        <View style={styles.container}>
                            <View style={styles.row}>
                                <View style={styles.fullWidth}>
                                    <Text style={[styles.bold, styles.textCenter]}>Start date</Text>
                                    <Text style={[styles.body, styles.textCenter]}>{trip.start_date_text}</Text>

                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.threeCol}>
                        <View style={styles.container}>
                            <View style={styles.row}>
                                <View style={styles.fullWidth}>
                                    <Text style={[styles.bold, styles.textCenter]}>Destination</Text>
                                    <Text style={[styles.body, styles.textCenter]}>{trip.world_trip_destination}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.threeCol}>
                        <View style={styles.container}>
                            <View style={styles.row}>
                                <View style={styles.fullWidth}>
                                    <Text style={[styles.bold, styles.textCenter]}>End date</Text>
                                    <Text style={[styles.body, styles.textCenter]}>{trip.end_date_text}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={[styles.h2, styles.textCenter, styles.marginBottom]}>{`Tools(${trip.loaned_out_tools.length})`}</Text>
                    {
                        trip.loaned_out_tools.map((tool, index) => (
                            <View style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
                                <Text style={styles.body} >{index + 1} : </Text>
                                <Text style={styles.body} >{tool.description}</Text>
                            </View>
                        ))
                    }
                </View>
                <View>
                    <Text style={[styles.h2, styles.textCenter, styles.marginBottom]}>{`Employees(${trip.assigned_employees.length})`}</Text>
                    {
                        trip.assigned_employees.map((emp, index) => (
                            <View style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
                                <Text style={styles.body} >{index + 1} : </Text>
                                <Text style={styles.body} >{emp.name}</Text>
                            </View>
                        ))
                    }
                </View>
                {/* Footer fixed on each page of the pdf */}
                <View style={styles.footer} fixed>
                    <Text>Icelandair</Text>
                </View>
            </Page>
        </Document>
    );
    // Set the content as doc variable. Create blob with the content, that can take a while with much data. Then saved as document
    const generatePDF = async () => {
        setLoading(true)
        const doc = <PDFContent />;
        const blob = await pdf(doc).toBlob();
        saveAs(blob, trip.general_info.name + '.pdf');
        setLoading(false)
    }
    return (
        <Button startIcon={<CloudDownload />} variant="contained" color="primary" size="medium" onClick={() => generatePDF()}>{loading === false ? "Download PDF" : "Please wait..."}</Button>
    );
};
export default WorldToolPdf;
