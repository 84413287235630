import { useState, useEffect, FunctionComponent, } from 'react';
import { Grid, Button, Switch, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Input, Dialog } from '../../MaterialUiComponents';
import { Dictionary } from '../../components';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import SubContractsApi from '../../api/subContracts';
import { dictionary } from '../../types/general';

const useStyles = makeStyles(theme => ({
    createButton: {
        backgroundColor: '#407BAC',
        color: 'white',
        fontSize: '1em',
    },
}));

type CertificateProps = {
    setLoading: Function
}

// Basic dictionary page using the dictionary component so easy to change style of all dictionary pages if needed.  
const EditCompanies: FunctionComponent<CertificateProps> = ({ setLoading }) => {
    const [companies, setCompanies] = useState(Array<dictionary>());
    const [newCompanyName, setNewCompanyName] = useState('');
    const [newCompanyDescription, setNewCompanyDescription] = useState('');
    const store = useSelector((state: RootState) => state);
    const currentUser = store.user.me;
    const [showActive, setShowActive] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [selectedCompany, setSelectedCompany] = useState<dictionary | undefined>(undefined);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const refresh = () => {
        setLoading(true);
        SubContractsApi.GetAllCompanies().then(res => {
            setCompanies(res.data);
            setLoading(false);
        })
    }
    const onReasonEdit = (value: string) => {
        setDeleteReason(value);
    }
    const addCageCode = () => {
        SubContractsApi.AddNewCompany(currentUser.user_id, newCompanyName, newCompanyDescription).then(res => {
            refresh();
            setNewCompanyName('');
            setNewCompanyDescription('');
        })
    }
    const deleteCageCode = (cageCode: dictionary) => {
        setSelectedCompany(cageCode);
        setDeleteDialogOpen(true);
    }
    const confirmDelete = () => {
        if (selectedCompany !== undefined) {
            selectedCompany.deleted_reason = deleteReason;
            SubContractsApi.DeleteCompany(selectedCompany).then(res => {
                setSelectedCompany(undefined);
                refresh();
            })
        }
        setDeleteDialogOpen(false);
        setDeleteReason('');
    }

    const classes = useStyles();
    return (
        <Grid>
            <Grid xs={12} container alignItems="flex-start" justifyContent="center" >
                <Grid item xs={6}>
                    <Grid container xs={12}>
                        <Grid item xs={4} lg={8}></Grid>
                        <Grid item xs={2} lg={1}>
                            <Typography text="Active" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
                        </Grid>
                        <Grid item xs={2} lg={1}>
                            <Switch
                                checked={!showActive}
                                onChange={() => setShowActive(!showActive)}
                                color="secondary"
                                name="checkedB"
                            />
                        </Grid>
                        <Grid item xs={2} lg={1}>
                            <Typography text="Deleted" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
                        </Grid>
                    </Grid>
                    <Grid xs={10} item>
                        {
                            companies !== undefined && companies.filter(type => type.visible === showActive).map(row => (
                                <Dictionary name={row.name} object={row} deleted={showActive === true ? false : true} description={showActive === true ? row.description : row.deleted_reason} other={row.other} id={row.id} deleteProperty={(row: dictionary) => deleteCageCode(row)}></Dictionary>
                            ))
                        }
                    </Grid>
                </Grid>
                <Dialog
                    visible={deleteDialogOpen}
                    max_width={"md"} title={"Are you sure you want to delete this?"}
                    context={
                        <div>
                            <Box display="flex" justifyContent="center" width="100%" m={1} p={1}>
                                <Input
                                    label_text={"Type a reason"} value={deleteReason} help_text=""
                                    onTextChange={(value: string) => onReasonEdit(value)} type="text" width={50} multiline={false} />
                            </Box>
                            <Box display="flex" justifyContent="center" m={1} p={1}>
                                <Box p={1} >
                                    <Button variant="contained" color="primary" onClick={() => confirmDelete()}>Confirm delete</Button>
                                </Box>
                                <Box p={1}>
                                    <Button variant="contained" color="secondary" onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                                </Box>
                            </Box>
                        </div>
                    }
                    onClose={(status: any) => setDeleteDialogOpen(false)}
                    strict={false}
                    show_options={false}
                />
                <Grid container xs={5}>
                    <Grid item xs={12}>
                        <br />
                        <Input
                            multiline={false}
                            label_text="Company Name"
                            help_text="Please type in a name for the new company"
                            type="text"
                            value={newCompanyName}
                            width={50}
                            onTextChange={(value: string) => setNewCompanyName(value)}
                        />
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <br />
                        <Input
                            multiline={false}
                            label_text="Description"
                            help_text="Please type in a descrption for the company"
                            type="text"
                            value={newCompanyDescription}
                            width={50}
                            onTextChange={(value: string) => setNewCompanyDescription(value)}
                        />
                    </Grid>
                    <br />
                    <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addCageCode()} >
                        Add Company
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default EditCompanies;
