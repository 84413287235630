import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import SecurityApi from '../../api/security';
import Report from './Report';
import InformationPage from './InformationPage';
import { securityResult } from '../../types/security';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { Tabs } from '../../MaterialUiComponents';
import taskApi from './../../api/tasks';
import ReportSearch from './ReportSearch';

function Security() {

  const [selectedReport, setSelectedReport] = useState<securityResult>();
  const [bays, setBays] = useState<{ "name": string, id: number }[]>([]);
  const store = useSelector((state: RootState) => state);
  const user = store.user.me;
  const isSecurityAdmin = user.user_clearence_groups.includes("Security Edit");
  const [tabValue, setTabValue] = useState(1);
  const [reportStarted, setReportStarted] = useState<boolean>(false);
  const [tails, setTails] = useState<string[]>([]);

  // Check for open report and open if there is one, and get bays on initial load
  useEffect(() => {
    taskApi.GetAllTailNumbers().then(res => {
      setTails(res.data);
    });
    getOpenReport();
    getBays();
  }, []);

  const refresh = () => {
    if (selectedReport?.report.closed) {
      openReport(selectedReport.report.report_id);
    }
    else {
      getOpenReport();
    }
  }

  const getOpenReport = () => {
    SecurityApi.GetOpenReport().then(report => {
      if (report.status !== false) {
        setSelectedReport(report.data);
        setReportStarted(true);
        setTabValue(1);
      }
      else {
        setReportStarted(false);
        setSelectedReport(undefined);
        setTabValue(0);
      }
    });
  }
  // If tab 1 selected and no report is open then start a new report and open it
  const changeTab = (tabValue: number) => {
    if (tabValue === 1) {
      if (reportStarted === false && isSecurityAdmin) {
        startReport();
      }
      getOpenReport();
    }
    if (tabValue === 0) {
      setSelectedReport(undefined);
    }
    setTabValue(tabValue);
  }
  const closeReport = () => {
    changeTab(0);
    setSelectedReport(undefined);
  }

  const startReport = () => {
    SecurityApi.CreateNewDailyReport(user.user_id).then(res => {
      getOpenReport();
    });
  }

  const getBays = () => {
    SecurityApi.GetAllBays("BAY").then(bays => {
      // Adapting bays so it can be used with work_bay_number as id so it can be used in a Select component
      const baysTemp = bays.data.filter(s => s.name.includes("Bay")).map(bay => ({
        "id": bay.work_bay_number,
        "name": bay.name
      }));
      setBays(baysTemp);
    });
  }
  const openReport = (id: number) => {
    setTabValue(1);
    SecurityApi.GetReportById(id).then(res => {
      setSelectedReport(res.data.Result);
    });
  }

  return (
    <Grid container xs={12}>
      < Tabs
        tabNames={["Overview", "Report", "Information Board"]}
        onChangeTabValue={(tabValue: number) => changeTab(tabValue)}
        tabValue={tabValue}
      />
      {selectedReport !== undefined && tabValue === 1 &&
        <Report tails={tails} selectedReport={selectedReport} bays={bays} closeReport={() => closeReport()} refresh={() => refresh()} isSecurityAdmin={isSecurityAdmin} />
      }
      {tabValue === 0 && selectedReport === undefined &&
        <ReportSearch tails={tails} openReport={(id: number) => openReport(id)}></ReportSearch>}
      {tabValue === 2 &&
        <InformationPage isSecurityAdmin={isSecurityAdmin} />
      }
    </Grid>
  );
};
export default Security;