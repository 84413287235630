import { useState } from 'react';
import { Grid } from '@mui/material';
import { Tabs } from '../../MaterialUiComponents';
import LiteHandover from '../Lite/LiteHandover';

function ReykjavikLineSupervisor() {
    const [tabValue, setTabValue] = useState(0);
    const allTails = ["TF-FXG", "TF-FXI", "TF-FXK", "TF-FXA", "TF-FXH", "TF-FXE"];
    return (
        <Grid>
            <Tabs
                tabNames={["Handover"]}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                tabValue={tabValue}
            />
            {tabValue === 0 && <LiteHandover workPlace='RVK' allTails={allTails} />}
        </Grid>
    );
};
export default ReykjavikLineSupervisor;
