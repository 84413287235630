import { FunctionComponent, useEffect, useState } from 'react';
import { Grid, IconButton, Button } from '@mui/material';
import { Typography } from '../../MaterialUiComponents';
import liteApi from '../../api/lite';
import { redirectToMxi } from '../../config/mxiProvider';
import { Link } from '@mui/icons-material';
import { MxiEventsForUsersPackageOverviewByTailNUmber } from '../../types/line';

interface LiteHandoverMxiPackageInformationProps {
    tabValue: number,
    reportId: number
}

const LiteHandoverMxiPackageInformation: FunctionComponent<LiteHandoverMxiPackageInformationProps> = ({ tabValue, reportId }) => {
    const [mxiEvents, setMxiEvents] = useState(Array<MxiEventsForUsersPackageOverviewByTailNUmber>());
    useEffect(() => {
        const isTurnCheck = tabValue === 2 ? true : false;
        liteApi.GetMxiEventsForShift(reportId, isTurnCheck).then(res => {
            setMxiEvents(res.data);
        })
    }, [tabValue]);

    if (tabValue !== 0) {
        return (
            <Grid>
                <Grid container>
                    {mxiEvents !== undefined && mxiEvents.map(aircraft => (
                        <Grid>
                            <Typography text={aircraft.tail_number} variant='h2' fontWeight='bolder' />
                            {
                                aircraft.packages.map((wo, index) => (
                                    <Grid >
                                        <Grid container>
                                            <Typography text={index + 1} margin={[0, 2, 0, 0]} />
                                            <Typography text={wo.wo_name} textColor='black' margin={[0, 2, 0, 0]} />
                                            <Typography text={wo.wo_status} textColor={wo.wo_status_color} />
                                            <Button style={{ marginTop: '-15px' }} onClick={() => redirectToMxi(wo.wo_barcode)}><IconButton><Link color="primary" /></IconButton></Button>
                                        </Grid>
                                        {
                                            wo.mxiEvents.map(task => (
                                                <Grid container>
                                                    <Typography text={"-"} fontWeight={'bolder'} margin={[0, 2, 0, 2]} />
                                                    <Typography text={task.task_name} textColor='black' />
                                                    <Typography text={task.action_taken_text} textColor='black' />
                                                    <Typography text={"perf. by"} margin={[0, 1, 0, 1]} textColor='black' />
                                                    <Typography text={task.employee_name} textColor='black' />
                                                    <Typography text={"AT"} margin={[0, 1, 0, 1]} textColor={"black"} />
                                                    <Typography text={task.action_taken_date.toString()} textColor='black' />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                ))
                            }
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );
    } else {
        return (
            <></>
        );
    }
};
export default LiteHandoverMxiPackageInformation;