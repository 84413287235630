import { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { myPayment } from '../../types/extraPayments';
import { Grid, TableCell, TableRow } from '@mui/material';
import { Snackbar, Table, Typography } from '../../MaterialUiComponents';
import PaymentDialog from './PaymentDialog';
import { Search } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover,
        border: '5px solid black !important'
    },
    cursor: {
        cursor: "pointer",
    }
}));

type PaymentTableProps = {
    payments: myPayment[],
    viewType: "user" | "timeControl" | "supervisor" | "all"
    refresh: Function
}

const PaymentTable: FunctionComponent<PaymentTableProps> = ({ payments = [], viewType, refresh }) => {

    const [showPaymentDialog, setShowPaymentDialog] = useState<boolean>(false);
    const [selectedPayment, setSelectedPayment] = useState<myPayment>();
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    const onRowClick = (row: myPayment) => {
        setSelectedPayment(row);
        setShowPaymentDialog(true);
    }
    // Makes sure to refresh data each time when the dialog closes
    const onCloseDialog = (result: boolean) => {
        if (result === true) {
            refresh();
        }
        setShowPaymentDialog(false);
    }

    const generateTableBody = (request: myPayment, index: number) => {
        {/* Color rules for first column based on status. Also makes sure that supervisor can only approve requests assigned to them  */ }
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''}>
                <TableCell>{index}</TableCell>
                <TableCell
                    style={request.status === "Waiting for supervisor" ? { backgroundColor: "orange" } : request.status === "Confirmed" ? { backgroundColor: "green" } :
                        request.status === "Waiting for Time control" ? { backgroundColor: "blue", color: "white" } : { backgroundColor: "red" }}>
                    <Typography text={request.status} variant="body2" fontWeight="bold" align="center" />
                </TableCell>
                <TableCell>{request.in_date_text_short}</TableCell>
                <TableCell>{request.in_date_text_clock}</TableCell>
                <TableCell>{request.out_date_text_clock}</TableCell>
                <TableCell>{request.duration_text}</TableCell>
                <TableCell>{request.payment_types.map(row => row)}</TableCell>
                <TableCell>{request.description}</TableCell>
                <TableCell>{request.employee_name}</TableCell>
                <TableCell>{request.status === "Confirmed" ? request.supervisor_name + " - " + request.supervisor_accepted_date_text : request.supervisor_name}</TableCell>
                <TableCell><Search className={classes.cursor} onClick={() => onRowClick(request)} /></TableCell>
            </TableRow>
        )
    }

    const classes = useStyles();
    return (
        <Grid justifyContent="center" display="table" container item spacing={2} marginBottom={5} xs={12}>
            <Table
                headers={["", "Status", "Date", "In", "Out", "Duration", "Payment types", "Description", "Mechanic", "Supervisor", "Details"]}
                rows={payments}
                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                pagination={true}
                defaultRowsPerPage={10}
                skipIndexCol={true}
                displayEmptyText={true}
                emptyTableText="No payments found"
            />
            {selectedPayment !== undefined &&
                <PaymentDialog displaySnackbar={(message: string) => setSnackbarMessage(message)} payment={selectedPayment} visible={showPaymentDialog} onClose={(result: boolean) => onCloseDialog(result)} viewType={viewType} />
            }
            <Snackbar onSnackBarClose={() => setSnackbarMessage("")} open={snackbarMessage.length > 0} message={snackbarMessage} severity='success' autoHide={true} horizontalPosition='right' />
        </Grid>
    );
};
export default PaymentTable;