import { FunctionComponent, useEffect, useState } from 'react';
import { Typography } from '../../MaterialUiComponents';

type TimerProps = {
    startTime?: Date
}

const Timer: FunctionComponent<TimerProps> = ({ startTime }) => {

    const [hours, setHours] = useState<string>();
    const [minutes, setMinutes] = useState<string>();
    const [seconds, setSeconds] = useState<string>();

    // Timer that takes in a start time and starts counting the seconds since start time. Then calculates hours, minutes and seconds based on total seconds.
    // Calculations are stored as string and 0 added in front if the value is less then 10
    useEffect(() => {
        if (startTime !== undefined) {
            setTimeout(() => {
                const tempDuration = Math.abs(Math.floor((new Date().getTime() - new Date(startTime).getTime()) / 1000));
                const tempHours = (Math.floor(tempDuration / 3600))
                const tempMinutes = Math.floor((tempDuration - (tempHours * 3600)) / 60)
                const tempSeconds = tempDuration - (tempHours * 3600) - (tempMinutes * 60);
                if (tempHours < 10) { setHours("0" + tempHours) }
                else { setHours(tempHours.toString()) }
                if (tempMinutes < 10) { setMinutes("0" + tempMinutes) }
                else { setMinutes(tempMinutes.toString()) }
                if (tempSeconds < 10) { setSeconds("0" + tempSeconds) }
                else { setSeconds(tempSeconds.toString()) }
            }, 1000)
        }
    })
    return (
        hours !== undefined ? <Typography text={`${hours} : ${minutes} : ${seconds}`} variant="subtitle1" /> : <div></div>
    );
};
export default Timer