import { settings } from '../config';
import { TimeControlBarcode, EditTimeControlBarcode, CreateTimeControlBarcode } from '../types/timeControlBarcodes';
import { getToken } from './../global/TokenValidator';

class TimeControlBarcodes {
    // Get Requests
    getAllBarcodes = async (): Promise<{ status: Boolean, data: TimeControlBarcode[], message: string }> => {
        return fetch(`${settings.HOST}/BarcodesEditor/GetAllBarcodes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    // Put Requests
    editTimeControlBarcode = async (timeControlBarcode: EditTimeControlBarcode): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/BarcodesEditor/EditTimeControlBarcode`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(timeControlBarcode)
        }).then(x => {
            return x.json()
        })
    }
    // Post Requests
    createTimeControlBarcode = async (timeControlBarcode: CreateTimeControlBarcode): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/BarcodesEditor/CreateTimeControlBarcode`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(timeControlBarcode)
        }).then(x => {
            return x.json()
        })
    }
    removeTimeControlBarcodeById = async (id: number): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/BarcodesEditor/removeTimeControlBarcodeById/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
}
const TimeControlBarcodesApi = new TimeControlBarcodes();
export default TimeControlBarcodesApi;