import React, { FunctionComponent, useState } from 'react';
import { Grid, Button, ButtonGroup, TextField } from '@mui/material';
import { Search, DirectionsWalk, Work } from '@mui/icons-material'
import { Typography } from './../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import { securityResult } from '../../types/security';
import SecurityApi from '../../api/security';

const useStyles = makeStyles(theme => ({
    counter: {
        '&:disabled': {
            backgroundColor: theme.palette.action.active,
            color: 'white'
        },
    },
    counterRow: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: '12px'
    },
    logos: {
        marginTop: '12px'
    }
}));

type AmountControlsProps = {
    selectedReport: securityResult,
    refresh: Function,
    user: number,
    isSecurityAdmin: Boolean
}

const AmountControls: FunctionComponent<AmountControlsProps> = ({ selectedReport, refresh, user, isSecurityAdmin }) => {
    const [handSearches, setHandSearches] = useState<number>(selectedReport.report.amount_hand_searched);
    const [employees, setEmployees] = useState<number>(selectedReport.report.amount_employee_entered);
    const [luggages, setLuggages] = useState<number>(selectedReport.report.amount_luggages);
    const classes = useStyles();

    const onEmployeeSubmit = () => {
        SecurityApi.UpdateAmountEmployeeEntered(employees, selectedReport.report.report_id, user).then(res => {
            refresh();
        });
    }
    const onSearchesSubmit = () => {
        SecurityApi.UpdateAmountHandSearches(handSearches, selectedReport.report.report_id, user).then(res => {
            refresh();
        });
    }
    const onBaggagesSubmit = () => {
        SecurityApi.UpdateAmountLuggages(luggages, selectedReport.report.report_id, user).then(res => {
            refresh();
        });
    }
    const onEmployeesEnteredChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === null || event.target.value === undefined || event.target.value === "") {
            setEmployees(parseInt("0"));
        }
        else {
            if (typeof parseInt(event.target.value) === "number") {
                setEmployees(parseInt(event.target.value));
            }
        }
    }
    const onSearchesEnteredChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === null || event.target.value === undefined || event.target.value === "") {
            setHandSearches(0);
        }
        else {
            if (typeof parseInt(event.target.value) === "number") {
                setHandSearches(parseInt(event.target.value));
            }
        }
    }
    const onBaggagesEnteredChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === null || event.target.value === undefined || event.target.value === "") {
            setLuggages(0);
        }
        else {
            if (typeof parseInt(event.target.value) === "number") {
                setLuggages(parseInt(event.target.value));
            }
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} lg={4} className={classes.counterRow}>
                {isSecurityAdmin && !selectedReport.report.closed ?
                    <ButtonGroup size="small" fullWidth>
                        <Grid container xs={4} className={classes.logos}>
                            <Grid item xs={3}>
                                <DirectionsWalk />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography text="How many Employees" variant="subtitle1" />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" xs={8} spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Employees"
                                    size="small"
                                    defaultValue={0}
                                    variant="standard"
                                    value={employees === 0 ? "" : employees}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onEmployeesEnteredChanged(event)}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Button className={classes.button} color="primary" variant="contained" onClick={() => onEmployeeSubmit()}>Submit</Button>
                            </Grid>
                        </Grid>
                    </ButtonGroup>
                    :
                    <Grid><Typography text="Employees" variant="subtitle1" />{employees}</Grid>
                }
            </Grid>
            <Grid item xs={12} lg={4} className={classes.counterRow}>
                {isSecurityAdmin && !selectedReport.report.closed ?
                    <ButtonGroup size="small" fullWidth>
                        <Grid container xs={4} className={classes.logos}>
                            <Grid item xs={3}>
                                <Search />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography text="How many Searches" variant="subtitle1" />
                            </Grid>
                        </Grid>
                        <Grid container xs={8} spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Searches"
                                    size="small"
                                    defaultValue={0}
                                    variant="standard"
                                    value={handSearches === 0 ? "" : handSearches}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSearchesEnteredChanged(event)}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Button className={classes.button} color="primary" variant="contained" onClick={() => onSearchesSubmit()}>Submit</Button>
                            </Grid>
                        </Grid>
                    </ButtonGroup>
                    :
                    <Grid><Typography text="Searches" variant="subtitle1" />{handSearches}</Grid>
                }
            </Grid>
            <Grid item xs={12} lg={4} className={classes.counterRow}>
                {isSecurityAdmin && !selectedReport.report.closed ?
                    <ButtonGroup size="small" fullWidth>
                        <Grid container xs={4} className={classes.logos}>
                            <Grid item xs={3}>
                                <Work />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography text="How many Baggages" variant="subtitle1" />
                            </Grid>
                        </Grid>
                        <Grid container xs={8} spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Baggages"
                                    size="small"
                                    defaultValue={0}
                                    variant="standard"
                                    value={luggages === 0 ? "" : luggages}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onBaggagesEnteredChanged(event)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button className={classes.button} color="primary" variant="contained" onClick={() => onBaggagesSubmit()}>Submit</Button>
                            </Grid>
                        </Grid>
                    </ButtonGroup>
                    :
                    <Grid><Typography text="Baggages" variant="subtitle1" />{luggages}</Grid>
                }
            </Grid>
        </Grid>
    );
};
export default AmountControls;
