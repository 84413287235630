import { FunctionComponent, useState } from 'react';
import { Box, Button, Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Dialog, Input, Typography } from './../../MaterialUiComponents';
import { CertificateData } from './../../types/certificates'
import IcelandairLogo from '../../assets/icelandairLogo.svg';
import { EmployeeSearch } from '../../components'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import { makeStyles } from '@mui/styles';
import { Chat, Edit } from '@mui/icons-material';
import authApi from '../../api/auth';
import MessageBox from '../../components/Messages/MessageBox';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
    approval: {
        marginLeft: '2%',
        marginTop: '2%'
    },
    certInfo: {
        marginTop: '1%'
    },
    icelandairLogo: {
        height: '50px',
        bottom: '5px',
        position: 'relative',
    },
    employeeSearch: {
        marginBottom: '2%'
    },
}));

type CertificateInfoProps = {
    selectedCertificate: CertificateData,
    closeCertificate: Function,
    infoType: 'top' | 'bottom'
    viewType: 'view' | 'edit' | 'approval' | 'history',
    shouldBeAbleToClose?: boolean,
}

const CertificateTopInfo: FunctionComponent<CertificateInfoProps> = ({ selectedCertificate, closeCertificate, infoType, viewType, shouldBeAbleToClose }) => {
    const timeStamp = new Date();
    const timeStampFormatted = DateTime.fromJSDate(timeStamp).toFormat('dd.LL.yyyy, HH:mm:ss');
    const classes = useStyles();
    const store = useSelector((state: RootState) => state);
    const allUsers = store.employees.all_employees;
    const [employeeName, setEmployeeName] = useState(selectedCertificate.mechanic_full_name);
    const [employeeSSN, setEmployeeSSN] = useState(selectedCertificate.mechanic_social_security_number);
    const [employeeNumber, setEmployeeNumber] = useState(selectedCertificate.mechanic_employee_number);
    const [employeeNumberDialog, setEmployeeNumberDialog] = useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);

    const onSelectedEmployeeRow = (employeeId: number) => {
        if (employeeId !== null) {
            const selectedEmployee = allUsers.filter(s => s.user_id === employeeId);
            setEmployeeName(selectedEmployee[0].name);
            setEmployeeSSN(selectedEmployee[0].ssn);
            selectedCertificate.mechanic_employee_id = selectedEmployee[0].Id;
            selectedCertificate.mechanic_full_name = selectedEmployee[0].name;
            selectedCertificate.mechanic_social_security_number = selectedEmployee[0].ssn;
        }
    }

    const confirmEmployeeNumber = () => {
        if (employeeNumber !== undefined) {
            authApi.UpdateHREmployeeNumber(employeeNumber, selectedCertificate.mechanic_employee_id).then(res => {
                selectedCertificate.mechanic_employee_number = employeeNumber;
                setEmployeeNumberDialog(false);
            })
        }
    }
    return (
        <Grid>
            {infoType === 'top' ?
                <Grid>
                    <Grid container xs={12}>
                        <Grid item xs={9}>
                            <Typography color='primary' variant='h1' text={"Icelandair - Authorization Certificate"} align='left' />
                        </Grid>
                        <Grid item xs={2}>
                            <img src={IcelandairLogo} className={classes.icelandairLogo} alt="Logo" />
                        </Grid>
                        {shouldBeAbleToClose !== false &&
                            <Grid item xs={1}>
                                <IconButton onClick={() => closeCertificate()} color="default" >
                                    <Close />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                    <hr />
                    {selectedCertificate.certificate_id === undefined &&
                        <Grid className={classes.certInfo} container xs={12}>
                            <Grid item xs={12}>
                                <Typography variant='h2' text={"Select Employee"} align='left' />
                            </Grid>
                            <Grid item xs={4} className={classes.employeeSearch}> <EmployeeSearch place_holder={"Write employees name"} onSelectedEemployee={(employeeId: number) => onSelectedEmployeeRow(employeeId)} /> </Grid>
                        </Grid>
                    }
                    <Grid container xs={12} className={classes.certInfo} >
                        <Grid item lg={2} xs={6}><Typography paragraph={true} fontWeight='bold' text='Certificate displayed at:' /></Grid>
                        <Grid item lg={10} xs={6}>{timeStampFormatted}</Grid>
                        <Grid item lg={2} xs={6}><Typography paragraph={true} margin={[0, 0, 0, 0]} fontWeight='bold' text='Employee number:' /></Grid>
                        <Grid container item lg={10} xs={6}>
                            <Typography paragraph={true} margin={[0, 0, 0, 0]} text={selectedCertificate.mechanic_employee_number !== undefined ? selectedCertificate.mechanic_employee_number : ''} />
                            {viewType === "edit" &&
                                <Grid style={{ height: "18px" }}>
                                    <IconButton style={{ height: "24px" }} onClick={() => setEmployeeNumberDialog(true)}><Edit color='primary' /></IconButton>
                                </Grid>
                            }
                        </Grid>
                        <Grid item lg={2} xs={6}><Typography paragraph={true} fontWeight='bold' text='Employee name:' /></Grid>
                        <Grid item lg={10} xs={6}>{employeeName}</Grid>
                        <Grid item lg={2} xs={6}><Typography paragraph={true} fontWeight='bold' text='Social Security Number:' /></Grid>
                        <Grid item lg={10} xs={6}>{employeeSSN}</Grid>
                        {((viewType === "approval" || viewType === "edit") || viewType === "view") &&
                            <Grid container>
                                <Grid item lg={2} xs={6}><Typography paragraph={true} fontWeight='bold' text='Jira Link :' /></Grid>
                                <Grid onClick={() => window.open(selectedCertificate.jira_link, '_blank')} item lg={10} xs={6}><Typography textColor='blue' text={selectedCertificate.jira_link === undefined ? "" : selectedCertificate.jira_link} cursor='pointer' /></Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                :
                infoType === 'bottom' &&
                <Grid>
                    <Grid container xs={12} className={classes.certInfo} >
                        <Grid item xs={2}><Typography paragraph={true} fontWeight='bold' text='Date of issue:' /></Grid>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}><Typography paragraph={true} fontWeight='bold' text='Approval sent by:' /></Grid>
                        <Grid item xs={10}>{selectedCertificate.approval_sent_by_full_name}</Grid>
                        <Grid item xs={2}><Typography paragraph={true} fontWeight='bold' text='Approved by' /></Grid>
                        <Grid item xs={10}>{selectedCertificate.approved_by_full_name}</Grid>
                    </Grid>
                    <Grid container xs={12} className={classes.certInfo} >
                        <Grid item xs={12}><Typography paragraph={true} fontWeight='bold' text="This authorization is limited to the extend of Icelandair's part-145 approval IS.145.002" /></Grid>
                    </Grid>
                    <Grid container xs={12} className={classes.certInfo} >
                        <Grid item xs={12}><Typography paragraph={true} fontWeight='bold' text="MR005 er 20.07.2022" /></Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        {((viewType === "approval" || viewType === "edit")) &&
                            <Grid container>
                                <Grid>
                                    <Typography textDecoration fontWeight='bold' text={"Admin notes"} margin={[0.5, 0, 0, 0]} />
                                </Grid>
                                <IconButton onClick={() => setMessageDialogOpen(true)}>
                                    <Chat color='primary' />
                                </IconButton>
                            </Grid>
                        }
                        <Dialog
                            visible={messageDialogOpen}
                            max_width={"md"} title={selectedCertificate.mechanic_full_name!}
                            context={
                                <Grid>
                                    <MessageBox id={selectedCertificate.mechanic_employee_id} editMode={true} type="Certificate" />
                                </Grid>
                            }
                            onClose={(status: any) => { setMessageDialogOpen(false); }}
                            strict={false}
                            show_options={false}
                        />
                    </Grid>
                </Grid>
            }
            <Dialog
                visible={employeeNumberDialog}
                max_width={"md"} title={"Edit Employee Number for " + selectedCertificate.mechanic_full_name}
                context={
                    <div>
                        <Grid container justifyContent={'center'}>
                            <Input width={60} multiline={false} help_text={""} label_text={"Please enter new employee number"} value={employeeNumber !== undefined ? employeeNumber : ""} onTextChange={(text: string) => setEmployeeNumber(text)} type="text" />
                        </Grid>
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <Box p={1}>
                                <Button variant="contained" color="primary" onClick={() => confirmEmployeeNumber()}>Save changes</Button>
                            </Box>
                        </Box>
                    </div>
                }
                onClose={(status: any) => setEmployeeNumberDialog(false)}
                strict={false}
                show_options={false}
            />
        </Grid>
    );
};
export default CertificateTopInfo;
