import { FunctionComponent } from 'react';
import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip, Typography, ListItem, Grid } from '@mui/material';

// Takes in title for header, body wrapped in ReactFragment so it can be sent in as HTML. Type defines the color scheme. Pleacement is optional, defaults to 'right'. Default styling for list has been removed
// Demo Example: 
// <Tooltip title="Test header" body={<React.Fragment><p>Testing HTML body text with list items</p><ul><li>List item number 1</li><li>List item number 2</li></ul></React.Fragment>} type="error">
// <Button className={classes.button} type="primary">Test button</Button>
// </Tooltip>

type TooltipProps = {
    title: string,
    values?: {
        name: string;
        text: string;
    }[],
    type: 'primary' | 'secondary' | 'error',
    placement?:
    'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top',
    children: ReactNode;
}

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(1)
    },
    fontBold: {
        fontWeight: 800
    },
    text: {
        display: 'inline'
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        opacity: 0.95
    },
    secondary: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.95
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        opacity: 0.95
    },
}));

const TooltipUI: FunctionComponent<TooltipProps> = ({ title, values, placement = 'right-start', type, ...props }) => {
    const classes = useStyles();
    const a = 0;
    if (a === 0) {
        return (
            <Tooltip classes={{ tooltip: classes[type] }} placement={placement} title={title}
                {...props}>
                <div>{props.children}</div>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip classes={{ tooltip: classes[type] }} placement={placement} title={
                <Grid>
                    <Typography className={`${classes.title} ${classes.fontBold}`} variant="h5">{title}</Typography>
                    {values && values.map(value => (
                        <ListItem disableGutters dense>
                            <Grid>
                                <Typography className={`${classes.text} ${classes.fontBold}`} variant="h6">{value.name}: </Typography>
                                <Typography className={classes.text} variant="h6"> {value.text}</Typography>
                            </Grid>
                        </ListItem>
                    ))}
                </Grid>
            } {...props}>
                <div>{props.children}</div>
            </Tooltip>
        );
    }
};
export default TooltipUI;