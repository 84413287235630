import { FunctionComponent, useState } from 'react';
import { Grid, Switch } from '@mui/material';
import { Typography } from '../../MaterialUiComponents';
import { FlightSchedule } from '../../types/line';
import FlightScheduleComponent from '../../components/Line/FlightScheduleComponent';

type MyFlightScheduleProps = {
    flights: FlightSchedule[],
    myFilteredFlights: FlightSchedule[],
}

const MyFlightSchedule: FunctionComponent<MyFlightScheduleProps> = ({ flights, myFilteredFlights }) => {

    const [toggleFlights, setToggleFlights] = useState<boolean>(false);
    return (
        <Grid>
            <Grid xs={4} container direction="row">
                <Typography align='center' variant='h3' text={"Assigned to me"} margin={[1, 0, 0, 0]} />
                <Switch
                    checked={toggleFlights}
                    onChange={() => setToggleFlights(!toggleFlights)}
                    color="secondary"
                    name="checkedB"
                />
                <Typography align='center' variant='h3' text={"Full List"} margin={[1, 0, 0, 0]} />
            </Grid>
            {toggleFlights ?
                <FlightScheduleComponent flights={flights} isMonitor={false} />
                :
                <FlightScheduleComponent flights={myFilteredFlights} isMonitor={false} />
            }
        </Grid>
    );
};

export default MyFlightSchedule;