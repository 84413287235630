import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './../../reducer';
import { Grid, Paper, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Dialog, SelectMultiple } from '../../MaterialUiComponents';
import { CalendarUI, ProfileImg } from "../../components";
import LineWorkpackageCard from "../Line/LineWorkpackageCard";
import crewPlanApi from './../../api/crewPlan';
import { Event } from '../../types/tasks';
import { MxiStaffManagementEmployee } from '../../types/staffManagement';
import { LineWorkPackages } from './../../types/line';
import staffmanagementApi from '../../api/staffmanagement';
import CrewPlanDialog from './CrewPlanDialog';

const useStyles = makeStyles(theme => ({
    userPhoto: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: "50%",
        marginLeft: "8px",
        marginTop: "5px"
    },
    legendLi: {
        marginRight: "10px",
        listStyleType: 'none',
        float: 'left'
    },
    legendUl:{
        listStyleType: 'none',
        alignContent:'center'
    },
    legendColorSpan:{
        fontSize: '18px'
    }
}));
const statusOptions = [
    { id: 0, name: "ACTV" },
    { id: 1, name: "IN WORK" },
    { id: 2, name: "CANCEL" },
    { id: 3, name: "COMPLETE" },
    { id: 4, name: "PAUSE" },
];
function CrewPlan() {
    const classes = useStyles();
    const store = useSelector((state: RootState) => state);
    const [pageTitle, setPageTitle] = useState(store.user.me.crew_plan_location);
    const [tasksOnCalendar, setTasksOnCalendar] = useState<Event[]>([]);
    const [tasksbyUser, setTasksByUser] = useState(Array<LineWorkPackages>);
    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<LineWorkPackages>();
    const [myCrew, setMyCrew] = useState(Array<MxiStaffManagementEmployee>);
    const [selectedOptions, setSelectedOptions] = useState<number[]>([0, 1, 4]);
    const [tasksByUserDialogOpen, setTasksByUserDialogOpen] = useState(false);
    const users = store.employees.all_employees;

    useEffect(() => {
        refresh()
        staffmanagementApi.GetAllMembersFromMyCrew().then(res => {
            if (res.status) {
                setMyCrew(res.data)
            }
        })
    }, [selectedOptions]);

    const refresh = () => {
        const tempSelectedStatus: string[] = [];
        selectedOptions.map(index => {
            tempSelectedStatus.push(statusOptions[index].name)
        });
        crewPlanApi.GetTasksAssignedByMyCrew().then(res => {
            if (res.status === false) {
                setPageTitle(store.user.me.crew_plan_location + "(" + res.message + ")")
            } else {
                let tempTasks: Array<Event> = []
                res.data.forEach(task => {
                    task.start = new Date(task.start)
                    task.end = new Date(task.end)
                    tempTasks.push(task)
                });
                tempTasks = tempTasks.filter(s => tempSelectedStatus.includes(s.task_details.status));
                setPageTitle(store.user.me.crew_plan_location + "(" + tempTasks.length + ")");
                setTasksOnCalendar(tempTasks);
            }
        });
    }

    const onEventClick = (event: Event) => {
        const taskDetails = event.task_details;
        const lineWorkpackage = {
            wo_number: taskDetails.wo_number,
            wo_barcode: taskDetails.wo_barcode,
            task_barcode: taskDetails.barcode,
            status: taskDetails.status,
            title: event.title,
            mxi_start_date_text: event.start_date_text,
            mxi_end_date_text: event.end_date_text,
            mxi_due_date_text: "",
            maintener_planned_date_text: "",
            tail_number: taskDetails.tail_number,
            scheduled_task_id: event.scheduled_task_id,
            is_task_critical: taskDetails.is_task_critical,
            is_on_hold: false,
            is_on_time: false,
            package_duration_text: "",
            aircraft_arrival_and_departure_times: ""
        } as LineWorkPackages;
        setSelectedEvent(lineWorkpackage);
        setEventDialogOpen(true);

    }

    const onDialogClose = () => {
        refresh();
        setEventDialogOpen(false);
    }
    const countHowManyTasksAssigned = (employee_name: string) => {
        let count = 0;
        tasksOnCalendar.map(s => {
            const item = s.task_details.assigned_employees_on_calendar.includes(employee_name);
            if (item) {
                count++;
            }
        })
        return count;
    }
    const onUserClicked = (userId: number) => {
        crewPlanApi.GetTasksAssignedEmployeeId(userId).then(res => {
            if (res.status) {
                const array: Array<LineWorkPackages> = [];
                res.data.map(s => {
                    const taskDetails = s.task_details;
                    const task = {
                        task_barcode: taskDetails.barcode,
                        status: taskDetails.status,
                        task_name: s.title,
                        mxi_start_date_text: s.start_date_text,
                        mxi_end_date_text: s.end_date_text,
                        is_task_critical: taskDetails.is_task_critical,
                        assigned_employees: taskDetails.assigned_employees_on_calendar,
                        wo_name: taskDetails.wo_name,
                    } as LineWorkPackages;
                    array.push(task);
                });
                setTasksByUser(array);
                setTasksByUserDialogOpen(true);
            }
        })
    }


    const getLegendStyle = (color: string) => {
        return {
            width: '16px', 
            height: '16px', 
            display: 'inline-block',
            backgroundColor: color,
            marginRight: "5px",
            marginLeft: "10px",
            marginTop: "20px"
        };
    };

    return (
        <Grid>
            <Typography text={pageTitle} align='center' variant='h1' color='primary' />
            <Grid container>
                {myCrew.map((user, index) => (
                    <Grid xs={6} sm={4} md={3} lg={3} xl={2}>
                        <Paper style={{ margin: "3px" }} onClick={() => onUserClicked(user.employee_id)}>
                            <Grid container xs={12}>
                                <Grid xs={1} justifyContent={'center'}>
                                    <ProfileImg imgKey={index} userName={users?.find(s => s.Id === user.employee_id)?.userName?.split('@')[0]} />
                                </Grid>
                                <Grid xs={9}><Typography text={user.employee_full_name} align='center' /></Grid>
                                <Grid>{countHowManyTasksAssigned(user.employee_full_name)}</Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Grid container xs={12}>
                <Grid xs={4} item justifyContent={'left'}>
                    <SelectMultiple
                        optionsObject={statusOptions}
                        onSelectedItem={(e: any) => setSelectedOptions(e.target.value)}
                        selectedItems={selectedOptions}
                        width={200}
                        filter_by_text={"Filter by status"}
                    />
                </Grid>
                <Grid item xs={6} justifyContent={'center'}>
                        <span style={{ ...getLegendStyle('#53C0E8')}}></span><span className={classes.legendColorSpan}> PRE</span>
                        <span style={{ ...getLegendStyle('#FFDB0C')}}></span><span className={classes.legendColorSpan}> OPEN</span>
                        <span style={{ ...getLegendStyle('#001A71')}}></span><span className={classes.legendColorSpan}> INSP</span>
                        <span style={{ ...getLegendStyle('#FFFFFF'), borderWidth: "1px", borderStyle: "solid", borderColor: "black"}}></span><span className={classes.legendColorSpan}> IN WORK</span>
                        <span style={{ ...getLegendStyle('#BE03FF')}}></span><span className={classes.legendColorSpan}> NON_ROUTINE</span>
                        <span style={{ ...getLegendStyle('#FF37BE')}}></span><span className={classes.legendColorSpan}> TEST</span>
                        <span style={{ ...getLegendStyle('#50E68C')}}></span><span className={classes.legendColorSpan}> CLOSE</span>
                        <span style={{ ...getLegendStyle('#6495ED')}}></span><span className={classes.legendColorSpan}> ADMIN</span>
                </Grid>
            </Grid>
            <CalendarUI
                isTaskAssignment={true}
                edit={false}
                totalPlanned={"-1"}
                fullScreen={true}
                setFullScreen={(fullScreen: boolean) => console.log("full screen")}
                clickedTaskDate={undefined}
                tasksOnCalendar={tasksOnCalendar}
                draggedTasks={[]}
                onDropFromOutside={() => { }}
                onEventDrop={() => { }}
                onCheckboxClicked={() => { }}
                onEventClick={(event: Event) => { onEventClick(event); }}
            />
            <Dialog
                visible={tasksByUserDialogOpen}
                max_width={"lg"}
                title={selectedEvent?.title}
                context={
                    <Grid container spacing={2}>
                        {tasksbyUser.map((task, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Card>
                                    <LineWorkpackageCard type='BAY-USER' task={task} onOpenDialogClicked={(taskBarcode: string) => { }} />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                }
                onClose={(status: any) => { setTasksByUserDialogOpen(false); setTasksByUser([]) }}
                strict={false}
                show_options={false}
            />
            <Dialog
                visible={tasksByUserDialogOpen}
                max_width={"lg"}
                title={selectedEvent?.title}
                context={
                    <Grid container spacing={2}>
                        {tasksbyUser.map((task, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Card>
                                    <LineWorkpackageCard type='BAY-USER' task={task} onOpenDialogClicked={(taskBarcode: string) => { }} />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                }
                onClose={(status: any) => { setTasksByUserDialogOpen(false); setTasksByUser([]) }}
                strict={false}
                show_options={false}
            />
            <Dialog
                visible={eventDialogOpen}
                max_width={"lg"}
                title={selectedEvent?.title}
                context={
                    <CrewPlanDialog myCrew={myCrew} selectedEvent={selectedEvent} />
                }
                onClose={(status: any) => { onDialogClose() }}
                strict={false}
                show_options={false}
            />
        </Grid >
    );
}
export default CrewPlan;