import { FunctionComponent } from "react";
import { MyLineTask } from "../../types/tasks";
import { Card, CardContent, Grid, IconButton } from '@mui/material';
import { Link } from '@mui/icons-material';
import { Typography } from '../../MaterialUiComponents';
import { redirectToMxi } from './../../config/mxiProvider';

type LineTaskProps = {
    myLineTasks?: MyLineTask[],
    loading: Boolean,
}

const LineTasks: FunctionComponent<LineTaskProps> = ({ myLineTasks, loading }) => {
    return (
        <Grid>
            {loading ?
                <Typography text={`Loading`} color={'primary'} variant={'h6'} align={'center'} margin={[0, 0, 1, 0]} />
                :
                (!loading && myLineTasks?.length !== undefined) &&
                    (myLineTasks?.length !== undefined && myLineTasks.length > 0) ?
                    <Grid container spacing={2}>
                        {myLineTasks.map((task, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Card>
                                    <CardContent>
                                        <Grid>
                                            <Typography text={`${task.name}`} color={'primary'} variant={'h3'} align={'center'} margin={[0, 0, 4, 0]} />
                                        </Grid>
                                        <Grid container direction={"row"} justifyContent='center' >
                                            <Typography text={`Status: ${' '}`} color={'primary'} variant={'h5'} align={'center'} margin={[0, 0, 1, 0]} />
                                            <Typography text={``} color={'primary'} variant={'h5'} align={'center'} margin={[1, 1, 1, 1]} />
                                            <Typography text={`${task.status}`} fontWeight="bold" textColor={`${task.status_color === '#FFFF66' ? '#ebcb00' : task.status_color}`} color={'primary'} variant={'h5'} align={'center'} margin={[0, 0, 1, 0]} />
                                        </Grid>
                                        <Grid>
                                            <Typography text={`Start date: ${task.start_date}`} color={'primary'} variant={'h6'} align={'center'} margin={[0, 0, 1, 0]} />
                                        </Grid>
                                        <Grid>
                                            <Typography text={`Due date: ${task.due_date}`} color={'primary'} variant={'h6'} align={'center'} margin={[0, 0, 1, 0]} />
                                        </Grid>
                                        {task.notes &&
                                            <Grid>
                                                <Typography text={`Notes: ${task.notes ? task.notes : "No written notes"}`} color={'primary'} variant={'h6'} align={'center'} margin={[0, 0, 1, 0]} />
                                            </Grid>
                                        }
                                        <Grid>
                                            <Typography text={`Tail number: ${task.tail_number ? task.tail_number : "No tail specified"}`} color={'primary'} variant={'h6'} align={'center'} margin={[0, 0, 1, 0]} />
                                        </Grid>
                                        <Grid item xs={2} >
                                            <IconButton onClick={() => redirectToMxi(task.barcode)} style={{ marginLeft: 8 }}><Link color="primary" /></IconButton>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <Typography text={`No tasks`} color={'primary'} variant={'h6'} align={'center'} margin={[0, 0, 1, 0]} />
            }
        </Grid>
    )
}

export default LineTasks;