import { useEffect, useState, FunctionComponent } from 'react';
import { Grid, TableRow, TableCell, IconButton } from '@mui/material';
import VacationApi from './../../api/vacation';
import { VacationRequest } from './../../types/vacation';
import { Dialog, Table, Typography, Input } from '../../MaterialUiComponents';
import { Delete } from '@mui/icons-material';

const MyVacationRequests: FunctionComponent = () => {
  const [myVacationRequests, setMyVacationRequests] = useState(Array<VacationRequest>());
  const [myVacationRequest, setMyVacationRequest] = useState<VacationRequest>();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRowDialogOpenView, setSelectedRowDialogOpenView] = useState(false);
  const [selectedRowDialogOpenDelete, setSelectedRowDialogOpenDelete] = useState(false);
  const [mechanicReason, setMechanicReason] = useState("");

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    VacationApi.GetAllOfMyVacationRequests().then(res => {
      if (res.status === true) {
        setMyVacationRequests(res.data);
      } else {
        setErrorMessage(res.message);
      }
    });
  }

  const generateTableBody = (row: VacationRequest, index: number) => {
    return (
      <TableRow hover style={{ background: row.half_day_checked ? '#f2e54f' : undefined }}>
        <TableCell>{index}</TableCell>
        <TableCell>{row.created_date_text}</TableCell>
        <TableCell>{row.department_name}</TableCell>
        <TableCell>{row.mechanic_reason}</TableCell>
        <TableCell>{row.vacation_from_date_string}</TableCell>
        <TableCell>{row.vacation_to_date_string}</TableCell>
        <TableCell>{row.time_control_last_modified_date_string} - {row.last_modified_by_employee_name}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.time_control_reason}</TableCell>
        <TableCell>{row.half_day_checked}</TableCell>
        <TableCell>
          <Grid container>
            {row.status === "Waiting for response" && <IconButton onClick={() => onDeleteSelectedRow(row)}><Delete color="primary" style={{ color: 'red' }} /></IconButton>}
          </Grid>
        </TableCell>
      </TableRow>
    )
  }
  const onDeleteSelectedRow = (selectedRow: VacationRequest) => {
    setMyVacationRequest(selectedRow);
    setSelectedRowDialogOpenDelete(true);
  }
  const onDeleteSelectedDialogClose = (status: boolean) => {
    if (status === true) {
      VacationApi.DeleteMyVacation(myVacationRequest!.vacation_request_id, mechanicReason).then(res => {
        refresh();
        setSelectedRowDialogOpenDelete(false);
      });
    } else {
      setSelectedRowDialogOpenDelete(false);
    }
  }

  return (
    <Grid>
      <Typography text="My Vacation Requests" align='center' variant='h1' />

      <Grid container xs={12} justifyContent={'center'}>
                        <span style={{ backgroundColor:'#f2e54f',
                                        width: '16px', 
                                        height: '16px', 
                                        display: 'inline-block',
                                        marginRight: "5px",
                                      marginTop: "5px"}}>
                        </span><span> Half day</span>
      </Grid>

      <Table
        headers={["Created date", "Workstation", "Mechanic reason", "from", "to", "Time control Modified", "Status", "Time control reason", ""]}
        rows={myVacationRequests}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={5}
        OnOrderByTitles={(order_by_text: string) => ""}
      />
      {errorMessage !== "" && <Typography text={errorMessage} align='center' variant='h3' />}
      <Dialog
        visible={selectedRowDialogOpenView}
        max_width={"xs"} title={"Edit Vacation request"}
        context={
          <Grid xs={12} style={{ justifyContent: 'center' }}>

          </Grid>
        }
        onClose={(status: any) => setSelectedRowDialogOpenView(false)}
        strict={false}
        show_options={false}
      />
      <Dialog
        visible={selectedRowDialogOpenDelete}
        max_width={"sm"} title={"Are you sure you want to delete this request"}
        context={
          <Grid xs={12} style={{ justifyContent: 'center' }}>
            <Input multiline={true} help_text={""} error={false} label_text={"Reason for delete request"} value={mechanicReason} onTextChange={(text: string) => setMechanicReason(text)} type="text" width={100} />
          </Grid>
        }
        onClose={(status: any) => onDeleteSelectedDialogClose(status)}
        strict={false}
        show_options={true}
      />
    </Grid>
  );
}
export default MyVacationRequests;