import { FunctionComponent, ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { Tooltip, Typography } from '../../MaterialUiComponents';

interface LinkProps {
    icon: ReactElement,
    extraIcon?: ReactElement | undefined
    extraIconName?: string | undefined,
    name: string,
    customColor?: "active" | undefined
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(1),
    },
    active: {
        backgroundColor: 'gray',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    icons: {
        color: theme.palette.common.white,
        opacity: 0.7,
        cursor: 'pointer',
    },
    extraIcon: {
        paddingLeft: theme.spacing(1),
    },
}));

const LinkInfo: FunctionComponent<LinkProps> = ({ icon, name, customColor, extraIcon, extraIconName }) => {
    const classes = useStyles();
    return (
        <Grid container className={customColor === 'active' ? classes.active : classes.container}>
            <Grid item xs={2} className={classes.icons}>
                <Tooltip title={name} type="secondary" placement="right">{icon}</Tooltip>
            </Grid>
            <Typography cursor="pointer" textColor='white' variant='h4' margin={[0.5, 0, 0, 1.5]} text={name} align='center' />
            {extraIcon !== null && extraIconName !== undefined ?
                <Grid item xs={3} className={classes.extraIcon}>
                    <Tooltip title={extraIconName} type="secondary" placement="right"> {extraIcon}</Tooltip>
                </Grid>
                : ''
            }
        </Grid>
    );
};
export default LinkInfo;
