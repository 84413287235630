import React, { FunctionComponent } from 'react';
import { FormControl, InputLabel, Input, FormHelperText, InputAdornment } from '@mui/material';

type InputUIProps = {
  label_text: string,
  value: string | number | undefined,
  onTextChange: Function,
  help_text: string,
  type: "text" | "number" | "password"
  width: number,
  multiline: boolean,
  icon?: JSX.Element,
  iconPlacement?: "start" | "end",
  error?: boolean,
  onKeyDown?: Function,
  disabled?: boolean,
  textAlign?: 'center' | 'left' | 'right'
  required?: boolean,
  autoFocus?: boolean
}

const InputUI: FunctionComponent<InputUIProps> = ({ label_text, required = false, value, onTextChange, help_text, type, width, multiline, icon, iconPlacement = "start", error = false, onKeyDown, disabled = false, textAlign = 'left', autoFocus = false }) => {
  return (
    <FormControl style={{ width: width + "%" }} variant="standard">
      <InputLabel htmlFor="component-helper" required={required}>{label_text}</InputLabel>
      <Input
        id="textfield"
        endAdornment={icon !== undefined && iconPlacement === "end" && <InputAdornment position="start">
          {icon}
        </InputAdornment>}
        startAdornment={icon !== undefined && iconPlacement === "start" && <InputAdornment position="start">
          {icon}
        </InputAdornment>}
        value={value}
        disabled={disabled}
        onChange={(e) => onTextChange(e.target.value)}
        aria-describedby="component-helper-text"
        inputProps={{ style: { textAlign: textAlign } }}
        type={type}
        multiline={multiline}
        onKeyDown={(keyNumber: React.KeyboardEvent) => onKeyDown !== undefined ? onKeyDown(keyNumber) : {}}
        error={error}
        autoFocus={autoFocus}
      />
      <FormHelperText id="component-helper-text">{help_text}</FormHelperText>
    </FormControl>
  );
};
export default InputUI