import { FunctionComponent, useState } from 'react';
import { Grid, Paper, TableRow, TableCell, IconButton, Divider } from '@mui/material';
import { Typography, Dialog } from '../../MaterialUiComponents';
import { EmployeeSearch } from '../../components';
import SkillApi from '../../api/skillsApi';
import { AssignedMechanicSkill, EmployeeAssigndToMechanicSkill, Skills } from '../../types/skills';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { makeStyles } from '@mui/styles';
import { Table } from '../../MaterialUiComponents';
import { Delete } from '@mui/icons-material';

type SkillsProps = {
    allSkills: Skills[],
    loading: boolean,
    setLoading: Function,
}

const useStyles = makeStyles((theme) => ({
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    delete: {
        color: 'red'
    },
    searchContainer: {
        marginLeft: "15%",
        marginBottom: '28px',
    },
    verticalDivider: {
        height: '100%',
        width: '2px',  // Width of the divider
        background: theme.palette.divider,  // You can customize the color here
    },
}));

const ManageSkills: FunctionComponent<SkillsProps> = ({ allSkills, setLoading }) => {
    const [selectedSkills, setSelectedSkills] = useState<number[]>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(-1);
    const [assignedMechanicSkills, setAssignedMechanicSkills] = useState<AssignedMechanicSkill[]>([]);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false);
    const [employeeAssigndToMechanicSkill, setEmployeeAssigndToMechanicSkill] = useState<EmployeeAssigndToMechanicSkill[]>([]);
    const [selectedEmployeeFromMechanicSkillList, setSelectedEmployeeFromMechanicSkillList] = useState<EmployeeAssigndToMechanicSkill>();
    const [isRemoveEmployeeFromSkillDialogOpen, SetRemoveEmployeeFromSkillDialogOpen] = useState<boolean>(false);

    const store = useSelector((state: RootState) => state);
    const selectedUser = store.employees.all_employees.find(s => s.user_id === selectedEmployeeId);

    const refreshEmployeeSkills = () => {
        setLoading(true);
        SkillApi.GetMechanicSkillsById(selectedEmployeeId).then(res => {
            setAssignedMechanicSkills(res.data);
            setLoading(false);
        });
    }

    const addSkillToMechanic = () => {
        setLoading(true);
        SkillApi.AssignMechanicSkills(selectedSkills, selectedEmployeeId).then(res => {
            refreshEmployeeSkills();
        });
    }
    const removeSkillFromMechanic = () => {
        setLoading(true);
        SkillApi.RemoveMechanicSkills(selectedSkills, selectedEmployeeId).then(res => {
            refreshEmployeeSkills();
        });
    }

    const removeEmployeeFromSkill = () => {
        setLoading(true);
        if (selectedEmployeeFromMechanicSkillList !== null && selectedEmployeeFromMechanicSkillList !== undefined) {
            SkillApi.RemoveMechanicSkills(selectedSkills, selectedEmployeeFromMechanicSkillList.EmployeeId).then(res => {
                refreshSkill();
            });
        }
    }

    const refreshSkill = () => {
        setLoading(true);
        SkillApi.GetEmployeesAssignedToMechanicSkillsById(selectedSkills[0]).then(res => {
            setEmployeeAssigndToMechanicSkill(res.data);
            setLoading(false);
        });
    }

    const onSelectMechanic = (employeeId: number) => {
        setSelectedEmployeeId(employeeId);
        setLoading(true);
        SkillApi.GetMechanicSkillsById(employeeId).then(res => {
            setAssignedMechanicSkills(res.data);
            setLoading(false);
        });
    }

    const onCloseAddDialog = (status: boolean) => {
        setIsAddDialogOpen(false);
        if (status === true) {
            addSkillToMechanic();
        }
        setSelectedSkills([]);
    }

    const onCloseRemoveDialog = (status: boolean) => {
        setIsRemoveDialogOpen(false);
        if (status === true) {
            removeSkillFromMechanic();
        }
        setSelectedSkills([]);
    }

    const onCloseRemoveEmployeeFromSkillDialog = (status: boolean) => {
        SetRemoveEmployeeFromSkillDialogOpen(false);
        if (status === true) {
            removeEmployeeFromSkill();
        }
    }

    const onSkillClicked = (skillId: number) => {
        if (selectedEmployeeId > 0) {
            setIsAddDialogOpen(true);
            setSelectedSkills([skillId]);
        } else {
            setLoading(true);
            setSelectedSkills([skillId]);
            SkillApi.GetEmployeesAssignedToMechanicSkillsById(skillId).then(res => {
                setEmployeeAssigndToMechanicSkill(res.data);
                setLoading(false);
            });
        }
    }

    const classes = useStyles();
    const generateTableBody = (row: AssignedMechanicSkill, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''}>
                <TableCell>{index}</TableCell>
                <TableCell>{row.SkillName}</TableCell>
                <TableCell>{row.CreatedDateText}</TableCell>
                <TableCell>
                    <IconButton onClick={() => { setIsRemoveDialogOpen(true); setSelectedSkills([row.SkillId]) }} className={classes.delete} >
                        <Delete />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    const generateTableBodySkillOverview = (row: EmployeeAssigndToMechanicSkill, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''}>
                <TableCell>{index}</TableCell>
                <TableCell>{row.Name}</TableCell>
                <TableCell>{row.AssignedAtDate}</TableCell>
                <TableCell>
                    <IconButton onClick={() => { SetRemoveEmployeeFromSkillDialogOpen(true); setSelectedEmployeeFromMechanicSkillList(row) }} className={classes.delete} >
                        <Delete />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Grid >
            <Grid className={classes.searchContainer}>
                <Grid>
                    <EmployeeSearch
                        inputWidth={75}
                        place_holder={"Write employee name"}
                        onSelectedEemployee={(employeeId: number) => { onSelectMechanic(employeeId) }}
                        customCloseFunction={() => { }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item style={{ width: '31%', cursor: 'pointer' }}>
                    <Typography variant={'h2'} align={'center'} text={"All skills"} margin={[0, 0, 0, 0]} />
                    {
                        allSkills !== undefined && allSkills.filter(type => type.Visible === true).map((row, index) => (
                            <Paper style={{ width: '90%', height: '35px', margin: '8px', paddingTop: '4px', cursor: 'pointer' }} key={index} elevation={10} variant="elevation" onClick={() => { onSkillClicked(row.Id) }}>
                                <Grid container alignContent="center" alignItems="center" >
                                    <Grid item xs={12}>
                                        <Typography cursor={"pointer"} align={'center'} text={row.Skill} margin={[0, 0, 0, 0]} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))
                    }
                </Grid>
                <Divider orientation="vertical" flexItem style={{ width: '6px', marginRight: '42px' }} />
                <Grid item style={{ width: '50%' }}>
                    {(selectedUser !== null && selectedEmployeeId > 0) &&
                        <Typography text={`Skills assigned to ${selectedUser?.name}`} color={'primary'} variant={'h2'} align={'left'} margin={[0, 0, 2, 0]} />
                    }
                    {(assignedMechanicSkills.length > 0 && selectedEmployeeId > 0) &&
                        <Table
                            headers={["Mechanic Skill", "Assigned at", ""]}
                            rows={assignedMechanicSkills}
                            generateBody={(row: any, index: number) => generateTableBody(row, index)}
                            defaultRowsPerPage={100}
                        />
                    }
                    {((selectedEmployeeId <= 0 || selectedEmployeeId === null) && selectedSkills.length > 0) &&
                        <Typography text={`Emplyees with skill ${allSkills.find(s => s.Id === selectedSkills[0])?.Skill}`} color={'primary'} variant={'h2'} align={'left'} margin={[0, 0, 2, 0]} />
                    }
                    {(employeeAssigndToMechanicSkill.length > 0 && (selectedEmployeeId <= 0 || selectedEmployeeId === null) && selectedSkills.length > 0) &&
                        <Table
                            headers={["Employee", "Assigned at", ""]}
                            rows={employeeAssigndToMechanicSkill}
                            generateBody={(row: any, index: number) => generateTableBodySkillOverview(row, index)}
                            defaultRowsPerPage={100}
                        />
                    }
                </Grid>
            </Grid>
            <Dialog
                visible={isAddDialogOpen}
                max_width={"md"}
                title={`Add skill ${allSkills.find(s => s.Id === selectedSkills[0])?.Skill} to ${selectedUser?.name}`}
                context={
                    <Grid>
                    </Grid>
                }
                onClose={(status: any) => { onCloseAddDialog(status) }}
                strict={false}
                show_options={true}
            />
            <Dialog
                visible={isRemoveDialogOpen}
                max_width={"md"}
                title={`Remove skill ${allSkills.find(s => s.Id === selectedSkills[0])?.Skill} from ${selectedUser?.name}`}
                context={
                    <Grid>
                    </Grid>
                }
                onClose={(status: any) => { onCloseRemoveDialog(status) }}
                strict={false}
                show_options={true}
            />
            <Dialog
                visible={isRemoveEmployeeFromSkillDialogOpen}
                max_width={"md"}
                title={`Remove skill ${allSkills.find(s => s.Id === selectedSkills[0])?.Skill} from ${selectedEmployeeFromMechanicSkillList?.Name}`}
                context={
                    <Grid>
                    </Grid>
                }
                onClose={(status: any) => { onCloseRemoveEmployeeFromSkillDialog(status) }}
                strict={false}
                show_options={true}
            />
        </Grid >
    )
}

export default ManageSkills