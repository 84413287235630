import { settings } from '../config';
import { getToken } from './../global/TokenValidator';

class WorkPackages {
    CreateWorkPackageInfo = async (manHours: number, woBarcode: string, area: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/WorkPackages/CreateWorkPackageInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    man_hours: manHours,
                    wo_barcode: woBarcode,
                    area: area,
                }
            )
        }).then(x => {
            return x.json()
        })
    }
}
const WorkPackagesApi = new WorkPackages();
export default WorkPackagesApi;