import React, { FunctionComponent } from 'react';
import { Dialog, DialogTitle, IconButton, DialogActions, DialogContent, Button, Grid, Slide } from '@mui/material';
import { Typography } from '../';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import { TransitionProps } from '@mui/material/transitions';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: "1px",
    top: "1px"
  },
  printerButton: {
    position: 'absolute',
    right: "30px",
    top: "1px"
  },
}));

type DialogUIProps = {
  visible: boolean,
  max_width: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false,
  title: string,
  print_icon_visible?: boolean | undefined,
  context: React.ReactNode,
  onClose: Function,
  strict: boolean,
  show_options: boolean,
  transitionDuration?: number,
  hideCloseButton?: boolean,
  onPrint?: Function,
  cancelText?: string,
  isSlide?: boolean | undefined,
  fullScreen?: boolean | undefined
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogUI: FunctionComponent<DialogUIProps> = ({ fullScreen = false, isSlide = false, visible, max_width, title, context, print_icon_visible = false, onClose, strict, show_options, transitionDuration = 500, hideCloseButton = false, onPrint = () => console.log("No ink?"), cancelText }) => {
  const classes = useStyles();
  const handleCloseDialog = (status: boolean, pressed_button: boolean) => {
    if (strict === true) {
      if (pressed_button === true) {
        onClose(status);
      }
    } else {
      onClose(status);
    }
  }

  return (
    <Dialog
      open={visible}
      onClose={() => handleCloseDialog(false, false)}
      transitionDuration={transitionDuration}
      aria-labelledby="multi Dialog"
      maxWidth={max_width}
      fullWidth={true}
      fullScreen={fullScreen}
      TransitionComponent={isSlide ? Transition : undefined}
    >
      <DialogTitle >
        <Grid>
          <Grid>
            {print_icon_visible === true && <IconButton color="default" onClick={() => onPrint()} className={classes.printerButton}><PrintIcon /></IconButton>}
          </Grid>
          <Grid>
            {
              !hideCloseButton && <IconButton color="default" onClick={() => handleCloseDialog(false, true)} className={classes.closeButton}>
                <Close />
              </IconButton>
            }
          </Grid>
          <Typography align="center" variant="h3" text={title} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        {context}
      </DialogContent>
      {show_options === true && <DialogActions>
        <Button onClick={() => handleCloseDialog(false, true)} color="secondary">
          {cancelText !== undefined ? "Cancel" : "Cancel/Decline"}
        </Button>
        <Button onClick={() => handleCloseDialog(true, true)} color="primary">
          Confirm
        </Button>
      </DialogActions>}
    </Dialog>

  );
};
export default DialogUI;