import { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Table } from '../../MaterialUiComponents';
import { Grid, TableRow, TableCell } from '@mui/material';
import EditColumn from '../Security/EditColumn';
import { Request, CapabilityTools } from '../../types/capabilityList';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

interface ToolsTableProps {
    request: Request,
    setRequest: Function,
    viewMode: boolean,
}

const ToolsTable: FunctionComponent<ToolsTableProps> = ({ request, setRequest, viewMode }) => {
    const classes = useStyles();
    const [toolsEditIndex, setToolsEditIndex] = useState<number>(-1);

    const generateToolsTableBody = (row: CapabilityTools, index: number) => {
        const onDeletedTool = () => {
            const updatedList = request?.capability_tools.filter((_, i) => i !== (index - 1));
            setRequest((prevState: Request) => ({
                ...prevState,
                capability_tools: updatedList,
            }));
            setToolsEditIndex(-1);
        }
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{row.tool_name}</TableCell>
                <TableCell>{row.part_number}</TableCell>
                {!viewMode ? <EditColumn
                    abort={false}
                    index={index}
                    saveChanges={() => { setToolsEditIndex(-1) }}
                    deleteRow={() => onDeletedTool()}
                    editRow={toolsEditIndex}
                    setEditRow={(editRow: number) => { setToolsEditIndex(index) }}
                /> : <TableCell></TableCell>}
            </TableRow>
        )
    }
    return (
        <>
            <Grid style={{ marginTop: '16px' }} container justifyContent="center">
                <Typography color='primary' variant='h2' text={"Tools"} align='center' />
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item style={{ width: '80%' }}>
                    <Table
                        headers={["tool name", "part number", ""]}
                        rows={request.capability_tools}
                        generateBody={(row: any, index: number) => generateToolsTableBody(row, index)}
                        pagination={false}
                        skipIndexCol={true}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ToolsTable;