import { FunctionComponent, useState, useEffect } from 'react';
import { Grid, Paper, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LabourAssignedByTaskBarcode } from '../../types/tasks';
import crewPlanApi from './../../api/crewPlan';
import { Typography, Tabs } from '../../MaterialUiComponents';
import LineWorkpackageCard from "../Line/LineWorkpackageCard";
import PartDetails from '../../components/Parts/PartDetails';
import { LineWorkPackages } from './../../types/line';
import { MxiStaffManagementEmployee } from '../../types/staffManagement';
import { actionItem } from '../../types/warranty';
import TasksApi from "./../../api/tasks";
import { useSelector } from 'react-redux';
import { RootState } from './../../reducer';
import ProfileImg from '../../components/Images/ProfileImg';

const useStyles = makeStyles(theme => ({
    userPhoto: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: "50%",
        marginLeft: "8px",
        marginTop: "5px"
    },
}));
interface CrewPlanDialogProps {
    selectedEvent: LineWorkPackages,
    myCrew: Array<MxiStaffManagementEmployee>
}

const CrewPlanDialog: FunctionComponent<CrewPlanDialogProps> = ({ selectedEvent, myCrew }) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [assignedLabour, setAssignedLabour] = useState(Array<LabourAssignedByTaskBarcode>);
    const [selectedTaskActionItems, setSelectedTaskActionItems] = useState(Array<actionItem>);
    const [tabNames, setTabNames] = useState(["Task", "Action Notes (0)", "Parts(0)"]);
    const store = useSelector((state: RootState) => state);
    const users = store.employees.all_employees;

    useEffect(() => {
        crewPlanApi.GetMechanicTaskNotesByBarcode(selectedEvent.task_barcode).then(note => {
            let parts = 0;
            let notes = 0;
            TasksApi.GetAllPartsByTaskBarcode(selectedEvent.task_barcode).then(part => {
                if (part.status === true) {
                    parts = part.data.length;
                }
                if (note.status) {
                    notes = note.data.length;
                    setSelectedTaskActionItems(note.data);
                }
                setTabNames(["Task", `Action Notes (${notes})`, `Parts (${parts})`]);
            })
        })
        refreshAssignedLabour(selectedEvent.task_barcode);

    }, [])


    const refreshAssignedLabour = (barcode: string) => {
        crewPlanApi.GetLabourAssignedByTaskBarcode(barcode).then(res => {
            if (res.status) {
                setAssignedLabour(res.data);
            }
            else {
                setAssignedLabour([]);
            }
        })
    }
    const onChangeLabour = (user: MxiStaffManagementEmployee) => {
        crewPlanApi.ChangeLabour(selectedEvent.task_barcode, user.employee_id, selectedEvent.wo_barcode).then(res => {
            refreshAssignedLabour(selectedEvent.task_barcode);
        })
    }
    return (
        <Grid>
            <Grid item>
                <Tabs variant="fullWidth" tabNames={tabNames} tabValue={tabValue} onChangeTabValue={(tabValue: number) => setTabValue(tabValue)} />
                {tabValue === 0 && <Grid>
                    <LineWorkpackageCard task={selectedEvent} type='BAY' onOpenDialogClicked={() => { }} />
                    <Typography text={`Labour(${assignedLabour.length})`} variant='h2' align='center' margin={[2, 0, 2, 0]} />
                    <Grid container>
                        {myCrew.map((user, index) => (
                            <Grid xs={4}>
                                <Paper style={{ margin: "3px" }}>
                                    <Grid container xs={12}>
                                        <Grid xs={1} justifyContent={'center'}>
                                            <ProfileImg imgKey={index} userName={users?.find(s => s.Id === user.employee_id)?.userName?.split('@')[0]} />
                                        </Grid>
                                        <Grid xs={9}><Typography text={user.employee_full_name} align='center' /></Grid>
                                        <Grid xs={1}><Switch
                                            checked={assignedLabour.filter(s => s.employee_id === user.employee_id).length > 0}
                                            onChange={() => onChangeLabour(user)}
                                            color="secondary"
                                            name="checkedB"
                                        /></Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>}
                {tabValue === 1 && <Grid>
                    {selectedTaskActionItems?.map(action => (
                        <Paper variant="outlined" elevation={0}>
                            <Grid alignItems="center" container xs={12}>
                                <Grid item xs={3} md={2}>
                                    <Typography text={action.created_by_name} />
                                </Grid>
                                <Grid item xs={9} md={10}>
                                    <Typography display="inline" text={action.action_text} variant="body1" />
                                </Grid>
                            </Grid>
                        </Paper>))
                    }
                </Grid>}
                {tabValue === 2 &&
                    <PartDetails taskBarcode={selectedEvent?.task_barcode} />
                }
            </Grid>
        </Grid>
    );
}
export default CrewPlanDialog;