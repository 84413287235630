import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import store from './store';

console.log("Starting React app index.tsx ...");
ReactDOM.render(
	<StyledEngineProvider injectFirst>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<React.StrictMode>
					<CssBaseline />
					<App />
				</React.StrictMode>
			</ThemeProvider>
		</Provider>
	</StyledEngineProvider>,
	document.getElementById('root')
);

console.log("React app rendered successfully. index.tsx");