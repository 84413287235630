import { Me } from "../types/user";
import { UserActions } from "../types/actions";

const user = {
	me:{} as Me,	
}
export const userReducer = (state = { ...user }, action: UserActions) => {
	switch (action.type) {
		case 'USER_LOGIN':
			return { ...state, me: action.me };
		default:
			return state;
	}
}