import { FunctionComponent, ReactFragment } from 'react';
import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Paper } from '@mui/material';
import { Close, Person } from '@mui/icons-material';
import { Typography } from '../../MaterialUiComponents';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import ProfileImg from '../Images/ProfileImg';

type CollectionProps = {
    collectionList: any[],
    removeItem: Function,
    nameProperty?: string,
    imageProperty?: string,
    idProperty?: string,
    title: string,
    iconFallback?: ReactFragment,
    rows?: 1 | 2
    background?: boolean,
    edit?: boolean,
}

const Collection: FunctionComponent<CollectionProps> = ({ collectionList, title, edit = true, removeItem, nameProperty = "name", idProperty = "id", imageProperty = 'image', rows = 2, iconFallback = <Person />, background = true }) => {
    const useStyles = makeStyles(theme => ({
        itemContainer: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderRadius: '25px',
            border: '2px solid #737373',
            display: 'flex',
            marginTop: theme.spacing(2),
            alignItems: 'center',
            justifyContent: 'center',

        },
        photo: {
            height: theme.spacing(4),
            width: theme.spacing(4),
            borderRadius: "50%",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1)
        },
        removeIcon: {
            color: theme.palette.common.white
        },
        mainContainer: {
            marginBottom: theme.spacing(4),
            backgroundColor: theme.palette.action.focus,
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(2),
            borderRadius: '10px',
            color: theme.palette.common.black
        },
        mainContainerNoBackground: {
            marginBottom: theme.spacing(4),
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(2),
            borderRadius: '10px'
        },
    }));
    const classes = useStyles();
    const users = useSelector((state: RootState) => state.employees.all_employees);
    return (
        <Grid container xs={12} justifyContent="space-evenly" alignItems="flex-start" className={background ? classes.mainContainer : classes.mainContainerNoBackground} >
            <Grid item xs={12}>
                <Typography align="center" color="textPrimary" text={title} variant="h2"></Typography>
            </Grid>
            {collectionList.map((item, idx) => (
                <Grid item justifyContent="center" alignItems="center" xs={rows === 2 ? 5 : 10} >
                    <Paper className={classes.itemContainer} elevation={3}>
                        {item[imageProperty] !== null && item[imageProperty] !== undefined ?
                            <ProfileImg imgKey={idx} userName={users?.find(s => s.Id === item.id)?.userName.split('@')[0]} /> :
                            <div className={classes.photo}>{iconFallback}</div>
                        }
                        <Typography align="left" text={item[nameProperty]}></Typography>

                        {edit === true && <IconButton className={classes.removeIcon} onClick={() => removeItem(item[idProperty])}><Close /> </IconButton>}
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};
export default Collection