import { useState, useEffect, FunctionComponent } from 'react';
import { Grid, TableRow, TableCell, Button, Checkbox, FormControlLabel } from '@mui/material';
import { Table, Typography, DatePicker, Select, SelectMultiple } from './../../MaterialUiComponents';
import { Certificates, CertificateData, SelectObject } from './../../types/certificates';
import { CheckCircle, RemoveCircle, Info } from '@mui/icons-material';
import Certificate from './Certificate';
import { makeStyles } from '@mui/styles';
import CertificatesApi from '../../api/certificates';
import { EmployeeSearch, PDFCreate, ProfileImg } from '../../components';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';

const useStyles = makeStyles(theme => ({
    userPhoto: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: '50%'
    },
    topContainer: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    valid: {
        color: 'green'
    },
    resetButton: {
        height: '30px',
        marginTop: theme.spacing(1),
    },
    resultsAmount: {
        marginTop: theme.spacing(1),
        color: 'gray'
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    }
}));

type CertificateProps = {
    setLoading: Function
}

const History: FunctionComponent<CertificateProps> = ({ setLoading }) => {
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(0);
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateData>();
    const [selectedType, setSelectedType] = useState<string>("All Certificates");
    const [orderByText, setOrderByText] = useState("Issue Date");
    const [ascending, setAscending] = useState(false);
    const [selectedDateFrom, setSelectedDateFrom] = useState<Date | null>(null);
    const [selectedDateTo, setSelectedDateTo] = useState(new Date());
    const [certificatesResult, setCertificatesResult] = useState(Array<Certificates>());
    const [certificatesExportResult, setCertificatesExportResult] = useState(Array<Certificates>());
    const [ratingOptions, setRatingOptions] = useState<SelectObject[]>([]);
    const [selectedRatingOptions, setSelectedRatingOptions] = useState<number[]>([]);
    const [aircraftOptions, setAircraftOptions] = useState<SelectObject[]>([]);
    const [selectedAircraftOptions, setSelectedAircraftOptions] = useState<number[]>([]);
    const [onlyValid, setOnlyValid] = useState<boolean>(false);
    const [historicalLoaded, setHistoricalLoaded] = useState<boolean>(false);
    const [clearEmployee, setClearEmployee] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const users = useSelector((state: RootState) => state.employees.all_employees);

    // Gets rating and aircrafts for dropdown upon first render when ratings are empty
    // Refreshes the search on every field change.
    useEffect(() => {
        if (ratingOptions.length === 0) {
            getRatingsAndAircrafts();
        }
        setClearEmployee(false);
        refresh(ascending, orderByText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ascending, orderByText, selectedDateFrom, selectedDateTo, selectedRatingOptions, selectedAircraftOptions, onlyValid, selectedEmployeeId, clearEmployee, selectedType])

    const onSelectedEmployeeRow = (employee_id: number) => {
        setSelectedEmployeeId(employee_id);
    }
    // Refreshes the search. Run on every search criteria change through useEffect
    const refresh = (ascending: Boolean, orderByText: string) => {
        setLoading(true);
        CertificatesApi.GetCertificateHistoricalData(selectedType, orderByText, ascending, selectedAircraftOptions, selectedRatingOptions, onlyValid, selectedEmployeeId, selectedDateFrom, selectedDateTo).then(res => {
            setCertificatesResult(res.data);
            setLoading(false);
        })
    }
    // Gets the data for export. Changes a boolean value to trigger the pdf creation through PDFCreate component's useEffect
    const getExportData = (isOnlySelectedFields: boolean) => {
        refresh(ascending, orderByText);
        CertificatesApi.GetCertificateHistoricalDataToPdf(selectedType, orderByText, ascending, selectedAircraftOptions, selectedRatingOptions, onlyValid, selectedEmployeeId, selectedDateFrom, selectedDateTo, isOnlySelectedFields).then(res => {
            setCertificatesExportResult(res.data);
            setHistoricalLoaded(true);
            setHistoricalLoaded(false);
        })
    }
    const onRowClick = (row: Certificates) => {
        CertificatesApi.GetCertificateById(row.certificate_id).then(res => {
            setSelectedCertificate(res.data);
        })
    }
    const onTypeChange = (type: any) => {
        setSelectedType(type);
    }
    const generateTableBody = (row: Certificates, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} onClick={() => onRowClick(row)} >
                <TableCell>{index}</TableCell>
                <TableCell >
                    <Grid container>
                        <ProfileImg imgKey={index} userName={users?.find(s => s.Id === row.mechanic_employee_id)?.userName?.split('@')[0]} />
                        <Typography margin={[1, 0, 0, 2]} align="left" text={row.mechanic_full_name}></Typography>
                    </Grid>
                </TableCell>
                <TableCell>{row.quality_assurance_accepted_date_text}</TableCell>
                <TableCell>{row.certificate_expired_date_text} </TableCell>
                <TableCell>
                    {
                        row.invalid_date !== null && row.quality_assurance_accepted_date === null ? "Declined" :
                            row.invalid_date !== null && row.quality_assurance_accepted_date !== null ? "Inactive" :
                                row.expired_date_status.toString() === 'Valid' ? <CheckCircle className={classes.valid} />
                                    :
                                    row.expired_date_status.toString() === 'Expiring' ? <Info color="secondary" />
                                        :
                                        <RemoveCircle color="error" />
                    }
                </TableCell>
                <TableCell>
                    {row.quality_assurance_accepted_date === null && row.declined_reason}
                </TableCell>
            </TableRow>
        )
    }
    const OnOrderByTitles = (givenOrderByText: string) => {
        if (orderByText === givenOrderByText) {
            setAscending(!ascending);
        }
        setOrderByText(givenOrderByText);
    }
    const closeCertificate = () => {
        setSelectedCertificate(undefined);
    }
    // Change function for both date fields
    const onDateChange = async (date: Date, type: 'from' | 'to') => {
        if (type === 'from') {
            setSelectedDateFrom(date);
        }
        if (type === 'to') {
            setSelectedDateTo(date);
        }
    }
    // Change function for both Aircrafts and Ratings dropdown
    const onSelectedRating = (selectedRating: number[], type: string) => {
        if (type === "rating") {
            if (selectedRating.includes(999) && selectAll === false) {
                setSelectAll(!selectAll);
                setSelectedRatingOptions([]);
            }
            else if (selectedRating.includes(999)) {
                const options: number[] = [];
                ratingOptions.forEach(rating => {
                    if (rating.id !== 999) {
                        options.push(rating.id);
                    }
                });
                setSelectedRatingOptions(options);
                setSelectAll(!selectAll);
            }
            else {
                setSelectedRatingOptions(selectedRating);
            }
        }
        if (type === "aircraft") {
            setSelectedAircraftOptions(selectedRating);
        }
    }
    // Function to populate dropdown for Rating and Aircrafts. Run through useEffect when ratings hasn't been populated
    const getRatingsAndAircrafts = () => {
        let tempRating: Array<SelectObject> = [];
        let tempAircrafts: Array<SelectObject> = [];
        setLoading(true);
        CertificatesApi.GetAllCertificationRatings().then(res => {
            res.data.filter(r => r.visible === true).map(rating => (
                tempRating.push({ name: rating.rating, id: rating.id })
            ));
            CertificatesApi.GetAllCertififcateAircraftCategories().then(res => {
                res.data.filter(a => a.visible === true).map(aircraft => (
                    tempAircrafts.push({ name: aircraft.aircraft_category, id: aircraft.id })
                ))
                setLoading(false);
                const selectAllObj = { name: "Select all", id: 999 } as SelectObject;
                tempRating.unshift(selectAllObj);
                setRatingOptions(tempRating);
                setAircraftOptions(tempAircrafts);
            })
        })
    }
    // onClick function for resetting all search fields
    const onResetFields = () => {
        setSelectedDateFrom(null);
        setSelectedDateTo(new Date());
        setSelectedRatingOptions([]);
        setSelectedAircraftOptions([]);
        setOnlyValid(false);
        setSelectedEmployeeId(0);
        setClearEmployee(true);
    }

    const classes = useStyles();
    return (
        <Grid>
            {selectedCertificate === undefined ?
                <Grid>
                    <Grid className={classes.topContainer} spacing={2} container xs={12}>
                        <Grid container item sm={10} lg={2}>
                            <EmployeeSearch reset={clearEmployee} place_holder={"All employees"} showDisabledEmployees onSelectedEemployee={(employee_id: number) => onSelectedEmployeeRow(employee_id)} />
                        </Grid>
                        <Grid container item sm={5} lg={2}>
                            <DatePicker onDateChange={(date: Date) => onDateChange(date, 'from')} type={"Date"} label="Issued from" selected={selectedDateFrom} />
                        </Grid>
                        <Grid container item sm={5} lg={2} >
                            <DatePicker onDateChange={(date: Date) => onDateChange(date, 'to')} type={"Date"} label="Issued to" selected={selectedDateTo} />
                        </Grid>
                        <Grid container item sm={5} lg={2} >
                            <SelectMultiple optionsObject={ratingOptions} selectedItems={selectedRatingOptions} width={200} onSelectedItem={(e: any) => onSelectedRating(e.target.value, "rating")} filter_by_text="Ratings" />
                        </Grid>
                        <Grid container item sm={5} lg={2} >
                            <SelectMultiple optionsObject={aircraftOptions} selectedItems={selectedAircraftOptions} width={200} onSelectedItem={(e: any) => onSelectedRating(e.target.value, "aircraft")} filter_by_text="Aircrafts" />
                        </Grid>
                        <Grid container item sm={5} lg={2} >
                            <Select onSelectedItem={(e: any) => onTypeChange(e.target.value)} selectedItem={selectedType} width={200} filter_by_text={"Certificate Type"} options={["All Certificates", "Icelandair Employees", "Subcontracts"]} />
                        </Grid>
                        <Grid container alignContent="center" xs={12}>
                            <Grid item lg={2} xs={4}>
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={onlyValid} onChange={() => { setOnlyValid(!onlyValid) }} />}
                                    label="Valid Certificates only"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item lg={1} xs={3}>
                                <Button variant="text" className={classes.resetButton} color="primary" size="small" onClick={() => onResetFields()}>Reset filters</Button>
                            </Grid>
                            <Grid item lg={2} xs={3} className={classes.resultsAmount}>
                                <Typography text={certificatesResult !== undefined ? certificatesResult.length + " certificates found" : '0 certificates found'} />
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item marginRight={2}>
                                <PDFCreate
                                    buttonText="Export certificates"
                                    dataLoaded={historicalLoaded}
                                    data={certificatesExportResult}
                                    subRows={true}
                                    mainRowColumnHeaders={["Rating", "Aircraft", "Expiration date"]}
                                    mainRowColumnNames={["mechanic_full_name"]}
                                    subRowObject="rightsForCertificate"
                                    subRowColumnNames={["rating_name", "aircraft_category_name", "expiration_date_text"]}
                                    getExportData={() => getExportData(false)}>
                                </PDFCreate>
                            </Grid>
                            <Grid item>
                                <PDFCreate
                                    buttonText="Export selected Ratings/Aircrafts"
                                    dataLoaded={historicalLoaded}
                                    data={certificatesExportResult}
                                    subRows={true}
                                    mainRowColumnHeaders={["Rating", "Aircraft", "Expiration date"]}
                                    mainRowColumnNames={["mechanic_full_name"]}
                                    subRowObject="rightsForCertificate"
                                    subRowColumnNames={["rating_name", "aircraft_category_name", "expiration_date_text"]}
                                    getExportData={() => getExportData(true)}></PDFCreate>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Table
                            headers={["Employee Name", "Issue Date", "Expiration Date", "Status", "Declined Reason"]}
                            rows={certificatesResult}
                            generateBody={(row: any, index: number) => generateTableBody(row, index)}
                            defaultRowsPerPage={25}
                            OnOrderByTitles={(order_by_text: string) => OnOrderByTitles(order_by_text)}
                        />
                    </Grid>
                </Grid>
                :
                <Grid>
                    <Certificate
                        setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)}
                        selectedCertificate={selectedCertificate}
                        closeCertificate={() => closeCertificate()}
                        viewType='history'
                        userType={"CertAdmin"}
                    />
                </Grid>
            }
        </Grid>
    );
};
export default History;
