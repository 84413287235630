import { settings } from '../config';
import { BayHandoverIssue } from '../types/bayHandover';
import { getToken } from './../global/TokenValidator';
class BayHandover {
    GetAllBayHandOverIssues = async (): Promise<{ status: boolean, message: string, data: BayHandoverIssue[] }> => {
        return fetch(`${settings.HOST}/BayHandOver/GetAllBayHandOverIssues`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    AddIssue = async (location: string, issue: string, tailNumber: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/BayHandOver/CreateBayHandOverIssues`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "location": location,
                "issue": issue,
                "tail_number": tailNumber
            })
        }).then(x => {
            return x.json()
        })
    }
    DeleteIssue = async (issueId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/BayHandOver/DeleteIssue`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": issueId
            })
        }).then(x => {
            return x.json()
        })
    }
    UpdateIssue = async (issueId: number, issue: string, tailNumber: string, location: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/BayHandOver/UpdateIssue`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "id": issueId,
                "issue": issue,
                "tail_number": tailNumber,
                "location": location,
            })
        }).then(x => {
            return x.json()
        })
    }
}
const BayHandoverAPI = new BayHandover();
export default BayHandoverAPI;