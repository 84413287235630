import { Grid } from "@mui/material";
import AirlinesRoundedIcon from '@mui/icons-material/AirlinesRounded';
import { Typography } from '../../MaterialUiComponents';
import { Public } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { FunctionComponent } from "react";
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import ProfileImg from "../Images/ProfileImg";

const useStyles = makeStyles(theme => ({
  userPhoto: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: "50%"
  },
}));

type ToolDataForRow = {
  employee_id: number,
  tail_number: string,
  world_trip_name: string,
  employee_image: string,
  employee_name: string,
}

const ReturnNameData: FunctionComponent<ToolDataForRow> = ({ employee_id, tail_number, world_trip_name, employee_image, employee_name }) => {
  const classes = useStyles();
  const employeeId = employee_id;
  const user = useSelector((state: RootState) => state.employees.all_employees.find(s => s.Id === employee_id));
  const userName = user?.userName.split("@")[0];

  if (employeeId === 0) {
    //Tool Was assigned to Tail
    return (
      <Grid>
        <AirlinesRoundedIcon fontSize="large" titleAccess="Tool loaned out on tail" />
        <Typography text={tail_number} align='left' variant='body1' />
      </Grid>
    )
  }
  else if (employeeId === -1) {
    //Tool was assigned to world Trip
    return (
      <Grid>
        <Public fontSize="large" color="primary" />
        <Typography text={world_trip_name} align='left' variant='body1' />
      </Grid>
    )
  }
  else {
    //Tool was assigned to employee
    return (
      <Grid>
        <ProfileImg userName={userName} />
        <Typography text={employee_name} align='left' variant='body1' />
      </Grid>
    )
  }
}

export default ReturnNameData;