import { LiteWPOFiltersAction } from "../types/actions";
import { LiteFilters } from "../types/lite";

const filtersInitial = {
	litefilters:{} as LiteFilters,	
}

export const liteReducer = (state = { ...filtersInitial }, action: LiteWPOFiltersAction) => {
	switch (action.type) {
		case 'LITE_WORK_PACKAGE_OVERVIEW_FILTERS': 
			return { ...state, litefilters: action.litefilters };
		default:
			return state;
	}
}