import { settings } from '../config';
import { getToken } from './../global/TokenValidator';
import { Report } from './../types/maintenanceReports';

class MaintenenceReport {
    GetAllMaintenanceReports = async (): Promise<{ data: Report[] }> => {
        return fetch(`${settings.HOST}/MaintenanceReports/GetAllMaintenanceReports`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    CreateMaintenanceReport = async (barcode: string, location: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/MaintenanceReports/CreateMaintenanceReport`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "barcode": barcode,
                "location": location
            })
        }).then(x => {
            return x.json()
        })
    }
    CreateAction = async (reportId: number, comment: string, hours: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/MaintenanceReports/CreateAction`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "reportId": reportId,
                "comment": comment,
                "hours": hours
            })
        }).then(x => {
            return x.json()
        })
    }
    AddWorkPackageToMro = async (barcode: string, reportId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/MaintenanceReports/AddWorkPackageToMro`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "reportId": reportId,
                "barcode": barcode
            })
        }).then(x => {
            return x.json()
        })
    }
    GetMroById = async (morId: number): Promise<{ status: boolean, message: string, data: Report }> => {
        return fetch(`${settings.HOST}/MaintenanceReports/GetMroById/${morId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    CloseMro = async (morId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/MaintenanceReports/CloseMro/${morId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
}
const MaintenenceReportsApi = new MaintenenceReport();
export default MaintenenceReportsApi;