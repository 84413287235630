import { settings } from '../config';
import { getToken } from '../global/TokenValidator';
import { AssignedMechanicSkill, EmployeeAssigndToMechanicSkill, Skills } from '../types/skills';

class SkillApi {
    EditAbbreviation = async (employeeId: number, abbreviation: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Skills/EditAbbreviation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "employeeId": employeeId,
                "abbreviation": abbreviation,
            })
        }).then(x => {
            return x.json()
        })
    }
    GetAbbreviationByEmployeeId = async (employeeId: number): Promise<{ status: boolean, message: string, data: string }> => {
        return fetch(`${settings.HOST}/Skills/GetAbbreviationByEmployeeId/${employeeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    CreateSkill = async (skill: string): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Skills/CreateSkill`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "skill": skill,
            })
        }).then(x => {
            return x.json()
        })
    }
    GetSkills = async (): Promise<{ status: boolean, message: string, data: Skills[] }> => {
        return fetch(`${settings.HOST}/Skills/GetSkills`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    GetMechanicSkillsById = async (employeeId: number): Promise<{ status: boolean, message: string, data: AssignedMechanicSkill[] }> => {
        return fetch(`${settings.HOST}/Skills/GetMechanicSkillsById/${employeeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
    DeleteSkill = async (deletedReason: string, skillId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Skills/DeleteSkill`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "skillId": skillId,
                "reason": deletedReason,
            })
        }).then(x => {
            return x.json()
        })
    }
    AssignMechanicSkills = async (skillIds: number[], employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Skills/AssignMechanicSkills`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "skillIds": skillIds,
                "employeeId": employeeId,
            })
        }).then(x => {
            return x.json()
        })
    }
    RemoveMechanicSkills = async (skillIds: number[], employeeId: number): Promise<{ status: boolean, message: string }> => {
        return fetch(`${settings.HOST}/Skills/RemoveMechanicSkills`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "skillIds": skillIds,
                "employeeId": employeeId,
            })
        }).then(x => {
            return x.json()
        })
    }
    GetEmployeesAssignedToMechanicSkillsById = async (skillId: number): Promise<{ status: boolean, message: string, data: EmployeeAssigndToMechanicSkill[] }> => {
        return fetch(`${settings.HOST}/Skills/GetEmployeesAssignedToMechanicSkillsById/${skillId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            }
        }).then(x => {
            return x.json()
        })
    }
}
export default new SkillApi();