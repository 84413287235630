import { FunctionComponent, useEffect, useState } from "react";
import { AccessControlGroup, UserAccessInfo, AccessGroupUserGroupRelation } from '../../types/accessControl';
import { Grid, TableCell, TableRow, Button, Switch, IconButton } from '@mui/material';
import { Table, Input, Typography, Dialog } from './../../MaterialUiComponents';
import { Datepicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { AllInclusive } from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import AccessControlApi from '../../api/accessControl';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import ProfileImg from "../../components/Images/ProfileImg";

const useStyles = makeStyles(theme => ({
    Button: {
        marginBottom: theme.spacing(4),
    },
    userPhoto: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: "50px",
        marginRight: "8px"
    },
}));

type PermissionsProps = {
    groupsRelation: AccessGroupUserGroupRelation[],
    groupDetails: AccessControlGroup | undefined,
    permissions: UserAccessInfo[],
    setInGroupState: Function,
    inGroupState: boolean,
    refresh: Function,
    onUpdateGroupDetails: Function,
    tabValue: number,
    setLoading: Function,
}

const Permissions: FunctionComponent<PermissionsProps> = ({ tabValue, groupDetails, permissions, setInGroupState, inGroupState, refresh, onUpdateGroupDetails, setLoading }) => {
    const classes = useStyles();
    const [areYouSureDialogOpen, setAreYouSureDialogOpen] = useState(false);
    const [employee, setEmployee] = useState<UserAccessInfo>();
    const [expirationDate, setExpirationDate] = useState<Date>(new Date());
    const [startsAt, setStartsAt] = useState<Date>(new Date());
    const [createdOrRemovedReason, setCreatedReason] = useState<string | undefined>(undefined);
    const [permissionId, setPermissionId] = useState<number | undefined>(undefined);
    const [usersInTable, setUsersInTable] = useState<UserAccessInfo[]>([]);
    const [employeeSearch, setEmployeeSearch] = useState<string>("");
    const store = useSelector((state: RootState) => state);
    const users = store.employees.all_employees.filter(s => s.visible === true);

    useEffect(() => {
        setUsersInTable(permissions);
        const escFunction = (event: any) => {
            //Select top users
            if (event.keyCode === 13 && usersInTable.length > 0) {
                if (inGroupState) {
                    setAreYouSureDialogOpen(true); setEmployee(usersInTable[0]); setPermissionId(usersInTable[0].permission_id);
                } else {
                    setAreYouSureDialogOpen(true); setEmployee(usersInTable[0]);
                }
            }
        }
        document.addEventListener('keydown', escFunction, false);
    }, [permissions]);

    const onAreYouDialogClicked = (status: boolean) => {
        if (status === true) {
            if (tabValue === 0) {
                if (inGroupState && (createdOrRemovedReason !== "" && createdOrRemovedReason !== undefined)) {
                    setLoading(true);
                    AccessControlApi.RemoveAccessGroupPermission(permissionId!, createdOrRemovedReason!).then(res => {
                        if (res.status) {
                            refresh();
                            onUpdateGroupDetails(groupDetails);
                            setLoading(false);
                            setAreYouSureDialogOpen(false);
                        }
                    });
                }
                else if (!inGroupState && (createdOrRemovedReason !== "" && createdOrRemovedReason !== undefined)) {
                    setLoading(true);
                    AccessControlApi.CreateAccessGroupPermission(groupDetails!.group_name, createdOrRemovedReason!, groupDetails!.id, employee!.employee_id, expirationDate, startsAt).then(res => {
                        if (res.status) {
                            refresh();
                            onUpdateGroupDetails(groupDetails);
                            setLoading(false);
                            setAreYouSureDialogOpen(false);
                        }
                    });
                }

            }
            else if (tabValue === 1) {
                if (inGroupState && (createdOrRemovedReason !== "" && createdOrRemovedReason !== undefined)) {
                    setLoading(true);
                    AccessControlApi.RemoveUserFromUserGroup(groupDetails!.id, employee!.employee_id, createdOrRemovedReason!).then(res => {
                        if (res.status) {
                            refresh();
                            onUpdateGroupDetails(groupDetails);
                            setLoading(false);
                            setAreYouSureDialogOpen(false);
                        }
                    });
                }
                else if (!inGroupState && (createdOrRemovedReason !== "" && createdOrRemovedReason !== undefined)) {
                    setLoading(true);
                    AccessControlApi.AddUserToUserGroup(createdOrRemovedReason!, groupDetails!.id, employee!.employee_id, expirationDate, startsAt).then(res => {
                        if (res.status) {
                            refresh();
                            onUpdateGroupDetails(groupDetails);
                            setLoading(false);
                            setAreYouSureDialogOpen(false);
                        }
                    });
                }

            } else {
                setAreYouSureDialogOpen(false);
            }
        }
        else if (status === false) {
            setAreYouSureDialogOpen(false);
        }
    }
    const onShowEmployeeStatus = (value: string) => {
        setEmployeeSearch(value);
        const filteredUsers: UserAccessInfo[] = permissions.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
        setUsersInTable(filteredUsers);
    }

    const generateTableBody = (row: UserAccessInfo,  index: number) => {
        return (
            <TableRow>
                <TableCell>
                    <Grid container>
                        <ProfileImg imgKey={index} userName={users.find(s => s.Id === row.employee_id)?.userName?.split('@')[0]} />
                    </Grid>
                </TableCell>
                <TableCell> {row.name} </TableCell>
                <TableCell> {row.job_title} </TableCell>
                <TableCell> {row.starts_at} </TableCell>
                <TableCell> {row.expiration_date} </TableCell>
                <TableCell>
                    {inGroupState ?
                        <Button variant="contained" color='secondary' className={classes.Button} onClick={() => { setAreYouSureDialogOpen(true); setEmployee(row); setPermissionId(row.permission_id) }}>Remove</Button>
                        :
                        <Button variant="contained" color='primary' className={classes.Button} onClick={() => { setAreYouSureDialogOpen(true); setEmployee(row) }}>Insert</Button>
                    } </TableCell>
            </TableRow>
        )
    }
    return (
        <Grid>
            <Grid sx={{ padding: '8px', marginBottom: 4 }}>
                <Paper elevation={16} >
                    {groupDetails ?
                        <Grid>
                            <Grid container direction="row">
                                <Grid container direction="row">
                                    <Typography align="center" alignSelf="center" fontWeight="bold" margin={[0.5, 5, 0, 1]} text={groupDetails.group_name} />
                                    <Grid item>
                                        <Typography margin={[1.5, 0, 0, 1]} text={"In group"} />
                                    </Grid>
                                    <Grid item style={{ marginTop: '8px' }}>
                                        <Switch
                                            checked={!inGroupState}
                                            onChange={() => setInGroupState()}
                                            color="secondary"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography margin={[1.5, 0, 0, 1]} text={"Not in group"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        : <Grid style={{ padding: '16px', marginLeft: '8px', fontSize: '20px' }}>Select a group to view permission</Grid>
                    }
                    {groupDetails &&
                        <Grid style={{ marginLeft: '8px', width: '25%' }}>
                            <Input
                                multiline={false}
                                label_text=""
                                help_text=""
                                type="text"
                                value={employeeSearch}
                                width={100}
                                onTextChange={(value: string) => onShowEmployeeStatus(value)}
                            />
                        </Grid>
                    }
                    {groupDetails !== undefined &&
                        <Grid>
                            <Table
                                headers={["IMAGE", "FULL NAME", "JOB TITLE", "STARTS AT", "EXPIRES AT", `${!inGroupState ? "INSERT" : "REMOVE"}`]}
                                rows={usersInTable}
                                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                                pagination={true}
                                defaultRowsPerPage={10}
                                skipIndexCol={true}
                            />
                        </Grid>
                    }
                </Paper>
            </Grid>
            <Dialog
                visible={areYouSureDialogOpen}
                max_width={"md"}
                title={`${inGroupState ? `Are you sure you want to remove ${employee?.name} from group` : `Are you sure you want to insert ${employee?.name} to group`}`}
                context={
                    <Grid container direction="row">
                        <Input
                            multiline={false}
                            label_text="Reason"
                            help_text="Please give a reason for privileges"
                            type="text"
                            value={createdOrRemovedReason}
                            width={100}
                            onTextChange={(value: string) => setCreatedReason(value)}
                        />
                        {!inGroupState &&
                            <Grid  >
                                <Grid margin={[0, 0, 1, 0]}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Typography margin={[2.5, 0, 0, 0]} text={"Expiration date"} />
                                        </Grid>
                                        <Grid item>
                                            <Datepicker
                                                controls={['calendar']}
                                                inputProps={{
                                                    placeholder: 'Select date'
                                                }}
                                                display='anchored'
                                                touchUi={true}
                                                value={expirationDate}
                                                onChange={(e: any) => setExpirationDate(e.value)}
                                                themeVariant='light'
                                                dateFormat='DD.MM.YYYY'
                                            />
                                        </Grid>
                                        <IconButton onClick={() => { setExpirationDate(new Date("01.01.2099")); setCreatedReason("Permanent permission"); }}><AllInclusive /></IconButton>
                                    </Grid>
                                </Grid>
                                <Grid margin={[0, 0, 1, 0]}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Typography margin={[2.5, 0, 0, 0]} text={"Starts at"} />
                                        </Grid>
                                        <Grid item>
                                            <Datepicker
                                                controls={['calendar']}
                                                inputProps={{
                                                    placeholder: 'Select date'
                                                }}
                                                display='anchored'
                                                touchUi={true}
                                                value={startsAt}
                                                onChange={(e: any) => setStartsAt(e.value)}
                                                themeVariant='light'
                                                dateFormat='DD.MM.YYYY'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                }
                onClose={(status: any) => onAreYouDialogClicked(status)}
                strict={false}
                show_options={true}
            />
        </Grid >
    )
}

export default Permissions;