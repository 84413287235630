import { Grid, TableRow, TableCell, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { makeStyles } from '@mui/styles';
import { Edit, Search } from '@mui/icons-material';
import { FunctionComponent, useEffect, useState } from "react";
import liteApi from '../../api/lite';
import { Typography, Table, Dialog } from '../../MaterialUiComponents';
import LiteHandoverDialogContext from './LiteHandoverDialogContext';
import { AllLiteHandoverReports, LiteHandoverReportInformation } from '../../types/lite';
import { SearchTailAndIssue } from '../../components/RVKLite';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

type LiteHandoverProps = {
    allTails: Array<string>,
    workPlace: string,
}

const LiteHandover: FunctionComponent<LiteHandoverProps> = ({ allTails, workPlace }) => {
    const classes = useStyles();
    const store = useSelector((state: RootState) => state);
    const accessGroups = store.user.me.user_clearence_groups;
    const isLiteSupervisorAdmin = accessGroups.includes("Lite Edit") || accessGroups.includes("Rvk Line Edit");
    const [reports, setReports] = useState(Array<AllLiteHandoverReports>());
    const [handoverDialogOpen, setHandoverDialogOpen] = useState(false);
    const [liteHandoverReport, setLiteHandOverReport] = useState<LiteHandoverReportInformation>();
    const [searchIssue, setSearchIssue] = useState<string>("");
    const [searchTail, setSearchTail] = useState<string>("");

    useEffect(() => {
        refreshHandOverTab();
    }, []);

    const refreshHandOverTab = () => {
        liteApi.GetAllLiteHandOverReports(workPlace).then(res => {
            setReports(res.data);
        });
    }
    const refresh = (reportId: number) => {
        liteApi.GetLiteHandoverReportByID(reportId).then(res => {
            if (res.status === true) {
                setLiteHandOverReport(res.data);
                setHandoverDialogOpen(true);
            }
        });
    }
    const onCreateNewHandoverReport = () => {
        liteApi.CreateNewLiteHandoverReport(workPlace).then(res => {
            if (res.status === false) {
                if(res.message !== null){
                    alert(res.message);
                }
                else{
                    alert(`{Something went wrong, was not able to create a ${workPlace === 'RVK' ? "RVK Line" : "Lite"} handover}`);
                }
            } else {
                refresh(res.data);
                refreshHandOverTab();
            }
        });
    }
    const onClickRow = (reportId: number) => {
        refresh(reportId);
    }

    const generateTableBody = (row: AllLiteHandoverReports, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} >
                <TableCell>{row.created_date_text}</TableCell>
                <TableCell>{row.supervisor_name}</TableCell>
                <TableCell>{row.begin_date_text}</TableCell>
                <TableCell>{row.end_date_text}</TableCell>
                <TableCell>{row.issues_count}</TableCell>
                <TableCell>{row.employees_count}</TableCell>
                <TableCell >
                    {row.is_submitted && <Search style={{ cursor: 'pointer' }} onClick={() => onClickRow(row.id)} color='primary' />}
                    {!row.is_submitted && <Edit onClick={() => onClickRow(row.id)} color='primary' style={{ cursor: 'pointer' }} />}
                </TableCell>
            </TableRow>
        )
    }

    const getHandoversBySearch = () => {
        liteApi.GetHandoverBySearchFilters(workPlace, searchIssue, searchTail).then(res => {
            setReports(res.data);
        });
    }

    return (
        <Grid>
            <Typography variant={'h1'} align={'center'} text={workPlace === "RVK" ? "Reykjavík Line Handover" : "Lite Handover reports"} />
            <SearchTailAndIssue
                searchIssue={searchIssue}
                searchTail={searchTail}
                setSearchTail={setSearchTail}
                setSearchIssue={setSearchIssue}
                getHandoversBySearch={getHandoversBySearch}
                refreshHandOverTab={refreshHandOverTab}
            />
            {!(reports.filter(s => !s.is_submitted).length > 0) && isLiteSupervisorAdmin && <Button color='primary' onClick={() => onCreateNewHandoverReport()}>Create new</Button>}
            {reports.filter(s => s.is_submitted === true).length > 0 && <Grid container justifyContent={'center'}></Grid>}
            <Table
                headers={["Created", "Created By", "start", "end", "Issues", "Employees", "Submitted"]}
                rows={reports}
                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                defaultRowsPerPage={25}
                pagination={true}
                skipIndexCol={true}
            />
            <Dialog visible={handoverDialogOpen} max_width={"xl"} strict={false} show_options={false}
                onClose={(status: any) => { setHandoverDialogOpen(false); }}
                title={liteHandoverReport?.is_submitted === true ? (workPlace === "RVK" ? "RVK Line Handover": "Lite Handover") : (workPlace === "RVK" ? "Create new RVK Line Handover" : "Create new Lite Handover")}
                context={
                    <LiteHandoverDialogContext isEditMode={isLiteSupervisorAdmin} workPlace={workPlace} refreshLiteHandOverTab={() => refreshHandOverTab()} allTails={allTails} liteHandoverReport={liteHandoverReport!} refresh={(id: number) => refresh(id)} onClose={() => { setHandoverDialogOpen(false) }} />
                }
            />
        </Grid>
    )
}

export default LiteHandover;