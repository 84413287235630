import { FunctionComponent, useState } from 'react';
import { Input, Typography } from '../../MaterialUiComponents';
import { Grid, Button } from '@mui/material'
import { Request } from '../../types/capabilityList';
import { ToolData } from '../../types/tool';
import BuildIcon from '@mui/icons-material/Build';
import toolapi from '../../api/tools';

interface AddToolsProps {
    request: Request,
    viewMode: boolean,
    setRequest: Function,
}

const AddTools: FunctionComponent<AddToolsProps> = ({ request, setRequest, viewMode }) => {
    const [filteredTools, setFilteredTools] = useState<ToolData[]>([]);
    const [partNumber, setPartNumber] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const onAddToolToList = () => {
        const foundPart = filteredTools[0];
        if (foundPart !== undefined) {
            const updatedObject = {
                ...request, // Spread the current state object
                capability_tools: [...request.capability_tools, foundPart],
            };
            setRequest(updatedObject);
            setPartNumber("");
        }
    }

    function filterObjectsByPartNumber(objects: ToolData[]) {
        const filteredObjects = [];
        const partNumberSet = new Set();

        for (const obj of objects) {
            if (!partNumberSet.has(obj.part_number)) {
                filteredObjects.push(obj);
                partNumberSet.add(obj.part_number);
            }
        }
        setFilteredTools(filteredObjects);
    }

    const handleKeyDown = (keyNumber: React.KeyboardEvent) => {
        if (keyNumber.key === "Enter") {
            setLoading(true);
            toolapi.GetToolByPartNumber(partNumber).then((response) => {
                filterObjectsByPartNumber(response.data);
                setLoading(false);
            });
        }
    }

    return (
        <Grid container direction="column" alignItems="center" style={{ marginTop: '28px' }}>
            <Input
                icon={<BuildIcon />}
                onKeyDown={(e: React.KeyboardEvent) => handleKeyDown(e)}
                disabled={viewMode}
                multiline={true}
                help_text={""}
                label_text={"Choose tool"}
                value={partNumber}
                onTextChange={(text: string) => { setPartNumber(text) }}
                type="text" width={15}
            />
            {loading && <Typography color='primary' variant='h2' text={`Loading...`} align='center' />}
            {filteredTools.length > 0 ?
                <Grid>
                    <Grid container direction="row" style={{ marginTop: '18px' }}>
                        <Typography color='primary' variant='h2' text={`${filteredTools[0].tool_name}`} align='center' />
                        <Button style={{ marginLeft: '18px', marginBottom: '18px' }}
                            onClick={() => onAddToolToList()}
                            variant='contained'
                            color={viewMode ? 'primary' : 'secondary'}>
                            Add
                        </Button>
                    </Grid>
                </Grid>
                :
                !loading ?
                    <Typography color='primary' variant='h2' text={`No tool Found`} align='center' />
                    : null
            }
        </Grid>
    )
}
export default AddTools;
