import React, { FunctionComponent } from 'react';
import { SelectObject } from './../../types/certificates';
import { FormControl, InputLabel, Select, MenuItem, Input, Checkbox, ListItemText } from '@mui/material';

type SelectMultipleProps = {
  selectedItems?: number[],
  selectedItemsString?: string[],
  width: number,
  widthPercentage?: boolean
  filter_by_text: string,
  options?: string[],
  optionsObject?: SelectObject[],
  onSelectedItem: Function,
  customSelectedText?: string,
  required?: boolean,
  disabled?: boolean,
}

const SelectMultiple: FunctionComponent<SelectMultipleProps> = React.memo(({ selectedItems = [], required = false, selectedItemsString = [], width, filter_by_text, options, optionsObject, onSelectedItem, widthPercentage = false, customSelectedText, disabled = false }) => {

  return (
    <FormControl
      style={widthPercentage === false ? { minWidth: width + "px", maxWidth: width + "px" } : { width: width + "%" }}
      variant="standard">
      <InputLabel htmlFor="select-type" required={required}>{filter_by_text}</InputLabel>
      <Select
        value={selectedItems.length > 0 ? selectedItems : selectedItemsString}
        onChange={(e: any) => onSelectedItem(e)}
        input={<Input id="select-type" />}
        renderValue={(selected) =>
        (
          selectedItems.length > 0 ?
            selectedItems.length + (customSelectedText !== undefined ? customSelectedText : " items selected")
            :
            selectedItemsString.length + (customSelectedText !== undefined ? customSelectedText : " items selected")
        )
        }
        multiple
        variant="standard"
        disabled={disabled}
      >
        {
          options !== undefined ?
            options.map((item, idx) => (
              <MenuItem value={item} key={idx}>
                <Checkbox color="primary" checked={selectedItemsString.length > 0 ? selectedItemsString.includes(item) : false} />
                <ListItemText primary={item} />
              </MenuItem>
            ))
            :
            optionsObject?.map((item, idx) => (
              <MenuItem key={idx} value={selectedItemsString.length > 0 ? item.name : item.id}>
                <Checkbox color="primary" checked={selectedItemsString.length > 0 ? selectedItemsString.indexOf(item.name) > -1 : selectedItems.indexOf(item.id) > -1} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))
        }
      </Select>
    </FormControl>
  );
});
export default SelectMultiple;