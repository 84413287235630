export const typography = {
    //fontFamily: 'Altitude', Old font, not all machines (mac) can use the Altitude font
    fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', //works with apple
    h1: {
        fontWeight: 400,
        fontSize: 35,
        letterSpacing: '-0.24px'
    },
    h2: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: '30px',
        letterSpacing: '-0.24px'
    },
    h3: {
        fontWeight: 200,
        fontSize: 20,
        lineHeight: '21px',
        letterSpacing: '-0.06px'
    },
    h4: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '18px',
        letterSpacing: '-0.06px'
    },
    h5: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '17px',
        letterSpacing: '-0.05px'
    },
    h6: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '14px',
        letterSpacing: '-0.05px'
    },
    overline: {
        fontWeight: 500
    },    
};