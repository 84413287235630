import { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, TableCell, TableRow } from '@mui/material';
import { Search, Link } from '@mui/icons-material';
import { Table, Typography } from '../../MaterialUiComponents';
import { warrantyRequest } from '../../types/warranty';
import { redirectToMxi } from './../../config/mxiProvider';
import AttachmentIcon from '@mui/icons-material/Attachment';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    }
}));

type SortableKeys = keyof warrantyRequest;

type OverviewProps = {
    allWarranties: warrantyRequest[],
    selectWarranty: Function,
    isWarrantyAdmin: Boolean,
    setWarrantiesResults: Function
}
// Overview page contains a table with search results for all warranty requests.
const Overview: FunctionComponent<OverviewProps> = ({ allWarranties, setWarrantiesResults, selectWarranty, isWarrantyAdmin }) => {

    const [partCostSum, setPartCostSum] = useState<number>(0);
    const [manhoursCostSum, setManhoursCostSum] = useState<number>(0);
    const [shippingCostSum, setShippingCostSum] = useState<number>(0);
    const [totalCostSum, setTotalCostSum] = useState<number>(0);
    const [orderByText, setOrderByText] = useState("Created");
    const [ascending, setAscending] = useState<boolean>(false);

    useEffect(() => {
        refresh(orderByText, ascending);
    }, [orderByText, ascending]);

    const refresh = (orderByText: string, ascending: boolean) => {
        switch (orderByText) {
            case "Created":
                orderByText = "created_date";
                break;
            case "MXI":
                orderByText = "fault_barcode";
                break;
            case "Tail":
                orderByText = "tail_number";
                break;
            case "Cage Code":
                orderByText = "cage_code_text";
                break;
            case "Classification":
                orderByText = "claim_classification_type";
                break;
            case "Last comment":
                orderByText = "last_comment";
                break;
            case "PO/Task Barcode":
                orderByText = "po_number";
                break;
            case "Part amounts":
                orderByText = "part_numbers";
                break;
            case "Parts":
                orderByText = "part_claimed_cost";
                break;
            case "Manhours":
                orderByText = "manhours_claimed_cost";
                break;
            case "Shipping":
                orderByText = "shipping_claimed_cost";
                break;
            case "Total":
                orderByText = "total_claimed_cost";
                break;
            case "Status":
                orderByText = "warranty_status";
                break;
            case "Result":
                orderByText = "claim_request_type";
                break;
            case "":
                orderByText = "has_attachment";
                break;
            default:
                orderByText = "created_date";
                break;
        }
        let sortedByPartNumbersLength = sortWarranties(allWarranties, orderByText, ascending);
        setWarrantiesResults(sortedByPartNumbersLength);
    }

    useEffect(() => {
        let tempPart: number = 0;
        let tempManhours: number = 0;
        let tempShipping: number = 0;
        let tempTotal: number = 0;
        allWarranties.forEach(warranty => {
            tempPart = tempPart + warranty.part_claimed_cost;
            tempManhours = tempManhours + warranty.manhours_claimed_cost;
            tempShipping = tempShipping + warranty.shipping_claimed_cost;
            tempTotal = tempTotal + warranty.total_claimed_cost;
        });
        setPartCostSum(tempPart);
        setManhoursCostSum(tempManhours);
        setShippingCostSum(tempShipping);
        setTotalCostSum(tempTotal);
    }, [allWarranties]);

    const usdFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'usd',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    const generateTableBody = (row: warrantyRequest, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''}>
                <TableCell>{row.created_date_text}</TableCell>
                <TableCell onClick={() => redirectToMxi(row.fault_barcode)}>
                    {row.is_dummy === false &&
                        <IconButton><Link color="primary" /></IconButton>
                    }
                </TableCell>
                <TableCell style={{ minWidth: '75px' }}>{row.tail_number}</TableCell>
                <TableCell>{row.cage_code_text}</TableCell>
                <TableCell>{row.claim_classification_type}</TableCell>
                <TableCell style={{ maxWidth: '180px' }}>{row.last_comment}</TableCell>
                <TableCell>{row.po_number}</TableCell>
                <TableCell style={{ maxWidth: '140px' }}>{row.part_numbers.length}</TableCell>
                <TableCell>{usdFormatter.format(row.part_claimed_cost)}</TableCell>
                <TableCell>{usdFormatter.format(row.manhours_claimed_cost)}</TableCell>
                <TableCell>{usdFormatter.format(row.shipping_claimed_cost)}</TableCell>
                <TableCell>{usdFormatter.format(row.total_claimed_cost)}</TableCell>
                <TableCell>{row.warranty_status}</TableCell>
                <TableCell>{row.claim_request_type ? row.claim_request_type : ''}</TableCell>
                <TableCell>{row.has_attachment ? <AttachmentIcon color="primary" /> : ""}</TableCell>
                <TableCell>{isWarrantyAdmin && <IconButton onClick={() => selectWarranty(row.warranty_id)}><Search color="primary" /></IconButton>}</TableCell>
            </TableRow>
        )
    }
    const OnOrderByTitles = (givenOrderByText: string) => {
        if (orderByText === givenOrderByText) {
            setAscending(!ascending);
        }
        else {
            setAscending(true);
        }
        setOrderByText(givenOrderByText);
    }
    function sortWarranties(warranties: warrantyRequest[], sortBy: string, ascending: boolean = true): warrantyRequest[] {
        return warranties.sort((a, b) => {
            let aValue = a[sortBy as SortableKeys];
            let bValue = b[sortBy as SortableKeys];

            // If property is an array, compare its length
            if (Array.isArray(aValue)) {
                aValue = aValue.length;
                bValue = bValue.length;
            }

            // Compare dates
            if (aValue instanceof Date) {
                aValue = aValue.getTime();
                bValue = bValue.getTime();
            }

            // Sort in ascending or descending based on the flag
            if (ascending) {
                if (aValue > bValue) return 1;
                if (aValue < bValue) return -1;
            } else {
                if (aValue < bValue) return 1;
                if (aValue > bValue) return -1;
            }
            return 0;
        });
    }
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Typography align='center' text={"Total: " + usdFormatter.format(totalCostSum)} />
            <Table
                headers={["Created", "MXI", "Tail", "Cage Code", "Classification", "Last comment", "PO/Task Barcode", "Part amounts", "Parts", "Manhours", "Shipping", "Total", "Status", "Result", ""]}
                rows={allWarranties}
                generateBody={(row: any, index: number) => generateTableBody(row, index)}
                pagination={false}
                skipIndexCol={true}
                bottomRow={["", "", "", "", "", "", "", "",
                    usdFormatter.format(partCostSum),
                    usdFormatter.format(manhoursCostSum),
                    usdFormatter.format(shippingCostSum),
                    usdFormatter.format(totalCostSum),
                    "", "", "", ""]}
                OnOrderByTitles={(orderByText: string) => OnOrderByTitles(orderByText)}
            />
        </Grid>
    );
};
export default Overview;