import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, Box, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Calendar, momentLocalizer, NavigateAction, View } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Event, PlannedTask } from '../../types/tasks';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Typography } from '../../MaterialUiComponents';
import CalendarTask from './CalendarTask';
import { addDays, addMonths, addWeeks } from 'date-fns';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    toolbarContainer: {
        position: 'sticky',
        top: '0px'
    },
    calendarContentContainer: {
        height: 'calc(100vh - 250px)',
        overflowY: 'auto',
        '& .rbc-row.rbc-time-header-cell': {
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: 'white',
        },
        '& .rbc-time-view': {
            border: 0
        }
    }
}));
type CalendarProps = {
    isTaskAssignment?: boolean,
    edit?: Boolean,
    tasksOnCalendar: Event[],
    onDropFromOutside: Function,
    onEventDrop: Function,
    onEventClick: Function,
    onCheckboxClicked: Function,
    draggedTasks: Event[],
    clickedTaskDate: PlannedTask | undefined,
    fullScreen: boolean,
    setFullScreen: Function,
    totalPlanned: string,
    isLinePlanner?: boolean
}

const CalendarUI: FunctionComponent<CalendarProps> = React.memo(({
    edit = true,
    tasksOnCalendar,
    onDropFromOutside,
    onEventDrop,
    onEventClick,
    onCheckboxClicked,
    draggedTasks,
    clickedTaskDate,
    setFullScreen,
    fullScreen,
    totalPlanned,
    isLinePlanner,
    isTaskAssignment,
}) => {

    const DnDCalendar = withDragAndDrop(Calendar as any);
    const localizer = momentLocalizer(moment);
    const [selectedView, setSelectedView] = useState<"month" | "day" | "work_week" | "week" | "agenda">("week");
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [isTodayInView, setIsTodayInView] = useState<boolean>(false);
    const today = new Date();

    const classes = useStyles();
    // When calendar date is defined that means that planned task date in left view was clicked. If in month view we change to week.
    useEffect(() => {
        if (clickedTaskDate !== undefined) {
            setSelectedDate(new Date(clickedTaskDate.start_time));
        }
    }, [clickedTaskDate]);

    useEffect(() => {
        if ((selectedDate.getDay() === 6 || selectedDate.getDay() === 0) && selectedView === "work_week") {
            setSelectedView("week");
        }
        if (selectedView === "month") {
            setSelectedView("work_week");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    // Triggers today check when date or view is changed
    useEffect(() => {
        checkIfTodayIsInView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selectedView]);

    // Handle change for default view so it doesn't get reset on every set state function
    const onViewChange = (view: View) => {
        setSelectedView(view);
    }
    const onTaskChecked = (task: Event, checked: boolean) => {
        onCheckboxClicked(task, checked);
    }
    // Custom toolbar logic to toggle between dates/weeks/months
    const toolbarNavigate = (action: NavigateAction) => {
        let dateDiff = 1;
        if (action === "TODAY") {
            setSelectedDate(today);
        }
        if (action === "NEXT" || action === "PREV") {
            if (action === "PREV") {
                dateDiff = -1;
            }
            if (selectedView === "day") {
                setSelectedDate(addDays(selectedDate, dateDiff));
            }
            if (selectedView === "month") {
                setSelectedDate(addMonths(selectedDate, dateDiff));
            }
            if (selectedView === "week" || selectedView === "work_week") {
                setSelectedDate(addWeeks(selectedDate, dateDiff));
            }
        }
    }
    // Custom toolbar logic for toggling between views
    const toolbarChangeView = (newView: View) => {
        setSelectedView(newView);
    }

    const customEvent = React.memo((event: any) => {
        return (
            <CalendarTask isTaskAssignment={isTaskAssignment} edit={edit} clickedTaskDate={clickedTaskDate} onCheckboxClicked={(task: Event, checked: boolean) => onTaskChecked(task, checked)} event={event.event} checked={draggedTasks.some(task => task.scheduled_task_id === event.event.scheduled_task_id)} isLinePlanner={isLinePlanner} />
        )
    })
    // Check if today is in the view based on selected view and date
    const checkIfTodayIsInView = () => {
        if (selectedView === "day") {
            if (selectedDate.getDate() === today.getDate()) {
                setIsTodayInView(true);
            }
            else {
                setIsTodayInView(false);
            }
        }
        if (selectedView === "week" || selectedView === "work_week") {
            let firstDayOfCurrentWeek = new Date(today.setDate(today.getDate() - today.getDay()));
            let lastDayOfCurrentWeek = new Date(firstDayOfCurrentWeek);
            if (selectedView === "week") {
                lastDayOfCurrentWeek.setDate(lastDayOfCurrentWeek.getDate() + 6);
            }
            // If Work week, then we need to add 1 day to the first day to start on monday
            if (selectedView === "work_week") {
                firstDayOfCurrentWeek = addDays(firstDayOfCurrentWeek, 1);
                lastDayOfCurrentWeek.setDate(lastDayOfCurrentWeek.getDate() + 5);
            }
            if (selectedDate >= firstDayOfCurrentWeek && selectedDate <= lastDayOfCurrentWeek) {
                setIsTodayInView(true);
            }
            else {
                setIsTodayInView(false);
            }
        }
        if (selectedView === "month") {
            if (selectedDate.getMonth() === new Date().getMonth()) {
                setIsTodayInView(true);
            }
            else {
                setIsTodayInView(false);
            }
        }
    }

    return (
        <Grid>
            <style>{
                '.rbc-time-content {\
          display:none\
        }\
        .rbc-label{\
          display:none\
        }\
        .rbc-time-view .rbc-allday-cell{\
          height: 100%;\
          min-height: 70vh !important\
        }\
        .rbc-event-content\
        {\
          white-space:inherit !important\
        }\
        .rbc-addons-dnd-drag-preview [class^="makeStyles-calendarTask"]\
        {\
          background-color: rgba(0,0,0,0.3) !important;\
          border-bottom: 500vh solid rgba(0,0,0,0.3);\
        }\
        .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor"\
        {\
          border-left: 3px double;\
          height: 30px;\
          width: 5px;\
          margin-top: 20px !important;\
        }\
        .rbc-time-header-cell{\
          position: sticky;\
          top: 0px;\
          z-Index: 99;\
          border: 2px solid rgba(18, 58, 125, 0.5);\
          background-color: white;\
      }\
      '
            }
            </style>
            <Grid container className={classes.toolbarContainer}>
                <Grid item xs={7}>
                    <Button onClick={() => toolbarNavigate("TODAY")} variant={isTodayInView ? "contained" : "outlined"}>Today</Button>
                    <Button onClick={() => toolbarNavigate("PREV")} variant="outlined">Back</Button>
                    <Button onClick={() => toolbarNavigate("NEXT")} variant="outlined">Next</Button>
                    {totalPlanned !== "-1" && <Typography margin={[0, 0, 0, 2]} display="inline" variant="subtitle2" text={tasksOnCalendar.length + "/" + totalPlanned + " planned tasks"} />}
                    {edit &&
                        <IconButton size="large" onClick={() => { setFullScreen(!fullScreen) }}>
                            {!fullScreen ? <Fullscreen /> : <FullscreenExit />}
                        </IconButton>
                    }
                    <Typography
                        margin={[0, 0, 0, 2]}
                        display="inline"
                        variant="body1"
                        text={
                            selectedView === "day" ?
                                selectedDate.toLocaleString('default', { day: 'numeric', month: 'long' })
                                :
                                selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' })} />
                </Grid>
                <Grid item xs={5}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={() => toolbarChangeView("day")} variant={selectedView === "day" ? "contained" : "outlined"}>Day</Button>
                        <Button onClick={() => toolbarChangeView("week")} variant={selectedView === "week" ? "contained" : "outlined"}>Week</Button>
                        {/*<Button onClick={() => toolbarChangeView("work_week")} variant={selectedView === "work_week" ? "contained" : "outlined"}>Work Week</Button>*/}
                        <Button onClick={() => toolbarChangeView("month")} variant={selectedView === "month" ? "contained" : "outlined"}>Month</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid className={classes.calendarContentContainer}>
                <DnDCalendar
                    //Control if events are draggable
                    draggableAccessor={edit ? event => true : event => false}
                    selectable
                    showMultiDayTimes={false}
                    /* Workaround for disabling default tooltip from calendar events */
                    tooltipAccessor={(event: any) => event.null}
                    defaultDate={selectedDate}
                    views={['day', 'month', 'work_week', 'week']}
                    defaultView={selectedView}
                    events={tasksOnCalendar}
                    localizer={localizer}
                    doShowMoreDrillDown={true}
                    onDropFromOutside={(args) => onDropFromOutside(args.start, args.end, args.allDay)}
                    onEventResize={(args) => onEventDrop(args)}
                    onEventDrop={(args) => onEventDrop(args)}
                    toolbar={false}
                    resizable
                    style={{ height: 'calc(100vh - 260px)', }}
                    components={{
                        event: customEvent
                    }}
                    onSelectEvent={(event: any) => onEventClick(event)}
                    onView={(view: View) => onViewChange(view)}
                    eventPropGetter={(event) => { return { style: { backgroundColor: 'transparent', padding: 'none' } } }}
                />
            </Grid>
        </Grid>
    )
});

export default React.memo(CalendarUI);
