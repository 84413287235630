import { FunctionComponent, useState } from 'react';
import { Grid, TableRow, TableCell, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Table, Select, Input, Dialog, DatePicker, Tabs } from '../../MaterialUiComponents';
import { AddCircle, Edit } from '@mui/icons-material';
import EditColumn from '../Security/EditColumn';
import liteApi from '../../api/lite';
import { EmployeeSearch, Collection } from '../../components';
import { LiteHandoverReportInformation, LiteHandoverIssues } from '../../types/lite';
import { User } from '../../types/user';
import LineHandoverMxiPackageInformation from './LiteHandoverMxiPackageInformation';

const useStyles = makeStyles(theme => ({
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    save: {
        color: 'green'
    },
}));

interface LiteHandoverDialogContextProps {
    allTails: Array<string>,
    liteHandoverReport: LiteHandoverReportInformation,
    refresh: Function,
    onClose: Function,
    refreshLiteHandOverTab: Function,
    workPlace: string,
    isEditMode: Boolean
}

const LiteHandoverDialogContext: FunctionComponent<LiteHandoverDialogContextProps> = ({ allTails, liteHandoverReport, refresh, onClose, refreshLiteHandOverTab, workPlace, isEditMode }) => {
    const [editIndex, setEditIndex] = useState(-1);
    const [selectedTailNumber, setSelectedTailNumber] = useState("");
    const [selectedIssue, setSelectedIssue] = useState("");
    const [areYouSureDialogOpen, setAreYouSureDialogOpen] = useState<boolean>(false);
    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
    const [beginDate, setBeginDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [tabValue, setTabValue] = useState(0);
    
    const classes = useStyles();

    const generateTableBody = (row: LiteHandoverIssues, index: number) => {
        return (
            editIndex === index ?
                <TableRow className={index % 2 ? classes.evenRows : ''} >
                    <TableCell>{index}</TableCell>
                    <TableCell>{row.created_date_text}</TableCell>
                    <TableCell>
                        <Select width={100} widthPercentage filter_by_text="Tail number" onSelectedItem={(e: any) => setSelectedTailNumber(e.target.value)} options={allTails} selectedItem={selectedTailNumber} />
                    </TableCell>
                    <TableCell>   <Input multiline={true} help_text={""} label_text={"Issue"} value={selectedIssue} onTextChange={(text: string) => setSelectedIssue(text)} type="text" width={100} /></TableCell>
                    <EditColumn
                        index={index}
                        saveChanges={() => onModifieIssue(row.id)}
                        deleteRow={() => onDeleteIssue(row.id)}
                        editRow={index}
                        setEditRow={(editRow: number) => { }} />
                </TableRow>
                :
                <TableRow className={index % 2 ? classes.evenRows : ''} >
                    <TableCell>{index}</TableCell>
                    <TableCell>{row.created_date_text}</TableCell>
                    <TableCell>{row.tail_number}</TableCell>
                    <TableCell>{row.issue}</TableCell>
                    {liteHandoverReport.is_submitted === false ? isEditMode && <TableCell><IconButton onClick={() => onSelectRow(row, index)}><Edit /></IconButton> </TableCell> : <TableCell></TableCell>}
                </TableRow>
        )
    }
    const onSelectRow = (row: LiteHandoverIssues, index: number) => {
        setEditIndex(index);
        setSelectedIssue(row.issue);
        setSelectedTailNumber(row.tail_number);
    }
    const onAddIssue = () => {
        if (isEditMode) {
            liteApi.AddIssue(liteHandoverReport.id, "", "").then(res => {
                setEditIndex(liteHandoverReport.issues_count + 1);
                setSelectedTailNumber("");
                setSelectedIssue("");
                refresh(liteHandoverReport.id);
            });
        }
    }
    const onDeleteIssue = (issueId: number) => {
        if (isEditMode) {
            liteApi.DeleteIssue(issueId).then(res => {
                refresh(liteHandoverReport.id);
                setSelectedTailNumber("");
                setSelectedIssue("");
                setEditIndex(-1);
            });
        }
    }
    const onModifieIssue = (issueId: number) => {
        if (isEditMode) {
            liteApi.UpdateIssue(issueId, selectedIssue, selectedTailNumber).then(res => {
                refresh(liteHandoverReport.id);
                setSelectedTailNumber("");
                setEditIndex(-1);
            });
        }
    }
    const onAddEmployeeLabour = (user: User) => {
        if (isEditMode) {
            liteApi.AddLabour(liteHandoverReport.id, user.user_id).then(res => {
                refresh(liteHandoverReport.id);
            });
        }
    }
    const onDeleteEmployeeLabour = (labourID: number) => {
        if (isEditMode) {
            liteApi.DeleteLabour(liteHandoverReport.id, labourID).then(res => {
                refresh(liteHandoverReport.id);
            });
        }
    }
    const onAddEmployeeLabourMC = (user: User) => {
        if (isEditMode) {
            liteApi.AddLabourMC(liteHandoverReport.id, user.user_id).then(res => {
                refresh(liteHandoverReport.id);
            });
        }
    }
    const onDeleteEmployeeLabourMC = (labourID: number) => {
        if (isEditMode) {
            liteApi.DeleteLabourMC(liteHandoverReport.id, labourID).then(res => {
                refresh(liteHandoverReport.id);
            });
        }
    }
    const onAreYouDialogClicked = (status: boolean) => {
        if (isEditMode) {
            if (status === true) {
                liteApi.CloseLiteHandoverReport(workPlace).then(res => {
                    setAreYouSureDialogOpen(false);
                    onClose();
                    refreshLiteHandOverTab();
                });
            } else {
                setAreYouSureDialogOpen(false);
                onClose();
            }
        }
    }
    const onSubmitBeginEndDate = () => {
        if (isEditMode) {
            liteApi.UpdateLiteHandOverReportDates(liteHandoverReport.id, beginDate, endDate).then(res => {
                refresh(liteHandoverReport.id);
            });
            setOpenDatePicker(false);
        }
    }
    return (
        <Grid>
            <Grid>
                {liteHandoverReport.is_submitted === false && isEditMode && <Grid container justifyContent={'center'}>
                    <Button onClick={() => setAreYouSureDialogOpen(true)} color='primary'>Finish report</Button>
                    <Button onClick={() => setOpenDatePicker(!openDatePicker)} color='primary'>Select start end date</Button>
                </Grid>}
                <Grid container justifyContent={'center'}>
                    {openDatePicker === true &&
                        <Grid container justifyContent={'center'}>
                            <Grid margin={[0, 0, 1, 0]}>
                                <DatePicker type="DateAndTime" selected={beginDate} label="Begin time" onDateChange={(date: Date) => { setBeginDate(date) }} />
                            </Grid>
                            <Grid margin={[0, 0, 1, 0]}>
                                <DatePicker type="DateAndTime" selected={endDate} label="End time" onDateChange={(date: Date) => { setEndDate(date) }} />
                            </Grid>
                            <Grid margin={[0, 0, 3, 0]}>
                                <Button onClick={() => onSubmitBeginEndDate()} color='primary'>Submit dates</Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid>
                    <Grid container>
                        <Typography text={"Created by : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
                        <Typography text={liteHandoverReport.supervisor_name} />
                    </Grid>
                    <Grid container>
                        <Typography text={"Created date : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
                        <Typography text={liteHandoverReport.created_date_text} />
                    </Grid>
                    <Grid container>
                        <Typography text={"Start date : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
                        <Typography text={liteHandoverReport.begin_date_text} />
                    </Grid>
                    <Grid container>
                        <Typography text={"End date : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
                        <Typography text={liteHandoverReport.end_date_text} />
                    </Grid>
                    {liteHandoverReport.shift !== null &&
                    <Grid container>
                        <Typography text={"Shift : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
                        <Typography text={liteHandoverReport.shift} />
                    </Grid>}
                    {liteHandoverReport.day !== null &&
                    <Grid container>
                        <Typography text={"Type : "} fontWeight={'bolder'} margin={[0, 1, 0, 0]} />
                        <Typography text={liteHandoverReport.day ? "Day" : "Night"} />
                    </Grid>}
                </Grid>
                <Typography text={'Issues'} align={'center'} variant='h2' />
                <Table
                    headers={["Created date", "Aircraft", "Issue", ""]}
                    rows={liteHandoverReport.lite_handOver_issues}
                    generateBody={(row: any, index: number) => generateTableBody(row, index)}
                    defaultRowsPerPage={25}
                    pagination={false}
                    skipIndexCol={false}
                />
                {liteHandoverReport.is_submitted === false && isEditMode &&
                    <Grid container direction="column" alignItems="center">
                        <IconButton onClick={() => onAddIssue()} className={classes.save} >
                            <AddCircle />
                        </IconButton>
                    </Grid>
                }
            </Grid>
            <Grid container>
                <Grid xs={workPlace === "LITE" ? 6 : 12}>
                    <Grid >
                        {liteHandoverReport.is_submitted === false && isEditMode &&
                            <EmployeeSearch place_holder={workPlace === "LITE" ? "Lite employees" : "RVK Line Employees"} clearOnSelect fullUser={true} onSelectedEemployee={(employee: User) => onAddEmployeeLabour(employee)} inputWidth={95} />
                        }
                        <Collection edit={liteHandoverReport.is_submitted === false} background={false} collectionList={liteHandoverReport.lite_handOver_employees} title={workPlace === "LITE" ? "Lite employees" : "RVK Line Employees"} nameProperty="name" idProperty="Id" imageProperty="user_image" removeItem={(itemId: number) => onDeleteEmployeeLabour(itemId)} />
                    </Grid>
                </Grid>
                {workPlace === "LITE" &&
                    <Grid xs={6}>
                        <Grid >
                            {liteHandoverReport.is_submitted === false && isEditMode &&
                                <EmployeeSearch place_holder={"Mc employees"} clearOnSelect fullUser={true} onSelectedEemployee={(employee: User) => onAddEmployeeLabourMC(employee)} inputWidth={95} />
                            }
                            <Collection edit={liteHandoverReport.is_submitted === false} background={false} collectionList={liteHandoverReport.lite_handOver_mc_employees} title="MC employees" nameProperty="name" idProperty="Id" imageProperty="user_image" removeItem={(itemId: number) => onDeleteEmployeeLabourMC(itemId)} />
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid container justifyContent={'center'}></Grid>
            {
                workPlace === "LITE" &&
                <Grid container justifyContent={'center'}>
                    <Tabs
                        tabNames={["Hidden", "Lite Package", "Turn Checks"]}
                        onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                        tabValue={tabValue}
                    />
                </Grid>
            }
            <LineHandoverMxiPackageInformation tabValue={tabValue} reportId={liteHandoverReport.id} />
            <Dialog
                visible={areYouSureDialogOpen}
                max_width={"md"} title={"Are you sure you want to finish this report"}
                context={
                    <div></div>
                }
                onClose={(status: any) => onAreYouDialogClicked(status)}
                strict={false}
                show_options={true}
            />
        </Grid >
    );
};
export default LiteHandoverDialogContext;