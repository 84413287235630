import { FunctionComponent, useEffect } from 'react';
import { Grid, Button } from '@mui/material'
import { Request } from '../../types/capabilityList';
import CompareRequest from './CompareRequest';
interface PrintProps {
    setTabValue: Function;
    filteredShopsForPrint: Request[];
}

const Print: FunctionComponent<PrintProps> = ({ setTabValue, filteredShopsForPrint }) => {

    useEffect(() => {
        const headerElement = document.getElementById('header');
        if (headerElement) {
            headerElement.style.visibility = 'hidden';
        }
    }, [])

    const hideHeader = () => {
        setTabValue(0);
        const headerElement = document.getElementById('header');
        if (headerElement) {
            headerElement.style.visibility = 'visible';
        }
    }

    return (
        <Grid>
            <Button onClick={() => hideHeader()} variant="outlined">Back</Button>
            {(filteredShopsForPrint.length > 0) && filteredShopsForPrint.map((request: Request, index: number) => (
                <div id="printableArea" style={{ width: '1000px' }}>
                    <CompareRequest
                        rIndex={index}
                        printMode={true}
                        isChanged={false}
                        request={request}
                    />
                    <hr style={{ marginBottom: '42px', marginTop: '42px', }} />
                </div>
            ))
            }
        </Grid >
    )
}

export default Print;