import { Grid, TableRow, TableCell, Switch } from '@mui/material';
import { Typography } from '../../MaterialUiComponents';
import { FunctionComponent, useState } from 'react';
import { Table } from '../../MaterialUiComponents';
import { LoanedToolsForMe } from '../../types/tool';

type ToolProps = {
  tools: LoanedToolsForMe[],
  filteredTools: LoanedToolsForMe[],
}

const MyTools: FunctionComponent<ToolProps> = ({ tools, filteredTools }) => {
  const [toggleTools, setToggleTools] = useState<boolean>(false);

  const generateTableBody = (row: LoanedToolsForMe, index: number) => {
    return (
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.bay}</TableCell>
        <TableCell>{row.loaned_out_date_text}</TableCell>
        <TableCell>{row.returned_date_text}</TableCell>
        <TableCell>{row.tail_number}</TableCell>
        <TableCell>{row.work_area}</TableCell>
        <TableCell>{row.serial}</TableCell>
      </TableRow>
    )
  }
  return (
    <Grid>
      <Grid xs={4} container direction="row">
        <Typography align='center' variant='h3' text={"Not returned"} margin={[1, 0, 0, 0]} />
        <Switch
          checked={toggleTools}
          onChange={() => setToggleTools(!toggleTools)}
          color="secondary"
          name="checkedB"
        />
        <Typography align='center' variant='h3' text={"Full List"} margin={[1, 0, 0, 0]} />
      </Grid>
      <Table
        headers={["Tool", "Bay", "Loaned out", "Returned", "Tail", "Area", "Serial"]}
        rows={!toggleTools ? filteredTools : tools}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={100}
      />
    </Grid>
  )
}

export default MyTools;