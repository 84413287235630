import { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from './../../../MaterialUiComponents';

const useStyles = makeStyles(theme => ({
  tapBox: {
    padding: theme.spacing(1, 0, 1, 0),
    border: '1px solid black',
    borderRadius: theme.spacing(2),
    margin: theme.spacing(0, 1, 0, 1),
    color: theme.palette.common.white,
  },
  toolOut: {
    backgroundColor: theme.palette.primary.main,
  },
  toolIn: {
    backgroundColor: theme.palette.secondary.main,
  }
}));

type TopRowProps = {
  tabValue: number,
  staticTabValue: number,
  text: string
}

const TopRow: FunctionComponent<TopRowProps> = ({ tabValue, staticTabValue, text }) => {
  const classes = useStyles();
  return (
    <Grid className={tabValue === staticTabValue ?
      `${tabValue === 0 ? classes.toolOut : classes.toolIn} ${classes.tapBox} `
      :
      classes.tapBox}>
      <Typography color='textPrimary' textColor={tabValue === staticTabValue ? 'white' : undefined} align='center' variant='h1' text={text} />
    </Grid>
  );
};
export default TopRow;