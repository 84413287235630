import { useState, useEffect, FunctionComponent, } from 'react';
import { Grid, Button, Switch, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Tabs, Input, Dialog } from '../../MaterialUiComponents';
import { Dictionary } from '../../components';
import CertificatesApi from './../../api/certificates';
import { CertificateAircraftCategory, CertificateRating } from '../../types/certificates';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';

const useStyles = makeStyles(theme => ({
  createButton: {
    backgroundColor: '#407BAC',
    color: 'white',
    fontSize: '1em',
  },
}));

type CertificateProps = {
  setLoading: Function
}

const EditProperties: FunctionComponent<CertificateProps> = ({ setLoading }) => {
  const [aircraftTypes, setAircraftTypes] = useState(Array<CertificateAircraftCategory>());
  const [ratingTypes, setRatingTypes] = useState(Array<CertificateRating>());
  const [newPropertyInput, setNewPropertyInput] = useState('');
  const store = useSelector((state: RootState) => state);
  const currentUser = store.user.me;
  const [tabValue, setTabValue] = useState(0);
  const [showActive, setShowActive] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [selectedAircraft, setSelectedAircraft] = useState<CertificateAircraftCategory | undefined>(undefined);
  const [selectedRating, setSelectedRating] = useState<CertificateRating | undefined>(undefined);

  useEffect(() => {
    refresh('rating');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = (dictionaryType: string) => {
    setLoading(true);
    if (dictionaryType === 'aircraft') {
      CertificatesApi.GetAllCertififcateAircraftCategories().then(res => {
        setAircraftTypes(res.data);

      })
    }
    if (dictionaryType === 'rating') {
      setLoading(true);
      CertificatesApi.GetAllCertificationRatings().then(res => {
        setRatingTypes(res.data);
        setLoading(false);
      })
    }
  }

  const onReasonEdit = (value: string) => {
    setDeleteReason(value);
  }

  const addProperty = (newProperty: string) => {
    let propertyToAdd;
    if (tabValue === 0) {
      propertyToAdd = { rating: newProperty, created_by_employee_id: currentUser.user_id }
      CertificatesApi.CreateNewCertificateRating(propertyToAdd).then(res => {
        refresh('rating');
        setNewPropertyInput('');
      })
    }
    if (tabValue === 1) {
      propertyToAdd = { aircraft_category: newProperty, created_by_employee_id: currentUser.user_id };
      CertificatesApi.CreateNewAircraftCategory(propertyToAdd).then(res => {
        refresh('aircraft');
        setNewPropertyInput('');
      });
    }
  }

  const deleteRating = (rating: CertificateRating) => {
    setSelectedRating(rating);
    setDeleteDialog(true);
  }

  const deleteAircraftCategory = (category: CertificateAircraftCategory) => {
    setSelectedAircraft(category);
    setDeleteDialog(true);
  }

  const confirmDelete = () => {
    if (selectedRating !== undefined) {
      selectedRating.reason = deleteReason;
      CertificatesApi.RemoveCertificateRatings(selectedRating).then(res => {
        setSelectedRating(undefined);
        refresh('rating');
      })
    }
    if (selectedAircraft !== undefined) {
      selectedAircraft.reason = deleteReason;
      CertificatesApi.RemoveCertificateAircraftCategoryById(selectedAircraft).then(res => {
        setSelectedAircraft(undefined);
        refresh('aircraft');
      })
    }
    setDeleteDialog(false);
    setDeleteReason('');
  }

  const onChangeTabValue = (tabValue: number) => {
    if (tabValue === 0) {
      refresh('rating');
    }
    if (tabValue === 1) {
      refresh('aircraft');
    }
    setTabValue(tabValue);
  }

  const classes = useStyles();
  return (
    <Grid>
      <Grid item xs={12}>
        <Typography align='center' variant='h2' text={tabValue === 0 ? "Add new Rating Property" : "Add new Aircraft Property"}></Typography>
      </Grid>
      <Grid xs={12} container >
        <Grid container xs={1}>
        </Grid>
        <Grid container xs={6}>
          <Grid container xs={12}>
            <Grid item xs={12} lg={7} xl={6}>
              <Tabs
                tabNames={["Ratings", "Aircrafts"]}
                onChangeTabValue={(tabValue: number) => onChangeTabValue(tabValue)}
                tabValue={tabValue}
              />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Typography text="Active" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Switch
                checked={!showActive}
                onChange={() => setShowActive(!showActive)}
                color="secondary"
                name="checkedB"
              />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Typography text="Deleted" variant="subtitle2" color="textPrimary" margin={[1, 0, 0, 0]} />
            </Grid>
          </Grid>
          {tabValue === 0 &&
            <Grid xs={10} item>
              {
                ratingTypes.filter(rating => rating.visible === showActive).map(row => (
                  <Dictionary name={row.rating} object={row} deleted={showActive === true ? false : true} description={showActive === true ? '' : row.reason} id={row.id} deleteProperty={(row: CertificateRating) => deleteRating(row)}></Dictionary>
                ))
              }
            </Grid>
          }
          {tabValue === 1 &&
            <Grid xs={10} item>
              {
                aircraftTypes.filter(type => type.visible === showActive).map(row => (
                  <Dictionary name={row.aircraft_category} object={row} deleted={showActive === true ? false : true} description={showActive === true ? '' : row.reason} id={row.id} deleteProperty={(row: CertificateAircraftCategory) => deleteAircraftCategory(row)}></Dictionary>
                ))
              }
            </Grid>
          }
        </Grid>
        <Dialog
          visible={deleteDialog}
          max_width={"md"} title={"Are you sure you want to delete this?"}
          context={
            <div>
              <Box display="flex" justifyContent="center" width="100%" m={1} p={1}>
                <Input
                  label_text={"Type a reason"} value={deleteReason} help_text=""
                  onTextChange={(value: string) => onReasonEdit(value)} type="text" width={50} multiline={false} />
              </Box>
              <Box display="flex" justifyContent="center" m={1} p={1}>
                <Box p={1} >
                  <Button variant="contained" color="primary" onClick={() => confirmDelete()}>Confirm delete</Button>
                </Box>
                <Box p={1}>
                  <Button variant="contained" color="secondary" onClick={() => setDeleteDialog(false)}>Cancel</Button>
                </Box>
              </Box>
            </div>
          }
          onClose={(status: any) => setDeleteDialog(false)}
          strict={false}
          show_options={false}
        />
        <Grid item xs={5}>
          <Grid item xs={12}>
            <br />
            <Input
              multiline={false}
              label_text="New Property"
              help_text=""
              type="text"
              value={newPropertyInput}
              width={50}
              onTextChange={(value: string) => setNewPropertyInput(value)}
            />
          </Grid>
          <br />
          <Button variant="contained" color="primary" className={classes.createButton} onClick={() => addProperty(newPropertyInput)} >
            Add Property
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EditProperties;
