import { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import IcelandairLogo from '../../assets/icelandairLogo.svg';
import { Grid, IconButton, TextField, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { DatePicker, Input, Typography } from '../../MaterialUiComponents';
import { destinations, WorldTrip } from '../../types/worldTools';
import worldTools from '../../api/worldTools';
import { Autocomplete } from '@mui/material';
import { EmployeeSearch } from '../../components';
import { User } from '../../types/user';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { ToolData } from '../../types/tool';
import { Collection } from '../../components/Collection';
import taskApi from './../../api/tasks';
import WorldToolPdf from './WorldToolPdf';
import ToolsList from './ToolsList';


const useStyles = makeStyles(theme => ({
    icelandairLogo: {
        height: '50px',
        bottom: '5px',
        position: 'relative',
    },
    infoTop: {
        marginBottom: theme.spacing(2)
    },
    formContainer: {
        marginTop: theme.spacing(5)
    },
}));
type NewWorldTripProps = {
    closeForm: Function,
    selected?: WorldTrip,
    allDestinations: destinations[],
}

// This page contains the form for both editing and creating World Trips (based on viewType value)
const NewWorldTrip: FunctionComponent<NewWorldTripProps> = ({ closeForm, selected, allDestinations }) => {
    const store = useSelector((state: RootState) => state);
    const isWorldToolAdmin = store.user.me.user_clearence_groups.includes("World Tools Edit");
    const [selectedTrip, setSelectedTrip] = useState<WorldTrip>();
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [destination, setDestination] = useState<destinations | undefined>(selected && allDestinations.find(dest => dest.location === selected.destination));
    const [name, setName] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [rfid, setRfid] = useState<string>("");
    const [employees, setEmployees] = useState<User[]>([]);
    const [tools, setTools] = useState<ToolData[]>([]);
    const [employeeIds, setEmployeeIds] = useState<number[]>([]);
    const [toolIds, setToolIds] = useState<number[]>([]);
    const [tempTail, setTempTail] = useState<string | null>("");
    const [errorMessage, setErrorMessage] = useState("");
    const [tails, setTails] = useState<string[]>([]);

    useEffect(() => {
        if (selected !== undefined) {
            getWorldTripById(selected.id);
        }
    }, [selected]);

    useEffect(() => {
        taskApi.GetAllTailNumbers().then(res => {
            setTails(res.data);
        })
    }, []);

    // Populating all values based on the trip props when that's included in props (Triggered in use effect) Collection component created and used here to display selected items of any sort
    const getWorldTripById = (worldTripId: number) => {
        worldTools.GetWorldTripById(worldTripId).then(res => {
            setSelectedTrip(res.data);
            setName(res.data.general_info.name);
            setStartDate(res.data.general_info.start_date);
            setEndDate(res.data.general_info.end_date);
            setReason(res.data.general_info.reason);
            setEmployees(res.data.assigned_employees);
            setTools(res.data.loaned_out_tools);
            setTempTail(res.data.general_info.tail_number);
            const tempDestination = allDestinations.find(dest => dest.id === res.data.general_info.location_id);
            setDestination(tempDestination);
        });
    }
    const onSelectedEmployeeRow = (employee: User) => {
        if (employee !== null) {
            setEmployees([...employees, employee]);
            setEmployeeIds([...employeeIds, employee.user_id]);
        }
    }
    // This is fired on keydown event in input field for tools. Logic applies if the key clicked was enter (Scanner types in the code and automatically hit's enter)
    const onSelectedTool = (keyCode: any) => {
        if (keyCode === 13) {
            let tempTool = store.tools.mxi_tools.find(s => s.rf_id === rfid);
            if (tempTool !== undefined) {
                setTools([...tools, tempTool]);
                setToolIds([...toolIds, tempTool.mxi_inv_no_id]);
                setRfid("");
            }
        }
    }
    const saveWorldTrip = () => {
        if (destination) {
            if (selected && selectedTrip) {
                if (selected.status !== "Complete") {
                    selectedTrip.assigned_employees = employees;
                    selectedTrip.loaned_out_tools = tools;
                    selectedTrip.general_info.last_modified_by_employee_id = store.user.me.user_id;
                    selectedTrip.general_info.tail_number = tempTail;
                    selectedTrip.general_info.start_date = startDate;
                    selectedTrip.general_info.end_date = endDate;
                    selectedTrip.general_info.location_id = destination.id;
                    selectedTrip.general_info.name = name;
                    selectedTrip.general_info.reason = reason;
                    worldTools.UpdateWorldTrip(selectedTrip).then(res => {
                        if (res.status === false) {
                            setErrorMessage(res.message);
                        } else {
                            closeForm();
                        }
                    });
                }
            } else {
                worldTools.CreateNewWorldTrip(destination.id, reason, startDate, endDate, employees, tools, name, tempTail).then(res => {
                    if (res.status === false) {
                        setErrorMessage(res.message);
                    } else {
                        closeForm();
                    }
                });
            }
        }
    }
    const removeEmployee = (employeeId: number) => {
        const employeeToRemove = employees.findIndex(employee => employee.user_id === employeeId);
        const tempEmployees = [...employees];
        tempEmployees.splice(employeeToRemove, 1);
        setEmployees(tempEmployees);
    }
    const OnDateChange = (status: 'Start' | 'End', date: Date) => {
        if (status === 'Start') {
            setStartDate(date);
        } else {
            if (new Date(startDate) > new Date(date)) {
                setErrorMessage("End date is incorrect");
            } else {
                setErrorMessage("");
            }
            setEndDate(date);
        }
    }
    const classes = useStyles();
    return (
        <Grid className={classes.infoTop}>
            <Grid container justifyContent="center" xs={12}>
                <Grid item xs={7}>
                    <Typography color='primary' variant='h1' text={selected ? selected.trip_name : "Create new World Trip"} align='left' />
                </Grid>
                <Grid item xs={4}>
                    <img src={IcelandairLogo} className={classes.icelandairLogo} alt="Logo" />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => closeForm()} color="default" >
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
            <hr />
            <Grid container xs={12}>
                <Grid item xs={12} lg={7}>
                    {selectedTrip && <WorldToolPdf trip={selectedTrip!} />}
                    <Grid container xs={12} justifyContent="flex-start" spacing={2} className={classes.formContainer} style={{ marginTop: "-5px" }}>
                        <Grid item xs={6}>
                            <Input multiline={true} help_text={"Set a name for the world trip"} label_text={"World Trip Name"} value={name} onTextChange={(text: string) => setName(text)} type="text" width={100} />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                fullWidth
                                noOptionsText="No tails found"
                                options={allDestinations}
                                getOptionLabel={option => option.location}
                                defaultValue={destination}
                                value={destination}
                                clearOnEscape
                                autoHighlight
                                onChange={(e: any, newValue: destinations | null) => newValue ? setDestination(newValue) : {}}
                                renderInput={(params) => (
                                    <TextField {...params} label="Destination" variant="standard" />
                                )} />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker type="Date" selected={startDate} label="Start date" onDateChange={(date: Date) => OnDateChange("Start", date)} />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker type="Date" selected={endDate} label="End date" onDateChange={(date: Date) => OnDateChange("End", date)} errorString={errorMessage} />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                fullWidth
                                options={tails}
                                value={tempTail}
                                noOptionsText="No tails found"
                                clearOnEscape
                                autoHighlight
                                onChange={(e: any, newValue: string | null) => setTempTail(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tails" margin="none" variant="standard" size="small" fullWidth />
                                )} />
                        </Grid>
                        <Grid item xs={12}>
                            <Input multiline={true} help_text={"What is the Reason for this world trip. Write minimum of 50 words"} label_text={"Reason for World Trip"} value={reason} onTextChange={(text: string) => setReason(text)} type="text" width={100} />
                        </Grid>
                        <Grid item xs={6}>
                            {isWorldToolAdmin && selected?.status !== "Complete" &&
                                <EmployeeSearch clearOnSelect={true} fullUser={true} place_holder={"All employees"} onSelectedEemployee={(employee: User) => onSelectedEmployeeRow(employee)} />
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {isWorldToolAdmin && selected?.status !== "Complete" &&
                                <Input multiline={false} help_text={"Please scan your tool for this trip"} label_text={"Scan Tool"} value={rfid} onKeyDown={(keyDown: any) => onSelectedTool(keyDown.keyCode)} onTextChange={(text: string) => setRfid(text.toUpperCase())} type="text" width={100} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {
                                isWorldToolAdmin && selected?.status !== "Complete" &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={() => { saveWorldTrip() }}>
                                    {selected ? "Save Changes" : "Create new World Tool"}
                                </Button>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Collection edit={isWorldToolAdmin && selected?.status !== "Complete"} collectionList={employees} title="Employees assigned to trip" idProperty="user_id" imageProperty="user_image" removeItem={(itemId: number) => removeEmployee(itemId)} />
                    <ToolsList tools={tools} />
                </Grid>
            </Grid>
        </Grid >
    );
};
export default NewWorldTrip;
