import { Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { Tabs } from '../../MaterialUiComponents';
import toolApi from '../../api/tools';
import taskApi from '../../api/tasks';
import lineApi from '../../api/line';
import certificatesApi from '../../api/certificates';
import { MyLineTask } from '../../types/tasks';
import { CertificateData } from '../../types/certificates'
import { LoanedToolsForMe } from '../../types/tool';
import { FlightSchedule } from '../../types/line';
import { MxiStaffManagementEmployee } from './../../types/staffManagement';
import MyTaskPlan from './MyTaskPlan';
import MyTools from './MyTools';
import MyCertificates from './MyCertificates';
import MyFlightSchedule from './MyFlightSchedule';
import { RootState } from '../../reducer';
import { useSelector } from 'react-redux';
import LineTasks from './LineTasks';
import staffmanagementApi from '../../api/staffmanagement';

const MyProfilePage = () => {

    const [tabValue, setTabValue] = useState(0);
    const [tools, setTools] = useState<LoanedToolsForMe[]>([]);
    const [myFilteredTools, setFilteredTools] = useState<LoanedToolsForMe[]>([]);
    const [validCert, setValidCert] = useState<CertificateData>();
    const [myCrew, setMyCrew] = useState(Array<MxiStaffManagementEmployee>);
    const [flights, setFlights] = useState(Array<FlightSchedule>());
    const [myFilteredFlights, setMyFilteredFlights] = useState(Array<FlightSchedule>());
    const [myLineTasks, setMyLineTasks] = useState<MyLineTask[]>([]);
    const [lineTasksLoaded, setLineTasksLoadding] = useState<Boolean>(true);

    const store = useSelector((state: RootState) => state);
    const currentUserId = store.user.me.user_id;

    useEffect(() => {
        getCertificates();
        getTools();
        getTasks();
        getFlightSchedule();
        getMyCrew();
    }, []);

    const getMyCrew = () => {
        staffmanagementApi.GetAllMembersFromMyCrew().then(res => {
            if (res.status) {
                setMyCrew(res.data);
            }
        });
    }
    const getCertificates = () => {
        certificatesApi.GetCertificateForMe().then(res => {
            setValidCert(res.data);
        });
    }
    const getTools = () => {
        toolApi.GetToolsForMe().then(res => {
            const filteredTools = res.data?.filter(tool => {
                return tool.returned_date === null;
            });
            setFilteredTools(filteredTools);
            setTools(res.data);
        });
    }

    const getTasks = () => {
        setLineTasksLoadding(true);
        taskApi.GetLineTasksForMe().then(res => {
            if (res.status === true) {
                setMyLineTasks(res.data);
            }
            setLineTasksLoadding(false);
        });
    }

    const getFlightSchedule = () => {
        lineApi.getFlightSchedule().then(res => {
            const filteredFlights = res.data.filter(flight => {
                return flight.assigned_mechanics.find(item => item.user_id === currentUserId)
            });
            setMyFilteredFlights(filteredFlights);
            setFlights(res.data);
        });
    }

    return (
        <Grid>
            <Tabs
                tabNames={["Bay tasks", "Line tasks ", "My tools", "Certificates", "Flight schedule"]}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                tabValue={tabValue}
            />
            {tabValue === 0 && <MyTaskPlan myCrew={myCrew} />}
            {(tabValue === 1 && myLineTasks !== undefined) && <LineTasks myLineTasks={myLineTasks} loading={lineTasksLoaded} />}
            {(tabValue === 2 && tools !== undefined) && <MyTools tools={tools} filteredTools={myFilteredTools} />}
            {(tabValue === 3 && validCert !== undefined) && <MyCertificates validCert={validCert} />}
            {(tabValue === 4) && <MyFlightSchedule flights={flights} myFilteredFlights={myFilteredFlights} />}
        </Grid>
    )
}

export default MyProfilePage;