import React, { FunctionComponent, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, IconButton } from '@mui/material';
import { Typography } from '../../MaterialUiComponents';
import CircularProgress from '@mui/material/CircularProgress';
import { TableColumns } from '../../types/table';
import { DateTime } from 'luxon';
import { KeyboardDoubleArrowUp, KeyboardDoubleArrowDown } from '@mui/icons-material';

const BottomRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'pink'
}));

type DataItemToSort = {
  [key: string]: string | number | boolean | Date;
};

type TableUIProps = {
  headers: string[],
  generateBody: Function,
  rows: any,
  defaultRowsPerPage?: number,
  OnOrderByTitles?: Function
  pagination?: true | false,
  skipIndexCol?: true | false,
  bottomRow?: string[],
  loading?: boolean,
  emptyTableText?: string,
  displayEmptyText?: boolean,
  displaySortAscending?: boolean,
  sortTableColumns?: TableColumns[],
}

const TableUI: FunctionComponent<TableUIProps> = ({ sortTableColumns, headers, displaySortAscending, loading = false, rows, generateBody, defaultRowsPerPage, OnOrderByTitles, pagination, skipIndexCol, bottomRow, displayEmptyText = false, emptyTableText = "No data in table" }) => {
  const [data, setData] = useState<any>(rows);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  const parseStringToDate = (dateString: string): Date | null => {
    const luxonDate = DateTime.fromFormat(dateString, "dd.MM.yyyy HH:mm");
  
    if (luxonDate.isValid) {
      return luxonDate.toJSDate();
    } else {
      return null;
    }
  };

  const dynamicSort = (key: string) => {
    const direction = sortConfig?.direction === 'asc' ? 'desc' : 'asc';
    const sortedData = [...data].sort((a: DataItemToSort, b: DataItemToSort) => {
      let comparison: number = 0;

      const valueA = a[key];
      const valueB = b[key];

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        comparison = valueA - valueB;
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {

        const dateA = parseStringToDate(valueA)
        const dateB = parseStringToDate(valueB)

        if ((dateA) && (dateB)) {
          comparison = dateA.getTime() - dateB.getTime();
        } else {
          comparison = valueA.localeCompare(valueB);
        }
      } else if (valueA instanceof Date && valueB instanceof Date) {
        comparison = valueA.getTime() - valueB.getTime();
      } else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
        comparison = Number(valueA) - Number(valueB);
      }

      return direction === 'asc' ? comparison : -comparison;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }
  const [page, setPage] = useState(0);
  const [sortByColumn, setSortByColumn] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const onOrderClick = (header: string) => {
    if (OnOrderByTitles !== undefined) {
      OnOrderByTitles(header);
      setSortByColumn(header);
    }
  }

  return (
    loading === false ?
      <Grid>
        <TableContainer >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {skipIndexCol !== true && <TableCell align={"left"} >{""}</TableCell>}
                {sortTableColumns === undefined ? headers.map((header: string, index: number) => (
                  <TableCell
                    onClick={() => { onOrderClick(header) }}
                    key={index}
                    align={"left"}
                  >
                    {header}
                    {((displaySortAscending === undefined || header === "" || sortByColumn !== header) ?
                      ''
                      :
                      displaySortAscending === true ?
                        <IconButton color="primary"><KeyboardDoubleArrowDown /> </IconButton>
                        :
                        <IconButton color="primary"><KeyboardDoubleArrowUp /> </IconButton>)
                    }
                  </TableCell>
                )) :
                sortTableColumns.map((column, idx) => (
                    <TableCell
                      onClick={() => { dynamicSort(column.key) }}
                      key={idx}
                      align={"left"}
                    >
                      {column.label}
                      {((displaySortAscending === undefined || column.label === "" || sortByColumn !== column.label) ?
                        ''
                        :
                        displaySortAscending === true ?
                          <IconButton color="primary"><KeyboardDoubleArrowDown /> </IconButton>
                          :
                          <IconButton color="primary"><KeyboardDoubleArrowUp /> </IconButton>)
                      }
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows.length === 0 && displayEmptyText === true ?
                  <TableRow>
                    <TableCell colSpan={headers.length}>
                      <Typography text={emptyTableText} align="center" variant="subtitle1" fontWeight="bold" />
                    </TableCell>
                  </TableRow> :
                  pagination === true ?
                    data.slice((page) * defaultRowsPerPage!, page * rowsPerPage! + rowsPerPage!).map((row: any, index: number) => (
                      generateBody(row, index + 1)
                    ))
                    :
                    data.map((row: any, index: number) => (
                      generateBody(row, index + 1)
                    ))
              }
              {bottomRow !== undefined &&
                <BottomRow>
                  {bottomRow.map((column, idx) => (
                    <TableCell key={idx}>
                      <Typography variant="subtitle1" fontWeight="bold" text={column}>
                      </Typography>
                    </TableCell>
                  ))}
                </BottomRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        {pagination === true &&
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage!}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </Grid>
      :
      <Grid container justifyContent="center">
        <Grid>
          <CircularProgress />
        </Grid>
      </Grid>
  );
};
export default TableUI;