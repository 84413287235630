import { FunctionComponent } from 'react';
import { Grid, Chip } from '@mui/material';
import { AssignedMechanicSkill } from '../../types/skills';
import { makeStyles } from '@mui/styles';

type SkillsProps = {
    assignedSkills: AssignedMechanicSkill[],
}

const useStyles = makeStyles((theme) => ({
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    skills: {
        height: '50px',
        padding: '8px',
        margin: '8px',
        marginBottom: 8,
        fontSize: '1.2rem',
        fontWeight: '600',
    },
}));

const SkillsTab: FunctionComponent<SkillsProps> = ({ assignedSkills }) => {
    const classes = useStyles();
    return (
        <Grid style={{ marginTop: '18px', minHeight: '200px' }}>
            <Grid container className={classes.modalContent}>
                {(assignedSkills.length > 0) &&
                    assignedSkills.map((skill, index) => (
                        <Chip
                            className={classes.skills}
                            onClick={() => { }}
                            key={index}
                            label={skill.SkillName}
                        />
                    ))
                }
            </Grid>
        </Grid>
    )
}
export default SkillsTab