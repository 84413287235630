import { settings } from '../config';
import { Me, User, EmployeeAttendanceStatistic } from '../types/user';
import { getToken } from './../global/TokenValidator';

class Auth {
    getUserByUserName = async (): Promise<{ message: string, status:boolean, me: Me }> => {
        return fetch(`${settings.HOST}/User/getUserByUserName`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    getAllUsers = async (): Promise<User[]> => {
        return fetch(`${settings.HOST}/User/getAllUsers/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }

    AllHangarEmployeeAttendance = async (): Promise<{ status: Boolean, message: string, data: Array<EmployeeAttendanceStatistic>, total: number }> => {
        return fetch(`${settings.HOST}/User/AllHangarEmployeeAttendance`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
        }).then(x => {
            return x.json()
        })
    }
    UpdateHREmployeeNumber = async (newEmployeeNumber: string, employeeId: number): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/User/UpdateHrEmployeeNumber`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "hr_employee_number": newEmployeeNumber,
                    "id": employeeId
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    updateUserCardCode = async (employeeId: number, userCardCode: string): Promise<{ status: Boolean, message: string }> => {
        return fetch(`${settings.HOST}/User/updateUserCardCode`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify(
                {
                    "id": employeeId,
                    "user_card_code": userCardCode
                }
            )
        }).then(x => {
            return x.json()
        })
    }
}
const AuthApi = new Auth();
export default AuthApi;