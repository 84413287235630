import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import clsx from 'clsx';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import authProvider from "./config/authProvider";
import { Header, Draw, LoginDigitsPin } from './components';
import userApi from './api/userApi';
import {
	ToolRegister, ToolStock, ClothingRequests, Certificates, NotAuthorized, LoanedToolForToLong, HangarOverview,
	Security, WorldTools, Warranties, ReykjavikLineSupervisor, Vacations, ExtraPayments, FlightScheduleView, MyProfilePage, StaffManagement,
	Line, TimeControlBarcodes, AccessControl, Courses, BayHandover, Capability, MechanicInfo, Lite, CrewPlan, MaintenanceReports, HangarPlan, ToolsBayTwoMonitor
} from './Pages';
import toolsApi from './api/tools';
import { RootState } from './reducer';
import { Typography, Dialog, Input,LinearProgress } from './MaterialUiComponents';
import { hangarLineOptions, statusOptions } from './properties/lineProperties';
import { hangarLiteOptions } from './properties/liteProperties';
import { DateTime } from 'luxon';
import useGlobalKeydownForTools from './utils/useGlobalKeydownForTools';
import { getToken } from './global/TokenValidator';
import { User } from './types/user';

console.log("Starting React app App.tsx ...");

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	container: {
		padding: theme.spacing(6, 0.5, 0, 0),
		maxWidth: '100%',
		marginLeft: 210
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -210,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));

function App() {

	const dispatch = useDispatch();
	const store = useSelector((state: RootState) => state);
	const routes = store.user?.me?.user_clearence_groups !== undefined ? store.user.me.user_clearence_groups : [];
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [drawerOpen, setDrawerOpen] = useState(true);
	const [loginDigitDialogOpen, setLoginDigitDialogOpen] = useState(false);
	const [userPin, setUserPin] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [isImagesLoading, setIsImagesLoading] = useState<boolean>(false);
    
	const isToolRegisteruser = (store.user?.me?.user_clearence_groups !== undefined) ? store.user?.me?.user_clearence_groups.includes("Tool Register") : false;
	useGlobalKeydownForTools(isToolRegisteruser);

	const fetchUserImage = async (userName:string) => {
		const token = await getToken();
		const response = await fetch(`/api/maintener/fetch-user-image/${userName}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'token': token
			}
		});
		const blob = await response.blob();
		return URL.createObjectURL(blob);
	};

	const fetchAllImages = async (allEmployees: User[]) => {
		const imagePromises = allEmployees.map(async employee => {
			const userName = employee.userName?.split("@")[0];
			const image = await fetchUserImage(userName);
			return { image, userName };
		});
		return Promise.all(imagePromises);
	};
	
	const fetchAllUserProfilePictures = async (allEmployees: User[]) => {
		setIsImagesLoading(true);
		fetchAllImages(allEmployees)
			.then(allImages => {
				dispatch({ type: "GET_ALL_IMAGES", all_images: allImages });
				setIsImagesLoading(false);
			})
			.catch(error => {
				console.error("Error fetching images:", error);
			});
			// Set a timeout to stop loading after 60 seconds, in case .then never runs and all images fail to load
			setTimeout(() => {	
				setIsImagesLoading(false);
			}, 60000);
	}

	authProvider.initialize();

	useEffect(() => {
		//Check if path is yodeck path 
		const url = window.location.href;
		if (url.includes("/#/flight-schedule-view") || url.includes("/#/staff-management/yodeck") || url.includes("ToolsBayTwoMonitor")) {
			setDrawerOpen(false);
		}
		if (window.screen.width < 1100) {
			setDrawerOpen(false);
		}
		if (url.includes("LoanedToolsForToLong") || url.includes("flight-schedule-view") || url.includes("ToolsBayTwoMonitor")) {
			setDrawerOpen(false);
			setLoading(false);
			const res = {
				"display_name": "Maintener",
				"job_title": "ITS",
				"user_image": "https://maintener.icelandair.is/images/unknown@icelandair.is.jpg"
			}
			dispatch({ type: "USER_LOGIN", me: res });
			userApi.getAllUsers().then(response => {
				dispatch({ type: "GET_ALL_EMPLOYEES", all_employees: response });
			});
		} else {
			console.log("auth");
			const authenticateUser = async () => {
				try {
					let authResult = await authProvider.handleRedirectPromise();
					let accountObj;
					if (authResult) {
						console.log(`setting token in localstorage`, authResult?.accessToken?.length);
						localStorage.setItem("token", authResult.accessToken);
						accountObj = authResult.account;
					} else {
						accountObj = authProvider.getAllAccounts()[0];
					}
					if (accountObj && authResult) { }
					else if (accountObj) {
						try {
							console.log("authProvider.acquireTokenSilent...");
							authResult = await authProvider.acquireTokenSilent({
								account: authProvider.getAllAccounts()[0],
								scopes: ["user.read"]
							});
							console.log("accessToken.length:", authResult?.accessToken?.length);
							console.log("authResult.expiresOn:", authResult?.expiresOn);
							console.log("authResult.extExpiresOn:", authResult?.extExpiresOn);
							console.log("username:", authResult?.account?.username);
						} catch (err) {
							console.log("Auth error:", err);
							await authProvider.acquireTokenRedirect({ scopes: ["user.read"] });
						}
					}
					else {
						await authProvider.loginRedirect({ scopes: ["user.read"] });
					}
					if (authResult && authResult.accessToken) {
						localStorage.setItem("token", authResult.accessToken);
						setLineAndLiteFiltersToStore();

						if (authResult.account!.username === "itssecurity@icelandair.is") {
							setLoginDigitDialogOpen(true);
							userApi.getAllUsers().then(response => {
								dispatch({ type: "GET_ALL_EMPLOYEES", all_employees: response });
							})
						} else {
							//All Users excep the its security
							userApi.getUserByUserName().then(res => {
								dispatch({ type: "USER_LOGIN", me: res.me });
								userApi.getAllUsers().then(response => {
									dispatch({ type: "GET_ALL_EMPLOYEES", all_employees: response });
									fetchAllUserProfilePictures(response);
									setLoading(false);
								})
							})
						}

						toolsApi.getAllToolsFromMxi("empty", false).then(res => {
							dispatch({ type: 'ON_MXI_TOOLS', tools: res });
						})
					}
				} catch (err) {
					console.error("authenticateUser error: ", err);
				}
			}
			authenticateUser();
		}
	}, [dispatch]);

	const onDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	}
	const onAddNumber = (value: string) => {
		const fullPin = userPin + value;
		setUserPin(fullPin);
		if (fullPin.length === 4) {
			findUserByUserName(fullPin);
		} else {
			setUserPin(fullPin);
			setErrorMessage("");
		}
	}
	const onNumberPadKeyboardEdited = (value: string) => {
		if (value.length === 4) {
			findUserByUserName(value);
		} else {
			setUserPin(value);
		}
	}

	const setLineAndLiteFiltersToStore = () => {
		const currentDate = new Date();
		const lineDateFrom = DateTime.fromJSDate(currentDate).minus({ days: 30 });
		const lineDateTo = DateTime.fromJSDate(currentDate).plus({ days: 30 });

		dispatch({
			type: "LINE_WORK_PACKAGE_OVERVIEW_FILTERS",
			linefilters: {
				dateFrom: lineDateFrom.toJSDate(),
				dateTo: lineDateTo.toJSDate(),
				tail: "",
				filterByHangers: [hangarLineOptions.kefLine],
				filterByStatus: [statusOptions.commit],
				dueDateHourCycles: false,
				dueDateExceedsFifteen: false,
			}
		});

		const liteDateFrom = DateTime.fromJSDate(currentDate).minus({ weeks: 1 });
		const liteDateTo = DateTime.fromJSDate(currentDate).plus({ weeks: 3 });

		dispatch({
			type: "LITE_WORK_PACKAGE_OVERVIEW_FILTERS",
			litefilters: {
				dateFrom: liteDateFrom.toJSDate(),
				dateTo: liteDateTo.toJSDate(),
				tail: "",
				filterByHangers: [hangarLiteOptions.kefHangarBay4, hangarLiteOptions.kefHangarBay5, hangarLiteOptions.liteAirsite],
				filterByStatus: [statusOptions.commit],
				dueDateHourCycles: false,
				dueDateExceedsFifteen: false,
			}
		});
	}

	const findUserByUserName = (pin: string) => {
		const findEmployee = store.employees.all_employees.find(s => s.login_pin === pin);
		if (findEmployee !== undefined) {
			userApi.getUserByUserName().then(res => {
				dispatch({ type: "USER_LOGIN", me: res.me });
				setLoading(false);
				userApi.getAllUsers().then(response => {
					dispatch({ type: "GET_ALL_EMPLOYEES", all_employees: response });
					setLoginDigitDialogOpen(false);
					setLoading(false);
					setUserPin("");
					setErrorMessage("");
				})
			})
		}
		else {
			setUserPin("");
			setErrorMessage("Incorrect pin");
		}
	}

	const generateRoute = (accessGroups: string[], route: string, component: React.ReactNode) => {
		if ((routes === undefined)) {
			return (
				<Route path={route} element={component} />
			)
		}
		else if (routes.some(r => accessGroups.includes(r)) || accessGroups.length === 0) {
			return (
				<Route path={route} element={component} />
			)
		}
		else {
			return <Route path={route} element={<NotAuthorized />} />;
		}
	}
	if (loginDigitDialogOpen === true) {
		return (
			<Dialog
				visible={loginDigitDialogOpen}
				max_width={"sm"} title={"Please enter your pin"}
				context={
					<Grid>
						<LoginDigitsPin onAddNumber={(value: string) => onAddNumber(value)} errorMessage={errorMessage} onRemoveNumber={() => setUserPin(userPin.slice(0, -1))} onClearAllNumber={() => setUserPin("")} pin={userPin} />
						<Grid container justifyContent='center'><Input multiline={false} help_text={"Only numbers"} label_text={"Please write your pin"} value={userPin} onTextChange={(value: string) => onNumberPadKeyboardEdited(value)} type="password" width={40} /></Grid>
					</Grid>
				}
				onClose={(status: any) => setLoginDigitDialogOpen(false)}
				strict={false}
				show_options={false}
			/>
		)
	}
	return (
		<main
			className={clsx(classes.content, {
				[classes.contentShift]: drawerOpen,
			})}
			id="main"
		>
			<div className={classes.root}>
				{loading === false ? <Container className={classes.container} maxWidth='xl' id="main-container">
					<HashRouter>
						<Header onDrawerToggle={() => onDrawerToggle()} />
						<Draw onDrawerToggle={() => onDrawerToggle()} open={drawerOpen} />
						{isImagesLoading && <LinearProgress /> }
						<div>
							<Routes>
								{generateRoute(["Developers", "Access Control Admin"], "/access-control", <AccessControl/>)}
								{generateRoute(["Certificates View", "Certificates Create", "Certificates Approver"], "/certificates", <Certificates/>)}
								{generateRoute(["Extra Payment User", "Extra Payment Supervisor", "Extra Payment Admin"], "/extra-payments", <ExtraPayments/>)}
								{generateRoute(["Clothes Request", "Clothes Request - View"], "/clothing-request", <ClothingRequests/>)}
								{generateRoute(["Course Managers"], "/courses", <Courses/>)}
								{generateRoute(["Hangar Overview"], "/hangar-overview", <HangarOverview/>)}
								{generateRoute([], "/my-profile", <MyProfilePage/>)}
								{generateRoute(["Line Planner", "Line Planner View", "Line Supervisor Edit", "Line Supervisor View"], "/line/:id?", <Line/>)}
								{generateRoute(["Lite Edit", "Lite View"], "/lite", <Lite/>)}
								{generateRoute(["Tool Register"], "/tool-register", <ToolRegister/>)}
								{generateRoute(["World Tools Edit", "World Tools View"], "/world-tools", <WorldTools/>)}
								{generateRoute(["Tool Stock View", "Tool Stock"], "/tool-stock", <ToolStock/>)}
								{generateRoute(["Vacation User", "Vacation Admin"], "/vacations", <Vacations/>)}
								{generateRoute(["Staff Management Edit", "Staff Management View"], "/staff-management", <StaffManagement/>)}
								{generateRoute(["Security Edit", "Security View"], "/security", <Security/>)}
								{generateRoute(["Warranty Edit", "Warranty View"], "/warranty", <Warranties/>)}
								{generateRoute(["Time Barcodes Edit", "Time Barcodes View"], "/time-control-barcodes", <TimeControlBarcodes/>)}
								{generateRoute(["Rvk Line Edit", "Rvk Line View"], "/line-rvk", <ReykjavikLineSupervisor/>)}
								{generateRoute(["Task Assignment Edit", "Task Assignment View"], "/task-assignment", <CrewPlan/>)}
								{generateRoute(["Bay Handover View", "Bay Handover Edit"], "/bay-handover", <BayHandover/>)}
								{generateRoute(["Capability Edit", "Capability View"], "/capability", <Capability/>)}
								{generateRoute(["Mechanic Skills Edit"], "/skills", <MechanicInfo/>)}

								{generateRoute(["Maintenance Reports Edit", "Maintenance Reports View"], "/maintenance-reports",<MaintenanceReports/>)}
								{generateRoute([], "/ToolsBayTwoMonitor", <ToolsBayTwoMonitor/>)}
								{generateRoute([], "/LoanedToolsForToLong", <LoanedToolForToLong/>)}
								{generateRoute([], "/flight-schedule-view", <FlightScheduleView/>)}
								{generateRoute(["Staff Management Edit", "Staff Management View"], "/staff-management/yodeck", <StaffManagement/>)}
								{generateRoute(["Hangar Plan View", "Hangar Plan Edit"], "/HangarPlan", <HangarPlan/>)}
								<Route path="*" element={<Navigate to="/hangar-overview" replace />} />
							</Routes>
						</div>
					</HashRouter>
				</Container>
					:
					<Typography text={"Loading . . ."} />
				}
			</div>
		</main>
	);
}

export default App;

console.log("Successfully rendered React app App.tsx ...");