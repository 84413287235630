
  const hangarLiteOptions = {
    kefHangar: "KEF/HANGAR",
    kefHangarBay1: "KEF/HANGAR/BAY 1",
    kefHangarBay2: "KEF/HANGAR/BAY 2",
    kefHangarBay3: "KEF/HANGAR/BAY 3",
    kefHangarBay4: "KEF/HANGAR/BAY 4",
    kefHangarBay5: "KEF/HANGAR/BAY 5",
    kefHangarBay6: "KEF/HANGAR/BAY 6",
    kefHangarBay7: "KEF/HANGAR/BAY 7",
    kefHangarBayH885: "KEF/HANGAR/H885",
    liteAirsite: "LITE/AIRSITE",
  } as const;

  export {
    hangarLiteOptions
}