import { FunctionComponent } from 'react';
import { Tabs, Tab, Grid, IconButton } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Notifications } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  },
  fixedContainer: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(1),
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    zIndex: 1
  },
  tabContainer: {
    height: '60px'
  },
  fixedTabContainer: {
    height: 'unset'
  },
  shiftContent: {
    height: '50px'
  }

}));
type TabsUIProps = {
  fixedTabs?: boolean
  tabNames: string[],
  tabValue: number,
  variant?: "scrollable" | "fullWidth" | "standard",
  onChangeTabValue: Function,
  isMessageRead?: Array<Boolean>
  hideTabs?: number[]
}

// This is the main tab component for tabs on top of pages/components. By using variant fullwidth it can be used for tabs in dialogs as well.
// For tabs that can be closed/opened, use the ActionTabs component.
const TabsUI: FunctionComponent<TabsUIProps> = ({
  fixedTabs = false,
  tabNames,
  onChangeTabValue,
  variant = "standard",
  tabValue,
  isMessageRead,
  hideTabs = []
}) => {
  const classes = useStyles();

  // Hide tabs only set's the display as none, so to make sure no one can display the tab through devtools, here we only change tabs if the selected tab should not be hidden
  const handleChange = (event: any, new_value: number) => {
    if (!hideTabs.includes(new_value)) {
      onChangeTabValue(new_value);
    }
  };

  return (
    <Grid>
      <Grid className={fixedTabs ? classes.fixedContainer : classes.container}>
        <Tabs
          className={fixedTabs ? classes.fixedTabContainer : classes.tabContainer}
          value={tabValue}
          indicatorColor="primary"
          onChange={handleChange}
          variant={variant}
        >
          {
            tabNames.map((name, index) => (
              isMessageRead === undefined ?
                <Tab label={name} key={index}
                  style={hideTabs.includes(index) === true ? { display: 'none' } : {}}
                  iconPosition="end" />
                :
                <Tab label={name}
                  style={hideTabs.includes(index) === true ? { display: 'none' } : {}}
                  icon={
                    isMessageRead[index] === false ?
                      <IconButton><Notifications color='primary' /></IconButton>
                      :
                      ""
                  }
                  iconPosition="end" />
            ))
          }
        </Tabs>
      </Grid>
      {fixedTabs &&
        <Grid className={classes.shiftContent} />
      }
    </Grid>
  );
};
export default TabsUI;
