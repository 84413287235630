import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './../../reducer';
import { Grid } from '@mui/material';
import { LinearProgress, Tabs } from '../../MaterialUiComponents';
import AllClothingRequests from './AllClothingRequests';
import AddNewClothingRequests from './AddNewClothingRequests';
import { EmployeeSearch } from '../../components';
import ClothingOverview from './ClothingOverview';

function CLothingRequests() {
  const store = useSelector((state: RootState) => state);
  const isClothingRequestAdmin = store.user.me.user_clearence_groups.includes("Clothes Request");
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [employee_id, setEmployeeId] = useState(-1);
  const tabNames = isClothingRequestAdmin ? ["All clothing requests", "Overview", "New Clothing requests"] : ["All clothing requests", "Overview"];

  return (
    <>
      <Grid xs={12} item>
        {loading === true && <LinearProgress />}
        <Grid container xs={12}>
          <Grid xs={6}>
            <Tabs
              tabNames={tabNames}
              onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
              tabValue={tabValue}
            />
          </Grid>
          <Grid xs={6}>
            {tabValue === 0 && <Grid item xs={6}> <EmployeeSearch place_holder={"Write employee name"} onSelectedEemployee={(employee_id: number) => setEmployeeId(employee_id)} /> </Grid>}
          </Grid>
        </Grid>
        {tabValue === 0 && <AllClothingRequests searched_user_id={employee_id} setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
        {tabValue === 1 && <ClothingOverview isLoading={loading} setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
        {tabValue === 2 && <AddNewClothingRequests setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)} />}
      </Grid>
    </>
  );
};
export default CLothingRequests