import { useEffect, useState, FunctionComponent } from 'react';
import Grid from '@mui/material/Grid2';
import { TableRow, TableCell, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography, Table, Dialog } from '../../MaterialUiComponents';
import lineApi from '../../api/line';
import { AllLineHandoverReports, LineHandoverReturnReportObj } from '../../types/line';
import { Edit, Search, Visibility } from '@mui/icons-material';
import HandoverDialogContext from './HandoverDialogContext';

const useStyles = makeStyles(theme => ({
  evenRows: {
    backgroundColor: theme.palette.action.hover
  },
}));

type LineHandoverProps = {
  allTails: Array<string>,
  isLineSupervisorAdmin: Boolean
}

const LineHandover: FunctionComponent<LineHandoverProps> = ({ allTails, isLineSupervisorAdmin }) => {

  const classes = useStyles();
  const [reports, setReports] = useState(Array<AllLineHandoverReports>());
  const [handoverDialogOpen, setHandoverDialogOpen] = useState(false);
  const [lineHandoverReport, setLineHandOverReport] = useState<LineHandoverReturnReportObj>();

  useEffect(() => {
    lineApi.getAllLineHandoverReports().then(res => {
      setReports(res.data);
    });
  }, [handoverDialogOpen]);

  const refresh = (reportId: number) => {
    lineApi.getLineHandoverReportByID(reportId).then(res => {
      if (res.status === true) {
        setLineHandOverReport(res.data);
        setHandoverDialogOpen(true);
      }
    });
  }
  const onClickRow = (reportId: number, type: 'VIEW' | 'EDIT') => {
    lineApi.getLineHandoverReportByID(reportId).then(res => {
      if (res.status === true) {
        setLineHandOverReport(res.data);
        setHandoverDialogOpen(true);

      }
    });
  }
  const onCreateNewHandoverReport = () => {
    lineApi.CreateNewLineHandoverReport().then(res => {
      if (res.status === false) {
        alert("Employee is not registered correctly in Staff Management");
      } else {
        refresh(res.data);
      }
    });
  }

  const generateTableBody = (row: AllLineHandoverReports, index: number) => {
    return (
      <TableRow className={index % 2 ? classes.evenRows : ''} >
        <TableCell>{row.created_date_text} : {row.is_day_shift ? "Day" : "Night"}</TableCell>
        <TableCell>{row.supervisor_name}</TableCell>
        <TableCell>{row.shift_letter}</TableCell>
        <TableCell>{row.issues_count}</TableCell>
        <TableCell>{row.employees_count}</TableCell>
        <TableCell >
          {row.is_submitted && <Search style={{ cursor: 'pointer' }} onClick={() => onClickRow(row.id, "VIEW")} color='primary' />}
          {!row.is_submitted && isLineSupervisorAdmin && <Edit onClick={() => onClickRow(row.id, "EDIT")} color='primary' style={{ cursor: 'pointer' }} />}
          {!row.is_submitted && !isLineSupervisorAdmin && <Visibility onClick={() => onClickRow(row.id, "EDIT")} color='primary' style={{ cursor: 'pointer' }} />}
        </TableCell>
      </TableRow>
    )
  }
  return (
    <Grid>
      <Typography variant={'h1'} align={'center'} text={"Line Handover reports"} />
      {!(reports.filter(s => !s.is_submitted).length > 0) && isLineSupervisorAdmin && <Button color='primary' onClick={() => onCreateNewHandoverReport()}>Create new</Button>}
      {reports.filter(s => s.is_submitted === true).length > 0 && <Grid container justifyContent={'center'}>
      </Grid>
      }
      <Table
        headers={["Created", "Created By", "Shift", "Issues", "Employees", "Submitted"]}
        rows={reports}
        generateBody={(row: any, index: number) => generateTableBody(row, index)}
        defaultRowsPerPage={25}
        pagination={true}
        skipIndexCol={true}
      />
      <Dialog visible={handoverDialogOpen} max_width={"xl"} strict={false} show_options={false}
        onClose={(status: any) => setHandoverDialogOpen(false)}
        title={"Create new Handover"}
        context={
          <HandoverDialogContext isLineSupervisorAdmin={isLineSupervisorAdmin} allTails={allTails} lineHandoverReport={lineHandoverReport!} refresh={(id: number) => refresh(id)} onClose={() => setHandoverDialogOpen(false)} />
        }
      />
    </Grid>
  );
};
export default LineHandover;