import { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Dialog, Typography, Input } from './../../MaterialUiComponents';

const useStyles = makeStyles(theme => ({
  daysAllowedContainer: {
    marginLeft: "35%"
  }
}));

interface allowedDaysProps {
  visible: boolean,
  closeAllowedDaysDialog: Function,
  tool_description: string,
  days_allowed: string,
  onDaysAllowedChange: Function
}

const EditAllowedDays: FunctionComponent<allowedDaysProps> = ({ visible, tool_description, closeAllowedDaysDialog, days_allowed, onDaysAllowedChange }) => {
  const classes = useStyles();
  return (
    <Dialog
      visible={visible}
      max_width={"md"} title={"Change allowed days"}
      context={
        <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
          <Typography variant="body1" align="center" text={tool_description} />
          <Grid className={classes.daysAllowedContainer}>
            <Input multiline={false} help_text={"Only numbers"} label_text={"How many days allowed"} value={days_allowed} onTextChange={(days: number) => onDaysAllowedChange(days)} type="number" width={40} />
          </Grid>
        </Grid>
      }
      onClose={(status: any) => closeAllowedDaysDialog(status)}
      strict={false}
      show_options={true}
    />
  );
};
export default EditAllowedDays