import { FunctionComponent } from 'react';
import { Grid, Button } from '@mui/material';
import { Backspace } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Typography } from './../../MaterialUiComponents';

const useStyles = makeStyles(theme => ({
  numpadText: {
    fontSize: '1.5rem'
  }
}));

type LoginDigitsPinProps = {
  pin: string,
  onAddNumber: Function,
  onRemoveNumber: Function,
  onClearAllNumber: Function,
  errorMessage: string
}
const LoginDigitsPin: FunctionComponent<LoginDigitsPinProps> = ({ pin, onAddNumber, onRemoveNumber, onClearAllNumber, errorMessage }) => {
  const classes = useStyles();
  return (
    <Grid>
      <Typography text={pin.replace(/./g, '*')} align='center' variant='h3' fontWeight='bolder' />
      <Typography text={errorMessage} align='center' fontWeight='bolder' color='error' />
      <Grid item xs={12} container>
        <Grid item xs={3}></Grid>
        <Grid container xs={6} justifyContent='center' >
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("1")}>1</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("2")}>2</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("3")}>3</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("4")}>4</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("5")}>5</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("6")}>6</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("7")}>7</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("8")}>8</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("9")}>9</Button> </Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onClearAllNumber()}>A/C</Button></Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onAddNumber("0")}>0</Button></Grid>
          <Grid item xs={4} container justifyContent='center'><Button className={classes.numpadText} onClick={() => onRemoveNumber()}><Backspace /></Button></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LoginDigitsPin




